import { PureComponent } from 'react'
import { CabinetContext } from 'components/app/cabinet/context'
import { Provider } from '../../provider'
import { Graph } from './graph'
import regionSelectIcon from './images/select.svg'
import { SelectInput as RegionsInput, SelectInput as TypesInput } from './select'
import { Component, Bar, SearchInput, Head, Title, Counter, Button, Spacer, SelectInput } from './default.styled'
import { store } from 'store'
import { action, makeObservable, observable } from 'mobx'
import { InviteFailurePopup, InvitePopup, InviteSuccessPopup } from './popups'
import { observer } from 'mobx-react'
import { actions } from 'actions'
import { Loader } from 'components/ui'
import { createScrollAutoloader } from 'utils'

const typesOptions = [
    ['all', 'Все провайдеры'],
    ['valid', 'Верифицированные'],
    ['novalid', 'Неверифицированные'],
    ['isSpec', 'QR провайдеры'],
    ['drafts', 'Черновики (приглашенные)'],
    ['block', 'Заблокированные'],
] as [string, string][]

const orderByOptions = [
    ['name', 'Название'],
    ['balance', 'Баланс'],
    ['subscriptionFee', 'Абонентская плата'],
    ['createdAt', 'Дата регистрации'],
    ['rate', 'Рейтинг'],
] as [string, string][]

type ProviderKind = 'valid' | 'novalid' | 'isSpec' | 'drafts' | 'block'
type ProviderOrderBy = 'name' | 'balance' | 'subscriptionFee' | 'createdAt' | 'rate'

@observer
export class Default extends PureComponent {
    static contextType = CabinetContext

    @observable
    private invitePopupVisibility = false
    @observable
    private inviteSuccessPopupVisibility = false
    @observable
    private inviteFailurePopupVisibility = false
    @observable
    private query = store.providers.getParams().query

    private detachScrollListener!: () => void

    constructor(props: {}) {
        super(props)
        makeObservable(this)
    }

    componentDidMount() {
        this.context.setTitle('Список провайдеров')

        store.providers.resolve()

        this.detachScrollListener = createScrollAutoloader(store.providers.resolveMore)
    }

    @action
    toggleInvitePopup(visibility?: boolean) {
        this.invitePopupVisibility = arguments.length ? visibility! : !this.invitePopupVisibility
    }

    @action
    toggleInviteSuccessPopup(visibility?: boolean) {
        this.inviteSuccessPopupVisibility = arguments.length ? visibility! : !this.inviteSuccessPopupVisibility
    }

    @action
    toggleInviteFailurePopup(visibility?: boolean) {
        this.inviteFailurePopupVisibility = arguments.length ? visibility! : !this.inviteFailurePopupVisibility
    }

    async sendInvite(email: string, inn: string) {
        try {
            await actions.inviteProvider(email, inn)
            this.toggleInvitePopup(false)
            this.toggleInviteSuccessPopup()
        } catch (e) {
            if (e === 'EMAIL_ALREADY_EXIST') {
                throw e
            } else {
                this.toggleInviteFailurePopup()
            }
        }
    }

    handleRegionChange(region: number) {
        store.providers.setParams({ region })
    }

    handleKindChange(kind: ProviderKind) {
        store.providers.setParams({ kind })
    }

    handleOrderChange(orderBy: ProviderOrderBy) {
        store.providers.setParams({ orderBy })
    }

    private updateQueryTimer!: NodeJS.Timeout

    @action
    handleQueryChange(query: string) {
        this.query = query

        clearTimeout(this.updateQueryTimer)

        this.updateQueryTimer = setTimeout(() => store.providers.setParams({ query }), 500)
    }

    componentWillUnmount() {
        this.detachScrollListener()
    }

    render() {
        if (!store.providers.isResolved()) {
            return <Loader />
        }

        const { providers, length } = store.providers.get()
        const { region, kind, orderBy } = store.providers.getParams()
        const regionsOptions = [[0, 'Все регионы']].concat(store.regions.get().map((r) => [r.id, r.name])) as [
            number,
            string
        ][]
        const user = store.user.get()!

        return (
            <Component>
                {this.invitePopupVisibility && (
                    <InvitePopup
                        onClose={() => this.toggleInvitePopup(false)}
                        onSend={(email, inn) => this.sendInvite(email, inn)}
                    />
                )}
                {this.inviteSuccessPopupVisibility && (
                    <InviteSuccessPopup onClose={() => this.toggleInviteSuccessPopup(false)} />
                )}
                {this.inviteFailurePopupVisibility && (
                    <InviteFailurePopup onClose={() => this.toggleInviteFailurePopup(false)} />
                )}
                <Bar>
                    <RegionsInput
                        options={regionsOptions}
                        value={region}
                        icon={regionSelectIcon}
                        onChange={(v) => this.handleRegionChange(v)}
                    />
                    <TypesInput options={typesOptions} value={kind} onChange={(v) => this.handleKindChange(v)} />
                    <SearchInput
                        value={this.query}
                        placeholder="Поиск"
                        onChange={(e) => this.handleQueryChange(e.target.value)}
                    />
                </Bar>
                <Graph />
                <Head>
                    <Title>Провайдеры</Title>
                    <Counter>{length}</Counter>
                    <Spacer />
                    <SelectInput
                        options={orderByOptions}
                        value={orderBy}
                        onChange={(v) => this.handleOrderChange(v)}
                        small
                    />
                    {['admin', 'provider'].includes(user.type) && (
                        <Button onClick={() => this.toggleInvitePopup()}>+ Пригласить</Button>
                    )}
                </Head>
                {providers.map((provider) => (
                    <Provider key={provider.id} data={provider} />
                ))}
            </Component>
        )
    }
}
