export const formatMoney = (value: number) => {
    return new Intl.NumberFormat('ru', { style: 'currency', currency: 'RUB' }).format(value)
}

export const formatNumber = (value: number) => {
    return new Intl.NumberFormat('ru', { style: 'decimal' }).format(value)
}

export const formatPhone = (value: string) => {
    return value.replace(/^\+7([0-9]{3})([0-9]{3})([0-9]{2})([0-9]{2})$/, '+7 ($1) $2-$3-$4')
}
