import { request } from './request'

type AdStatus = 'all' | 'active' | 'archive' | 'blocked'
type AdOrder = 'countView' | 'createdAt' | 'price' | 'countClaim'
type DetailType =
    | 'drafted'
    | 'activated'
    | 'archivated'
    | 'claimed'
    | 'blocked'
    | 'unblocked'
    | 'reactivated'
    | 'removed'
    | 'saved'

export const list = (
    limit: number,
    offset: number,
    params: { year: number; regionId: number; status: AdStatus; hasClaim: boolean; orderBy: AdOrder }
) => {
    return request<ListResponse>('sales/list', { limit, offset, ...params })
}

export const details = (limit: number, offset: number, id: string) => {
    return request<DetailsResponse>('sales/details', { limit, offset, id })
}

export const lock = (id: string, detail: string) => {
    return request<BlockResponse>('sales/blocked', { id, detail })
}

export const unlock = (id: string) => {
    return request<BlockResponse>('sales/unblocked', { id })
}

interface ListResponse {
    chart: Chart
    sales: Sale[]
    length: number
}

interface DetailsResponse {
    details: Detail[]
    length: number
}

interface BlockResponse {}

interface Detail {
    type: DetailType
    detail: string
    createdAt: string
    user: User
}

interface User {
    id: number
    type: string
    name: string
    avatar: string
}

interface Sale {
    id: string
    user: User
    section: string
    sectionName: string
    category: string
    categoryName: string
    name: string
    description: string
    price: number
    files: File[]
    addressId: number
    address: Address
    canChat: boolean
    canCall: boolean
    status: 'active' | 'archive' | 'blocked'
    lastDetail: LastDetail
    countView: number
    createdAt: string
    updatedAt: string
    hasClaim: boolean
    countClaim: number
    detailBlock: string
}

interface LastDetail {
    type: string
    detail: string
    createdAt: string
    user: User
}

interface Address {
    city: string
    address: string
}

interface File {
    link: string
    name: string
    mimeType: string
    size: string
    createdAt: string
    updatedAt: string
}

interface User {
    id: number
    type: string
    name: string
    avatar: string
}

interface Chart {
    current: Current[]
}

interface Current {
    month: number
    count: number
}
