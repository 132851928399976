import { observer } from 'mobx-react'
import { store } from 'store'
import { formatNumber } from 'utils'
import { Component, ChartBlock, Amount, Desk, Counters, Counter, MobileBlock } from './stats.styled'

export const Stats = observer(() => {
    const { data } = store.dashboard.get()

    return (
        <Component>
            <ChartBlock color="blue">
                <Amount>{formatNumber(data.countSignUp)}</Amount>
                <Desk>Количество регистраций пользователей</Desk>
            </ChartBlock>
            <ChartBlock color="green">
                <Amount>{formatNumber(data.countRePayment)}</Amount>
                <Desk>Среднее количество повторных оплат</Desk>
            </ChartBlock>
            <ChartBlock color="yellow">
                <Amount>{formatNumber(data.countAccounts)}</Amount>
                <Desk>Среднее значение лицевых счётов провайдеров</Desk>
            </ChartBlock>
            <MobileBlock>
                <Counters>
                    <Counter type="ios">{formatNumber(data.countIos)}</Counter>
                    <Counter type="android">{formatNumber(data.countAndroid)}</Counter>
                </Counters>
                <Desk>Количество пользователей на мобильных платформах</Desk>
            </MobileBlock>
        </Component>
    )
})
