import { Button, Heading, Popup } from 'components/ui'
import { PureComponent } from 'react'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Desc, Buttons, SelectInput } from './lock.styled'

interface LockPopupProps {
    onConfirm: (reason: string) => void
    onClose: () => void
}

const options = [
    'Ошибки в оформлении',
    'Спам или неуместный контент',
    'Действия сексуального характера',
    'Враждебные высказывания, насилие, травля или преследование ',
    'Продажа незаконных товаров или услуг',
    'Нарушение авторских прав',
    'Мошенничество, обман или ложная информация',
].map((s) => [s, s]) as [string, string][]

@observer
export class LockPopup extends PureComponent<LockPopupProps> {
    @observable
    private reason = options[0][0]

    constructor(props: LockPopupProps) {
        super(props)
        makeObservable(this)
    }

    @action
    handleReasonChange(reason: string) {
        this.reason = reason
    }

    render() {
        const { onConfirm, onClose } = this.props

        return (
            <Popup width={655}>
                <Heading>Заблокировать объявление?</Heading>
                <Desc>
                    Данное действие скроет объявление из списка всех объявлений и пользователю будет направлено
                    уведомление для устранения неточностей.
                </Desc>
                <SelectInput
                    options={options}
                    value={this.reason}
                    onChange={(v) => this.handleReasonChange(v)}
                    label="Причина блокировки"
                />
                <Buttons>
                    <Button secondary onClick={onClose} width={170}>
                        Отмена
                    </Button>
                    <Button onClick={() => onConfirm(this.reason)} red width={170}>
                        Заблокировать
                    </Button>
                </Buttons>
            </Popup>
        )
    }
}
