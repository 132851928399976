import { api } from 'api'
import { store } from 'store'

let noticesWatcherId: NodeJS.Timeout

export const runNoticesWatcher = async () => {
    try {
        const data = await api.notification.list(20, 0)

        store.notices.sync(data)
    } catch (e) {
        console.log('Error sync notifications')
    }

    noticesWatcherId = setTimeout(runNoticesWatcher, 60 * 1000)
}

export const stopNoticesWatcher = () => {
    clearTimeout(noticesWatcherId)
}

export const removeNotice = async (id: number) => {
    store.notices.remove(id)
    await api.notification.remove(id)
}

export const readAllNotices = async () => {
    store.notices.readAll()
    await api.notification.read()
}
