import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Component = styled.div`
    border-top: 1px solid #eaecf0;
`

export const Thead = styled.div`
    height: 54px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #707b8e;
    display: flex;
    align-items: center;

    & > span {
        &:nth-child(1) {
            flex: 1;
        }
        &:nth-child(2) {
            flex: 0 0 244px;
        }
        &:nth-child(3) {
            flex: 0 0 160px;
        }
        &:nth-child(4) {
            flex: 0 0 144px;
        }
    }
`

export const Row = styled.div`
    height: 64px;
    background: #f6faf8;
    display: flex;
    align-items: center;
    padding: 0 14px;

    & > span {
        &:nth-child(1) {
            flex: 1;
        }
        &:nth-child(2) {
            flex: 0 0 230px;
        }
        &:nth-child(3) {
            flex: 0 0 160px;
        }
        &:nth-child(4) {
            flex: 0 0 130px;
        }
    }
`

export const Datetime = styled.span`
    & > span:nth-child(2) {
        color: #b7bcd6;
        margin-left: 4px;
    }
`

export const Balance = styled.span<{ isPositive: boolean }>`
    color: ${(props) => (props.isPositive ? '#68C376;' : '#EB5757')};
`

export const Button = styled(Link)`
    width: 134px;
    padding: 0 11px;
    height: 37px;
    background: #68c376;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;

    :hover {
        background: #68c376;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    }

    :active {
        background: #68c376;
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
        border-radius: 8px;
    }

    :disabled {
        background: #68c376;
        opacity: 0.5;
        border-radius: 8px;
    }
`
