import styled from 'styled-components'
import { SelectInput as UISelectInput } from 'components/ui'

export const Component = styled.div``

export const Head = styled.div`
    display: flex;
    align-items: center;
    column-gap: 15px;
    margin: 24px 0;
`

export const Title = styled.div`
    flex: 1;
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 124.7%;
    color: #39434b;
`

export const SelectInput = styled(UISelectInput)`
    width: 230px;
`

export const List = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`
