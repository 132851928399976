import { observable, makeObservable, action } from 'mobx'
import { observer } from 'mobx-react'
import { ChangeEvent, PureComponent } from 'react'
import { Component, Label, Input, Error } from './textarea.styled'

interface TextareaInputProps {
    label?: string
    value?: string
    placeholder?: string
    onChange?: (e: ChangeEvent<HTMLTextAreaElement>) => void
    error?: string | boolean
}

@observer
export class TextareaInput extends PureComponent<TextareaInputProps> {
    @observable
    private focused = false

    constructor(props: TextareaInputProps) {
        super(props)
        makeObservable(this)
    }

    render() {
        const { label, value, placeholder, onChange, error, ...other } = this.props
        const err = !this.focused && error

        return (
            <Component {...other}>
                <Label>{label}</Label>
                <Input
                    value={value}
                    onChange={onChange}
                    placeholder={placeholder}
                    error={!!err}
                    onFocus={action(() => (this.focused = true))}
                    onBlur={action(() => (this.focused = false))}
                />
                <Error>{err}</Error>
            </Component>
        )
    }
}
