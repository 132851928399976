import { api } from 'api'
import { observable } from 'mobx'

const STORE = observable.box<RegionsData>()

export const get = () => {
    return STORE.get()
}

export const isResolved = () => {
    return !!STORE.get()
}

export const resolve = async () => {
    const { regions } = await api.address.regionList()

    STORE.set(regions)
}

export type RegionsData = RegionsItemData[]

export interface RegionsItemData {
    id: number
    name: string
    countAddresses: number
    countClients: number
    countSales: number
    countPayments: number
    partner: Partner | null
}

interface Partner {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}
