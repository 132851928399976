import { withRouter, match } from 'react-router'
import { Link } from 'react-router-dom'
import { CabinetContext } from 'components/app/cabinet/context'
import { Component, Breadcrumbs, Title, Label, Button, Customer, Avatar, Name, Spacer } from './history.styled'
import { PureComponent } from 'react'
import { store } from 'store'
import { Payment } from './payment'
import { observer } from 'mobx-react'
import { avatarUrl, formatMoney } from 'utils'
import { Loader } from 'components/ui'
import { createScrollAutoloader } from 'utils'

interface HistoryProps {
    match?: match<{ id: string }>
}

@(withRouter as any)
@observer
export class History extends PureComponent<HistoryProps> {
    static contextType = CabinetContext

    private detachScrollListener!: () => void

    get customerId() {
        return parseInt(this.props.match!.params.id)
    }

    componentDidMount() {
        this.context.setTitle('История оплат пользователя')

        const { customerId } = this

        store.customer.resolve(customerId)
        store.customer.payments.resolve(customerId)

        this.detachScrollListener = createScrollAutoloader(() => store.customer.payments.resolveMore(customerId))
    }

    componentWillUnmount() {
        this.detachScrollListener()
    }

    render() {
        const { customerId } = this

        if (!store.customer.isResolved(customerId) || !store.customer.payments.isResolved(customerId)) {
            return <Loader />
        }

        const { id, avatar, type, totalPayments, first, last, patronymic } = store.customer.get(customerId)!
        const { payments } = store.customer.payments.get(customerId)!

        return (
            <Component>
                <Breadcrumbs>
                    <Link to="/payings">История оплат</Link>
                    <span>ID{id}</span>
                </Breadcrumbs>
                <Customer>
                    <Avatar src={avatarUrl(avatar)} />
                    <Name>
                        {last} {first} {patronymic}
                    </Name>
                    <Label type={type} />
                    <Spacer />
                    <Button to={`/support/create/user/${id}`}>+ Создать тикет</Button>
                </Customer>
                <Title>
                    <b>Оборот</b>
                    <span>{formatMoney(totalPayments)}</span>
                </Title>
                {payments.map((data) => (
                    <Payment key={data.id} data={data} />
                ))}
            </Component>
        )
    }
}
