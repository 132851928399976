import { api } from 'api'
import { observable, runInAction } from 'mobx'

const STORE = observable.map<number, CustomerPaymentsData>()

export const isResolved = (id: number) => {
    return STORE.has(id)
}

export const get = (id: number) => {
    return STORE.get(id)
}

const BUSY_MAP = new Map<number, boolean>()

export const resolve = async (id: number) => {
    const data = await api.payment.paymentClientList(20, 0, id)

    runInAction(() => STORE.set(id, data))

    BUSY_MAP.set(id, false)
}

export const resolveMore = async (id: number) => {
    if (!isResolved(id)) return

    if (!BUSY_MAP.get(id)) {
        BUSY_MAP.set(id, true)

        const { payments } = get(id)!
        const offset = payments.length
        const data = await api.payment.paymentClientList(20, offset, id)

        if (data.payments.length) {
            BUSY_MAP.set(id, false)

            runInAction(() => payments.push(...data.payments))
        }
    }
}

export interface CustomerPaymentsData {
    payments: CustomerPaymentsItemData[]
    length: number
}

export interface CustomerPaymentsItemData {
    id: number
    createdAt: string
    clientId: number
    client: Client
    type: 'apple' | 'google' | 'card'
    amount: number
    serviceFee: number
    link: string
    items: Item[]
}

interface Item {
    id: number
    accountId: number
    account: ItemAccount
    providerId: number
    provider: Provider
    amountWithFee: number
    serviceFee: number
    percent: number
    amount: number
    type: 'qr' | 'marketplace'
    state: 'error' | 'success' | 'wait'
}

interface ItemAccount {
    id: number
    providerId: number
    lastImportAt?: any
    addressId: number
    legalEntityId: number
    number: string
    address?: any
    fiasId?: any
    flat?: any
    dadata?: any
    fio: string
    isClosed: boolean
    isSpec: boolean
    createdAt: string
    updatedAt: string
    deletedAt?: any
}

interface Client {
    id: number
    phone: string
    first: string
    last: string
    patronymic: string
    createdAt: string
    updatedAt: string
    address: string
    countAddresses: number
    account: string
    countAccounts: number
    rate: number
    countReviews: number
    sex: string
    birth: string
    email: string
    lastSignInAt: string
    countSales: number
    countPayments: number
    totalPayments: number
    addresses: Address[]
}

interface Address {
    id: number
    full: string
    short: string
    fiasId: string
    postalCode: string
    region: string
    city: string
    street: string
    house: string
    flat: string
    countClients: number
    countAccounts: number
    countSales: number
}

interface Provider {
    id: number
    name: string
    shopCode: string
    createdAt: string
    partnerUserId: number
    partnerUser: PartnerUser
    rate: number
    countReviews: number
    countAccounts: number
    countActiveAccounts: number
    balance: number
    subscriptionFee: number
    shortLegalEntity: ShortLegalEntity
    email: string
}

interface ShortLegalEntity {
    inn: string
    name: string
}

interface PartnerUser {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}
