import pictureSvg from './img/picture.svg'
import { Element, Picture, Title, Desc } from './empty.styled'

export const Empty = () => {
	return (
		<Element>
			<Picture src={pictureSvg} />
			<Title>Рассылки отсутствуют</Title>
			<Desc>За данный период не было ни одной рассылки.</Desc>
		</Element>
	)
}
