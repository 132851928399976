import { Activity, Params, Param, Value } from './transactions_info.styled'
import { store } from 'store'
import { formatMoney } from 'utils'
import { observer } from 'mobx-react'
import { Loader } from 'components/ui'

type TransactionsInfoProps = {
    id: number
}

export const TransactionsInfo = observer((props: TransactionsInfoProps) => {
    const { id } = props

    if (!store.admin.transactions.isResolved(id)) {
        return <Loader />
    }

    const { info } = store.admin.transactions.get(id)!
    const { totalAmount, totalTurnover, revenueSharing, nextPayout, nextPayoutAmount } = info

    return (
        <>
            <Params>
                <Param>Суммарный оборот</Param>
                <Value>
                    <b>{formatMoney(totalAmount)}</b>
                </Value>
                <Param>Ревеню-шеринг</Param>
                <Value>{revenueSharing}%</Value>
                <Param>Всего выплачено</Param>
                <Value>{formatMoney(totalTurnover)}</Value>
            </Params>
            <Activity>
                <Param>Следущая выплата</Param>
                <Value>{new Date(nextPayout).toLocaleDateString()}</Value>
                <Param>К выплате {nextMonthName()}</Param>
                <Value>{formatMoney(nextPayoutAmount)}</Value>
            </Activity>
        </>
    )
})

const nextMonthName = () => {
    const current = new Date().getMonth()
    const index = current === 11 ? 0 : current + 1
    return months[index]
}

const months = [
    'январь',
    'февраль',
    'март',
    'апрель',
    'май',
    'июнь',
    'июль',
    'август',
    'сентябрь',
    'октябрь',
    'ноябрь',
    'декабрь',
]
