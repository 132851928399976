import { Button, Heading, Popup, SelectInput, TextInput, TextareaInput } from 'components/ui'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { PureComponent } from 'react'
import { Buttons, Inputs, Additionals, ButtonToggleInput, UrlInput, ButtonNameInput } from './new_mail.styled'

type MailingType = 'all' | 'user' | 'client' | 'provider' | 'admin' | 'support' | 'partner' | 'analyst'

interface NewMailingData {
    type: MailingType
    title: string
    description: string
    withButton: boolean
    button: string
    link: string
}

interface NewMailPopupProps {
    onSend: (v: NewMailingData) => void
    onClose: () => void
}

const recepients = [
    //['all', 'Всем'],
    //['user', 'Пользователям'],
    ['provider', 'Провайдерам'],
    ['admin', 'Администраторам'],
    ['client', 'Абонентам'],
    ['analyst', 'Аналитикам'],
    ['support', 'Службе поддержки'],
    ['partner', 'Партнерам'],
] as [string, string][]

@observer
export class NewMailPopup extends PureComponent<NewMailPopupProps> {
    @observable
    private type = 'all' as MailingType

    @observable
    private title = ''

    @observable
    private titleError = ''

    @observable
    private description = ''

    @observable
    private descriptionError = ''

    @observable
    private withButton = false

    @observable
    private button = ''

    @observable
    private buttonError = ''

    @observable
    private link = ''

    @observable
    private linkError = ''

    constructor(props: NewMailPopupProps) {
        super(props)
        makeObservable(this)
    }

    @action
    handleTitleChange(v: string) {
        this.titleError = ''
        this.title = v
    }

    @action
    handleDescriptionChange(v: string) {
        this.descriptionError = ''
        this.description = v
    }

    @action
    handleWithButtonChange() {
        this.buttonError = ''
        this.linkError = ''
        this.withButton = !this.withButton
    }

    @action
    handleButtonChange(v: string) {
        this.buttonError = ''
        this.button = v
    }

    @action
    handleLinkChange(v: string) {
        this.linkError = ''
        this.link = v
    }

    handleButtonClick() {
        const { type, title, description, withButton, button, link } = this

        if (!this.title) this.titleError = 'Введите заголовок'
        if (!this.description) this.descriptionError = 'Введите описание'
        if (this.withButton) {
            if (!this.button) this.buttonError = 'Введите название кнопки'
            if (!this.link) this.linkError = 'Введите ссылку'
        }

        if (this.titleError || this.descriptionError || this.buttonError || this.linkError) return

        this.props.onSend({ type, title, description, withButton, button, link })
    }

    render() {
        const { onClose } = this.props
        const onTypeChange = action((v: MailingType) => (this.type = v))
        const onTitleChange = (e: React.ChangeEvent<HTMLInputElement>) => this.handleTitleChange(e.target.value)
        const onDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement>) =>
            this.handleDescriptionChange(e.target.value)
        const onButtonChange = (e: React.ChangeEvent<HTMLInputElement>) => this.handleButtonChange(e.target.value)
        const onLinkChange = (e: React.ChangeEvent<HTMLInputElement>) => this.handleLinkChange(e.target.value)

        return (
            <Popup width={655}>
                <Heading>Новое уведомление</Heading>
                <Inputs>
                    <SelectInput options={recepients} value={this.type} label="Кому" onChange={onTypeChange} />
                    <TextInput
                        placeholder="Заголовок"
                        value={this.title}
                        onChange={onTitleChange}
                        error={this.titleError}
                    />
                    <TextareaInput
                        placeholder="Описание"
                        value={this.description}
                        onChange={onDescriptionChange}
                        error={this.descriptionError}
                    />
                    <Additionals>
                        <ButtonToggleInput onChange={() => this.handleWithButtonChange()}>Кнопка</ButtonToggleInput>
                        <UrlInput
                            placeholder="Ссылка"
                            value={this.link}
                            visible={this.withButton}
                            onChange={onLinkChange}
                            error={this.linkError}
                        />
                        <ButtonNameInput
                            placeholder="Надпись"
                            value={this.button}
                            visible={this.withButton}
                            onChange={onButtonChange}
                            error={this.buttonError}
                        />
                    </Additionals>
                </Inputs>
                <Buttons>
                    <Button secondary onClick={onClose}>
                        Отмена
                    </Button>
                    <Button onClick={() => this.handleButtonClick()}>Отправить</Button>
                </Buttons>
            </Popup>
        )
    }
}
