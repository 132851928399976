import { PureComponent } from 'react'
import { CabinetContext } from 'components/app/cabinet/context'
import {
    Avatar,
    Body,
    Component,
    Content,
    Day,
    Name,
    New,
    NoticeComponent,
    Spacer,
    Time,
    Remove,
} from './notices.styled'
import { store } from 'store'
import { observer } from 'mobx-react'
import { actions } from 'actions'
import { avatarUrl, createScrollAutoloader } from 'utils'

@observer
export class Notices extends PureComponent {
    static contextType = CabinetContext

    private readTimerId!: NodeJS.Timeout
    private detachScrollListener!: () => void

    componentDidMount() {
        this.context.setTitle('Уведомления')
        this.readTimerId = setTimeout(actions.readAllNotices, 5000)
        this.detachScrollListener = createScrollAutoloader(store.notices.resolveMore)
    }

    componentWillUnmount() {
        clearTimeout(this.readTimerId)
        this.detachScrollListener()
    }

    handleRemoveNoticeClick(id: number) {
        actions.removeNotice(id)
    }

    render() {
        const { notifications } = store.notices.get()

        let lastDate = ''

        return (
            <Component>
                {notifications.map((notice) => {
                    const { id, title, state, createdAt, user } = notice
                    const datetime = new Date(createdAt)
                    const date = datetime.toLocaleDateString()
                    const time = datetime.toLocaleTimeString()
                    const noticeDate = !lastDate || lastDate !== date ? (lastDate = date) : ''

                    return (
                        <NoticeComponent key={id}>
                            <Day>{noticeDate}</Day>
                            <Body>
                                <Time>{time}</Time>
                                {state === 'created' && <New />}
                                <Content>{title}</Content>
                                {user && (
                                    <>
                                        <Avatar url={avatarUrl(user.avatar)} />
                                        <Name>
                                            {user.lastname} {user.firstname}
                                        </Name>
                                    </>
                                )}
                                <Spacer />
                                <Remove onClick={() => this.handleRemoveNoticeClick(id)} />
                            </Body>
                        </NoticeComponent>
                    )
                })}
            </Component>
        )
    }
}
