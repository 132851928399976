import { Stars } from 'components/ui'
import { store } from 'store'
import { avatarUrl } from 'utils'
import {
    Component,
    Avatar,
    Head,
    Name,
    Label,
    Spacer,
    DateAndStars,
    Datetime,
    Text,
    DeleteButton,
} from './review.styled'

interface ReviewProps {
    data: ReviewData
}

export const Review = (props: ReviewProps) => {
    const { id, user, createdAt, message, rate } = props.data
    const date = new Date(createdAt)

    return (
        <Component>
            <Avatar src={avatarUrl(user.avatar)} />
            <Head>
                <Name>
                    {user.lastname} {user.firstname} {user.patronymic}
                </Name>
                <Label type={user.type} />
                <Spacer />
                <DateAndStars>
                    <Datetime>
                        <span>{date.toLocaleDateString()}</span>
                        <span>{date.toLocaleTimeString()}</span>
                    </Datetime>
                    <Stars count={rate} />
                </DateAndStars>
            </Head>
            <Text>{message}</Text>
            <DeleteButton onClick={() => store.reviews.admin.remove(id)}>Удалить</DeleteButton>
        </Component>
    )
}

interface ReviewData {
    id: number
    user: User
    createdAt: string
    rate: number
    message: string
}

interface User {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}
