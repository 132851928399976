import styled from 'styled-components'
import calendarIcon from './images/calendar.svg'

export const Component = styled.div`
    border: 1px solid #eaecf0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    position: relative;
    width: 170px;
    user-select: none;
    background-color: #ffffff;
    outline: none;
`

export const Arrow = styled.div<{ icon: string; substrate?: boolean }>`
    width: 24px;
    height: 24px;
    margin: 10px;
    cursor: pointer;
    background-image: url(${(props) => props.icon});
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${(props) => (props.substrate ? '#F7F8FC' : 'none ')};
    border-radius: 6px;
    transition: background-color 0.1s ease;

    :hover {
        background-color: #e2e5ef;
    }
`

export const CurrentValue = styled.div`
    background-image: url(${calendarIcon});
    background-position: center left;
    background-repeat: no-repeat;
    padding-left: 30px;
    height: 30px;
    line-height: 33px;
    margin-left: -10px;
    cursor: pointer;
`
