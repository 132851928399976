import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { Default, Info } from './routes'

export const Mailings = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/:id`}>
                <Info />
            </Route>
            <Route path={path}>
                <Default />
            </Route>
        </Switch>
    )
}
