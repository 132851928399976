import styled from 'styled-components'
import { NavLink } from 'react-router-dom'

export const Component = styled.div`
    height: 100%;
`

export const Links = styled.div`
    display: flex;
    height: 36px;
    width: 420px;
    margin-top: -56px;
    margin-bottom: 20px;
`

export const Link = styled(NavLink).attrs({
    activeClassName: 'active',
})<{ disabled?: boolean }>`
    flex: 0 0 33.3%;
    color: #707b8e;
    padding: 0 20px;
    text-decoration: none;
    text-align: center;
    user-select: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    border-bottom: 5px solid transparent;
    transition: border-bottom-color 0.2s ease;

    :hover {
        color: #68c376;
    }

    &.active {
        border-bottom-color: #68c376;
        color: #111111;
    }
`
