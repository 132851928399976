import styled from 'styled-components'
import searchIcon from './images/search.svg'
import { SelectInput as UISelectInput } from 'components/ui'

export const Component = styled.div``

export const SearchInputWrapper = styled.div`
    margin: -20px -28px 20px -18px;
`

export const SearchInput = styled.input.attrs({
    type: 'text',
})`
    display: block;
    box-sizing: border-box;
    width: 100%;
    height: 51px;
    background-color: #ffffff;
    background-image: url(${searchIcon});
    background-position: 20px center;
    background-repeat: no-repeat;
    padding: 0 20px 0 52px;
    outline: none;
    border: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;

    ::placeholder {
        font-size: 16px;
        line-height: 127.3%;
        color: #b7bcd6;
    }
`

export const Head = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;
`

export const Title = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 124.7%;
    color: #39434b;
`

export const Counter = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 124.7%;
    color: #b7bcd6;
    margin: 0 12px;
`

export const Spacer = styled.div`
    flex: 1;
`

export const Button = styled.button`
    width: 134px;
    padding: 0 11px;
    height: 37px;
    background: #68c376;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    outline: none;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;

    :hover {
        background: #68c376;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    }

    :active {
        background: #68c376;
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
        border-radius: 8px;
    }

    :disabled {
        background: #68c376;
        opacity: 0.5;
        border-radius: 8px;
        pointer-events: none;
    }
`

export const SelectInput = styled(UISelectInput)`
    width: 154px;
    margin: -10px 10px -10px 0;
`
