import * as auth from './auth'
import * as imports from './imports'
import * as dadata from './dadata'
import * as address from './address'
import * as mailing from './mailing'
import * as monitor from './monitor'
import * as notification from './notification'
import * as payment from './payment'
import * as provider from './provider'
import * as review from './review'
import * as sale from './sale'
import * as statistic from './statistic'
import * as ticket from './ticket'
import * as token from './token'
import * as user from './user'
import * as request from './request'
import * as ads from './ads'

export const api = {
    ...request,
    address,
    auth,
    dadata,
    imports,
    mailing,
    monitor,
    notification,
    payment,
    provider,
    review,
    sale,
    statistic,
    ticket,
    token,
    user,
    ads,
}
