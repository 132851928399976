import { action, observable, reaction, runInAction } from 'mobx'
import { api } from 'api'
import { createStore, Store } from './abstract'

interface Params {
    query: string
}

type UserType = 'admin' | 'user' | 'provider'

export const STORE = observable.map<string, Store<Params, TicketsData>>()

const BUSY_MAP = new Map<Store<Params, TicketsData>, boolean>()

const createSubstore = (isMine: boolean, userId: number, userType: UserType) => {
    const store = createStore<Params, TicketsData>({ query: '' })

    let lastKey: string

    reaction(
        () => store.getRealKey(),
        (key) => {
            lastKey = key

            const { query } = store.getRealParams()

            if (store.hasKey(key)) {
                store.setKey(key)

                const limit = store.get(key).close.items.length

                api.ticket
                    .list(limit, 0, userId, userType, isMine, query)
                    .then(action((data) => store.set(key, data.tickets)))
            } else {
                api.ticket.list(20, 0, userId, userType, isMine, query).then(
                    action((data) => {
                        store.set(key, data.tickets)

                        if (key === lastKey) {
                            store.setKey(key)
                        }
                    })
                )
            }
        }
    )

    return store
}

const getStoreKey = (isMine: boolean, userId: number, userType: UserType) => `${isMine}|${userId}|${userType}`

export const get = (isMine: boolean, userId: number, userType: UserType) => {
    const storeKey = getStoreKey(isMine, userId, userType)
    return STORE.get(storeKey)!.get()
}

export const getParams = (isMine: boolean, userId: number, userType: UserType) => {
    const storeKey = getStoreKey(isMine, userId, userType)
    return STORE.get(storeKey)!.getParams()
}

export const setParams = (isMine: boolean, userId: number, userType: UserType, params: Partial<Params>) => {
    const storeKey = getStoreKey(isMine, userId, userType)
    return STORE.get(storeKey)!.setParams(params)
}

export const isResolved = (isMine: boolean, userId: number, userType: UserType) => {
    const storeKey = getStoreKey(isMine, userId, userType)
    return STORE.has(storeKey)
}

export const resolve = async (isMine: boolean, userId: number, userType: UserType) => {
    const storeKey = getStoreKey(isMine, userId, userType)
    const store = STORE.has(storeKey) ? STORE.get(storeKey)! : createSubstore(isMine, userId, userType)
    const key = store.getKey()
    const { query } = store.getParams()
    const data = await api.ticket.list(20, 0, userId, userType, isMine, query)

    BUSY_MAP.set(store, false)

    store.set(key, data.tickets)

    if (!STORE.has(storeKey)) {
        runInAction(() => STORE.set(storeKey, store))
    }
}

export const resolveMore = async (isMine: boolean, userId: number, userType: UserType) => {
    if (!isResolved(isMine, userId, userType)) return

    const storeKey = getStoreKey(isMine, userId, userType)
    const store = STORE.get(storeKey)!

    if (!BUSY_MAP.get(store)) {
        BUSY_MAP.set(store, true)

        const { close } = store.get()
        const offset = close.items.length
        const { query } = store.getParams()
        const data = await api.ticket.list(20, offset, userId, userType, isMine, query)

        if (data.tickets.close.items.length === 0) {
            return
        }

        BUSY_MAP.set(store, false)

        runInAction(() => close.items.push(...data.tickets.close.items))
    }
}

export interface TicketsData {
    active: TicketsItemData[]
    wait: TicketsItemData[]
    close: List
}

interface List {
    count: number
    items: TicketsItemData[]
}

export interface TicketsItemData {
    id: number
    creatorUserId: number
    creatorUser: CreatorUser
    assignUserId: number
    assignUser: Executor
    createdAt: string
    updatedAt: string
    title: string
    countMessage: number
    status: string
}

interface Executor {
    id: number
    type: 'admin' | 'analyst' | 'partner' | 'support' | 'user' | 'provider'
    name: string
    avatar: string
}

interface CreatorUser extends Executor {
    phone: string
}
