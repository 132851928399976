import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import starIcon from './images/star.svg'
import verifiedIcon from './images/verified.svg'
import lockIcon from './images/lock.svg'

export const Component = styled.div`
    background-color: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 20px 20px 20px 84px;
    position: relative;
`

export const Avatar = styled.div<{ src: string; locked: boolean }>`
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-position: center;
    width: 48px;
    height: 48px;
    left: 20px;
    top: 20px;
    position: absolute;
    border-radius: 50%;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => (props.locked ? '#EB5757' : '#EAECF0')};

    ${(props) =>
        props.locked &&
        css`
            :before {
                content: '';
                display: block;
                position: absolute;
                background-image: url(${lockIcon});
                background-repeat: no-repeat;
                width: 19px;
                height: 19px;
                right: -4px;
                bottom: -4px;
            }
        `};
`

export const Row = styled.div<{ marginTop?: number }>`
    display: flex;
    align-items: flex-start;

    ${(props) =>
        props.marginTop &&
        css`
            margin-top: ${props.marginTop}px;
        `}
`

export const Name = styled.div<{ verified: boolean }>`
    max-width: calc(100% - 300px);
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #111111;
    margin-right: 34px;
    padding-right: 24px;
    padding-top: 2px;

    ${(props) =>
        props.verified &&
        css`
            background-image: url(${verifiedIcon});
            background-position: right center;
            background-repeat: no-repeat;
        `}
`

export const Small = styled.div<{ red?: boolean }>`
    padding-top: 2px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: ${(props) => (props.red ? '#EB5757' : '#707b8e')};
    margin-right: 34px;
`

export const Admin = styled.div`
    padding-top: 2px;
    font-size: 16px;
    line-height: 127.3%;
    color: #111111;
    margin-left: -24px;
    margin-right: 34px;
`

export const Phone = styled.div`
    font-size: 16px;
    line-height: 127.3%;
    color: #707b8e;
    margin-top: 4px;
`

export const Type = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #b7bcd6;
    flex: 0 0 150px;
`

export const Stars = styled.div`
    background-image: url(${starIcon});
    background-position: left center;
    background-repeat: no-repeat;
    background-size: 14px;
    background-position: left 1px;
    padding-left: 20px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #707b8e;
    flex: 0 0 60px;
`

export const ReviewsCount = styled(Link)`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #68c376;
    flex: 0 0 80px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    text-decoration: none;
`

export const Hr = styled.hr`
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: #eaecf0;
    margin: 13px 0 20px;
`

export const Buttons = styled.div`
    display: flex;
    position: absolute;
    top: 20px;
    right: 20px;
    z-index: 1;
`

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b7bcd6;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #707b8e;
    padding: 0 25px;
    height: 36px;
    margin-left: 15px;
    text-decoration: none;
    background-color: white;
    cursor: pointer;

    :hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    }

    :active {
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
    }

    :disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`

export const Column = styled.div<{ flex: string; alignRight?: boolean }>`
    flex: ${(props) => props.flex};
    display: flex;
    flex-direction: column;

    ${(props) =>
        props.alignRight &&
        css`
            align-items: flex-end;
        `}
`

export const ColumnName = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #707b8e;
`

export const Value = styled.div<{ red?: boolean }>`
    font-size: 16px;
    line-height: 127.3%;
    color: #111111;

    ${(props) =>
        props.red &&
        css`
            color: #eb5757;
        `}
`

export const RedButton = styled.button`
    color: #eb5757 !important;
`

export const LockButton = styled.button`
    color: #eb5757 !important;
`

export const UnlockButton = styled.button`
    color: #68c376 !important;
`
