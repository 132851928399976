import * as dashboard from './dashboard'
import * as provider from './provider'
import * as providers from './providers'
import * as customer from './customer'
import * as customers from './customers'
import * as admin from './admin'
import * as admins from './admins'
import * as payments from './payments'
import * as mailing from './mailing'
import * as mailings from './mailings'
import * as imports from './imports'
import * as reviews from './reviews'
import * as monitoring from './monitoring'
import * as ticket from './ticket'
import * as tickets from './tickets'
import * as regions from './regions'
import * as user from './user'
import * as address from './address'
import * as addresses from './addresses'
import * as notices from './notices'
import * as ads from './ads'

export type { DashboardData } from './dashboard'
export type { AddressData, AddressAccountData } from './address'
export type { AddressesData, AddressesItemData } from './addresses'
export type { PaymentsData, PaymentData } from './payments'
export type { QrPaymentsData, QrPaymentsItemData } from './payments_qr'
export type { CustomerPaymentsData, CustomerPaymentsItemData } from './customer_payments'
export type { AdminsData, AdminsItemData, AdminsOrderBy } from './admins'
export type { AdminTransactionsData, AdminTransactionsItemData } from './admin_transactions'
export type { CustomersData, CustomersItemData, CustomersOrderBy } from './customers'
export type { CustomerData, CustomerAddressData } from './customer'
export type { MailingData } from './mailing'
export type { MailingsData, MailingsItemData } from './mailings'
export type { ImportsData, ImportsItemData } from './imports'
export type { MonitoringData, MonitoringItemData } from './monitoring'
export type { ProvidersData, ProvidersItemData, ProvidersOrderBy, ProvidersItemKind } from './providers'
export type { TicketData, TicketMessageData } from './ticket'
export type { TicketsData, TicketsItemData } from './tickets'
export type { RegionsData, RegionsItemData } from './regions'
export type { UserData } from './user'
export type { NoticesData, NoticesItemData } from './notices'
export type { ReviewsData, ReviewsItemData } from './reviews'
export type { LinksData, LinksItemData } from './ticket_links'
export type { AdsData, AdsItemData } from './ads'
export type { AdsDetailsData, AdsDetailsItemData } from './ads_details'

export const store = {
    address,
    addresses,
    ads,
    dashboard,
    customer,
    customers,
    provider,
    admin,
    admins,
    reviews,
    payments,
    mailing,
    mailings,
    imports,
    monitoring,
    providers,
    ticket,
    tickets,
    regions,
    user,
    notices,
}
