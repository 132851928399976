export const testEmail = (s: string) =>
    /^[0-9\u00C0-\u017FA-Za-zА-Яа-яЁё_]+([+.-]?[0-9\u00C0-\u017FA-Za-zА-Яа-яЁё_]+)*@[0-9\u00C0-\u017FA-Za-zА-Яа-яЁё_]+([.-]?[0-9\u00C0-\u017FA-Za-zА-Яа-яЁё_]+)*(\.[0-9\u00C0-\u017FA-Za-zА-Яа-яЁё_]{2,5})+$/.test(
        s
    )

export const testEmailChar = (s: string) => /^[a-zA-Z0-9@\-._]$/.test(s)

export const testPhone = (s: string) => /^\+7 \([0-9]{3}\) [0-9]{3}-[0-9]{2}-[0-9]{2}$/.test(s)

export const testBirthDate = (s: string) => {
    if (!/^(0?[1-9]|[12][0-9]|3[01])\.(0?[1-9]|1[012])\.(19|20)[0-9]{2}$/.test(s)) return false

    const parts = s.split('.')
    const day = parseInt(parts[0])
    const month = parseInt(parts[1])
    const year = parseInt(parts[2])

    const monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31]

    if (year % 400 === 0 || (year % 100 !== 0 && year % 4 === 0)) monthLength[1] = 29

    if (day <= 0 || day > monthLength[month - 1]) return false

    const date = new Date(year, month - 1, day)

    if (date.getTime() > Date.now()) return false

    return true
}
