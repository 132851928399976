import styled from 'styled-components'
import arrowLeftIcon from './images/arrow_left.svg'
import arrowRightIcon from './images/arrow_right.svg'

export const Component = styled.div`
    width: 375px;
    height: 245px;
    position: relative;
`

export const Photos = styled.div``

export const Photo = styled.div<{ src: string; active: boolean }>`
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-image: url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    border-radius: 10px;
    opacity: ${(props) => (props.active ? 1 : 0)};
    transition: opacity 0.2s ease;
`

export const ArrowLeft = styled.div`
    position: absolute;
    left: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-image: url(${arrowLeftIcon});
    background-position: center;
    background-size: 24px;
    background-repeat: no-repeat;
    opacity: 0.5;
    transition: opacity 0.2s ease;
    cursor: pointer;

    :hover {
        opacity: 1;
    }
`

export const ArrowRight = styled.div`
    position: absolute;
    right: 4px;
    top: 50%;
    transform: translateY(-50%);
    width: 24px;
    height: 24px;
    background-image: url(${arrowRightIcon});
    background-position: center;
    background-size: 24px;
    background-repeat: no-repeat;
    opacity: 0.5;
    transition: opacity 0.2s ease;
    cursor: pointer;

    :hover {
        opacity: 1;
    }
`

export const Dots = styled.div`
    position: absolute;
    bottom: 17px;
    left: 50%;
    transform: translateX(-50%);
    display: flex;
    align-items: center;
    column-gap: 3px;
`

export const Dot = styled.div<{ active: boolean }>`
    width: 6px;
    height: 6px;
    border-radius: 50%;
    background-color: ${(props) => (props.active ? '#FEFFFF' : '#828282')};
    transition: background-color 0.2s ease;
`
