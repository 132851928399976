import { action, reaction, runInAction } from 'mobx'
import { MONTH, YEAR } from 'utils'
import { api } from 'api'
import { createStore } from './abstract'

export * as qr from './payments_qr'

interface Params {
    month: number
    year: number
}

const store = createStore<Params, PaymentsData>({
    month: MONTH,
    year: YEAR,
})

export const get = store.get
export const setParams = store.setParams
export const getParams = store.getParams
export const isResolved = store.isResolved

const BUSY_MAP = new Map<string, boolean>()

export const resolve = async () => {
    const key = store.getKey()
    const { month, year } = getParams()
    const data = await api.payment.paymentList(20, 0, month, year)

    BUSY_MAP.set(key, false)

    runInAction(() => store.set(key, data))
}

export const resolveMore = async () => {
    if (!isResolved()) return

    const key = store.getKey()

    if (!BUSY_MAP.get(key)) {
        BUSY_MAP.set(key, true)

        const { payments } = store.get()
        const offset = payments.length
        const { month, year } = getParams()
        const data = await api.payment.paymentList(20, offset, month, year)

        if (data.payments.length === 0) {
            return
        }

        BUSY_MAP.set(key, false)

        runInAction(() => payments.push(...data.payments))
    }
}

// resolver

let lastKey: string

reaction(
    () => store.getRealKey(),
    (key) => {
        lastKey = key

        const { month, year } = store.getRealParams()

        if (store.hasKey(key)) {
            store.setKey(key)

            const limit = store.get(key).payments.length

            api.payment.paymentList(limit, 0, month, year).then(action((data) => store.set(key, data)))
        } else {
            api.payment.paymentList(20, 0, month, year).then(
                action((data) => {
                    store.set(key, data)

                    if (key === lastKey) {
                        store.setKey(key)
                    }
                })
            )
        }
    }
)

export interface PaymentsData {
    payments: PaymentData[]
    total: number
    chart: Chart
}

export interface PaymentData {
    id: number
    createdAt: string
    clientId: number
    client: Client
    type: 'apple' | 'google' | 'card'
    amount: number
    serviceFee: number
    bankFee: number
}

interface Client {
    id: number
    phone: string
    first: string
    last: string
    patronymic: string
    createdAt: string
    updatedAt: string
    address: string
    countAddresses: number
    account: string
    countAccounts: number
    rate: number
    countReviews: number
    sex: string
    birth: string
    email: string
    lastSignInAt: string
    countSales: number
    countPayments: number
    totalPayments: number
    addresses: Address[]
}

interface Address {
    id: number
    full: string
    short: string
    fiasId: string
    postalCode: string
    region: string
    city: string
    street: string
    house: string
    flat: string
    countClients: number
    countAccounts: number
    countSales: number
}

interface Chart {
    payment: Payment2
}

interface Payment2 {
    current: Current[]
}

interface Current {
    date: string
    amount: number
}
