import { observer } from 'mobx-react'
import { store } from 'store'
import { Cabinet } from './cabinet'
import { Login } from './login'
import { Component } from './app.styled'
import { GlobalStyle } from 'components/ui'
import { BrowserRouter } from 'react-router-dom'

export const App = observer(() => (
    <Component>
        <GlobalStyle />
        <BrowserRouter>{store.user.isCabinetAccessAllowed() ? <Cabinet /> : <Login />}</BrowserRouter>
    </Component>
))
