import { action, computed, observable, runInAction } from 'mobx'

const STORE = observable.box<UserData | null>(null)

const CABINET_ACCESS_ALLOWED = computed(() => {
    if (!isAuth()) return false

    const { isNeedSMSValidation, isNeedEmailValidation, isNeedLegalValidation } = get()!

    return !isNeedSMSValidation && !isNeedEmailValidation && !isNeedLegalValidation
})

export const get = () => {
    return STORE.get()
}

export const set = action((data: UserData) => {
    return runInAction(() => STORE.set(data))
})

export const clear = action(() => {
    return STORE.set(null)
})

export const isCabinetAccessAllowed = () => {
    return CABINET_ACCESS_ALLOWED.get()
}

export const isAuth = () => {
    return !!STORE.get()
}

export interface UserData {
    id: number
    type: 'admin' | 'support' | 'partner' | 'analyst'
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isOwner: boolean
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
    birthDate: string
    sex: string
    createdAt: string
    lastSignInAt: string
    legalEntity: LegalEntity
}

interface LegalEntity {
    inn: string
    name: string
    id: number
    fullName: string
    kpp: string
    ogrn: string
    bankName: string
    bankAddress: string
    bik: string
    bankAccount: string
    taxType: string
    account: string
    details: string
    firstName: string
    lastName: string
    middleName: string
    birthDate: string
    phone: string
    state: string
    isTaxpayer: boolean
}
