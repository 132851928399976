import { api } from 'api'
import { observable, runInAction } from 'mobx'

export const STORE = observable.map<number, AddressAccountBalanceData>()

export const isResolved = (accountId: number) => {
    return STORE.has(accountId)
}

export const get = (accountId: number) => {
    return STORE.get(accountId)
}

export const resolve = async (accountId: number) => {
    const { transactions } = await api.payment.paymentAccountList(20, 0, accountId)

    BUSY_MAP.set(accountId, false)

    runInAction(() => STORE.set(accountId, transactions))
}

const BUSY_MAP = new Map<number, boolean>()

export const resolveMore = async (accountId: number) => {
    if (!isResolved(accountId)) return

    if (!BUSY_MAP.get(accountId)) {
        BUSY_MAP.set(accountId, true)

        const data = get(accountId)!
        const offset = data.length
        const { transactions } = await api.payment.paymentAccountList(20, offset, accountId)

        if (transactions.length) {
            BUSY_MAP.set(accountId, false)

            runInAction(() => data.push(...transactions))
        }
    }
}

export type AddressAccountBalanceData = Transaction[]

interface Transaction {
    id: number
    createdAt: string
    amount: number
    balance: number
    type: 'qr' | 'marketplace' | 'provider'
}
