import { store, CustomerAddressData } from 'store'
import { plural } from 'utils'
import { Component, Title, ObjComponent, Address, AccountsLink } from './objects.styled'

interface ObjectsProps {
    id: number
}

export const Objects = (props: ObjectsProps) => {
    const { id } = props
    const customer = store.customer.get(id)!
    const { addresses } = customer

    return (
        <Component>
            <Title>
                <b>Объекты недвижимости</b>
                <span>{plural(addresses.length, 'объект', 'объекта', 'объектов')}</span>
            </Title>
            {addresses.map((obj) => (
                <Obj key={obj.id} data={obj} />
            ))}
        </Component>
    )
}

interface ObjectProps {
    data: CustomerAddressData
}

const Obj = (props: ObjectProps) => {
    const { id, short, countAccounts } = props.data

    return (
        <ObjComponent>
            <Address>{short}</Address>
            <AccountsLink to={`/objects/address/${id}`}>Лицевые счета ({countAccounts})</AccountsLink>
        </ObjComponent>
    )
}
