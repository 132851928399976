import { action, runInAction } from 'mobx'
import { api } from 'api'
import { createStore } from './abstract'

interface Params {}

const store = createStore<Params, MailingsData>({})

export const get = store.get
export const setParams = store.setParams
export const isResolved = store.isResolved

const BUSY_MAP = new Map<string, boolean>()

export const insert = action((item: MailingsItemData) => {
    const { mailings } = store.get()
    mailings.unshift(item)
})

export const resolve = async () => {
    const key = store.getKey()
    const data = await api.mailing.list(20, 0)

    BUSY_MAP.set(key, false)

    store.set(key, data)
}

export const resolveMore = async () => {
    if (!isResolved()) return

    const key = store.getKey()

    if (!BUSY_MAP.get(key)) {
        BUSY_MAP.set(key, true)

        const { mailings } = store.get()
        const offset = mailings.length
        const data = await api.mailing.list(20, offset)

        if (data.mailings.length === 0) {
            return
        }

        BUSY_MAP.set(key, false)

        runInAction(() => mailings.push(...data.mailings))
    }
}

export interface MailingsData {
    mailings: MailingsItemData[]
    length: number
}

export type MailingType = 'all' | 'user' | 'client' | 'provider' | 'admin' | 'support' | 'partner' | 'analyst'

export interface MailingsItemData {
    id: number
    type: MailingType
    title: string
    createdAt: string
    countSent: number
    countViewed: number
    description: string
    withButton: boolean
    button: string
    link: string
}
