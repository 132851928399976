import styled, { css } from 'styled-components'
import { Dot as UIDot } from 'components/ui'

export const Component = styled.div`
    margin-bottom: 24px;
`

export const Tabs = styled.div`
    display: flex;
    column-gap: 4px;
`

export const Tab = styled.div<{ isActive: boolean }>`
    height: 36px;
    flex: 0 0 180px;
    border-radius: 14px 14px 0px 0px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    ${(props) =>
        props.isActive
            ? css`
                  background-color: white;
                  color: #68c376;
                  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.05);
              `
            : css`
                  background-color: #d7dae9;
                  color: white;
              `}
`

export const Dot = styled(UIDot)`
    display: inline-flex;
    margin-right: 2px;
`

export const Content = styled.div`
    background-color: white;
    padding: 16px 30px 20px;
`

export const Provider = styled.div`
    padding-bottom: 24px;

    & > div:nth-child(1) {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #707b8e;
        margin-bottom: 4px;
    }
    & > div:nth-child(2) {
        display: flex;
        align-items: center;
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #111111;
        font-weight: 500;

        & > span:nth-child(2) {
            margin-left: 2px;
        }
        & > span:nth-child(3) {
            margin-left: 50px;

            & > i {
                color: #707b8e;
                font-weight: normal;
                margin-right: 4px;
            }
        }
    }
`
