import { request } from './request'

export const list = (
    limit: number,
    offset: number,
    userId: number,
    type: 'admin' | 'user' | 'provider',
    isMine: boolean,
    query: string
) => {
    return request<ListResponse>('ticket/list', { limit, offset, userId, type, isMine, query })
}

export const get = (id: number) => {
    return request<GetResponse>('ticket/get', { id })
}

export const messages = (id: number, lastMessageId?: number) => {
    return request<MessagesResponse>('ticket/messages', { id, lastMessageId })
}

export const answer = (id: number, message: NewMessage) => {
    return request<AnswerResponse>('ticket/answer', { id, message })
}

export const create = (ticket: NewTicket) => {
    return request<CreateResponse>('ticket/create', { ticket })
}

export const note = (id: number, note: string) => {
    return request<NoteResponse>('ticket/note', { id, note })
}

export const executors = (query: string, id?: number, userId?: number, userType?: 'admin' | 'user' | 'provider') => {
    return request<ExecutorsResponse>('ticket/executors', { query, id, userId, userType })
}

export const assign = (id: number, userId: number, type: 'admin' | 'user' | 'provider') => {
    return request<AssignResponse>('ticket/assign', { id, userId, type })
}

export const spam = (id: number) => {
    return request<SpamResponse>('ticket/spam', { id })
}

export const links = (query: string) => {
    return request<LinksResponse>('ticket/links', { query })
}

export const action = (id: number) => {
    return request<ActionResponse>('ticket/action', { id })
}

export const getFastAnswers = () => {
    return require('./fast_answers.json') as string[]
}

interface ListResponse {
    tickets: Tickets
}

interface GetResponse {
    ticket: Ticket
}

interface MessagesResponse {
    messages: Message[]
}

interface AnswerResponse {
    message: Message
}

interface CreateResponse {
    ticket: Ticket
}

interface NoteResponse {
    ticket: Ticket
}

interface ExecutorsResponse {
    users: Executor[]
}

interface AssignResponse {
    ticket: Ticket
}

interface SpamResponse {}

interface LinksResponse {
    items: Link[]
}

interface ActionResponse {
    ticket: Ticket
}

interface Executor {
    id: number
    type: 'admin' | 'analyst' | 'partner' | 'support' | 'user' | 'provider'
    name: string
    avatar: string
}

interface Message {
    id: number
    ticketId: number
    userId: number
    user: CreatorUser
    createdAt: string
    isSystem: boolean
    template: string
    options: Options
    message: string
    attachments: Attachment[]
    links: Link[]
}

interface CreatorUser extends Executor {
    phone: string
}

interface NewMessage {
    message: string
    attachments: Attachment[]
    links: Link[]
}

interface NewTicket {
    assignUserId: number
    assignUserType: 'admin' | 'user' | 'provider'
    title: string
    message: NewTicketMessage
}

interface NewTicketMessage {
    message: string
    attachments: Attachment[]
    links: Link[]
}

interface Tickets {
    active: TicketsItem[]
    wait: TicketsItem[]
    close: List
}

interface List {
    count: number
    items: TicketsItem[]
}

interface TicketsItem {
    id: number
    creatorUserId: number
    creatorUser: CreatorUser
    assignUserId: number
    assignUser: Executor
    createdAt: string
    updatedAt: string
    title: string
    countMessage: number
    status: string
}

interface Ticket {
    id: number
    creatorUserId: number
    creatorUser: CreatorUser
    assignUserId: number
    assignUser: Executor
    createdAt: string
    updatedAt: string
    title: string
    countMessage: number
    status: 'active' | 'wait' | 'close'
    description: string
    note: string
    details: string
    messages: Message[]
}

interface Link {
    id: string
    link: string
    name: string
}

interface Attachment {
    link: string
    name: string
    mimeType: string
    size: string
    createdAt: string
    updatedAt: string
}

interface Options {}
