import styled from 'styled-components'

export const Component = styled.div`
    background: #ffffff;
    border-radius: 10px;
    padding: 25px 30px;
`

export const Counter = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;

    span {
        font-size: 14px;
        line-height: 127.3%;
        color: #707b8e;
    }

    b {
        font-weight: bold;
        font-size: 24px;
        line-height: 124.7%;
        color: #39434b;
        margin-left: 16px;
    }
`

export const Chart = styled.div`
    height: 270px;
    margin: 30px 0;
    max-width: calc(100vw - 395px);
`
