import { createRef, PureComponent, ChangeEvent } from 'react'
import { Button, Heading, Popup } from 'components/ui'
import { Desc, Inputs, Input, Timer, Repeat, Buttons } from './email_confirmation.styled'
import { action, computed, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { actions } from 'actions'

interface EmailConfirmationPopupProps {
    error: boolean
    email: string
    onCancel: () => void
    onSend: (code: string) => void
}

@observer
export class EmailConfirmationPopup extends PureComponent<EmailConfirmationPopupProps> {
    @observable
    private timer!: number
    private timerId!: NodeJS.Timeout

    @observable
    private code = '******'

    private inputs = Array.from({ length: 6 }, () => createRef()) as React.RefObject<HTMLInputElement>[]

    constructor(props: EmailConfirmationPopupProps) {
        super(props)
        makeObservable(this)
    }

    startTimer() {
        this.timer = 60
        this.timerId = setInterval(
            action(() => {
                --this.timer

                if (this.timer === 0) {
                    this.stopTimer()
                }
            }),
            1000
        )
    }

    stopTimer() {
        clearInterval(this.timerId)
    }

    @computed
    get canRepeatCode() {
        return this.timer === 0
    }

    @action
    async repeatCode() {
        await actions.sendEmailConfirmationCode()
        this.startTimer()
    }

    componentDidMount() {
        this.startTimer()
    }

    componentWillUnmount() {
        this.stopTimer()
    }

    @action
    handleChangeEvent(e: ChangeEvent<HTMLInputElement>, index: number) {
        const value = e.target.value.slice(-1)
        const chars = this.code.split('')

        chars.splice(index, 1, value)

        this.code = chars.join('')

        if (index < 5) {
            this.inputs[index + 1].current!.focus()
        } else {
            this.inputs[index].current!.blur()
        }
    }

    getInputValue(index: number) {
        const value = this.code.slice(index, index + 1)
        return value === '*' ? '' : value
    }

    render() {
        const { onSend, onCancel, email, error } = this.props

        return (
            <Popup>
                <Heading>Подтвердите email</Heading>
                <Desc>Введите код, который был отправлен на ваш email {email}</Desc>
                <Inputs>
                    <Input
                        ref={this.inputs[0]}
                        value={this.getInputValue(0)}
                        onChange={(e) => this.handleChangeEvent(e, 0)}
                        error={error}
                        autoFocus
                    />
                    <Input
                        ref={this.inputs[1]}
                        value={this.getInputValue(1)}
                        onChange={(e) => this.handleChangeEvent(e, 1)}
                        error={error}
                    />
                    <Input
                        ref={this.inputs[2]}
                        value={this.getInputValue(2)}
                        onChange={(e) => this.handleChangeEvent(e, 2)}
                        error={error}
                    />
                    <Input
                        ref={this.inputs[3]}
                        value={this.getInputValue(3)}
                        onChange={(e) => this.handleChangeEvent(e, 3)}
                        error={error}
                    />
                    <Input
                        ref={this.inputs[4]}
                        value={this.getInputValue(4)}
                        onChange={(e) => this.handleChangeEvent(e, 4)}
                        error={error}
                    />
                    <Input
                        ref={this.inputs[5]}
                        value={this.getInputValue(5)}
                        onChange={(e) => this.handleChangeEvent(e, 5)}
                        error={error}
                    />
                </Inputs>
                <Buttons>
                    <Button secondary onClick={onCancel}>
                        Отмена
                    </Button>
                    <Button onClick={() => onSend(this.code)}>Подтвердить</Button>
                </Buttons>
                {this.canRepeatCode ? (
                    <Repeat onClick={() => this.repeatCode()}>Отправить код повторно</Repeat>
                ) : (
                    <Timer>Отправить повторно можно через {this.timer} сек</Timer>
                )}
            </Popup>
        )
    }
}
