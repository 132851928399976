import styled from 'styled-components'
import { Label as UILabel } from 'components/ui'
import { Link } from 'react-router-dom'

export const Component = styled.div``

export const Breadcrumbs = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    a,
    span {
        :not(:last-child) {
            font-size: 16px;
            line-height: 127.3%;
            color: #707b8e;
            text-decoration: none;
            transition: color 0.2s ease;

            :after {
                content: '•';
                margin: 0 8px;
                text-decoration: none;
            }
        }
        :last-child {
            font-size: 18px;
            line-height: 21px;
            color: #68c376;
            font-weight: 500;
        }
    }

    a:hover {
        color: #4e5a6f;
    }
`

export const Title = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;

    b {
        font-weight: bold;
        font-size: 24px;
        line-height: 124.7%;
        color: #39434b;
    }
    span {
        font-weight: bold;
        font-size: 24px;
        line-height: 124.7%;
        color: #b7bcd6;
        margin-left: 12px;
    }
`

export const Label = styled(UILabel)``

export const Button = styled(Link)`
    width: 134px;
    padding: 0 11px;
    height: 37px;
    background: #68c376;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;

    :hover {
        background: #68c376;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    }

    :active {
        background: #68c376;
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
        border-radius: 8px;
    }

    :disabled {
        background: #68c376;
        opacity: 0.5;
        border-radius: 8px;
    }
`

export const Customer = styled.div`
    background: #ffffff;
    padding: 20px 20px 20px 30px;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    border-radius: 10px;
    display: flex;
    align-items: center;
`

export const Avatar = styled.img`
    width: 48px;
    height: 48px;
    border-radius: 50%;
`

export const Name = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #39434b;
    margin: 0px 16px;
`

export const Spacer = styled.div`
    flex: 1;
`
