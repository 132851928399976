import { action, reaction, runInAction } from 'mobx'
import { api } from 'api'
import { createStore } from './abstract'
import { MONTH, YEAR } from 'utils'

interface Params {
    month: number
    year: number
    query: string
}

const store = createStore<Params, QrPaymentsData>({
    month: MONTH,
    year: YEAR,
    query: '',
})

export const get = store.get
export const setParams = store.setParams
export const getParams = store.getParams
export const isResolved = store.isResolved

const BUSY_MAP = new Map<string, boolean>()

export const resolve = async () => {
    const key = store.getKey()
    const { month, year, query } = store.getParams()
    const data = await api.payment.qrList(20, 0, month, year, query)

    BUSY_MAP.set(key, false)

    store.set(key, data)
}

export const resolveMore = async () => {
    if (!isResolved()) return

    const key = store.getKey()

    if (!BUSY_MAP.get(key)) {
        BUSY_MAP.set(key, true)

        const { qrpayments } = store.get()
        const offset = qrpayments.length
        const { month, year, query } = getParams()
        const data = await api.payment.qrList(20, offset, month, year, query)

        if (data.qrpayments.length === 0) {
            return
        }

        BUSY_MAP.set(key, false)

        runInAction(() => qrpayments.push(...data.qrpayments))
    }
}

// resolver

let lastKey: string

reaction(
    () => store.getRealKey(),
    (key) => {
        lastKey = key

        const { month, year, query } = store.getRealParams()

        if (store.hasKey(key)) {
            store.setKey(key)

            const limit = store.get(key).qrpayments.length

            api.payment.qrList(limit, 0, month, year, query).then(action((data) => store.set(key, data)))
        } else {
            api.payment.qrList(20, 0, month, year, query).then(
                action((data) => {
                    store.set(key, data)

                    if (key === lastKey) {
                        store.setKey(key)
                    }
                })
            )
        }
    }
)

export interface QrPaymentsData {
    qrpayments: QrPaymentsItemData[]
    total: number
}

export interface QrPaymentsItemData {
    id: number
    provider: Provider
    note: string
    amount: number
    fileId: string
    createdAt: string
    state: 'wait' | 'done' | 'cancel'
}

interface Provider {
    id: number
    name: string
    short: string
    shopCode: string
    createdAt: string
    partnerUserId: number
    partnerUser: PartnerUser
    rate: number
    countReviews: number
    countAccounts: number
    countActiveAccounts: number
    balance: number
    subscriptionFee: number
    shortLegalEntity: ShortLegalEntity
    email: string
}

interface ShortLegalEntity {
    inn: string
    name: string
}

interface PartnerUser {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}
