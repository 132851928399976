import { request } from './request'

export const list = () => {
    return request<ListResponse>('monitor/list')
}

interface ListResponse {
    monitors: Monitor[]
}

interface Monitor {
    name: string
    params: Param[]
}

interface Param {
    name: string
    value: string
}
