import { Component, Tools, SearchInput, Title, TheadComp, ItemComp, Dot, Button } from './imports.styled'
import { PureComponent } from 'react'
import { DatePicker, Loader } from 'components/ui'
import { store, ImportsItemData } from 'store'
import searchIcon from './images/search.svg'
import bugIcon from './images/bug.svg'
import downloadIcon from './images/download.svg'
import { observer } from 'mobx-react'
import { makeObservable, observable, runInAction } from 'mobx'
import { download, fileUrl, plural } from 'utils'
import { match, withRouter } from 'react-router-dom'

interface ImportsProps {
    match?: match<{ query: string }>
}

@(withRouter as any)
@observer
export class Imports extends PureComponent {
    @observable
    private query!: string
    private queryTimeout!: NodeJS.Timeout

    constructor(props: ImportsProps) {
        super(props)
        makeObservable(this)

        const { query } = props.match!.params

        store.imports.setParams({ query })
        runInAction(() => (this.query = query))
    }

    componentDidMount() {
        store.imports.resolve()
    }

    handleQueryChange(query: string) {
        this.query = query

        clearTimeout(this.queryTimeout)

        this.queryTimeout = setTimeout(() => store.imports.setParams({ query }), 500)
    }

    render() {
        if (!store.imports.isResolved()) {
            return <Loader />
        }

        const { month, year } = store.imports.getParams()
        const { breaked, created, failed, successed } = store.imports.get()

        return (
            <Component>
                <Tools>
                    <DatePicker month={month} year={year} onChange={(v) => store.imports.setParams(v)} />
                    <SearchInput
                        value={this.query}
                        onChange={(e) => this.handleQueryChange(e.target.value)}
                        icon={searchIcon}
                        placeholder="Поиск по ID импорта или названию провайдера"
                    />
                </Tools>
                {!!created.length && (
                    <>
                        <Title>
                            <span>Активные</span>
                            <span>{created.length}</span>
                        </Title>
                        <Thead />
                        {created.map((item) => (
                            <Item key={item.id} data={item} />
                        ))}
                    </>
                )}
                {!!successed.length && (
                    <>
                        <Title>
                            <span>Успешные</span>
                            <span>{successed.length}</span>
                        </Title>
                        <Thead />
                        {successed.map((item) => (
                            <Item key={item.id} data={item} />
                        ))}
                    </>
                )}
                {!!breaked.length && (
                    <>
                        <Title>
                            <span>Прерванные пользователем</span>
                            <span>{breaked.length}</span>
                        </Title>
                        <Thead />
                        {breaked.map((item) => (
                            <Item key={item.id} data={item} />
                        ))}
                    </>
                )}
                {!!failed.length && (
                    <>
                        <Title>
                            <span>Ошибочные</span>
                            <span>{failed.length}</span>
                        </Title>
                        <Thead />
                        {failed.map((item) => (
                            <Item key={item.id} data={item} />
                        ))}
                    </>
                )}
            </Component>
        )
    }
}

const Thead = () => (
    <TheadComp>
        <div>Импорт</div>
        <div>Дата</div>
        <div>Провайдер</div>
        <div>Тип</div>
        <div>Загружено</div>
        <div>Обработано</div>
    </TheadComp>
)

interface ItemProps {
    data: ImportsItemData
}

const calcDotColor = ({ state, errorFileId }: ImportsItemData) => {
    if (state === 'created') return 'purple'
    if (state === 'successed' && !!errorFileId) return 'yellow'
    if (state === 'successed' && !errorFileId) return 'green'
    if (state === 'failed') return 'red'

    return 'gray'
}

const calcDotLabel = ({ state, errorFileId, errorCount }: ImportsItemData) => {
    if (state === 'created') return 'Идет импорт данных'
    if (state === 'successed' && !!errorFileId)
        return `Успешно с ${plural(errorCount, 'ошибкой', 'ошибками', 'ошибками')}`
    if (state === 'successed' && !errorFileId) return 'Успешно без ошибок'
    if (state === 'failed') return `Завершено с ${plural(errorCount, 'ошибкой', 'ошибками', 'ошибками')}`

    return 'Остановлено вручную'
}

const Item = (props: ItemProps) => {
    const { id, createdAt, provider, type, finalCount, prepCount, fileId, errorFileId } = props.data
    const datetime = new Date(createdAt)
    const color = calcDotColor(props.data)
    const desc = calcDotLabel(props.data)

    return (
        <ItemComp>
            <div>
                <Dot color={color} label={desc} />
                {id}
            </div>
            <div>
                <span>{datetime.toLocaleDateString()}</span>
                <span>{datetime.toLocaleTimeString()}</span>
            </div>
            <div>{provider.short}</div>
            <div>{type.toUpperCase()}</div>
            <div>{finalCount}</div>
            <div>{prepCount}</div>
            <div>
                <Button icon={bugIcon} onClick={() => download(fileUrl(errorFileId))} disabled={!errorFileId} />
                <Button icon={downloadIcon} onClick={() => download(fileUrl(fileId))} disabled={!fileId} />
            </div>
        </ItemComp>
    )
}
