import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { ChangeEvent, PureComponent } from 'react'
import { Component, Label, Error, Input } from './text.styled'

export interface TextInputProps {
    mask?: string | (RegExp | string)[]
    maskPlaceholder?: string
    label?: string
    placeholder?: string
    icon?: string
    value?: string
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    error?: string | boolean
    type?: string
    autoFocus?: boolean
}

@observer
export class TextInput extends PureComponent<TextInputProps> {
    @observable
    private focused = false
    protected type = 'text'

    constructor(props: TextInputProps) {
        super(props)
        makeObservable(this)
    }

    render() {
        const {
            label,
            placeholder,
            icon,
            value,
            onChange,
            error,
            type,
            mask,
            maskPlaceholder = ' ',
            autoFocus,
            ...other
        } = this.props
        const err = !this.focused && error

        return (
            <Component {...other}>
                <Label>{label}</Label>
                <Input
                    mask={mask as any}
                    maskPlaceholder={maskPlaceholder}
                    icon={icon}
                    placeholder={placeholder}
                    value={value}
                    onChange={onChange}
                    error={!err ? 'false' : 'true'}
                    type={type || this.type}
                    onFocus={action(() => (this.focused = true))}
                    onBlur={action(() => (this.focused = false))}
                    autoFocus={autoFocus}
                />
                <Error>{err}</Error>
            </Component>
        )
    }
}
