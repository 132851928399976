import { PureComponent } from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import { Component, Content, ScrollToTop } from './cabinet.styled'
import { Aside } from './aside'
import { Head } from './head'
import { Route, Switch } from 'react-router-dom'
import {
    Customers,
    Admins,
    Dashboard,
    Mailings,
    Monitoring,
    Objects,
    Payments,
    Providers,
    Support,
    Notices,
    Ads,
} from './routes'
import { CabinetContext } from './context'

@observer
export class Cabinet extends PureComponent {
    @observable
    private title = ''
    @observable
    private hasError = false
    @observable
    private scrollToTopVisibility = document.documentElement.scrollTop > 0

    constructor(props: {}) {
        super(props)
        makeObservable(this)

        this.title = 'Все администраторы'
    }

    componentDidMount() {
        const listener = action(() => {
            this.scrollToTopVisibility = document.documentElement.scrollTop > 0
        })

        document.addEventListener('scroll', listener)
    }

    @action
    setTitle(title: string) {
        this.title = title
    }

    componentDidCatch(error: any, errorInfo: any) {
        console.log(error, errorInfo)
        this.hasError = true
    }

    render() {
        return (
            <CabinetContext.Provider value={this}>
                <Component>
                    <Head title={this.title} />
                    <Aside />
                    <Content>
                        {this.hasError ? (
                            'Unknown error'
                        ) : (
                            <Switch>
                                <Route path="/customers">
                                    <Customers />
                                </Route>
                                <Route path="/admins">
                                    <Admins />
                                </Route>
                                <Route path="/mailings">
                                    <Mailings />
                                </Route>
                                <Route path="/monitoring">
                                    <Monitoring />
                                </Route>
                                <Route path="/objects">
                                    <Objects />
                                </Route>
                                <Route path="/payings">
                                    <Payments />
                                </Route>
                                <Route path="/providers">
                                    <Providers />
                                </Route>
                                <Route path="/sales">
                                    <Ads />
                                </Route>
                                <Route path="/support">
                                    <Support />
                                </Route>
                                <Route path="/notices">
                                    <Notices />
                                </Route>
                                <Route path="/" exact>
                                    <Dashboard />
                                </Route>
                            </Switch>
                        )}
                    </Content>
                    {this.scrollToTopVisibility && (
                        <ScrollToTop onClick={() => (document.documentElement.scrollTop = 0)} />
                    )}
                </Component>
            </CabinetContext.Provider>
        )
    }
}
