import { PureComponent } from 'react'
import { observer } from 'mobx-react'
import { action, computed, makeObservable, observable } from 'mobx'
import { Logo, Component, LeftSide, RightSide, ForgotLink, LoginInput, PasswordInput } from './login.styled'
import { Heading, Button } from 'components/ui'
import {
    ForgotPasswordPopup,
    NeedEmailConfirmationPopup,
    NeedPhoneConfirmationPopup,
    EmailConfirmationPopup,
    PhoneConfirmationPopup,
    Invoice6ConfirmationPopup,
    Invoice4ConfirmationPopup,
    InfoConfirmationPopup,
} from './popups'
import { actions } from 'actions'
import { store } from 'store'
import { formatPhone, testEmailChar } from 'utils'

@observer
export class Login extends PureComponent {
    @observable
    private forgotPasswordPopupVisibility = false

    @observable
    private emailConfirmationPopupVisibility = false

    @observable
    private phoneConfirmationPopupVisibility = false

    @observable
    private invoice4ConfirmationPopupVisibility = false

    @observable
    private invoice6ConfirmationPopupVisibility = false

    @observable
    private username = ''

    @observable
    private password = ''

    @observable
    private loginError = false

    @observable
    private emailConfirmationError = false

    @observable
    private phoneConfirmationError = false

    @observable
    private legalConfirmationError = false

    constructor(props: {}) {
        super(props)
        makeObservable(this)
    }

    @computed
    get needEmailConfirmationPopupVisibility() {
        if (!store.user.isAuth()) {
            return false
        }

        const user = store.user.get()!

        return user.isNeedEmailValidation && !this.emailConfirmationPopupVisibility
    }

    @computed
    get needPhoneConfirmationPopupVisibility() {
        if (!store.user.isAuth()) {
            return false
        }

        const user = store.user.get()!

        return !user.isNeedEmailValidation && user.isNeedSMSValidation && !this.phoneConfirmationPopupVisibility
    }

    @computed
    get needLegalConfirmationPopupVisibility() {
        if (!store.user.isAuth()) {
            return false
        }

        const user = store.user.get()!

        return (
            !user.isNeedEmailValidation &&
            !user.isNeedSMSValidation &&
            user.isNeedLegalValidation &&
            !(this.invoice6ConfirmationPopupVisibility || this.invoice4ConfirmationPopupVisibility)
        )
    }

    @action
    showForgotPasswordPopup() {
        this.forgotPasswordPopupVisibility = true
    }

    @action
    hideForgotPasswordPopup() {
        this.forgotPasswordPopupVisibility = false
    }

    @action
    async sendNeedEmailConfirmationCode() {
        await actions.sendEmailConfirmationCode()
        this.emailConfirmationPopupVisibility = true
    }

    @action
    async sendNeedPhoneConfirmationCode() {
        await actions.sendPhoneConfirmationCode()
        this.phoneConfirmationPopupVisibility = true
    }

    @action
    async sendNeedLegalConfirmationCode() {
        await actions.sendLegalConfirmationCode()

        const user = store.user.get()!

        if (user.legalEntity.isTaxpayer) {
            this.invoice4ConfirmationPopupVisibility = true
        } else {
            this.invoice6ConfirmationPopupVisibility = true
        }
    }

    @action
    async confirmEmail(code: string) {
        try {
            this.emailConfirmationError = false
            await actions.confirmEmail(code)
            this.emailConfirmationPopupVisibility = false
        } catch (e) {
            this.emailConfirmationError = true
        }
    }

    @action
    async confirmPhone(code: string) {
        try {
            this.phoneConfirmationError = false
            await actions.confirmPhone(code)
            this.phoneConfirmationPopupVisibility = false
        } catch (e) {
            this.phoneConfirmationError = true
        }
    }

    @action
    async confirmLegal(code: string) {
        try {
            this.legalConfirmationError = false
            await actions.confirmLegal(code)
            this.invoice4ConfirmationPopupVisibility = false
            this.invoice6ConfirmationPopupVisibility = false
        } catch (e) {
            this.legalConfirmationError = true
        }
    }

    async handleLogin(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        try {
            this.loginError = false
            await actions.login(this.username, this.password)
        } catch (e) {
            this.loginError = true
        }
    }

    @action
    cancelConfirmation() {
        actions.softLogout()
        this.forgotPasswordPopupVisibility = false
        this.emailConfirmationPopupVisibility = false
        this.phoneConfirmationPopupVisibility = false
        this.invoice4ConfirmationPopupVisibility = false
        this.invoice6ConfirmationPopupVisibility = false
        this.username = ''
        this.password = ''
        this.loginError = false
        this.emailConfirmationError = false
        this.phoneConfirmationError = false
        this.legalConfirmationError = false
    }

    render() {
        const user = store.user.get()

        return (
            <Component>
                <Logo />
                <LeftSide onSubmit={(e) => this.handleLogin(e)}>
                    <Heading>Войти в админ-панель</Heading>
                    <LoginInput
                        label="Логин"
                        value={this.username}
                        error={this.loginError}
                        onChange={action((e) => {
                            this.loginError = false
                            this.username = e.target.value
                                .split('')
                                .filter((l) => testEmailChar(l))
                                .join('')
                        })}
                    />
                    <PasswordInput
                        label="Пароль"
                        value={this.password}
                        error={this.loginError}
                        onChange={action((e) => {
                            this.loginError = false
                            this.password = e.target.value
                        })}
                    />
                    <ForgotLink onClick={() => this.showForgotPasswordPopup()}>Забыли пароль?</ForgotLink>
                    <Button type="submit">Войти в личный кабинет</Button>
                </LeftSide>
                <RightSide />
                {this.forgotPasswordPopupVisibility && (
                    <ForgotPasswordPopup onClose={() => this.hideForgotPasswordPopup()} />
                )}
                {this.needEmailConfirmationPopupVisibility && (
                    <NeedEmailConfirmationPopup
                        onSend={() => this.sendNeedEmailConfirmationCode()}
                        onCancel={() => this.cancelConfirmation()}
                        email={user!.email}
                    />
                )}
                {this.needPhoneConfirmationPopupVisibility && (
                    <NeedPhoneConfirmationPopup
                        onSend={() => this.sendNeedPhoneConfirmationCode()}
                        onCancel={() => this.cancelConfirmation()}
                        phone={formatPhone(user!.phone)}
                    />
                )}
                {this.emailConfirmationPopupVisibility && (
                    <EmailConfirmationPopup
                        onSend={(code) => this.confirmEmail(code)}
                        onCancel={() => this.cancelConfirmation()}
                        email={user!.email}
                        error={this.emailConfirmationError}
                    />
                )}
                {this.phoneConfirmationPopupVisibility && (
                    <PhoneConfirmationPopup
                        onSend={(code) => this.confirmPhone(code)}
                        onCancel={() => this.cancelConfirmation()}
                        phone={formatPhone(user!.phone)}
                        error={this.phoneConfirmationError}
                    />
                )}
                {this.needLegalConfirmationPopupVisibility && (
                    <InfoConfirmationPopup
                        onSend={() => this.sendNeedLegalConfirmationCode()}
                        onCancel={() => this.cancelConfirmation()}
                    />
                )}
                {this.invoice6ConfirmationPopupVisibility && (
                    <Invoice6ConfirmationPopup
                        onSend={(code) => this.confirmLegal(code)}
                        onCancel={() => this.cancelConfirmation()}
                        error={this.legalConfirmationError}
                    />
                )}
                {this.invoice4ConfirmationPopupVisibility && (
                    <Invoice4ConfirmationPopup
                        onSend={(code) => this.confirmLegal(code)}
                        onCancel={() => this.cancelConfirmation()}
                        error={this.legalConfirmationError}
                    />
                )}
            </Component>
        )
    }
}
