import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { PureComponent, ChangeEvent, ReactNode } from 'react'
import { Component, Label } from './checkbox.styled'
import checkedImg from './images/checked.svg'
import uncheckedImg from './images/unchecked.svg'

interface CheckboxInputProps {
    checked?: boolean
    disabled?: boolean
    onChange?: (e: ChangeEvent<HTMLInputElement>) => void
    children?: ReactNode
}

@observer
export class CheckboxInput extends PureComponent<CheckboxInputProps> {
    @observable
    private checked: boolean

    constructor(props: CheckboxInputProps) {
        super(props)

        this.checked = !!props.checked

        makeObservable(this)
    }

    @action
    handleChange(e: ChangeEvent<HTMLInputElement>) {
        this.checked = !this.checked

        if (this.props.onChange) {
            this.props.onChange(e)
        }
    }

    @action
    componentDidUpdate(prevProps: CheckboxInputProps) {
        if (this.props.checked !== prevProps.checked) {
            this.checked = !!this.props.checked
        }
    }

    render() {
        const { disabled, children } = this.props
        const { checked } = this

        return (
            <Component disabled={!!disabled}>
                <img src={checked ? checkedImg : uncheckedImg} alt="" />
                <input type="checkbox" checked={checked} onChange={(e) => this.handleChange(e)} />
                <Label>{children}</Label>
            </Component>
        )
    }
}
