import styled from 'styled-components'

const getLabelBgColor = (type: string) => {
    switch (type) {
        case 'owner':
        case 'admin':
            return '#FDF6EC'
        case 'support':
            return '#FDF6EC'
        case 'partner':
            return '#F1FAF3'
        case 'analyst':
            return '#F4F5F9'
        case 'client':
            return '#EBEEFF'
        case 'provider':
            return '#EBFFFC'
        default:
            return 'black'
    }
}

const getLabelTextColor = (type: string) => {
    switch (type) {
        case 'owner':
        case 'admin':
            return '#E8A944'
        case 'support':
            return '#E8A944'
        case 'partner':
            return '#68C376'
        case 'analyst':
            return '#B7BCD6'
        case 'client':
            return '#A9B6FF'
        case 'provider':
            return '#42B5A0'
        default:
            return 'white'
    }
}

export const Component = styled.div<{ type: string }>`
    display: inline-block;
    padding: 7px 10px;
    position: static;
    height: 27px;
    background-color: ${(props) => getLabelBgColor(props.type)};
    color: ${(props) => getLabelTextColor(props.type)};
    border-radius: 7px;
    font-size: 11px;
    line-height: 13px;
    text-align: center;
`
