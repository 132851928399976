import { PureComponent } from 'react'
import { observer } from 'mobx-react'
import { AddressAccountData } from 'store'
import { Component, Tabs, Tab, Dot, Content, Provider } from './account.styled'
import { action, makeObservable, observable } from 'mobx'
import { Info } from './info'
import { Counters } from './counters'
import { Balance } from './balance'

interface AccountProps {
    data: AddressAccountData
}

@observer
export class Account extends PureComponent<AccountProps> {
    @observable
    private activeTab = 'account' as 'account' | 'counters' | 'balance'

    constructor(props: AccountProps) {
        super(props)
        makeObservable(this)
    }

    @action
    changeTab(name: 'account' | 'counters' | 'balance') {
        this.activeTab = name
    }

    render() {
        const { activeTab } = this
        const { id, counters, isClosed, provider, number } = this.props.data

        return (
            <Component>
                <Tabs>
                    <Tab isActive={activeTab === 'account'} onClick={() => this.changeTab('account')}>
                        Лицевой счёт
                    </Tab>
                    <Tab isActive={activeTab === 'counters'} onClick={() => this.changeTab('counters')}>
                        Счётчики ({counters.length})
                    </Tab>
                    <Tab isActive={activeTab === 'balance'} onClick={() => this.changeTab('balance')}>
                        Изменение баланса
                    </Tab>
                </Tabs>
                <Content>
                    {provider && (
                        <Provider>
                            <div>{provider.name}</div>
                            <div>
                                <Dot color={calcDotColor(isClosed)} label={calcDotDesc(isClosed)} />
                                <span>{number}</span>
                                <span>
                                    <i>ИНН</i>
                                    {provider.shortLegalEntity.inn}
                                </span>
                            </div>
                        </Provider>
                    )}
                    {activeTab === 'account' && <Info data={this.props.data} />}
                    {activeTab === 'counters' && <Counters data={this.props.data} />}
                    {activeTab === 'balance' && <Balance accountId={id} />}
                </Content>
            </Component>
        )
    }
}

const calcDotColor = (isClosed: boolean) => {
    if (isClosed) return 'red'
    return 'green'
}

const calcDotDesc = (isClosed: boolean) => {
    if (isClosed) return 'Ликвидирован'
    return 'Активен'
}
