import { request } from './request'
import { removeTokens } from './token'

export const login = (username: string, password: string) => {
    return request<LoginResponse>('auth/login', { username, password })
}

export const logout = async () => {
    const result = await request<LoginResponse>('auth/logout')

    removeTokens()

    return result
}

export const info = () => {
    return request<InfoResponse>('auth/info')
}

export const requestSms = () => {
    return request<RequestSmsResponse>('auth/request/sms')
}

export const checkSms = (code: string) => {
    return request<ChechSmsResponse>('auth/check/sms', { code })
}

export const requestEmail = () => {
    return request<RequestEmailResponse>('auth/request/email')
}

export const checkEmail = (code: string) => {
    return request<ChechEmailResponse>('auth/check/email', { code })
}

export const requestLegal = () => {
    return request<RequestLegalResponse>('auth/request/legal')
}

export const chechLegal = (code: string) => {
    return request<ChechLegalResponse>('auth/check/legal', { code })
}

interface LoginResponse {
    user: User
    token: string
    refreshToken: string
}

interface InfoResponse {
    user: User
}

interface RequestSmsResponse {}

interface ChechSmsResponse {}

interface RequestEmailResponse {}

interface ChechEmailResponse {}

interface RequestLegalResponse {}

interface ChechLegalResponse {}

interface User {
    id: number
    type: 'admin' | 'partner' | 'support' | 'analyst'
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isOwner: boolean
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
    birthDate: string
    sex: string
    createdAt: string
    lastSignInAt: string
    legalEntity: LegalEntity
}

interface LegalEntity {
    inn: string
    name: string
    id: number
    fullName: string
    kpp: string
    ogrn: string
    bankName: string
    bankAddress: string
    bik: string
    bankAccount: string
    taxType: string
    account: string
    details: string
    firstName: string
    lastName: string
    middleName: string
    birthDate: string
    phone: string
    state: string
    isTaxpayer: boolean
}
