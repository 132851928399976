import styled from 'styled-components'
import userIcon from './images/user.svg'
import { Label as UILabel, TextInput } from 'components/ui'
import { Link } from 'react-router-dom'
import crossIcon from './images/cross.svg'

export const Component = styled.div`
    grid-area: aside;
    background-color: #fcfcfe;
    padding: 25px 13px;
    display: flex;
    flex-direction: column;
`

export const Executor = styled.div`
    background: url(${userIcon}) no-repeat left top;
    padding-left: 20px;
    margin-bottom: 20px;

    span {
        &:nth-child(1) {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #707b8e;
        }
        &:nth-child(2) {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #68c376;
            margin-left: 3px;
        }
    }
`

export const Button = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b7bcd6;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #707b8e;
    padding: 0 12px;
    height: 36px;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;

    & + & {
        margin-left: 15px;
    }
`

export const ButtonLink = styled(Link)`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b7bcd6;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #707b8e;
    padding: 0 12px;
    height: 36px;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;
`

export const CopyButton = styled(Button)`
    margin: 0 16px;
`

export const SpamButton = styled(Button)`
    align-self: center;
    border: none;
    background: none;
    color: #b7bcd6;
    margin-top: 5px;
`

export const Hr = styled.hr`
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: #b7bcd6;
    margin: 30px -13px;
`

export const Manager = styled.div`
    display: flex;
    flex: 1;
`

export const Avatar = styled.img`
    width: 76px;
    height: 76px;
    border-radius: 50%;
`

export const Info = styled.div`
    margin-left: 14px;
    display: flex;
    flex-direction: column;
    gap: 14px;
`

export const Name = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #39434b;
`

export const Phone = styled.div`
    display: inline-block;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #707b8e;
`

export const Label = styled(UILabel)`
    margin-left: 14px;
`

export const Reassign = styled.div`
    position: relative;
`

export const ExecutorInput = styled(TextInput)`
    input {
        height: 36px;
    }
`

export const ReassignFlex = styled.div`
    display: flex;
    column-gap: 7px;
    align-items: center;

    ${ExecutorInput} {
        flex: 1;
    }
`

export const Bubble = styled.div`
    position: absolute;
    z-index: 10;
    top: calc(100% + 5px);
    left: 0;
    min-width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 14px rgba(133, 140, 166, 0.236724);
    border-radius: 4px;
`

export const BubbleOption = styled.div<{ avatar: string }>`
    padding: 8px 17px;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
    display: flex;
    align-items: center;

    &::before {
        content: '';
        display: block;
        background-image: url(${(props) => props.avatar});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        margin-right: 16px;
    }

    :hover {
        background-color: #f1faf3;
    }

    :last-child {
        border-radius: 0 0 4px 4px;
    }
`

export const ExecutorError = styled.div`
    color: #eb5757;
    font-family: Roboto;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    position: absolute;
    left: 0;
    top: calc(100% + 4px);
`

export const CurrentExecutor = styled.div<{ avatar: string }>`
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 17px;
    height: 36px;
    border-width: 1px;
    border-style: solid;
    border-color: #eaecf0;
    border-radius: 5px;
    color: #111111;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    cursor: pointer;
    text-overflow: ellipsis;

    &::before {
        content: '';
        display: block;
        background-image: url(${(props) => props.avatar});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 50%;
        width: 24px;
        height: 24px;
        margin-right: 10px;
    }
`

export const CancelReassign = styled.span`
    display: block;
    width: 24px;
    height: 24px;
    background-image: url(${crossIcon});
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;
    margin-left: 10px;
`
