import { Link } from 'react-router-dom'
import styled from 'styled-components'
import arrowRightIcon from './images/arrow_right.svg'

export const Component = styled.div``

export const Head = styled.div`
    display: flex;
    align-items: center;
    margin-top: 20px;
    height: 59px;
    background: #ffffff;
    border-radius: 10px;
    padding-left: 30px;
    padding-right: 82px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #111111;
`

export const Column = styled.div<{ flex: string; alignRight?: boolean }>`
    flex: ${(props) => props.flex};
    text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
`

export const PaymentComponent = styled.div``

export const PaymentDate = styled.div`
    display: flex;
    align-items: center;
    padding-left: 30px;
    font-size: 14px;
    line-height: 20px;
    text-align: right;
    letter-spacing: -0.24px;
    color: #b7bcd6;

    :not(:empty) {
        height: 60px;
    }

    :empty {
        height: 10px;
    }
`

export const PaymentInfo = styled(Link)`
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    height: 67px;
    display: flex;
    font-family: inherit;
    font-size: inherit;
    text-decoration: none;
    align-items: center;
    padding-left: 30px;
    padding-right: 82px;
    line-height: 150.7%;
    color: #111111;
    cursor: pointer;
    background-image: url(${arrowRightIcon});
    background-position: calc(100% - 15px) center;
    background-repeat: no-repeat;
    background-size: 24px;

    div:nth-child(1) {
        color: #707b8e;
    }
    div:nth-child(3) {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #b7bcd6;
    }
`
