import { request } from './request'

export const dashboard = (month: number, year: number, withRefunds: boolean, withPrev: boolean) => {
    return request<DashboardResponse>('statistic/dashboard', { month, year, withRefunds, withPrev })
}

interface DashboardResponse {
    chart: Chart
    turnover: Turnover
    data: Data
    regions: Region[]
}

interface Region {
    id: number
    name: string
    countAddresses: number
    countClients: number
    countSales: number
    countPayments: number
    partner: Partner
}

interface Partner {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
    birthDate: string
    sex: string
    createdAt: string
    lastSignInAt: string
    regions: string
    legalEntity: LegalEntity
    totalTurnover: number
    transaction: Transaction[]
}

interface Transaction {
    id: number
    createdAt: string
    amount: number
    percent: number
    serviceFee: number
    reportFileId: string
}

interface LegalEntity {
    inn: string
    name: string
    id: number
    fullName: string
    kpp: string
    ogrn: string
    bankName: string
    bankAddress: string
    bik: string
    bankAccount: string
    taxType: string
    account: string
    details: string
    firstName: string
    lastName: string
    middleName: string
    birthDate: string
    phone: string
    state: string
}

interface Data {
    bankCommission: number
    serviceCommission: number
    averageCheck: number
    countTransactions: number
    countSignUp: number
    countRePayment: number
    countAccounts: number
    countIos: number
    countAndroid: number
}

interface Turnover {
    current: number
    previos: number
}

interface Chart {
    qr: Common
    common: Common
    refund: Common
}

interface Common {
    current: Item[]
    previos: Item[]
}

interface Item {
    date: string
    amount: number
}
