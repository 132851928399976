import {
    Component,
    Avatar,
    Activity,
    Head,
    Label,
    Name,
    NameAndLabel,
    Params,
    Param,
    Value,
    Button,
} from './common.styled'
import { store } from 'store'
import defaultAvatar from './images/avatar.svg'
import { avatarUrl } from 'utils'

interface CommonProps {
    id: number
}

export const Common = (props: CommonProps) => {
    const { id } = props
    const customer = store.customer.get(id)!

    return (
        <Component>
            <Head>
                <Avatar src={customer.avatar ? avatarUrl(customer.avatar) : defaultAvatar} />
                <NameAndLabel>
                    <Name>
                        {customer.last} {customer.first}
                    </Name>
                    <Label type={customer.type} />
                </NameAndLabel>
                <Button to={`/support/create/user/${id}`}>+ Создать тикет</Button>
            </Head>
            <Params>
                <Param>Телефон</Param>
                <Value>{customer.phone}</Value>
                <Param>Пол</Param>
                <Value>{customer.sex === 'male' ? 'мужской' : 'женский'}</Value>
                <Param>Дата рождения</Param>
                <Value>{new Date(customer.birth).toLocaleDateString()}</Value>
            </Params>
            <Activity>
                <Param>Зарегистрирован</Param>
                <Value>{new Date(customer.createdAt).toLocaleDateString()}</Value>
                <Param>Последний вход</Param>
                <Value>{new Date(customer.lastSignInAt).toLocaleDateString()}</Value>
            </Activity>
        </Component>
    )
}
