import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { Default, Reviews } from './routes'

export const Providers = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/reviews/:id`}>
                <Reviews />
            </Route>
            <Route path={path}>
                <Default />
            </Route>
        </Switch>
    )
}
