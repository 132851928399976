import { AdminsItemData } from 'store'
import { avatarUrl, plural, generateAssignUserType } from 'utils'
import { Component, Avatar, Button, Name, Reviews, Stars, Tickets, Type } from './admin.styled'
import defaultAvatar from './images/avatar.svg'

type AdminProps = {
    data: AdminsItemData
}

export const Admin = (props: AdminProps) => {
    const { id, type, firstname, lastname, avatar, rate, countReviews, countTickets, isOwner, isBlocked } = props.data

    return (
        <Component>
            <Avatar src={avatar ? avatarUrl(avatar) : defaultAvatar} isOwner={isOwner} locked={isBlocked} />
            <Name>
                {lastname} {firstname}
            </Name>
            <Type>{generateTypeName(props.data)}</Type>
            <Tickets to={`/support/${generateAssignUserType(type)}/${id}`}>
                {plural(countTickets, 'тикет', 'тикета', 'тикетов')}
            </Tickets>
            <Stars>{rate.toFixed(1)}</Stars>
            <Reviews to={`/admins/reviews/${id}`}>{plural(countReviews, 'отзыв', 'отзыва', 'отзывов')}</Reviews>
            <Button to={`/support/create/admin/${id}`}>Создать тикет</Button>
            <Button to={`/admins/${id}`}>Карточка</Button>
        </Component>
    )
}

const generateTypeName = (data: AdminsItemData) => {
    if (data.isOwner) return 'Владелец'

    switch (data.type) {
        case 'admin':
            return 'Администратор'
        case 'analyst':
            return 'Аналитик'
        case 'partner':
            return 'Партнёр'
        case 'support':
            return 'Поддержка'
    }

    throw new Error(`Unknown user type ${data.type}`)
}
