import { actions } from 'actions'
import { Button, Heading, Popup } from 'components/ui'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { PureComponent } from 'react'
import { testEmail, testEmailChar } from 'utils'
import { Desc, Buttons, EmailInput, Bubble, BubbleOption, Inn, InnInput } from './invite.styled'

interface InvitePopupProps {
    onSend: (email: string, inn: string) => void
    onClose: () => void
}

@observer
export class InvitePopup extends PureComponent<InvitePopupProps> {
    @observable
    private email = ''
    @observable
    private emailError = ''
    @observable
    private inn = ''
    @observable
    private innError = ''
    @observable.ref
    private innSuggestions = [] as InnInfo[]

    constructor(props: InvitePopupProps) {
        super(props)
        makeObservable(this)
    }

    @computed
    get innResolved() {
        return this.innSuggestions.length && this.innSuggestions[0].data.inn === this.inn
    }

    @action
    handleEmailChange(email: string) {
        this.emailError = ''
        this.email = email
            .split('')
            .filter((l) => testEmailChar(l))
            .join('')
    }

    @action
    handleInnChange(v: string) {
        if (v !== '') {
            actions
                .getInnInfo(v)
                .then(
                    action((list) => {
                        this.innSuggestions = list.slice(0, 5)

                        if (!!v && !list.length) {
                            throw new Error('error')
                        }
                    })
                )
                .catch(
                    action(() => {
                        this.innSuggestions = []
                        this.innError = 'Такой ИНН не существует'
                    })
                )
        }
        this.innError = ''
        this.inn = v
    }

    async handleSendClick() {
        const { email, inn, innResolved } = this

        runInAction(() => {
            if (email === '') this.emailError = 'Введите email'
            if (!!email && !testEmail(email)) this.emailError = 'Некорректный e-mail'
            if (!innResolved) this.innError = 'Введите корректный ИНН'
        })

        if (this.emailError || this.innError) return

        try {
            await this.props.onSend(email, inn)
        } catch (e) {
            if (e === 'EMAIL_ALREADY_EXIST') {
                runInAction(() => (this.emailError = 'Этот email уже зарегистрирован в системе'))
            } else {
                throw e
            }
        }
    }

    render() {
        const { onClose } = this.props
        const { email, emailError, inn, innError, innResolved, innSuggestions } = this

        return (
            <Popup width={486}>
                <Heading>Приглашение</Heading>
                <Desc>Для приглашения провайдера укажите адрес электронной почты провайдера</Desc>
                <EmailInput
                    value={email}
                    error={emailError}
                    onChange={(e) => this.handleEmailChange(e.target.value)}
                    placeholder="E-mail"
                />
                <Inn>
                    <InnInput
                        placeholder="ИНН"
                        value={inn}
                        error={innError}
                        onChange={(e) => this.handleInnChange(e.target.value)}
                    />
                    {!innResolved && !!innSuggestions.length && (
                        <Bubble>
                            {innSuggestions.map((i) => (
                                <BubbleOption key={i.data.inn} onClick={() => this.handleInnChange(i.data.inn)}>
                                    <div>{i.data.name.short_with_opf}</div>
                                    <div>{i.data.inn}</div>
                                </BubbleOption>
                            ))}
                        </Bubble>
                    )}
                </Inn>
                <Buttons>
                    <Button secondary onClick={onClose}>
                        Отмена
                    </Button>
                    <Button onClick={() => this.handleSendClick()}>Отправить</Button>
                </Buttons>
            </Popup>
        )
    }
}

export interface InnInfo {
    value: string
    unrestricted_value: string
    data: Data2
}

interface Data2 {
    kpp: string
    capital?: any
    management: Management
    founders?: any
    managers?: any
    predecessors?: any
    successors?: any
    branch_type: string
    branch_count: number
    source?: any
    qc?: any
    hid: string
    type: string
    state: State
    opf: Opf
    name: LegalName
    inn: string
    ogrn: string
    okpo: string
    okato: string
    oktmo: string
    okogu: string
    okfs: string
    okved: string
    okveds?: any
    authorities?: any
    documents?: any
    licenses?: any
    finance: Finance
    address: Address
    phones?: any
    emails?: any
    ogrn_date: number
    okved_type: string
    employee_count?: any
}

interface Address {
    value: string
    unrestricted_value: string
    data: Data
}

interface Data {
    postal_code: string
    country: string
    country_iso_code: string
    federal_district: string
    region_fias_id: string
    region_kladr_id: string
    region_iso_code: string
    region_with_type: string
    region_type: string
    region_type_full: string
    region: string
    area_fias_id: string
    area_kladr_id: string
    area_with_type: string
    area_type: string
    area_type_full: string
    area: string
    city_fias_id?: any
    city_kladr_id?: any
    city_with_type?: any
    city_type?: any
    city_type_full?: any
    city?: any
    city_area?: any
    city_district_fias_id?: any
    city_district_kladr_id?: any
    city_district_with_type?: any
    city_district_type?: any
    city_district_type_full?: any
    city_district?: any
    settlement_fias_id: string
    settlement_kladr_id: string
    settlement_with_type: string
    settlement_type: string
    settlement_type_full: string
    settlement: string
    street_fias_id: string
    street_kladr_id: string
    street_with_type: string
    street_type: string
    street_type_full: string
    street: string
    house_fias_id: string
    house_kladr_id: string
    house_cadnum?: any
    house_type: string
    house_type_full: string
    house: string
    block_type?: any
    block_type_full?: any
    block?: any
    entrance?: any
    floor?: any
    flat_fias_id?: any
    flat_cadnum?: any
    flat_type?: any
    flat_type_full?: any
    flat?: any
    flat_area?: any
    square_meter_price?: any
    flat_price?: any
    postal_box?: any
    fias_id: string
    fias_code: string
    fias_level: string
    fias_actuality_state: string
    kladr_id: string
    geoname_id: string
    capital_marker: string
    okato: string
    oktmo: string
    tax_office: string
    tax_office_legal: string
    timezone: string
    geo_lat: string
    geo_lon: string
    beltway_hit?: any
    beltway_distance?: any
    metro?: any
    qc_geo: string
    qc_complete?: any
    qc_house?: any
    history_values?: any
    unparsed_parts?: any
    source: string
    qc: string
}

interface Finance {
    tax_system?: any
    income?: any
    expense?: any
    debt?: any
    penalty?: any
    year?: any
}

interface LegalName {
    full_with_opf: string
    short_with_opf: string
    latin?: any
    full: string
    short: string
    payment: string
}

interface Opf {
    type: string
    code: string
    full: string
    short: string
}

interface State {
    status: string
    code?: any
    actuality_date: number
    registration_date: number
    liquidation_date?: any
}

interface Management {
    name: string
    post: string
    disqualified?: any
}
