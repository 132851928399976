import { PureComponent } from 'react'
import {
    Component,
    Head,
    Stats,
    Small,
    Money,
    LastMoney,
    Trend,
    CurrentPeriod,
    LastPeriod,
    Chart,
    PaymentType,
    Foot,
    Space,
    ChartTooltip,
} from './graph.styled'
import { DatePicker, DatePickerValue, ToggleInput } from 'components/ui'
import { LineChart, Line, CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip } from 'recharts'
import { store } from 'store'
import { formatMoney } from 'utils'
import { observer } from 'mobx-react'

@observer
export class Graph extends PureComponent {
    handleMonthYearChange({ month, year }: DatePickerValue) {
        store.dashboard.setParams({ month, year })
    }

    handleRefundsChange(withRefunds: boolean) {
        store.dashboard.setParams({ withRefunds })
    }

    handleShowPrevChange(withPrev: boolean) {
        store.dashboard.setParams({ withPrev })
    }

    generateChartData() {
        type Item = {
            name: number
            common: number
            commonPrev?: number
            qr: number
            qrPrev?: number
            refund?: number
            refundPrev?: number
        }

        const { withPrev, withRefunds, month, year } = store.dashboard.getParams()
        const { chart } = store.dashboard.get()
        const acc = [] as Item[]
        const max = new Date(year, month, 0).getDate()

        for (let i = 0; i < max; i++) {
            const item = { name: i + 1 } as Item

            if (i < chart.common.current.length) {
                item.common = chart.common.current[i].amount
            }
            if (i < chart.qr.current.length) {
                item.qr = chart.qr.current[i].amount
            }
            if (withPrev) {
                if (i < chart.common.previos.length) {
                    item.commonPrev = chart.common.previos[i].amount
                }
                if (i < chart.qr.previos.length) {
                    item.qrPrev = chart.qr.previos[i].amount
                }
            }
            if (withRefunds) {
                if (i < chart.refund.current.length) {
                    item.refund = chart.refund.current[i].amount
                }
                if (withPrev) {
                    if (i < chart.refund.previos.length) {
                        item.refundPrev = chart.refund.previos[i].amount
                    }
                }
            }

            acc.push(item)
        }

        return acc
    }

    render() {
        const { month, year, withPrev, withRefunds } = store.dashboard.getParams()
        const { turnover } = store.dashboard.get()
        const data = this.generateChartData()

        return (
            <Component>
                <Head>
                    <DatePicker month={month} year={year} onChange={(value) => this.handleMonthYearChange(value)} />
                    <Stats>
                        <CurrentPeriod>
                            <Small>Оборот за период</Small>
                            <Money>{formatMoney(turnover.current)}</Money>
                        </CurrentPeriod>
                        <LastPeriod>
                            {withPrev &&
                                (() => {
                                    const direction = turnover.previos < turnover.current ? 'down' : 'up'
                                    const percents =
                                        turnover.previos === 0
                                            ? 100
                                            : parseInt(
                                                  (
                                                      (Math.abs(turnover.current - turnover.previos) /
                                                          turnover.previos) *
                                                      100
                                                  ).toFixed(3)
                                              )
                                    const desc =
                                        turnover.previos < turnover.current
                                            ? `меньше на ${percents}%`
                                            : `больше на ${percents}%`

                                    return (
                                        <>
                                            <Small>Сумма прошлого периода</Small>
                                            <LastMoney>{formatMoney(turnover.previos)}</LastMoney>
                                            <Trend direction={direction}>{desc}</Trend>
                                        </>
                                    )
                                })()}
                        </LastPeriod>
                    </Stats>
                </Head>
                <Chart>
                    <ResponsiveContainer>
                        <LineChart data={data}>
                            <CartesianGrid vertical={false} strokeDasharray="5 5" />
                            <XAxis tickLine={false} axisLine={false} tick={<AxisXTick />} dataKey="name" />
                            <YAxis tickLine={false} axisLine={false} tick={<AxisYTick />} />
                            <Tooltip
                                content={
                                    <CustomTooltip withPrev={withPrev} withRefunds={withRefunds} currentMonth={month} />
                                }
                            />
                            <Line dataKey="qr" stroke="#68C376" dot={false} activeDot={true} strokeWidth={2} />
                            <Line dataKey="common" stroke="#E8A944" dot={false} activeDot={true} strokeWidth={2} />
                            <Line dataKey="refund" stroke="#3c83ec" dot={false} activeDot={true} strokeWidth={2} />
                            <Line dataKey="qrPrev" stroke="#bcf1c4" dot={false} activeDot={true} strokeWidth={2} />
                            <Line dataKey="commonPrev" stroke="#f8d6a0" dot={false} activeDot={true} strokeWidth={2} />
                            <Line dataKey="refundPrev" stroke="#8ebafc" dot={false} activeDot={true} strokeWidth={2} />
                        </LineChart>
                    </ResponsiveContainer>
                </Chart>
                <Foot>
                    <PaymentType color="green">QR платежи</PaymentType>
                    <PaymentType color="orange">Direct платежи</PaymentType>
                    <Space />
                    <ToggleInput
                        onChange={(value) => this.handleRefundsChange(value)}
                        checked={withRefunds}
                        labelOnLeft
                    >
                        Показать возмещения
                    </ToggleInput>
                    <Space width={37} />
                    <ToggleInput onChange={(value) => this.handleShowPrevChange(value)} checked={withPrev} labelOnLeft>
                        Cравнить с прошлым периодом
                    </ToggleInput>
                </Foot>
            </Component>
        )
    }
}

type AxisTickProps = {
    x?: number
    y?: number
    payload?: any
}

const AxisXTick = (props: AxisTickProps) => {
    const { x, y, payload } = props

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={25} dx={0} fontSize="14px" fontFamily="Roboto" textAnchor="middle" fill="#707B8E">
                {payload.value}
            </text>
        </g>
    )
}

const AxisYTick = (props: AxisTickProps) => {
    const { x, y, payload } = props

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={5} dx={-55} fontSize="14px" fontFamily="Roboto" textAnchor="start" fill="#707B8E">
                {payload.value}
            </text>
        </g>
    )
}

const currency = Intl.NumberFormat('ru', { style: 'currency', currency: 'RUB', minimumFractionDigits: 2 })

interface CustomTooltipProps {
    withPrev: boolean
    withRefunds: boolean
    currentMonth: number
    active?: boolean
    payload?: { name: string; value: number }[]
}

const CustomTooltip = (props: CustomTooltipProps) => {
    const { active, payload, withPrev, currentMonth, withRefunds } = props

    if (!active) {
        return null
    }

    const data = payload!.reduce(
        (acc, i) => {
            acc[i.name as 'qr' | 'common' | 'qrPrev' | 'commonPrev' | 'refund' | 'refundPrev'] = i.value
            return acc
        },
        {} as {
            qr: number
            common: number
            qrPrev: number
            commonPrev: number
            refund: number
            refundPrev: number
        }
    )

    return (
        <ChartTooltip>
            {withPrev && <span>{months[currentMonth - 1]}</span>}
            <div>
                <div>{currency.format(data.common)}</div>
                <div>Direct платежи</div>
                <div>{currency.format(data.qr)}</div>
                <div>QR платежи</div>
                {withRefunds && (
                    <>
                        <div>{currency.format(data.refund)}</div>
                        <div>Возмещения</div>
                    </>
                )}
            </div>
            {withPrev && (
                <>
                    <span>{months[currentMonth - 1 === 0 ? 11 : currentMonth - 2]}</span>
                    <div>
                        <div>{currency.format(data.commonPrev)}</div>
                        <div>Direct платежи</div>
                        <div>{currency.format(data.qrPrev)}</div>
                        <div>QR платежи</div>
                        {withRefunds && (
                            <>
                                <div>{currency.format(data.refundPrev)}</div>
                                <div>Возмещения</div>
                            </>
                        )}
                    </div>
                </>
            )}
        </ChartTooltip>
    )
}

const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
]
