import { PureComponent } from 'react'
import { CabinetContext } from 'components/app/cabinet/context'
import { EditData, Editor } from '../../editor'
import { match, withRouter } from 'react-router-dom'
import { actions } from 'actions'
import { observer } from 'mobx-react'
import { store } from 'store'
import { Loader } from 'components/ui'

interface EditProps {
    match?: match<{ id: string }>
}

@(withRouter as any)
@observer
export class Edit extends PureComponent<EditProps> {
    static contextType = CabinetContext

    get adminId() {
        return parseInt(this.props.match!.params.id)
    }

    componentDidMount() {
        this.context.setTitle('Редактирование профиля')

        store.admin.resolve(this.adminId)
    }

    async handleEditorSave(data: EditData) {
        const id = parseInt(this.props.match!.params.id)

        await actions.saveAdmin({ id, ...data })

        const props = this.props as any

        props.history.push(`/admins/${id}`)
    }

    render() {
        const id = parseInt(this.props.match!.params.id)

        if (!store.admin.isResolved(id)) {
            return <Loader />
        }

        const admin = store.admin.get(id)!

        return <Editor mode="edit" data={admin} onSave={(data) => this.handleEditorSave(data)} />
    }
}
