import { withRouter, match } from 'react-router'
import { Link } from 'react-router-dom'
import { CabinetContext } from 'components/app/cabinet/context'
import { Component, Breadcrumbs, Title, Label, Button, Customer, Avatar, Name, Spacer } from './reviews.styled'
import { PureComponent } from 'react'
import { store } from 'store'
import { Review } from './review'
import { observer } from 'mobx-react'
import { Loader } from 'components/ui'
import { avatarUrl, createScrollAutoloader } from 'utils'

interface ReviewsProps {
    match?: match<{ id: string }>
}

@(withRouter as any)
@observer
export class Reviews extends PureComponent<ReviewsProps> {
    static contextType = CabinetContext
    private scrollAutoloadDisposer!: () => void

    get customerId() {
        return parseInt(this.props.match!.params.id)
    }

    componentDidMount() {
        this.context.setTitle('Отзывы об абоненте')

        const { customerId } = this

        store.customer.resolve(customerId)
        store.reviews.customer.resolve(customerId)

        this.scrollAutoloadDisposer = createScrollAutoloader(() => store.reviews.customer.resolveMore(customerId))
    }

    componentWillUnmount() {
        this.scrollAutoloadDisposer()
    }

    render() {
        const { customerId } = this

        if (!store.customer.isResolved(customerId) || !store.reviews.customer.isResolved(customerId)) {
            return <Loader />
        }

        const customer = store.customer.get(customerId)!
        const { reviews, length } = store.reviews.customer.get(customerId)!

        return (
            <Component>
                <Breadcrumbs>
                    <Link to="/customers">Абоненты</Link>
                    <Link to={`/customers/${customerId}`}>ID{customerId}</Link>
                    <span>Отзывы</span>
                </Breadcrumbs>
                <Customer>
                    <Avatar src={avatarUrl(customer.avatar)} />
                    <Name>
                        {customer.last} {customer.first} {customer.patronymic}
                    </Name>
                    <Label type={customer.type} />
                    <Spacer />
                    <Button to={`/support/create/user/${customer.id}`}>+ Создать тикет</Button>
                </Customer>
                <Title>
                    <b>Отзывы</b>
                    <span>{length}</span>
                </Title>
                {reviews.map((data) => (
                    <Review key={data.id} data={data} />
                ))}
            </Component>
        )
    }
}
