import styled from 'styled-components'
import linkIcon from './images/link.svg'
import { Link } from 'react-router-dom'

export const Component = styled.div`
    position: relative;
    padding-left: 56px;
    margin-bottom: 30px;
`

export const AvatarLink = styled(Link)`
    position: absolute;
    left: 0px;
    top: 0px;
    cursor: pointer;
`

export const Avatar = styled.div<{ src: string }>`
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 48px;
    height: 48px;
    border-radius: 50%;
`

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Author = styled(Link)`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #39434b;
    text-decoration: none;

    :hover {
        text-decoration: underline;
        cursor: pointer;
    }
`

export const System = styled.div`
    flex: 1;
    color: #39434b;

    a {
        font: inherit;
    }
`

export const Datetime = styled.div`
    font-size: 14px;
    line-height: 20px;

    span:nth-child(1) {
        color: #39434b;
    }
    span:nth-child(2) {
        margin-left: 14px;
        color: #b7bcd6;
    }
`

export const Body = styled.div`
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
    margin-top: 10px;
    font-size: 16px;
    line-height: 127.3%;
    color: #111111;
    white-space: pre-wrap;
`

export const Images = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 14px;

    :not(:last-child) {
        margin-bottom: 16px;
    }
`

export const Img = styled.div<{ src: string }>`
    background-image: url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 113px;
    height: 113px;
    border-radius: 8px;
    cursor: pointer;
`

export const Files = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    :not(:first-child) {
        margin-top: 16px;
    }

    & > div {
        color: #39434b;
        font-weight: 500;
        font-size: 14px;

        :hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
`

export const Links = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 8px;

    :not(:first-child) {
        margin-top: 16px;
    }

    & > a {
        font-family: inherit;
        color: #39434b;
        font-weight: 500;
        font-size: 14px;
        padding: 0 12px 0 42px;
        background-image: url(${linkIcon});
        background-position: 0px 2px;
        background-repeat: no-repeat;

        :hover {
            text-decoration: underline;
            cursor: pointer;
        }
    }
`
