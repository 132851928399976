import { observer } from 'mobx-react'
import React from 'react'
import { store } from 'store'
import { plural } from 'utils'
import { Component, Title, Rating, Region, RegionInfo, RegionName, RegionPayments, RegionStats } from './regions.styled'

@observer
export class Regions extends React.Component {
    render() {
        const { regions } = store.dashboard.get()

        return (
            <Component>
                <Title>Самые активные регионы</Title>
                <Rating>
                    {regions.slice(0, 6).map((data) => (
                        <Region key={data.id}>
                            <RegionName>{data.name}</RegionName>
                            <RegionInfo>
                                <RegionStats>
                                    {plural(data.countClients, 'абонент', 'абонента', 'абонентов')}{' '}
                                    {plural(data.countAddresses, 'адрес', 'адреса', 'адресов')}
                                </RegionStats>
                                <RegionPayments>
                                    {plural(data.countPayments, 'платёж', 'платежа', 'платежей')}
                                </RegionPayments>
                            </RegionInfo>
                        </Region>
                    ))}
                </Rating>
            </Component>
        )
    }
}
