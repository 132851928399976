import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import starIcon from './images/star.svg'
import lockIcon from './images/lock.svg'

export const Component = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    border-radius: 10px;
    height: 91px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 0 20px;
`

export const Avatar = styled.div<{ src: string; isOwner: boolean; locked: boolean }>`
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    position: relative;

    ${(props) =>
        props.isOwner &&
        css`
            border: 2px solid #68c376;
        `};

    ${(props) =>
        props.locked &&
        css`
            :before {
                content: '';
                display: block;
                position: absolute;
                background-image: url(${lockIcon});
                background-repeat: no-repeat;
                width: 19px;
                height: 19px;
                right: -4px;
                bottom: -4px;
            }
        `};
`

export const Name = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #111111;
    margin-left: 16px;
    flex: 1;
`

export const Type = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #b7bcd6;
    flex: 0 0 150px;
`

export const Stars = styled.div`
    background-image: url(${starIcon});
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 22px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #707b8e;
    flex: 0 0 60px;
`

export const Tickets = styled(Link)`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #68c376;
    flex: 0 0 100px;
    text-decoration: none;

    :hover {
        text-decoration: underline;
    }
`

export const Reviews = styled(Link)`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #68c376;
    flex: 0 0 80px;
    text-decoration: none;

    :hover {
        text-decoration: underline;
    }
`

export const Button = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b7bcd6;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #707b8e;
    padding: 0 25px;
    height: 36px;
    margin-left: 15px;
    text-decoration: none;
`
