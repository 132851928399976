import styled from 'styled-components'

export const Component = styled.div`
    display: flex;
    align-items: center;
    height: 16px;
    column-gap: 5px;
`

export const Counter = styled.span`
    font-size: 14px;
    padding-top: 3px;
    letter-spacing: -0.24px;
    color: #707b8e;
    margin-right: 2px;
`

export const Star = styled.span<{ type: 'empty' | 'full' | 'half' }>`
    background-image: url(${(props) => require(`./images/${props.type}.svg`).default});
    background-size: 16px;
    background-repeat: no-repeat;
    width: 16px;
    height: 16px;
`
