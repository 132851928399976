import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { PureComponent } from 'react'
import { YEAR } from 'utils'
import { Arrow, Component, CurrentValue } from './year_picker.styled'
import arrowLeftIcon from './images/arrow_left.svg'
import arrowRightIcon from './images/arrow_right.svg'

interface YearPickerProps {
    value?: number
    onChange?: (value: number) => void
}

@observer
export class YearPicker extends PureComponent<YearPickerProps> {
    @observable
    private value: number

    constructor(props: YearPickerProps) {
        super(props)
        makeObservable(this)
        const { value = YEAR } = props
        this.value = value
    }

    @action
    componentDidUpdate(prevProps: YearPickerProps) {
        const { value } = this.props

        if (value && value !== prevProps.value) {
            this.value = value
        }
    }

    @action
    setValue(value: number) {
        this.value = value

        if (this.props.onChange) {
            this.props.onChange(this.value)
        }
    }

    nextYear() {
        this.setValue(this.value + 1)
    }

    prevYear() {
        this.setValue(this.value - 1)
    }

    render() {
        return (
            <Component tabIndex={1}>
                <Arrow icon={arrowLeftIcon} substrate onClick={() => this.prevYear()} />
                <CurrentValue>{this.value}</CurrentValue>
                <Arrow icon={arrowRightIcon} substrate onClick={() => this.nextYear()} />
            </Component>
        )
    }
}
