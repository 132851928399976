import styled, { css } from 'styled-components'
import InputMask from 'react-input-mask'

export const Component = styled.div`
    position: relative;
`

export const Label = styled.label`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #39434b;
    margin-bottom: 2px;
    height: 17px;

    :empty {
        display: none;
    }
`

export const Input = styled(InputMask)<{ icon?: string; error: 'true' | 'false' }>`
    display: block;
    width: 100%;
    box-sizing: border-box;
    padding: 17px 20px;
    height: 44px;
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => (props.error === 'true' ? '#EB5757 !important' : '#eaecf0')};
    border-radius: 5px;
    color: #111111;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;

    ${(props) =>
        props.icon &&
        css`
            background-image: url(${props.icon});
            background-position: 15px center;
            background-repeat: no-repeat;
            padding-left: 52px;
        `};

    ::placeholder,
    ::-webkit-input-placeholder,
    :-ms-input-placeholder {
        font-size: 16px;
        line-height: 127.3%;
        color: #707b8e;
    }

    :focus {
        border: 1px solid #a9b6ff;
        box-sizing: border-box;
        border-radius: 4px;
        outline: none;
    }
`

export const Error = styled.div`
    color: #eb5757;
    font-family: Roboto;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    position: absolute;
    left: 0;
    top: 100%;
`
