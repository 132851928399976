import { store } from 'store'
import { Component, Logo, MenuItem, MenuItemIcon, MenuItemName, Section } from './aside.styled'

export const Aside = () => {
    const user = store.user.get()!

    return (
        <Component>
            <Logo />
            {['admin', 'analyst'].includes(user.type) && (
                <MenuItem to="/" exact>
                    <MenuItemIcon name="dashboard" />
                    <MenuItemName>Панель управления</MenuItemName>
                </MenuItem>
            )}
            <Section>
                {['admin'].includes(user.type) && (
                    <MenuItem to="/admins">
                        <MenuItemIcon name="admins" />
                        <MenuItemName>Администраторы</MenuItemName>
                    </MenuItem>
                )}
                {['admin', 'analyst', 'partner'].includes(user.type) && (
                    <MenuItem to="/providers">
                        <MenuItemIcon name="providers" />
                        <MenuItemName>Провайдеры</MenuItemName>
                    </MenuItem>
                )}
                {['admin', 'analyst', 'support'].includes(user.type) && (
                    <MenuItem to="/customers">
                        <MenuItemIcon name="customers" />
                        <MenuItemName>Абоненты</MenuItemName>
                    </MenuItem>
                )}
            </Section>
            <Section>
                {['admin', 'analyst', 'support'].includes(user.type) && (
                    <MenuItem to="/payings">
                        <MenuItemIcon name="payments" />
                        <MenuItemName>Оплаты ЖКХ</MenuItemName>
                    </MenuItem>
                )}
                {['admin', 'analyst', 'support'].includes(user.type) && (
                    <MenuItem to="/objects">
                        <MenuItemIcon name="objects" />
                        <MenuItemName>Объекты</MenuItemName>
                    </MenuItem>
                )}
                {['admin'].includes(user.type) && (
                    <MenuItem to="/sales">
                        <MenuItemIcon name="ads" />
                        <MenuItemName>Объявления</MenuItemName>
                    </MenuItem>
                )}
            </Section>
            <Section>
                {['admin', 'analyst', 'partner', 'support'].includes(user.type) && (
                    <MenuItem to="/support/my">
                        <MenuItemIcon name="support" />
                        <MenuItemName>Поддержка</MenuItemName>
                    </MenuItem>
                )}
                {['admin', 'analyst'].includes(user.type) && (
                    <MenuItem to="/mailings">
                        <MenuItemIcon name="mailings" />
                        <MenuItemName>Рассылки</MenuItemName>
                    </MenuItem>
                )}
                {['admin', 'analyst', 'support'].includes(user.type) && (
                    <MenuItem to="/monitoring">
                        <MenuItemIcon name="monitoring" />
                        <MenuItemName>Мониторинг</MenuItemName>
                    </MenuItem>
                )}
            </Section>
        </Component>
    )
}
