import styled, { css } from 'styled-components'

export const Component = styled.label<{ disabled: boolean }>`
    display: flex;
    align-items: center;
    cursor: pointer;

    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;

            img {
                background: #eaecf0;
            }
        `}

    input {
        display: none;
    }

    img {
        width: 24px;
        height: 24px;
        border-radius: 6px;
    }
`

export const Label = styled.div`
    margin-left: 16px;
    flex: 1;
    font-size: 18px;
    user-select: none;

    :empty {
        display: none;
    }
`
