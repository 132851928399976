import { observer } from 'mobx-react'
import { store } from 'store'
import { formatMoney, plural } from 'utils'
import { Component, Block, Summ, Desk } from './info.styled'

export const Info = observer(() => {
    const { data } = store.dashboard.get()

    return (
        <Component>
            <Block>
                <Summ>{formatMoney(data.bankCommission)}</Summ>
                <Desk>Комиссия банка по эквайрингу за период</Desk>
            </Block>
            <Block>
                <Summ>{formatMoney(data.serviceCommission)}</Summ>
                <Desk>Комиссия сервиса за период от пользователей</Desk>
            </Block>
            <Block>
                <Summ>{formatMoney(data.averageCheck)}</Summ>
                <Desk>
                    Средний чек из {plural(data.countTransactions, 'транзакции', 'транзакций', 'транзакций')} абонентов
                </Desk>
            </Block>
        </Component>
    )
})
