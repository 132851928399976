import { request } from './request'

export const list = (month: number, year: number, query: string) => {
    return request<ListResponse>('import/list', { month, year, query })
}

interface ListResponse {
    imports: GroupImports
}

interface GroupImports {
    created: Item[]
    successed: Item[]
    breaked: Item[]
    failed: Item[]
}

interface Item {
    id: number
    state: string
    provider: Provider
    fileId: string
    errorFileId: string
    type: 'api' | 'csv'
    prepCount: number
    errorCount: number
    finalCount: number
    createdAt: string
    updatedAt: string
}

interface Provider {
    id: number
    name: string
    short: string
    shopCode: string
    createdAt: string
    partnerUserId: number
    partnerUser: PartnerUser
    rate: number
    countReviews: number
    countAccounts: number
    balance: number
    subscriptionFee: number
    shortLegalEntity: ShortLegalEntity
}

interface ShortLegalEntity {
    inn: string
    name: string
}

interface PartnerUser {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}
