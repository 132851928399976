import styled from 'styled-components'

export const Element = styled.div`
    display: flex;
    flex-flow: column;
    align-items: center;
    text-align: center;
    margin: auto 30px;

    ::before,
    ::after {
        content: '';
        display: block;
        height: 60px;
    }

    ::after {
        height: 30px;
    }
`

export const Picture = styled.img`
    display: table;
    width: 100%;
    max-width: 195px;
    pointer-events: none;
`

export const Title = styled.h1`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 124.7%;
    text-align: center;
    color: #111111;
    margin-top: 30px;
`

export const Desc = styled.p`
    width: 100%;
    max-width: 570px;
    margin-top: 15px;
    color: #707b8e;
`
