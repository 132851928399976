import styled from 'styled-components'

export const Desc = styled.div`
    font-size: 18px;
    line-height: 150.7%;
    text-align: center;
    color: #39434b;
    margin: 10px 0px 36px;
`

export const Help = styled.div`
    margin-top: 20px;
    font-size: 18px;
    line-height: 30px;
    color: #39434b;
    text-align: center;
`

export const Info = styled.div`
    align-self: stretch;
    padding: 2px 20px;
    background: #f7f8fc;
    border-radius: 7px;
    margin: 16px -12px;
`

export const Row = styled.div`
    display: flex;
    margin: 18px 0px;
    font-size: 16px;
    line-height: 127.3%;
`

export const Name = styled.div`
    flex: 0 0 128px;
    color: #707b8e;
`

export const Value = styled.div`
    color: #111111;
    flex: 1;
    margin-left: 15px;
`

export const CheckboxWrapper = styled.div`
    align-self: stretch;
    margin: 0 -12px 36px;
`

export const Buttons = styled.div`
    display: flex;
    column-gap: 20px;
`
