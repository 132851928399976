import { Button, Heading, Popup } from 'components/ui'
import { Desc, Buttons } from './reset_password.styled'

interface ResetPasswordPopupProps {
    onConfirm: () => void
    onClose: () => void
}

export const ResetPasswordPopup = (props: ResetPasswordPopupProps) => {
    const { onConfirm, onClose } = props

    return (
        <Popup>
            <Heading>Сброс пароля</Heading>
            <Desc>Вы уверенны что вы хотите сбросить пароль?</Desc>
            <Buttons>
                <Button secondary onClick={onClose}>
                    Нет
                </Button>
                <Button onClick={onConfirm}>Да</Button>
            </Buttons>
        </Popup>
    )
}
