import {
    Component,
    Title,
    Inputs,
    Inn,
    InnInput,
    BankInput,
    BillInput,
    Params,
    Name,
    Value,
    Bank,
    Bubble,
    BubbleOption,
} from './partner.styled'

interface PartnerProps {
    inn: string
    innInfo: InnInfo | null
    innSuggestions: InnInfo[]
    innError: string
    onInnChange: (inn: string) => void
    bank: string
    bankInfo: BankInfo | null
    bankSuggestions: BankInfo[]
    bankError: string
    onBankChange: (bic: string) => void
    account: string
    accountError: string
    onAccountChange: (account: string) => void
}

export const Partner = (props: PartnerProps) => {
    const {
        inn,
        innInfo,
        innSuggestions,
        innError,
        onInnChange,
        bank,
        bankInfo,
        bankSuggestions,
        bankError,
        onBankChange,
        account,
        accountError,
        onAccountChange,
    } = props

    return (
        <Component>
            <Title>Платёжный профиль партнёра</Title>
            <Inn>
                <InnInput label="ИНН" value={inn} error={innError} onChange={(e) => onInnChange(e.target.value)} />
                {!innInfo && !!innSuggestions.length && (
                    <Bubble>
                        {innSuggestions.map((i) => (
                            <BubbleOption key={i.data.inn} onClick={() => onInnChange(i.data.inn)}>
                                <div>{i.data.name.short_with_opf}</div>
                                <div>{i.data.inn}</div>
                            </BubbleOption>
                        ))}
                    </Bubble>
                )}
            </Inn>
            {innInfo &&
                (innInfo.isTaxpayer ? (
                    <Params>
                        <Name>Статус</Name>
                        <Value>Самозанятый</Value>
                        <Name>ИНН</Name>
                        <Value>{innInfo.data?.inn}</Value>
                    </Params>
                ) : (
                    <Params>
                        <Name>Полное наименование юридического лица</Name>
                        <Value>{innInfo.data?.name?.full}</Value>
                        <Name>Сокращённое наименование</Name>
                        <Value>{innInfo.data?.name?.short_with_opf}</Value>
                        <Name>Юридический адрес</Name>
                        <Value>{innInfo.data?.address?.unrestricted_value}</Value>
                        <Name>ФИО руководителя юридического лица</Name>
                        <Value>{innInfo.data?.management?.name}</Value>
                        <Name>ИНН</Name>
                        <Value>{innInfo.data?.inn}</Value>
                        <Name>ОГРН</Name>
                        <Value>{innInfo.data?.ogrn}</Value>
                        <Name>КПП</Name>
                        <Value>{innInfo.data?.kpp}</Value>
                    </Params>
                ))}
            <Inputs>
                <Bank>
                    <BankInput
                        label="БИК или наименование банка"
                        value={bank}
                        error={bankError}
                        onChange={(e) => onBankChange(e.target.value)}
                    />
                    {!bankInfo && !!bankSuggestions.length && (
                        <Bubble>
                            {bankSuggestions.map((i) => (
                                <BubbleOption key={i.data.bic} onClick={() => onBankChange(i.data.name.payment)}>
                                    <div>{i.data.name.payment}</div>
                                    <div>{i.data.bic}</div>
                                </BubbleOption>
                            ))}
                        </Bubble>
                    )}
                </Bank>
                <BillInput
                    label="Расчётный счёт"
                    value={account}
                    error={accountError}
                    onChange={(e) => onAccountChange(e.target.value)}
                />
            </Inputs>
            {bankInfo && (
                <Params>
                    <Name>Наименование</Name>
                    <Value>{bankInfo.data.name.payment}</Value>
                    <Name>БИК</Name>
                    <Value>{bankInfo.data.bic}</Value>
                    <Name>Юридический адрес</Name>
                    <Value>{bankInfo.data.address.unrestricted_value}</Value>
                    <Name>Корреспонденский счёт</Name>
                    <Value>{bankInfo.data.correspondent_account}</Value>
                </Params>
            )}
        </Component>
    )
}

export interface BankInfo {
    value: string
    unrestricted_value: string
    data: BankData2
}

interface BankData2 {
    opf: Opf
    name: LegalName
    bic: string
    swift: string
    inn: string
    kpp: string
    okpo?: any
    correspondent_account: string
    treasury_accounts?: any
    registration_number: string
    payment_city: string
    state: State
    rkc?: any
    cbr?: any
    address: Address
    phones?: any
}

export interface InnInfo {
    value: string
    unrestricted_value: string
    isTaxpayer: boolean
    data: Data2
}

interface Data2 {
    kpp: string
    capital?: any
    management: Management
    founders?: any
    managers?: any
    predecessors?: any
    successors?: any
    branch_type: string
    branch_count: number
    source?: any
    qc?: any
    hid: string
    type: string
    state: State
    opf: Opf
    name: LegalName
    inn: string
    ogrn: string
    okpo: string
    okato: string
    oktmo: string
    okogu: string
    okfs: string
    okved: string
    okveds?: any
    authorities?: any
    documents?: any
    licenses?: any
    finance: Finance
    address: Address
    phones?: any
    emails?: any
    ogrn_date: number
    okved_type: string
    employee_count?: any
}

interface Address {
    value: string
    unrestricted_value: string
    data: Data
}

interface Data {
    postal_code: string
    country: string
    country_iso_code: string
    federal_district: string
    region_fias_id: string
    region_kladr_id: string
    region_iso_code: string
    region_with_type: string
    region_type: string
    region_type_full: string
    region: string
    area_fias_id: string
    area_kladr_id: string
    area_with_type: string
    area_type: string
    area_type_full: string
    area: string
    city_fias_id?: any
    city_kladr_id?: any
    city_with_type?: any
    city_type?: any
    city_type_full?: any
    city?: any
    city_area?: any
    city_district_fias_id?: any
    city_district_kladr_id?: any
    city_district_with_type?: any
    city_district_type?: any
    city_district_type_full?: any
    city_district?: any
    settlement_fias_id: string
    settlement_kladr_id: string
    settlement_with_type: string
    settlement_type: string
    settlement_type_full: string
    settlement: string
    street_fias_id: string
    street_kladr_id: string
    street_with_type: string
    street_type: string
    street_type_full: string
    street: string
    house_fias_id: string
    house_kladr_id: string
    house_cadnum?: any
    house_type: string
    house_type_full: string
    house: string
    block_type?: any
    block_type_full?: any
    block?: any
    entrance?: any
    floor?: any
    flat_fias_id?: any
    flat_cadnum?: any
    flat_type?: any
    flat_type_full?: any
    flat?: any
    flat_area?: any
    square_meter_price?: any
    flat_price?: any
    postal_box?: any
    fias_id: string
    fias_code: string
    fias_level: string
    fias_actuality_state: string
    kladr_id: string
    geoname_id: string
    capital_marker: string
    okato: string
    oktmo: string
    tax_office: string
    tax_office_legal: string
    timezone: string
    geo_lat: string
    geo_lon: string
    beltway_hit?: any
    beltway_distance?: any
    metro?: any
    qc_geo: string
    qc_complete?: any
    qc_house?: any
    history_values?: any
    unparsed_parts?: any
    source: string
    qc: string
}

interface Finance {
    tax_system?: any
    income?: any
    expense?: any
    debt?: any
    penalty?: any
    year?: any
}

interface LegalName {
    full_with_opf: string
    short_with_opf: string
    latin?: any
    full: string
    short: string
    payment: string
}

interface Opf {
    type: string
    code: string
    full: string
    short: string
}

interface State {
    status: string
    code?: any
    actuality_date: number
    registration_date: number
    liquidation_date?: any
}

interface Management {
    name: string
    post: string
    disqualified?: any
}
