import { Activity, Params, Param, Value } from './common_info.styled'
import { store } from 'store'
import { formatPhone } from 'utils'

type CommonInfoProps = {
    id: number
}

export const CommonInfo = (props: CommonInfoProps) => {
    const { id } = props
    const admin = store.admin.get(id)!

    return (
        <>
            <Params>
                <Param>Телефон</Param>
                <Value>{formatPhone(admin.phone)}</Value>
                <Param>E-mail</Param>
                <Value>{admin.email}</Value>
                <Param>Пол</Param>
                <Value>{admin.sex === 'male' ? 'мужской' : 'женский'}</Value>
                <Param>Дата рождения</Param>
                <Value>{new Date(admin.birthDate).toLocaleDateString()}</Value>
            </Params>
            <Activity>
                <Param>Зарегистрирован</Param>
                <Value>{new Date(admin.createdAt).toLocaleDateString()}</Value>
                <Param>Последний вход</Param>
                <Value>{admin.lastSignInAt ? new Date(admin.lastSignInAt).toLocaleDateString() : 'Не известно'}</Value>
            </Activity>
        </>
    )
}
