import { request } from './request'

export const list = (limit: number, offset: number, providerId: number, clientId: number, userId: number) => {
    return request<ListResponse>('review/list', { limit, offset, providerId, clientId, userId })
}

export const remove = (id: number) => {
    return request<RemoveResponse>('review/delete', { id })
}

interface ListResponse {
    reviews: Review[]
    length: number
}

interface RemoveResponse {}

interface Review {
    id: number
    user: User
    createdAt: string
    rate: number
    message: string
}

interface User {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}
