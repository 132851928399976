import { api } from 'api'
import { observable } from 'mobx'

const STORE = observable.map<number, ProviderData>()

export const resolve = async (id: number) => {
    const { provider } = await api.provider.get(id)
    STORE.set(id, provider)
}

export const isResolved = (id: number) => {
    return STORE.has(id)
}

export const get = (id: number) => {
    return STORE.get(id)
}

type ProviderKind = 'valid' | 'novalid' | 'isSpec' | 'drafts' | 'block'

interface ProviderData {
    id: number
    kind: ProviderKind
    isBlocked: boolean
    name: string
    short: string
    shopCode: string
    createdAt: string
    partnerUserId: number
    partnerUser: PartnerUser
    rate: number
    countReviews: number
    countAccounts: number
    countActiveAccounts: number
    balance: number
    subscriptionFee: number
    shortLegalEntity: ShortLegalEntity
    email: string
    encode: string
    service: string
    logo: string
    phone: string
    siteUrl: string
    lastChangePassword: string
    legalEntity: LegalEntity
    emails: Email[]
}

interface Email {
    id: number
    email: string
    type: string
    state: string
}

interface LegalEntity {
    inn: string
    name: string
    id: number
    fullName: string
    kpp: string
    ogrn: string
    bankName: string
    bankAddress: string
    bik: string
    bankAccount: string
    taxType: string
    account: string
    details: string
    firstName: string
    lastName: string
    middleName: string
    birthDate: string
    phone: string
    state: string
}

interface ShortLegalEntity {
    inn: string
    name: string
}

interface PartnerUser {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}
