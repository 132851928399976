import { PureComponent } from 'react'
import { CabinetContext } from 'components/app/cabinet/context'
import { store } from 'store'
import { Component, Head, OpenButton, THead, Column, Mail } from './default.styled'
import { Button, Loader } from 'components/ui'
import { NewMailPopup } from './popups'
import { makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { actions } from 'actions'
import { Empty } from './empty'
import { createScrollAutoloader } from 'utils'

type MailingType = 'all' | 'user' | 'client' | 'provider' | 'admin' | 'support' | 'partner' | 'analyst'

interface NewMailingData {
    type: MailingType
    title: string
    description: string
    withButton: boolean
    button: string
    link: string
}

const recepientType = {
    all: 'Все',
    user: 'Пользователи',
    provider: 'Провайдеры',
    admin: 'Администраторы',
    client: 'Абоненты',
    analyst: 'Аналитики',
    support: 'Служба поддержки',
    partner: 'Партнеры',
} as { [k: string]: string }

@observer
export class Default extends PureComponent {
    static contextType = CabinetContext

    @observable
    private newMailPopupVisibility = false

    private detachScrollListener!: () => void

    constructor(props: {}) {
        super(props)
        makeObservable(this)
    }

    componentDidMount() {
        this.context.setTitle('Рассылки')

        store.mailings.resolve()

        this.detachScrollListener = createScrollAutoloader(store.mailings.resolveMore)
    }

    componentWillUnmount() {
        this.detachScrollListener()
    }

    toggleNewMailPopup(visibility?: boolean) {
        this.newMailPopupVisibility = arguments.length ? visibility! : !this.newMailPopupVisibility
    }

    async onSend(data: NewMailingData) {
        await actions.createMailing(data)
        this.toggleNewMailPopup()
    }

    render() {
        if (!store.mailings.isResolved()) {
            return <Loader />
        }

        const { mailings } = store.mailings.get()

        return (
            <Component>
                {this.newMailPopupVisibility && (
                    <NewMailPopup
                        onSend={(v: NewMailingData) => this.onSend(v)}
                        onClose={() => this.toggleNewMailPopup(false)}
                    />
                )}
                <Head>
                    <Button onClick={() => this.toggleNewMailPopup(true)}>+ Создать уведомление</Button>
                </Head>
                {mailings.length === 0 ? (
                    <Empty />
                ) : (
                    <>
                        <THead>
                            <Column flex="1">Заголовок</Column>
                            <Column flex="0 0 200px">Дата</Column>
                            <Column flex="0 0 200px">Получатели</Column>
                            <Column flex="0 0 120px">Отправлено</Column>
                            <Column flex="0 0 120px">Просмотрено</Column>
                            <Column flex="0 0 109px"></Column>
                        </THead>
                        {mailings.map((mail) => {
                            const { id, title, type, createdAt, countViewed, countSent } = mail
                            const viewedPercents = Math.round((countViewed / countSent || 0) * 100)
                            const date = new Date(createdAt)

                            return (
                                <Mail key={id}>
                                    <Column flex="1">{title}</Column>
                                    <Column flex="0 0 200px">
                                        <span>{date.toLocaleDateString()}</span>
                                        <span>{date.toLocaleTimeString()}</span>
                                    </Column>
                                    <Column flex="0 0 200px">{recepientType[type]}</Column>
                                    <Column flex="0 0 120px">{mail.countSent}</Column>
                                    <Column flex="0 0 120px">
                                        <span>{mail.countViewed}</span>
                                        <span>{viewedPercents}%</span>
                                    </Column>
                                    <Column flex="0 0 109px">
                                        <OpenButton to={`/mailings/${mail.id}`}>Открыть</OpenButton>
                                    </Column>
                                </Mail>
                            )
                        })}
                    </>
                )}
            </Component>
        )
    }
}
