import styled, { css } from 'styled-components'
import arrowUpIcon from './images/arrow_up.svg'
import arrowDownIcon from './images/arrow_down.svg'

export const Component = styled.div<{ icon?: string }>`
    position: relative;
    border-right: 1px solid #b7bcd6;
    user-select: none;
    padding-right: 16px;
    outline: none;

    ${(props) =>
        props.icon &&
        css`
            background-image: url(${props.icon});
            background-position: 7px center;
            background-repeat: no-repeat;
            background-size: 16px;
            padding-left: 16px;
        `}
`

export const Current = styled.div<{ bubbleVisibility: boolean }>`
    display: flex;
    align-items: center;
    height: 31px;
    background-image: url(${(props) => (props.bubbleVisibility ? arrowUpIcon : arrowDownIcon)});
    background-position: calc(100% - 10px) center;
    background-repeat: no-repeat;
    background-size: 20px;
    padding: 0 40px 0 17px;
    cursor: pointer;
    font-size: 16px;
    line-height: 127.3%;
    color: #707b8e;
`

export const Bubble = styled.div<{ visible: boolean }>`
    position: absolute;
    z-index: 10;
    top: calc(100% + 10px);
    right: 0;
    min-width: 100%;
    margin-top: 5px;
    opacity: 0;
    visibility: hidden;
    background: #ffffff;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    padding: 17px 0;
    transition: margin-top 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s;

    ${(props) =>
        props.visible &&
        css`
            opacity: 1;
            visibility: visible;
            margin-top: 0;
            transition: margin-top 0.3s ease, opacity 0.3s ease, visibility 0s;
        `}
`

export const Option = styled.div<{ current: boolean }>`
    height: 34px;
    font-size: 16px;
    line-height: 127.3%;
    color: #707b8e;
    display: flex;
    align-items: center;
    padding: 0 20px;
    white-space: nowrap;
    cursor: pointer;
    color: ${(props) => (props.current ? '#66D576' : 'inherit')};
    transition: background-color 0.2s ease, color 0.2s ease;

    :hover {
        background-color: #f1faf3;
    }

    :last-child {
        border-radius: 0 0 4px 4px;
    }
`
