import { request } from './request'

export const list = (limit: number, offset: number, orderBy: UserOrderBy, query: string) => {
    return request<ListResponse>('user/list', { limit, offset, orderBy, query })
}

export const get = (id: number) => {
    return request<GetResponse>('user/get', { id })
}

export const create = (user: NewUser) => {
    return request<CreateResponse>('user/create', { user })
}

export const save = (user: SaveUserData) => {
    return request<SaveResponse>('user/save', { user })
}

export const saveProfile = (user: SaveUserData) => {
    return request<SaveProfileResponse>('user/profile/save', { user })
}

export const block = (id: number) => {
    return request<BlockResponse>('user/block', { id })
}

export const unblock = (id: number) => {
    return request<UnblockResponse>('user/unblock', { id })
}

export const resetPassword = (id: number) => {
    return request<ResetPasswordResponse>('user/reset-password', { id })
}

export const clientList = (limit: number, offset: number, fiasId: string, orderBy: ClientOrderBy, query: string) => {
    return request<ClientListResponse>('user/client/list', { limit, offset, fiasId, query, orderBy })
}

export const getClient = (id: number) => {
    return request<GetClientResponse>('user/client/get', { id })
}

export const inviteClient = (file: string) => {
    return request<InviteClientResponse>('user/client/invite', { file })
}

export const transactionsList = (limit: number, offset: number, userId: number) => {
    return request<TransactionsListResponse>('user/transaction/list', { userId, limit, offset })
}

type ClientOrderBy = 'name' | 'createdAt' | 'rate'
type UserOrderBy = 'name' | 'createdAt' | 'rate' | 'role'
type UserType = 'admin' | 'support' | 'partner' | 'analyst'
type UserSex = 'male' | 'female'

interface ListResponse {
    users: UserInList[]
    length: number
}

interface GetResponse {
    user: User
}

interface CreateResponse {
    user: CreatedUser
}

interface SaveResponse extends UserData {}

interface SaveProfileResponse extends UserData {}

interface BlockResponse {}

interface UnblockResponse {}

interface ResetPasswordResponse {}

interface ClientListResponse {
    clients: Client[]
    length: number
}

interface GetClientResponse {
    client: FullClient
}

interface InviteClientResponse {}

interface ClientListResponse {
    clients: Client[]
    length: number
}

interface TransactionsListResponse {
    transactions: Transaction[]
    info: TransactionsListInfo
    length: number
}

interface TransactionsListInfo {
    totalTurnover: number
    revenueSharing: number
    totalAmount: number
    nextPayout: string
    nextPayoutAmount: number
}

interface Transaction {
    id: number
    createdAt: string
    amount: number
    percent: number
    serviceFee: number
    reportFileId: string
}

interface FullClient {
    id: number
    type: 'client'
    phone: string
    avatar: string
    first: string
    last: string
    patronymic: string
    createdAt: string
    updatedAt: string
    address: string
    countAddresses: number
    account: string
    countAccounts: number
    rate: number
    countReviews: number
    sex: UserSex
    birth: string
    email: string
    lastSignInAt: string
    countSales: number
    countPayments: number
    totalPayments: number
    addresses: Address[]
}

interface Address {
    id: number
    full: string
    short: string
    fiasId: string
    postalCode: string
    region: string
    city: string
    street: string
    house: string
    flat: string
    countClients: number
    countAccounts: number
    countSales: number
}

interface Client {
    id: number
    avatar: string
    phone: string
    first: string
    last: string
    patronymic: string
    createdAt: string
    updatedAt: string
    address: string
    countAddresses: number
    account: string
    countAccounts: number
    rate: number
    countReviews: number
}

interface UserData {
    id: number
    type: UserType
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    isBlocked: boolean
    rate: number
    countReviews: number
    countTickets: number
    birthDate: string
    sex: UserSex
    createdAt: string
    lastSignInAt: string
}

interface NewUser {
    type: UserType
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    birthDate: string
    sex: UserSex
}

interface SaveUserData extends Partial<UserData> {
    id: number
}

interface CreatedUser {
    id: number
    type: UserType
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
    birthDate: string
    sex: UserSex
    createdAt: string
    lastSignInAt: string
}

interface User {
    id: number
    type: UserType
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isOwner: boolean
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    isBlocked: boolean
    rate: number
    countReviews: number
    countTickets: number
    birthDate: string
    sex: UserSex
    createdAt: string
    lastSignInAt: string
    regions: Region[]
    legalEntity: LegalEntity
    totalTurnover: number
}

interface LegalEntity {
    name: string
    fullName: string
    id: number
    inn: string
    kpp: string
    ogrn: string
    bankName: string
    bankAddress: string
    bik: string
    bankAccount: string // корр счет
    taxType: 'none' | 'vat0' | 'vat10' | 'vat20'
    account: string // р/с
    details: string
    firstName: string
    lastName: string
    middleName: string
    birthDate: string
    phone: string
    state: 'novalid' | 'sended' | 'valid'
    isTaxpayer: boolean
}

interface Region {
    id: number
    name: string
    countAddresses: number
    countClients: number
    countSales: number
    countPayments: number
}

interface UserInList {
    id: number
    type: UserType
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isOwner: boolean
    isBlocked: boolean
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}
