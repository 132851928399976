import { action, reaction, runInAction } from 'mobx'
import { api } from 'api'
import { createStore } from './abstract'

export type AdminsOrderBy = 'name' | 'createdAt' | 'rate' | 'role'

interface Params {
    orderBy: AdminsOrderBy
    query: string
}

const store = createStore<Params, AdminsData>({
    orderBy: 'name',
    query: '',
})

export const get = store.get
export const setParams = store.setParams
export const getParams = store.getParams
export const isResolved = store.isResolved

const BUSY_MAP = new Map<string, boolean>()

export const resolve = async () => {
    const key = store.getKey()
    const { orderBy, query } = store.getParams()
    const data = await api.user.list(20, 0, orderBy, query)

    BUSY_MAP.set(key, false)

    store.set(key, data)
}

export const resolveMore = async () => {
    if (!isResolved()) return

    const key = store.getKey()

    if (!BUSY_MAP.get(key)) {
        BUSY_MAP.set(key, true)

        const { users } = store.get()
        const offset = users.length
        const { orderBy, query } = getParams()
        const data = await api.user.list(20, offset, orderBy, query)

        if (data.users.length === 0) {
            return
        }

        BUSY_MAP.set(key, false)

        runInAction(() => users.push(...data.users))
    }
}

// resolver

let lastKey: string

reaction(
    () => store.getRealKey(),
    (key) => {
        lastKey = key

        const { orderBy, query } = store.getRealParams()

        if (store.hasKey(key)) {
            store.setKey(key)

            const limit = store.get(key).users.length

            api.user.list(limit, 0, orderBy, query).then(action((data) => store.set(key, data)))
        } else {
            api.user.list(20, 0, orderBy, query).then(
                action((data) => {
                    store.set(key, data)

                    if (key === lastKey) {
                        store.setKey(key)
                    }
                })
            )
        }
    }
)

type UserType = 'admin' | 'support' | 'partner' | 'analyst'

export interface AdminsData {
    users: AdminsItemData[]
    length: number
}

export interface AdminsItemData {
    id: number
    type: UserType
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isOwner: boolean
    isBlocked: boolean
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}
