import styled from 'styled-components'
import arrowIcon from './images/arrow.svg'

export const Component = styled.div`
    min-height: 100vh;
    display: grid;
    grid-template-columns: 270px 1fr;
    grid-template-rows: 76px 1fr;
    grid-template-areas:
        'aside head'
        'aside content';
`

export const Content = styled.div`
    grid-area: content;
    background-color: #f7f8fc;
    padding: 20px 28px 30px 18px;
`

export const ScrollToTop = styled.div`
    width: 48px;
    height: 48px;
    background-color: white;
    border-radius: 50%;
    background-image: url(${arrowIcon});
    background-position: center;
    background-repeat: no-repeat;
    position: fixed;
    cursor: pointer;
    opacity: 0.6;
    right: 40px;
    bottom: 40px;
    z-index: 1000;
    transition: opacity 0.2s ease;
    box-shadow: 0px 4px 10px rgb(0 0 0 / 14%);

    :hover {
        opacity: 1;
    }
`
