import { Button, Heading, Popup } from 'components/ui'
import { Desc, Buttons } from './unlock.styled'

interface UnlockPopupProps {
    onConfirm: () => void
    onClose: () => void
}

export const UnlockPopup = (props: UnlockPopupProps) => {
    const { onConfirm, onClose } = props

    return (
        <Popup width={655}>
            <Heading>Разблокировать провайдера</Heading>
            <Desc>
                Вы уверенны что вы хотите разблокировать <br /> провайдера?
            </Desc>
            <Buttons>
                <Button secondary onClick={onClose}>
                    Нет
                </Button>
                <Button onClick={onConfirm}>Да</Button>
            </Buttons>
        </Popup>
    )
}
