import { api } from 'api'
import { action, observable, reaction, runInAction } from 'mobx'
import { createStore, Store } from './abstract'

interface Params {
    fiasId: string
    query: string
}

export const STORE = observable.map<number, Store<Params, AddressesData>>()

const BUSY_MAP = new Map<Store<Params, AddressesData>, boolean>()

const createSubstore = (regionId: number) => {
    const store = createStore<Params, AddressesData>({ fiasId: '', query: '' })

    let lastKey: string

    reaction(
        () => store.getRealKey(),
        (key) => {
            lastKey = key

            const { fiasId, query } = store.getRealParams()

            if (store.hasKey(key)) {
                store.setKey(key)

                const limit = store.get(key).addresses.length

                api.address.list(limit, 0, regionId, fiasId, query).then(action((data) => store.set(key, data)))
            } else {
                api.address.list(20, 0, regionId, fiasId, query).then(
                    action((data) => {
                        store.set(key, data)

                        if (key === lastKey) {
                            store.setKey(key)
                        }
                    })
                )
            }
        }
    )

    return store
}

export const resolve = async (regionId: number) => {
    const store = STORE.has(regionId) ? STORE.get(regionId)! : createSubstore(regionId)
    const key = store.getKey()
    const { fiasId, query } = store.getParams()
    const data = await api.address.list(20, 0, regionId, fiasId, query)

    BUSY_MAP.set(store, false)

    store.set(key, data)

    if (!STORE.has(regionId)) {
        runInAction(() => STORE.set(regionId, store))
    }
}

export const resolveMore = async (regionId: number) => {
    if (!isResolved(regionId)) return

    const store = STORE.get(regionId)!

    if (!BUSY_MAP.get(store)) {
        BUSY_MAP.set(store, true)

        const { addresses } = store.get()
        const offset = addresses.length
        const { fiasId, query } = store.getParams()
        const data = await api.address.list(20, offset, regionId, fiasId, query)

        if (data.addresses.length === 0) {
            return
        }

        BUSY_MAP.set(store, false)

        runInAction(() => addresses.push(...data.addresses))
    }
}

export const isResolved = (regionId: number) => {
    return STORE.has(regionId)
}

export const get = (regionId: number) => {
    return STORE.get(regionId)!.get()
}

export const getParams = (regionId: number) => {
    return STORE.get(regionId)!.getParams()
}

export const setParams = (regionId: number, params: Partial<Params>) => {
    return STORE.get(regionId)!.setParams(params)
}

export interface AddressesData {
    addresses: AddressesItemData[]
    length: number
}

export interface AddressesItemData {
    id: number
    full: string
    short: string
    fiasId: string
    postalCode: string
    region: string
    city: string
    street: string
    house: string
    flat: string
    countClients: number
    countAccounts: number
    countSales: number
}
