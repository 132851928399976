import { api } from 'api'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { createRef, PureComponent } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { store } from 'store'
import {
    Component,
    Inputs,
    Attach,
    AttachMenu,
    AttachMenuItem,
    TextInput,
    SendButton,
    AttachmentsList,
    AttachmentPreview,
    AttachmentLoader,
    AttachmentRemove,
    AttachmentsLinksBubble,
    AttachmentsLinksBubbleItem,
    LinksList,
    LinksListItem,
    FastAnswersBubble,
    FastAnswersBubbleItem,
} from './new_message.styled'

interface NewMessageProps {
    ticketId: number
    onSend: (m: NewTicketMessageData) => Promise<void>
}

@observer
export class NewMessage extends PureComponent<NewMessageProps> {
    @observable
    private message = ''

    @observable.shallow
    private attachments = [] as AttachFile[]

    @observable.shallow
    private links = [] as Link[]

    constructor(props: NewMessageProps) {
        super(props)
        makeObservable(this)
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const { message } = this

        if (!message && !this.attachments.length && !this.links.length) {
            return
        }

        const attachments = this.attachments.map((i) => i.uploaded!)
        const links = this.links

        await this.props.onSend({ message, attachments, links })

        runInAction(() => {
            this.message = ''
            this.attachments = []
            this.links = []
        })
    }

    componentDidMount() {
        if (!store.ticket.links.isResolved()) {
            store.ticket.links.resolve()
        }
    }

    @computed
    get isAttachmentsUploaded() {
        return this.attachments.every((a) => a.isUploaded)
    }

    @action
    handleMessageChange(message: string) {
        this.message = message
    }

    @action
    handleAttachments(files: FileList) {
        const max = 5 - this.attachments.length
        const list = Array.prototype.slice.call(files, 0, max) as File[]

        for (const file of list) {
            const isset = this.attachments.some(
                (f) =>
                    f.file.name === file.name && f.file.size === file.size && f.file.lastModified === file.lastModified
            )

            if (!isset) {
                this.attachments.push(new AttachFile(file))
            }
        }
    }

    @action
    handleRemoveAttach(key: string) {
        for (let i = 0; i < this.attachments.length; i++) {
            if (this.attachments[i].key === key) {
                this.attachments.splice(i, 1)
                break
            }
        }
    }

    @action
    removeLink(i: number) {
        this.links.splice(i, 1)
    }

    @action
    addLink(link: Link) {
        if (!this.links.includes(link)) {
            this.links.push(link)
        }
    }

    @action
    fastAnswer(answer: string) {
        if (!!this.message) {
            this.message += '\n'
        }

        this.message += answer
    }

    render() {
        const fileInputRef = createRef<HTMLInputElement>()
        const { attachments } = this
        const links = store.ticket.links.get()
        const fastAnswers = api.ticket.getFastAnswers()

        return (
            <Component>
                <Inputs onSubmit={(e) => this.handleFormSubmit(e)}>
                    <Attach>
                        <AttachMenu>
                            <AttachMenuItem onClick={() => fileInputRef.current?.click()}>
                                Прикрепить файл
                            </AttachMenuItem>
                            <AttachMenuItem>
                                <span>Ссылку из Notion</span>
                                <AttachmentsLinksBubble>
                                    <Scrollbars>
                                        {links.map((i) => (
                                            <AttachmentsLinksBubbleItem key={i.id} onClick={() => this.addLink(i)}>
                                                {i.name}
                                            </AttachmentsLinksBubbleItem>
                                        ))}
                                    </Scrollbars>
                                </AttachmentsLinksBubble>
                            </AttachMenuItem>
                            <AttachMenuItem>
                                <span>Быстрый ответ</span>
                                <FastAnswersBubble>
                                    <Scrollbars>
                                        {fastAnswers.map((a, i) => (
                                            <FastAnswersBubbleItem key={i} onClick={() => this.fastAnswer(a)}>
                                                {a}
                                            </FastAnswersBubbleItem>
                                        ))}
                                    </Scrollbars>
                                </FastAnswersBubble>
                            </AttachMenuItem>
                        </AttachMenu>
                    </Attach>
                    <TextInput
                        placeholder="Сообщение"
                        value={this.message}
                        onChange={(e) => this.handleMessageChange(e.target.value)}
                    />
                    <input
                        ref={fileInputRef}
                        multiple={true}
                        type="file"
                        name="name"
                        onChange={(e) => this.handleAttachments(e.target.files!)}
                    />
                    <SendButton type="submit" disabled={!this.isAttachmentsUploaded}>
                        Отправить
                    </SendButton>
                </Inputs>
                {!!attachments.length && (
                    <AttachmentsList>
                        {attachments.map((a) => (
                            <AttachmentComp key={a.key} data={a} onRemove={(key) => this.handleRemoveAttach(key)} />
                        ))}
                    </AttachmentsList>
                )}
                {!!this.links.length && (
                    <LinksList>
                        {this.links.map((l, i) => {
                            return (
                                <LinksListItem key={l.id}>
                                    <span>{l.name}</span>
                                    <span onClick={() => this.removeLink(i)} />
                                </LinksListItem>
                            )
                        })}
                    </LinksList>
                )}
            </Component>
        )
    }
}

interface AttachmentCompProps {
    data: AttachFile
    onRemove: (k: string) => void
}

const AttachmentComp = observer((props: AttachmentCompProps) => {
    const { key, preview, isUploaded } = props.data

    return (
        <AttachmentPreview src={preview}>
            <AttachmentRemove onClick={() => props.onRemove(key)} />
            {!isUploaded && <AttachmentLoader />}
        </AttachmentPreview>
    )
})

class AttachFile {
    readonly key: string

    @observable
    uploaded: Attachment | null = null

    @observable
    preview!: string

    @computed
    get isUploaded() {
        return !!this.uploaded
    }

    @computed
    get name() {
        return this.isUploaded ? this.uploaded!.name : this.file.name
    }

    @computed
    get mimeType() {
        return this.isUploaded ? this.uploaded!.mimeType : this.file.type
    }

    @computed
    get size() {
        return this.isUploaded ? this.uploaded!.size : this.file.size
    }

    @computed
    get ext() {
        return this.name.split('.').pop()
    }

    constructor(readonly file: File) {
        makeObservable(this)
        this.key = `${file.name}+${file.size}`
        this.createPreview(file)
        api.upload(file).then(action((uploaded) => (this.uploaded = uploaded)))
    }

    createPreview(file: File) {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')!
        const ext = file.name.split('.').pop()!

        canvas.width = 300
        canvas.height = 300

        ctx.fillStyle = '#98a7b6'
        ctx.fillRect(0, 0, 300, 300)
        ctx.textAlign = 'center'
        ctx.font = '40px Roboto'
        ctx.fillStyle = '#F7F8FC'
        ctx.fillText(ext, 150, 280)

        this.preview = canvas.toDataURL()

        if (imagesMime.includes(file.type)) {
            const image = new Image()
            const reader = new FileReader()

            reader.readAsDataURL(file)

            reader.onload = (event) => {
                image.src = event.target!.result as string
            }
            image.onload = (e) => {
                const size = Math.min(image.width, image.height)

                const sx = image.width > image.height ? (image.width - size) / 2 : 0
                const sy = image.width < image.height ? (image.height - size) / 2 : 0

                ctx.drawImage(image, sx, sy, size, size, 0, 0, 300, 300)

                this.preview = canvas.toDataURL()

                runInAction(() => (this.preview = canvas.toDataURL()))
            }
        }
    }
}

const imagesMime = ['image/jpeg', 'image/png']

export interface NewTicketMessageData {
    message: string
    attachments: Attachment[]
    links: Link[]
}

interface Attachment {
    link: string
    name: string
    mimeType: string
    size: string
    createdAt: string
    updatedAt: string
}

interface Link {
    id: string
    link: string
    name: string
}
