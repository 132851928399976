import { Component, Tools, SearchInput, Total, TheadComp, ItemComp, Dot, Button } from './qr_payments.styled'
import { PureComponent } from 'react'
import { DatePicker, DatePickerValue, Loader } from 'components/ui'
import { store, QrPaymentsItemData } from 'store'
import searchIcon from './images/search.svg'
import downloadIcon from './images/download.svg'
import { observer } from 'mobx-react'
import { download, fileUrl, formatMoney } from 'utils'
import { action, makeObservable, observable } from 'mobx'
import { createScrollAutoloader } from 'utils'

@observer
export class QRPayments extends PureComponent {
    @observable
    private query = store.payments.qr.getParams().query
    private queryTimeout!: NodeJS.Timeout
    private detachScrollListener!: () => void

    constructor(props: {}) {
        super(props)
        makeObservable(this)
    }

    componentDidMount() {
        store.payments.qr.resolve()

        this.detachScrollListener = createScrollAutoloader(store.payments.qr.resolveMore)
    }

    componentWillUnmount() {
        this.detachScrollListener()
    }

    @action
    handleQueryChange(query: string) {
        this.query = query

        clearTimeout(this.queryTimeout)

        this.queryTimeout = setTimeout(() => store.payments.qr.setParams({ query }), 500)
    }

    handleMonthYearChange(value: DatePickerValue) {
        store.payments.qr.setParams(value)
    }

    render() {
        if (!store.payments.qr.isResolved()) {
            return <Loader />
        }

        const { month, year } = store.payments.qr.getParams()
        const { total, qrpayments } = store.payments.qr.get()

        return (
            <Component>
                <Tools>
                    <DatePicker month={month} year={year} onChange={(v) => this.handleMonthYearChange(v)} />
                    <SearchInput
                        value={this.query}
                        onChange={(e) => this.handleQueryChange(e.target.value)}
                        icon={searchIcon}
                        placeholder="Поиск по ID импорта или названию провайдера"
                    />
                </Tools>
                <Total>
                    <span>Всего за месяц</span>
                    <span>{formatMoney(total)}</span>
                </Total>
                <Thead />
                {qrpayments.map((item) => (
                    <Item key={item.id} data={item} />
                ))}
            </Component>
        )
    }
}

const Thead = () => (
    <TheadComp>
        <div>Дата</div>
        <div>Получатель</div>
        <div>Примечание</div>
        <div>Сумма</div>
    </TheadComp>
)

interface ItemProps {
    data: QrPaymentsItemData
}

const Item = (props: ItemProps) => {
    const { createdAt, provider, note, amount, fileId, state } = props.data
    const datetime = new Date(createdAt)

    return (
        <ItemComp>
            <div>
                <Dot color={calcDotColor(state)} label={calcDotDesc(state)} />
                <span>{datetime.toLocaleDateString()}</span>
                <span>{datetime.toLocaleTimeString()}</span>
            </div>
            <div>
                <span>{provider.short}</span>
                <span>ИНН {provider.shortLegalEntity.inn}</span>
            </div>
            <div>{note}</div>
            <div>{formatMoney(amount)}</div>
            <div>
                <Button icon={downloadIcon} onClick={() => download(fileUrl(fileId))} />
            </div>
        </ItemComp>
    )
}

const calcDotColor = (state: 'wait' | 'done' | 'cancel') => {
    if (state === 'wait') return 'yellow'
    if (state === 'done') return 'green'
    if (state === 'cancel') return 'red'

    throw new Error('Unknown item state')
}

const calcDotDesc = (state: 'wait' | 'done' | 'cancel') => {
    if (state === 'wait') return 'В ожидании'
    if (state === 'done') return 'Успешно'
    if (state === 'cancel') return 'Ошибка'

    throw new Error('Unknown item state')
}
