import { api } from 'api'
import { observable } from 'mobx'

export * as payments from './customer_payments'

const STORE = observable.map<number, CustomerData>([], { deep: false })

export const resolve = async (id: number) => {
    const { client } = await api.user.getClient(id)
    STORE.set(id, client)
}

export const isResolved = (id: number) => {
    return STORE.has(id)
}

export const get = (id: number) => {
    return STORE.get(id)
}

type UserSex = 'male' | 'female'

export interface CustomerData {
    id: number
    type: string
    avatar: string
    phone: string
    first: string
    last: string
    patronymic: string
    createdAt: string
    updatedAt: string
    address: string
    countAddresses: number
    account: string
    countAccounts: number
    rate: number
    countReviews: number
    sex: UserSex
    birth: string
    email: string
    lastSignInAt: string
    countSales: number
    countPayments: number
    totalPayments: number
    addresses: CustomerAddressData[]
}

export interface CustomerAddressData {
    id: number
    full: string
    short: string
    fiasId: string
    postalCode: string
    region: string
    city: string
    street: string
    house: string
    flat: string
    countClients: number
    countAccounts: number
    countSales: number
}
