import { Component, Counter, Star } from './stars.styled'

interface StarsProps {
    count: number
}

export const Stars = (props: StarsProps) => {
    const { count } = props

    return (
        <Component>
            <Counter>{count.toFixed(1)}</Counter>
            {Array.from({ length: 5 }).map((_, i) => (
                <Star key={i} type={i < Math.floor(count) ? 'full' : i < Math.ceil(count) ? 'half' : 'empty'} />
            ))}
        </Component>
    )
}
