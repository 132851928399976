import { action, reaction, runInAction } from 'mobx'
import { api } from 'api'
import { createStore } from './abstract'

interface Params {
    orderBy: CustomersOrderBy
    query: string
    fiasId: string
}

const store = createStore<Params, CustomersData>({
    orderBy: 'name',
    query: '',
    fiasId: '',
})

export const get = store.get
export const setParams = store.setParams
export const getParams = store.getParams
export const isResolved = store.isResolved

const BUSY_MAP = new Map<string, boolean>()

export const resolve = async () => {
    const key = store.getKey()
    const { orderBy, query, fiasId } = getParams()
    const data = await api.user.clientList(20, 0, fiasId, orderBy, query)

    BUSY_MAP.set(key, false)

    runInAction(() => store.set(key, data))
}

export const resolveMore = async () => {
    if (!isResolved()) return

    const key = store.getKey()

    if (!BUSY_MAP.get(key)) {
        BUSY_MAP.set(key, true)

        const { clients } = store.get()
        const offset = clients.length
        const { orderBy, query, fiasId } = getParams()
        const data = await api.user.clientList(20, offset, fiasId, orderBy, query)

        if (data.clients.length === 0) {
            return
        }

        BUSY_MAP.set(key, false)

        runInAction(() => clients.push(...data.clients))
    }
}

// resolver

let lastKey: string

reaction(
    () => store.getRealKey(),
    (key) => {
        lastKey = key

        const { orderBy, query, fiasId } = store.getRealParams()

        if (store.hasKey(key)) {
            store.setKey(key)

            const limit = store.get(key).clients.length

            api.user.clientList(limit, 0, fiasId, orderBy, query).then(action((data) => store.set(key, data)))
        } else {
            api.user.clientList(20, 0, fiasId, orderBy, query).then(
                action((data) => {
                    store.set(key, data)

                    if (key === lastKey) {
                        store.setKey(key)
                    }
                })
            )
        }
    }
)

export type CustomersOrderBy = 'name' | 'createdAt' | 'rate'

export interface CustomersData {
    clients: CustomersItemData[]
    length: number
}

export interface CustomersItemData {
    id: number
    avatar: string
    phone: string
    first: string
    last: string
    patronymic: string
    createdAt: string
    updatedAt: string
    address: string
    countAddresses: number
    account: string
    countAccounts: number
    rate: number
    countReviews: number
}
