import styled from 'styled-components'

export const Component = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    border-radius: 0px 8px 8px 8px;
    padding: 25px 30px;
`

export const Head = styled.div`
    display: flex;
    justify-content: space-between;
`

export const Stats = styled.div``

export const CurrentPeriod = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 12px 0;
`

export const LastPeriod = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 3px 0;
    height: 20px;
`

export const Small = styled.span`
    font-size: 14px;
    color: #707b8e;
`

export const Money = styled.span`
    color: #39434b;
    font-weight: bold;
    font-size: 24px;
    margin-left: 22px;
`

export const LastMoney = styled.span`
    font-weight: 500;
    font-size: 14px;
    color: #39434b;
    margin: 0 12px;
`

export const Trend = styled.span<{ direction: 'up' | 'down' }>`
    background-image: url(${(props) => require(`./images/trend_${props.direction}.svg`).default});
    background-position: left center;
    background-repeat: no-repeat;
    font-weight: 500;
    font-size: 14px;
    color: ${(props) => (props.direction === 'up' ? '#28C76F' : '#eb5757')};
    padding-left: 21px;
`

export const Chart = styled.div`
    height: 270px;
    margin: 30px 0;
    max-width: calc(100vw - 395px);
`

export const Foot = styled.div`
    display: flex;
    align-items: center;
    padding: 10px 0;
`

export const PaymentType = styled.div<{ color: 'green' | 'orange' }>`
    background-image: url(${(props) => require(`./images/${props.color}_dot.svg`).default});
    background-position: left center;
    background-repeat: no-repeat;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #39434b;
    padding-left: 22px;
    margin-left: 60px;
`

export const Space = styled.div<{ width?: number }>`
    flex: ${(props) => (Number.isInteger(props.width) ? ` 0 0 ${props.width}px;` : '1')};
`

export const ChartTooltip = styled.div`
    background: #ffffff;
    box-shadow: 0px 2px 4px rgba(68, 68, 79, 0.15), 0px 2px 15px rgba(68, 68, 79, 0.1);
    border-radius: 10px;
    padding: 20px;

    & > span {
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 11px;
        line-height: 13px;
        color: #b7bcd6;
        margin-bottom: 5px;
        display: block;

        &:not(:first-child) {
            margin-top: 12px;
        }
    }

    & > div {
        & > div:nth-child(odd) {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 14px;
            line-height: 16px;
            color: #111111;
        }
        & > div:nth-child(even) {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #707b8e;
            margin-top: 4px;
        }
        & > div:nth-child(even):not(:last-child) {
            margin-bottom: 12px;
        }
    }
`
