import { Button, Heading, Popup } from 'components/ui'
import { Desc } from './forgot_password.styled'

interface ForgotPasswordPopupProps {
    onClose: () => void
}

export const ForgotPasswordPopup = (props: ForgotPasswordPopupProps) => {
    const { onClose } = props

    return (
        <Popup>
            <Heading>Забыли пароль?</Heading>
            <Desc>Для восстановления доступа к сервису обратитесь к вашему администратору.</Desc>
            <Button onClick={onClose}>Закрыть</Button>
        </Popup>
    )
}
