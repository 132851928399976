export const createScrollAutoloader = (cb: () => void) => {
    const listener = () => {
        const { scrollHeight, clientHeight, scrollTop } = document.documentElement

        if ((scrollHeight - clientHeight - scrollTop) / (scrollHeight - clientHeight) < 0.25) {
            cb()
        }
    }

    document.addEventListener('scroll', listener)

    return () => document.removeEventListener('scroll', listener)
}
