import { api } from 'api'
import { observable } from 'mobx'

const STORE = observable.box<LinksData>([])

export const get = () => {
    return STORE.get()
}

export const getById = (id: string) => {
    const links = get()

    for (const link of links) {
        if (link.id === id) {
            return link
        }
    }

    throw new Error(`cannot find link with id = ${id}`)
}

export const isResolved = () => {
    const links = STORE.get()

    return !!links.length
}

export const resolve = async () => {
    const { items } = await api.ticket.links('')

    STORE.set(items)
}

export type LinksData = LinksItemData[]

export interface LinksItemData {
    id: string
    link: string
    name: string
}
