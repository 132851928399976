import { api } from 'api'
import { Button, Heading, Popup, TextInput, TextareaInput } from 'components/ui'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { createRef, PureComponent } from 'react'
import Scrollbars from 'react-custom-scrollbars-2'
import { store, LinksItemData } from 'store'
import { avatarUrl } from 'utils'
import { generateAssignUserType } from 'utils'
import {
    Form,
    Buttons,
    Inputs,
    Bubble,
    BubbleOption,
    Executor,
    ExecutorInput,
    CurrentExecutor,
    CurrentExecutorLabel,
    ExecutorError,
    AttachmentIcon,
    Attachments,
    AttachmentsBubble,
    AttachmentsBubbleItem,
    AttachmentsList,
    TextareaWrapper,
    AttachmentsLinksBubble,
    AttachmentsLinksBubbleItem,
    FastAnswersBubble,
    FastAnswersBubbleItem,
    AttachmentPreview,
    AttachmentRemove,
    AttachmentLoader,
    LinksList,
    LinksListItem,
} from './new_ticket.styled'

export interface NewTicketData {
    assignUserId: number
    assignUserType: 'admin' | 'user' | 'provider'
    title: string
    message: NewTicketMessageData
}

interface NewTicketMessageData {
    message: string
    attachments: Attachment[]
    links: LinksItemData[]
}

interface Attachment {
    link: string
    name: string
    mimeType: string
    size: string
    createdAt: string
    updatedAt: string
}

interface NewTicketPopupProps {
    onSend: (v: NewTicketData) => Promise<void>
    onClose: () => void
    assign?: {
        id: number
        type: 'admin' | 'user' | 'provider'
    }
}

@observer
export class NewTicketPopup extends PureComponent<NewTicketPopupProps> {
    @observable
    private executor = null as ExecutorData | null

    @observable
    private executorQuery = ''

    @observable
    private executorError = ''

    @observable
    private executorSuggestions = [] as ExecutorData[]

    @observable
    private title = ''

    @observable
    private description = ''

    @observable
    private titleError = ''

    @observable
    private descriptionError = ''

    @observable.shallow
    private attachments = [] as AttachFile[]

    private loadExecutorsTimer!: NodeJS.Timeout

    @observable
    private links = [] as LinksItemData[]

    constructor(props: NewTicketPopupProps) {
        super(props)
        makeObservable(this)
    }

    get userId() {
        return this.props.assign?.id || undefined
    }

    get userType() {
        return this.props.assign?.type || undefined
    }

    componentDidMount() {
        const { userId, userType } = this

        if (!store.ticket.links.isResolved()) {
            store.ticket.links.resolve()
        }

        api.ticket.executors('', undefined, userId, userType).then(
            action(({ users }) => {
                this.executorSuggestions = users

                if (userId && userType) {
                    this.executor = this.executorSuggestions[0]
                }
            })
        )
    }

    @computed
    get isAttachmentsUploaded() {
        return this.attachments.every((a) => a.isUploaded)
    }

    @action
    handleExecutorQueryChange(query: string) {
        this.executorQuery = query

        clearTimeout(this.loadExecutorsTimer)

        this.loadExecutorsTimer = setTimeout(() => {
            const { userId, userType } = this
            api.ticket
                .executors(query, undefined, userId, userType)
                .then(action(({ users }) => (this.executorSuggestions = users)))
        }, 500)
    }

    @action
    handleAttachments(files: FileList) {
        const max = 5 - this.attachments.length
        const list = Array.prototype.slice.call(files, 0, max) as File[]

        for (const file of list) {
            const isset = this.attachments.some(
                (f) =>
                    f.file.name === file.name && f.file.size === file.size && f.file.lastModified === file.lastModified
            )

            if (!isset) {
                this.attachments.push(new AttachFile(file))
            }
        }
    }

    @action
    handleRemoveAttach(key: string) {
        for (let i = 0; i < this.attachments.length; i++) {
            if (this.attachments[i].key === key) {
                this.attachments.splice(i, 1)
                break
            }
        }
    }

    @action
    removeLink(i: number) {
        this.links.splice(i, 1)
    }

    @action
    addLink(link: LinksItemData) {
        if (!this.links.includes(link)) {
            this.links.push(link)
        }
    }

    @action
    changeExecutor(e: ExecutorData | null) {
        this.executorError = ''
        this.executor = e
    }

    @action
    handleTitleChange(title: string) {
        this.titleError = ''
        this.title = title
    }

    @action
    handleDescChange(desc: string) {
        this.descriptionError = ''
        this.description = desc
    }

    @action
    fastAnswer(answer: string) {
        if (!!this.description) {
            this.description += '\n'
        }

        this.description += answer
    }

    async handleFormSubmit(e: React.FormEvent<HTMLFormElement>) {
        e.preventDefault()

        const { executor, title, description, links } = this

        if (!executor) this.executorError = 'Выберите исполнителя'
        if (title === '') this.titleError = 'Введите заголовок'
        if (description === '') this.descriptionError = 'Введите описание'

        if (this.executorError || this.titleError || this.descriptionError) {
            return
        }

        const attachments = this.attachments.map((i) => i.uploaded!)

        const data = {
            assignUserId: executor!.id,
            assignUserType: generateAssignUserType(executor!.type),
            title: title,
            message: {
                message: description,
                attachments,
                links,
            },
        } as NewTicketData

        await this.props.onSend(data)
    }

    render() {
        const { onClose } = this.props
        const onTitleChange = action((e: React.ChangeEvent<HTMLInputElement>) => this.handleTitleChange(e.target.value))
        const onDescriptionChange = action((e: React.ChangeEvent<HTMLTextAreaElement>) =>
            this.handleDescChange(e.target.value)
        )
        const onExecutorQueryChange = (e: React.ChangeEvent<HTMLInputElement>) =>
            this.handleExecutorQueryChange(e.target.value)
        const fileInputRef = createRef<HTMLInputElement>()
        const links = store.ticket.links.get()
        const fastAnswers = api.ticket.getFastAnswers()

        const {
            executor,
            executorQuery,
            executorError,
            executorSuggestions,
            title,
            titleError,
            description,
            descriptionError,
            attachments,
        } = this

        return (
            <Popup width={655}>
                <Heading>Новый тикет</Heading>
                <Form onSubmit={(e) => this.handleFormSubmit(e)}>
                    <Inputs>
                        <Executor>
                            {!!executor ? (
                                <>
                                    <CurrentExecutorLabel>Исполнитель</CurrentExecutorLabel>
                                    <CurrentExecutor
                                        avatar={avatarUrl(executor.avatar)}
                                        onClick={() => this.changeExecutor(null)}
                                    >
                                        {executor.name}
                                    </CurrentExecutor>
                                </>
                            ) : (
                                <>
                                    <ExecutorInput
                                        autoFocus
                                        label="Исполнитель"
                                        value={executorQuery}
                                        onChange={onExecutorQueryChange}
                                    />
                                    {!!executorSuggestions.length && (
                                        <Bubble>
                                            <Scrollbars
                                                style={{
                                                    height: Math.min(240, executorSuggestions.length * 48),
                                                    width: '100%',
                                                }}
                                            >
                                                {executorSuggestions.map((i) => (
                                                    <BubbleOption
                                                        key={i.id}
                                                        avatar={avatarUrl(i.avatar)}
                                                        onClick={() => this.changeExecutor(i)}
                                                    >
                                                        {i.name}
                                                    </BubbleOption>
                                                ))}
                                            </Scrollbars>
                                        </Bubble>
                                    )}
                                </>
                            )}
                            <ExecutorError>{executorError}</ExecutorError>
                        </Executor>
                        <TextInput placeholder="Заголовок" value={title} onChange={onTitleChange} error={titleError} />
                        <TextareaWrapper>
                            <TextareaInput
                                placeholder="Описание"
                                value={description}
                                onChange={onDescriptionChange}
                                error={descriptionError}
                            />
                            <Attachments>
                                <AttachmentIcon />
                                <AttachmentsBubble>
                                    <AttachmentsBubbleItem onClick={() => fileInputRef.current?.click()}>
                                        Прикрепить файл
                                    </AttachmentsBubbleItem>
                                    <AttachmentsBubbleItem>
                                        <span>Ссылку из Notion</span>
                                        <AttachmentsLinksBubble>
                                            <Scrollbars>
                                                {links.map((i) => (
                                                    <AttachmentsLinksBubbleItem
                                                        key={i.id}
                                                        onClick={() => this.addLink(i)}
                                                    >
                                                        {i.name}
                                                    </AttachmentsLinksBubbleItem>
                                                ))}
                                            </Scrollbars>
                                        </AttachmentsLinksBubble>
                                    </AttachmentsBubbleItem>
                                    <AttachmentsBubbleItem>
                                        <span>Быстрый ответ</span>
                                        <FastAnswersBubble>
                                            <Scrollbars>
                                                {fastAnswers.map((a, i) => (
                                                    <FastAnswersBubbleItem key={i} onClick={() => this.fastAnswer(a)}>
                                                        {a}
                                                    </FastAnswersBubbleItem>
                                                ))}
                                            </Scrollbars>
                                        </FastAnswersBubble>
                                    </AttachmentsBubbleItem>
                                </AttachmentsBubble>
                            </Attachments>
                        </TextareaWrapper>
                        <input
                            ref={fileInputRef}
                            multiple={true}
                            type="file"
                            name="name"
                            onChange={(e) => this.handleAttachments(e.target.files!)}
                        />
                        {!!attachments.length && (
                            <AttachmentsList>
                                {attachments.map((a) => (
                                    <AttachmentComp
                                        key={a.key}
                                        data={a}
                                        onRemove={(key) => this.handleRemoveAttach(key)}
                                    />
                                ))}
                            </AttachmentsList>
                        )}
                        {!!this.links.length && (
                            <LinksList>
                                {this.links.map((l, i) => {
                                    return (
                                        <LinksListItem key={l.id}>
                                            <span>{l.name}</span>
                                            <span onClick={() => this.removeLink(i)} />
                                        </LinksListItem>
                                    )
                                })}
                            </LinksList>
                        )}
                    </Inputs>
                    <Buttons>
                        <Button secondary onClick={onClose}>
                            Отмена
                        </Button>
                        <Button width={127} type="submit" disabled={!this.isAttachmentsUploaded}>
                            Создать
                        </Button>
                    </Buttons>
                </Form>
            </Popup>
        )
    }
}

interface ExecutorData {
    id: number
    type: 'admin' | 'analyst' | 'partner' | 'support' | 'user' | 'provider'
    name: string
    avatar: string
}

interface AttachmentCompProps {
    data: AttachFile
    onRemove: (k: string) => void
}

const AttachmentComp = observer((props: AttachmentCompProps) => {
    const { key, preview, isUploaded } = props.data

    return (
        <AttachmentPreview src={preview}>
            <AttachmentRemove onClick={() => props.onRemove(key)} />
            {!isUploaded && <AttachmentLoader />}
        </AttachmentPreview>
    )
})

class AttachFile {
    readonly key: string

    @observable
    uploaded: Attachment | null = null

    @observable
    preview!: string

    @computed
    get isUploaded() {
        return !!this.uploaded
    }

    @computed
    get name() {
        return this.isUploaded ? this.uploaded!.name : this.file.name
    }

    @computed
    get mimeType() {
        return this.isUploaded ? this.uploaded!.mimeType : this.file.type
    }

    @computed
    get size() {
        return this.isUploaded ? this.uploaded!.size : this.file.size
    }

    @computed
    get ext() {
        return this.name.split('.').pop()
    }

    constructor(readonly file: File) {
        makeObservable(this)
        this.key = `${file.name}+${file.size}`
        this.createPreview(file)
        api.upload(file).then(action((uploaded) => (this.uploaded = uploaded)))
    }

    createPreview(file: File) {
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')!
        const ext = file.name.split('.').pop()!

        canvas.width = 300
        canvas.height = 300

        ctx.fillStyle = '#98a7b6'
        ctx.fillRect(0, 0, 300, 300)
        ctx.textAlign = 'center'
        ctx.font = '40px Roboto'
        ctx.fillStyle = '#F7F8FC'
        ctx.fillText(ext, 150, 280)

        this.preview = canvas.toDataURL()

        if (imagesMime.includes(file.type)) {
            const image = new Image()
            const reader = new FileReader()

            reader.readAsDataURL(file)

            reader.onload = (event) => {
                image.src = event.target!.result as string
            }
            image.onload = (e) => {
                const size = Math.min(image.width, image.height)

                const sx = image.width > image.height ? (image.width - size) / 2 : 0
                const sy = image.width < image.height ? (image.height - size) / 2 : 0

                ctx.drawImage(image, sx, sy, size, size, 0, 0, 300, 300)

                this.preview = canvas.toDataURL()

                runInAction(() => (this.preview = canvas.toDataURL()))
            }
        }
    }
}

const imagesMime = ['image/jpeg', 'image/png']

interface Attachment {
    link: string
    name: string
    mimeType: string
    size: string
    createdAt: string
    updatedAt: string
}
