export const generateAssignUserType = (
    type: 'admin' | 'analyst' | 'partner' | 'support' | 'user' | 'provider' | 'client'
) => {
    switch (type) {
        case 'admin':
        case 'analyst':
        case 'partner':
        case 'support':
            return 'admin'
        case 'client':
            return 'user'
        default:
            return type
    }
}
