import styled from 'styled-components'
import { Button } from 'components/ui'

export const Component = styled.div`
    ${Button} {
        width: 250px;
        margin: 40px auto 0;
    }
`

export const Title = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 124.7%;
    color: #39434b;
`
