import { Link } from 'react-router-dom'
import styled from 'styled-components'
import starIcon from './images/star.svg'

export const Component = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    border-radius: 10px;
    height: 91px;
    display: flex;
    align-items: center;
    margin-bottom: 8px;
    padding: 0 20px;
`

export const Avatar = styled.img`
    border-radius: 50%;
    width: 48px;
    height: 48px;
`

export const NameAndPhone = styled.div`
    flex: 1;
    margin-left: 16px;
`

export const Name = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #111111;
`

export const Phone = styled.div`
    font-size: 16px;
    line-height: 127.3%;
    color: #707b8e;
    margin-top: 4px;
`

export const Type = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #b7bcd6;
    flex: 0 0 150px;
`

export const ObjectsCount = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #68c376;
    flex: 0 0 200px;
`

export const Stars = styled.div`
    background-image: url(${starIcon});
    background-position: left center;
    background-repeat: no-repeat;
    padding-left: 22px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #707b8e;
    flex: 0 0 60px;
`

export const Reviews = styled(Link)`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #68c376;
    flex: 0 0 80px;
    text-decoration: none;
`

export const Button = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b7bcd6;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #707b8e;
    padding: 0 25px;
    height: 36px;
    margin-left: 15px;
    text-decoration: none;
`
