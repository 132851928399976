import { withRouter, match } from 'react-router'
import { Link } from 'react-router-dom'
import { Component, Breadcrumbs, Wrapper, Title, Body, Stats, Button } from './info.styled'
import { CabinetContext } from 'components/app/cabinet/context'
import { store } from 'store'
import { PureComponent } from 'react'
import { observer } from 'mobx-react'
import { Loader } from 'components/ui'

const recepientType = {
    all: 'Все пользователи',
    providers: 'Провайдеры',
    admins: 'Администраторы',
    customers: 'Абоненты',
} as { [k: string]: string }

@(withRouter as any)
@observer
export class Info extends PureComponent<{ match?: match<{ id: string }> }> {
    static contextType = CabinetContext

    get mailingId() {
        return parseInt(this.props.match!.params.id)
    }

    componentDidMount() {
        this.context.setTitle('Рассылки')

        store.mailing.resolve(this.mailingId)
    }

    render() {
        const { mailingId } = this

        if (!store.mailing.isResolved(mailingId)) {
            return <Loader />
        }

        const mail = store.mailing.get(mailingId)!
        const { title, description, type, countViewed, countSent, link, button, withButton } = mail
        const viewedPercents = Math.round((countViewed / countSent || 0) * 100)

        return (
            <Component>
                <Breadcrumbs>
                    <Link to="/mailings">Рассылки</Link>
                    <span>Сообщение #H{mailingId}</span>
                </Breadcrumbs>
                <Wrapper>
                    <Title>{title}</Title>
                    <Body>{description}</Body>
                    {withButton && (
                        <Button href={link} target="_blank">
                            {button}
                        </Button>
                    )}
                    <Stats>
                        <div>
                            <span>Отправлено</span>
                            <span>{countSent}</span>
                        </div>
                        <div>
                            <span>Просмотрено</span>
                            <span>{countViewed}</span>
                            <span>({viewedPercents}%)</span>
                        </div>
                        <div>
                            <span>Получатели</span>
                            <span>{recepientType[type]}</span>
                        </div>
                    </Stats>
                </Wrapper>
            </Component>
        )
    }
}
