import styled, { css } from 'styled-components'
import { Dot as UIDot } from 'components/ui'
import downloadIcon from './images/download.svg'
import arrowUpIcon from './images/arrow_up.svg'
import arrowDownIcon from './images/arrow_down.svg'

export const Component = styled.div`
    background: #ffffff;
    border-radius: 4px;
    margin-bottom: 10px;
    padding: 0px 30px;
`

export const Common = styled.div`
    padding: 20px 0;
    display: flex;
    align-items: center;

    & > div {
        & > div:nth-child(1) {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #707b8e;
        }
        & > div:nth-child(2) {
            font-size: 16px;
            color: #111111;
            margin-top: 4px;
        }

        &:nth-child(1) {
            flex: 1;
        }

        &:nth-child(2) {
            flex: 0 0 200px;
        }

        &:nth-child(3) {
            flex: 0 0 120px;
            text-align: right;
        }

        &:nth-child(4) {
            flex: 0 0 120px;
            text-align: right;
        }
    }
`

export const Arrow = styled.div<{ collapsed: boolean }>`
    width: 20px;
    height: 20px;
    mask: url(${(props) => (props.collapsed ? arrowDownIcon : arrowUpIcon)});
    background-color: #39434b;
    cursor: pointer;
    transition: background-color 0.2s ease;
    margin-left: 30px;

    :hover {
        background-color: #96a0a9;
    }
`

export const Datetime = styled.div`
    span:nth-child(1) {
    }
    span:nth-child(2) {
        margin-left: 6px;
        color: #b7bcd6;
    }
`

export const DownloadButton = styled.button`
    background-color: transparent;
    background-image: url(${downloadIcon});
    background-position: right center;
    background-size: 20px;
    background-repeat: no-repeat;
    padding-right: 25px;
    outline: none;
    border: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 127.3%;
    color: #68c376;
    cursor: pointer;
    margin-left: 100px;
`

export const Details = styled.div<{ collapsed: boolean }>`
    overflow: hidden;
    transition: max-height 0.3s ease, opacity 0.3s ease;

    :after {
        content: '';
        display: block;
        height: 30px;
    }

    ${(props) =>
        props.collapsed
            ? css`
                  max-height: 0;
                  opacity: 0;
              `
            : css`
                  max-height: 500px;
                  opacity: 1;
              `}
`

export const Thead = styled.div`
    display: flex;
    border-top: 1px solid #eaecf0;
    padding: 30px 0 10px;

    & > span {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #707b8e;

        &:nth-child(1) {
            flex: 0 0 139px;
        }

        &:nth-child(2) {
            flex: 1;
        }

        &:nth-child(3) {
            flex: 0 0 130px;
        }

        &:nth-child(4) {
            flex: 0 0 100px;
            text-align: right;
        }

        &:nth-child(5) {
            flex: 0 0 119px;
            text-align: right;
        }

        &:nth-child(6) {
            flex: 0 0 129px;
            text-align: right;
        }

        &:nth-child(7) {
            flex: 0 0 134px;
            margin-left: 30px;
        }
    }
`

export const Row = styled.div`
    display: flex;
    height: 64px;
    background-color: #f6faf8;
    align-items: center;
    padding: 0 14px;

    :not(:last-child) {
        margin-bottom: 6px;
    }

    & > span {
        font-size: 14px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #111111;
        white-space: nowrap;

        &:nth-child(1) {
            flex: 0 0 125px;
            color: #68c376;
        }

        &:nth-child(2) {
            flex: 1;
        }

        &:nth-child(3) {
            flex: 0 0 130px;
        }

        &:nth-child(4) {
            flex: 0 0 100px;
            text-align: right;
        }

        &:nth-child(5) {
            flex: 0 0 119px;
            text-align: right;
        }

        &:nth-child(6) {
            flex: 0 0 129px;
            text-align: right;
        }

        &:nth-child(7) {
            flex: 0 0 120px;
            margin-left: 30px;
        }
    }
`

export const Dot = styled(UIDot)`
    display: inline-flex;
    margin-right: 2px;
    top: -1px;
`
