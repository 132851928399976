import { request } from './request'

export const list = (limit: number, offset: number) => {
    return request<ListResponse>('notification/list', { limit, offset })
}

export const read = () => {
    return request<ReadResponse>('notification/read')
}

export const remove = (id: number) => {
    return request<RemoveResponse>('notification/delete', { id })
}

interface ListResponse {
    length: number
    notifications: Notification[]
}

interface ReadResponse {}

interface RemoveResponse {}

interface Notification {
    id: number
    title: string
    createdAt: string
    user: User
    state: 'created' | 'read'
}

interface User {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}
