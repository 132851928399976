import { request } from './request'

export const suggest = (query: string) => {
    return request<SuggestResponse>('dadata/suggest', { query })
}

export const inn = (query: string) => {
    return request<InnResponse>('dadata/inn', { query })
}

export const bank = (query: string) => {
    return request<BankResponse>('dadata/kpp', { query })
}

type SuggestResponse = AddressSuggestion[]
type InnResponse = InnInfo[]
type BankResponse = BankInfo[]

interface BankInfo {}

interface InnInfo {
    value: string
    unrestricted_value: string
    isTaxpayer: boolean
    data: InnData2
}

interface BankInfo {
    value: string
    unrestricted_value: string
    data: BankData2
}

interface BankData2 {
    opf: Opf
    name: Name
    bic: string
    swift: string
    inn: string
    kpp: string
    okpo?: any
    correspondent_account: string
    treasury_accounts?: any
    registration_number: string
    payment_city: string
    state: State
    rkc?: any
    cbr?: any
    address: Address
    phones?: any
}

interface InnData2 {
    kpp: string
    capital?: any
    management: Management
    founders?: any
    managers?: any
    predecessors?: any
    successors?: any
    branch_type: string
    branch_count: number
    source?: any
    qc?: any
    hid: string
    type: string
    state: State
    opf: Opf
    name: Name
    inn: string
    ogrn: string
    okpo: string
    okato: string
    oktmo: string
    okogu: string
    okfs: string
    okved: string
    okveds?: any
    authorities?: any
    documents?: any
    licenses?: any
    finance: Finance
    address: Address
    phones?: any
    emails?: any
    ogrn_date: number
    okved_type: string
    employee_count?: any
}

interface Address {
    value: string
    unrestricted_value: string
    data: Data
}

interface Data {
    postal_code: string
    country: string
    country_iso_code: string
    federal_district: string
    region_fias_id: string
    region_kladr_id: string
    region_iso_code: string
    region_with_type: string
    region_type: string
    region_type_full: string
    region: string
    area_fias_id: string
    area_kladr_id: string
    area_with_type: string
    area_type: string
    area_type_full: string
    area: string
    city_fias_id?: any
    city_kladr_id?: any
    city_with_type?: any
    city_type?: any
    city_type_full?: any
    city?: any
    city_area?: any
    city_district_fias_id?: any
    city_district_kladr_id?: any
    city_district_with_type?: any
    city_district_type?: any
    city_district_type_full?: any
    city_district?: any
    settlement_fias_id: string
    settlement_kladr_id: string
    settlement_with_type: string
    settlement_type: string
    settlement_type_full: string
    settlement: string
    street_fias_id: string
    street_kladr_id: string
    street_with_type: string
    street_type: string
    street_type_full: string
    street: string
    house_fias_id: string
    house_kladr_id: string
    house_cadnum?: any
    house_type: string
    house_type_full: string
    house: string
    block_type?: any
    block_type_full?: any
    block?: any
    entrance?: any
    floor?: any
    flat_fias_id?: any
    flat_cadnum?: any
    flat_type?: any
    flat_type_full?: any
    flat?: any
    flat_area?: any
    square_meter_price?: any
    flat_price?: any
    postal_box?: any
    fias_id: string
    fias_code: string
    fias_level: string
    fias_actuality_state: string
    kladr_id: string
    geoname_id: string
    capital_marker: string
    okato: string
    oktmo: string
    tax_office: string
    tax_office_legal: string
    timezone: string
    geo_lat: string
    geo_lon: string
    beltway_hit?: any
    beltway_distance?: any
    metro?: any
    qc_geo: string
    qc_complete?: any
    qc_house?: any
    history_values?: any
    unparsed_parts?: any
    source: string
    qc: string
}

interface Finance {
    tax_system?: any
    income?: any
    expense?: any
    debt?: any
    penalty?: any
    year?: any
}

interface Name {
    full_with_opf: string
    short_with_opf: string
    latin?: any
    full: string
    short: string
    payment: string
}

interface Opf {
    type: string
    code: string
    full: string
    short: string
}

interface State {
    status: string
    code?: any
    actuality_date: number
    registration_date: number
    liquidation_date?: any
}

interface Management {
    name: string
    post: string
    disqualified?: any
}

interface AddressSuggestion {
    value: string
    unrestrictedValue: string
    data: AddressSuggestionData
}

interface AddressSuggestionData {
    postal_code?: any
    country: string
    country_iso_code: string
    federal_district?: any
    region_fias_id: string
    region_kladr_id: string
    region_iso_code: string
    region_with_type: string
    region_type: string
    region_type_full: string
    region: string
    area_fias_id?: any
    area_kladr_id?: any
    area_with_type?: any
    area_type?: any
    area_type_full?: any
    area?: any
    city_fias_id: string
    city_kladr_id: string
    city_with_type: string
    city_type: string
    city_type_full: string
    city: string
    city_area?: any
    city_district_fias_id?: any
    city_district_kladr_id?: any
    city_district_with_type?: any
    city_district_type?: any
    city_district_type_full?: any
    city_district?: any
    settlement_fias_id?: any
    settlement_kladr_id?: any
    settlement_with_type?: any
    settlement_type?: any
    settlement_type_full?: any
    settlement?: any
    street_fias_id: string
    street_kladr_id: string
    street_with_type: string
    street_type: string
    street_type_full: string
    street: string
    house_fias_id?: any
    house_kladr_id?: any
    house_cadnum?: any
    house_type?: any
    house_type_full?: any
    house?: any
    block_type?: any
    block_type_full?: any
    block?: any
    entrance?: any
    floor?: any
    flat_fias_id?: any
    flat_cadnum?: any
    flat_type?: any
    flat_type_full?: any
    flat?: any
    flat_area?: any
    square_meter_price?: any
    flat_price?: any
    postal_box?: any
    fias_id: string
    fias_code: string
    fias_level: string
    fias_actuality_state: string
    kladr_id: string
    geoname_id: string
    capital_marker: string
    okato: string
    oktmo: string
    tax_office: string
    tax_office_legal: string
    timezone?: any
    geo_lat: string
    geo_lon: string
    beltway_hit?: any
    beltway_distance?: any
    metro?: any
    qc_geo: string
    qc_complete?: any
    qc_house?: any
    history_values: string[]
    unparsed_parts?: any
    source?: any
    qc?: any
}
