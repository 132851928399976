import { action, computed, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { PureComponent } from 'react'
import { Component, Label, Current, Bubble, Option, Error } from './select.styled'
import { Scrollbars } from 'react-custom-scrollbars-2'

interface SelectInputProps<T = any> {
    small?: boolean
    label?: string
    options: [T, string][]
    value?: T
    placeholder?: string
    onChange?: (v: T) => void
    disabled?: boolean
    error?: string | boolean
}

@observer
export class SelectInput<T> extends PureComponent<SelectInputProps> {
    @observable
    private value: T

    @observable
    private bubbleVisibility = false

    constructor(props: SelectInputProps) {
        super(props)
        makeObservable(this)
        this.value = 'value' in props ? props.value : null
    }

    @computed
    get currentOptionIndex() {
        const { options } = this.props

        for (let i = 0; i < options.length; i++) {
            if (options[i][0] === this.value) {
                return i
            }
        }

        return null
    }

    @action
    componentDidUpdate(prevProps: SelectInputProps<T>) {
        if (this.props.value !== prevProps.value) {
            this.value = this.props.value
        }
    }

    @action
    toggleBubble(value?: boolean) {
        this.bubbleVisibility = arguments.length ? value! : !this.bubbleVisibility
    }

    @action
    setValue(value: T) {
        this.value = value
        this.toggleBubble(false)

        if (this.props.onChange) {
            this.props.onChange(value)
        }
    }

    render() {
        const { label, options, small, placeholder = '', disabled, error, ...other } = this.props
        const { currentOptionIndex, bubbleVisibility } = this
        const currentOptionName = currentOptionIndex !== null ? options[currentOptionIndex][1] : placeholder
        const err = !bubbleVisibility && error

        return (
            <Component tabIndex={1} onBlur={() => this.toggleBubble(false)} {...other}>
                <Label>{label}</Label>
                <Current
                    disabled={!!disabled}
                    bubbleVisibility={bubbleVisibility}
                    onClick={() => this.toggleBubble()}
                    small={!!small}
                    error={!!err}
                >
                    {currentOptionName}
                </Current>
                <Error>{err}</Error>
                <Bubble visible={bubbleVisibility}>
                    {options.length > 5 ? (
                        <Scrollbars
                            style={{
                                height: Math.min(220, options.length * (small ? 37 : 44)),
                                width: '100%',
                                backgroundColor: 'transparent',
                                //overflowX: 'visible',
                            }}
                        >
                            {options.map(([value, name], i) => (
                                <Option
                                    key={i}
                                    current={value === this.value}
                                    onClick={() => this.setValue(value)}
                                    small={!!small}
                                >
                                    <div>{name}</div>
                                </Option>
                            ))}
                        </Scrollbars>
                    ) : (
                        options.map(([value, name], i) => (
                            <Option
                                key={i}
                                current={value === this.value}
                                onClick={() => this.setValue(value)}
                                small={!!small}
                            >
                                {name}
                            </Option>
                        ))
                    )}
                </Bubble>
            </Component>
        )
    }
}
