import { api } from 'api'
import { observable, runInAction } from 'mobx'

export * as links from './ticket_links'

const STORE = observable.map<number, TicketData>()

export const isResolved = (id: number) => {
    return STORE.has(id)
}

export const get = (id: number) => {
    return STORE.get(id)
}

export const resolve = async (id: number) => {
    const { ticket } = await api.ticket.get(id)
    runInAction(() => STORE.set(id, ticket))
    BUSY_MAP.set(id, false)
}

const BUSY_MAP = new Map<number, boolean>()

export const resolveMoreMessages = async (id: number) => {
    if (!BUSY_MAP.get(id)) {
        BUSY_MAP.set(id, true)

        const { messages } = get(id)!
        const lastMessageId = messages[0].id
        const data = await api.ticket.messages(id, lastMessageId)

        if (data.messages.length) {
            BUSY_MAP.set(id, false)

            runInAction(() => messages.unshift(...data.messages))
        }
    }
}

export interface TicketData {
    id: number
    creatorUserId: number
    creatorUser: CreatorUser
    assignUserId: number
    assignUser: Executor
    createdAt: string
    updatedAt: string
    title: string
    countMessage: number
    status: 'active' | 'wait' | 'close'
    description: string
    note: string
    details: string
    messages: TicketMessageData[]
}

interface Executor {
    id: number
    type: 'admin' | 'analyst' | 'partner' | 'support' | 'user' | 'provider' | 'client'
    name: string
    avatar: string
}

interface CreatorUser extends Executor {
    phone: string
}

export interface TicketMessageData {
    id: number
    ticketId: number
    userId: number
    user: CreatorUser
    createdAt: string
    isSystem: boolean
    template: string
    options: Options
    message: string
    attachments: Attachment[]
    links: Link[]
}

interface Link {
    id: string
    link: string
    name: string
}

interface Attachment {
    link: string
    name: string
    mimeType: string
    size: string
    createdAt: string
    updatedAt: string
}

interface Options {}
