import styled from 'styled-components'
import searchIcon from './images/search.svg'
import { Link } from 'react-router-dom'

export const Component = styled.div``

export const Bar = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    margin: -20px -28px 20px -18px;
    padding-left: 10px;
`

export const Title = styled.div`
    margin-top: 47px;
    margin-bottom: 20px;

    b {
        font-weight: bold;
        font-size: 24px;
        line-height: 124.7%;
        color: #39434b;
    }
    span {
        font-weight: bold;
        font-size: 24px;
        line-height: 124.7%;
        color: #b7bcd6;
        margin-left: 12px;
    }
`

export const SearchInput = styled.input.attrs({
    type: 'text',
})`
    width: 100%;
    height: 51px;
    background-color: #ffffff;
    background-image: url(${searchIcon});
    background-position: 20px center;
    background-repeat: no-repeat;
    padding: 0 20px 0 52px;
    outline: none;
    border: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;

    ::placeholder {
        font-size: 16px;
        line-height: 127.3%;
        color: #b7bcd6;
    }
`

export const Addresses = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 10px;
`

export const AddressComponent = styled(Link)`
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    height: 67px;
    display: grid;
    grid-template-columns: 1fr 180px 180px;
    column-gap: 20px;
    align-items: center;
    padding-left: 30px;
    padding-right: 20px;
    font-size: 18px;
    line-height: 150.7%;
    color: #111111;
    cursor: pointer;
    text-decoration: none;

    & > div:nth-child(1) {
    }
    & > div:nth-child(2) {
        color: #68c376;
        text-align: right;
    }
    & > div:nth-child(3) {
        color: #68c376;
        text-align: right;
    }
`

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b7bcd6;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #707b8e;
    padding: 0 25px;
    height: 36px;
    text-decoration: none;
    background-color: white;
    cursor: pointer;
    white-space: nowrap;

    :hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    }

    :active {
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
    }

    :disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`

export const Query = styled.div`
    flex: 1;
    position: relative;
    outline: none;
`

export const Bubble = styled.div`
    position: absolute;
    z-index: 10;
    top: calc(100% + 10px);
    left: 0;
    width: 95%;
    background: #ffffff;
    box-shadow: 0px 2px 14px rgba(133, 140, 166, 0.236724);
    border-radius: 4px;

    :not(:hover) > div:first-child {
        background-color: #f1faf3;
    }
`

export const BubbleOption = styled.div`
    padding: 8px 17px;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;

    :hover {
        background-color: #f1faf3;
    }

    :last-child {
        border-radius: 0 0 4px 4px;
    }
`
