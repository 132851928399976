import { api } from 'api'
import { observable, runInAction } from 'mobx'

export const STORE = observable.map<number, AdminTransactionsData>()

export const isResolved = (accountId: number) => {
    return STORE.has(accountId)
}

export const get = (accountId: number) => {
    return STORE.get(accountId)
}

export const resolve = async (userId: number) => {
    const data = await api.user.transactionsList(20, 0, userId)

    BUSY_MAP.set(userId, false)

    runInAction(() => STORE.set(userId, data))
}

const BUSY_MAP = new Map<number, boolean>()

export const resolveMore = async (userId: number) => {
    if (!isResolved(userId)) return

    if (!BUSY_MAP.get(userId)) {
        BUSY_MAP.set(userId, true)

        const data = get(userId)!
        const offset = data.length
        const { transactions } = await api.user.transactionsList(20, offset, userId)

        if (transactions.length) {
            BUSY_MAP.set(userId, false)

            runInAction(() => data.transactions.push(...transactions))
        }
    }
}

//export type AdminTransactionsData = AdminTransactionsItemData[]
export interface AdminTransactionsData {
    transactions: AdminTransactionsItemData[]
    info: AdminTransactionsInfo
    length: number
}

export interface AdminTransactionsInfo {
    totalTurnover: number
    revenueSharing: number
    totalAmount: number
    nextPayout: string
    nextPayoutAmount: number
}

export interface AdminTransactionsItemData {
    id: number
    createdAt: string
    amount: number
    percent: number
    serviceFee: number
    reportFileId: string
}
