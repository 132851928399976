import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { Default, Ticket } from './routes'

export const Support = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/ticket/:id`}>
                <Ticket />
            </Route>
            <Route path={`${path}/create/:assignType/:assignId`}>
                <Default />
            </Route>
            <Route path={`${path}/new`}>
                <Default />
            </Route>
            <Route path={`${path}/:filter?/:userId?`}>
                <Default />
            </Route>
        </Switch>
    )
}
