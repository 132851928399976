import { request } from './request'

export const get = (id: number) => {
    return request<GetResponse>('mailing/get', { id })
}

export const create = (mailing: NewMailing) => {
    return request<CreateResponse>('mailing/create', { mailing })
}

export const list = (limit: number, offset: number) => {
    return request<ListResponse>('mailing/list', { limit, offset })
}

interface GetResponse {
    mailing: Mailing
}

interface CreateResponse {
    mailing: Mailing
}

interface ListResponse {
    mailings: Mailing[]
    length: number
}

type MailingType = 'all' | 'user' | 'client' | 'provider' | 'admin' | 'support' | 'partner' | 'analyst'

interface NewMailing {
    type: MailingType
    title: string
    description: string
    withButton: boolean
    button: string
    link: string
}

interface Mailing {
    id: number
    type: MailingType
    title: string
    createdAt: string
    countSent: number
    countViewed: number
    description: string
    withButton: boolean
    button: string
    link: string
}
