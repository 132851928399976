import { ReactNode } from 'react'
import { Component, Label, Bubble } from './options.styled'

type OptionsProps = {
    label: string
    children: ReactNode
}

export const Options = (props: OptionsProps) => {
    const { label, children, ...other } = props

    return (
        <Component {...other}>
            <Label>{label}</Label>
            <Bubble>{children}</Bubble>
        </Component>
    )
}
