import styled from 'styled-components'
import arrowIcon from './images/arrow.svg'
import bellIcon from './images/bell.svg'
import bellActiveIcon from './images/bell-active.svg'
import { Link } from 'react-router-dom'

export const Component = styled.header`
    grid-area: head;
    display: flex;
    align-items: center;
    padding: 15px 28px 15px 18px;
    border-bottom: 1px solid #eaecf0;
`

export const Title = styled.div`
    flex: 1;
    font-family: Arsenal;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 124.7%;
    color: #111111;
`

export const NoticeLink = styled(Link)`
    flex: 0 0 26px;
    height: 28px;
    margin: 0 25px;
`

export const Bell = styled.span<{ active: boolean }>`
    display: block;
    height: 28px;
    width: 28px;
    background-image: url(${(props) => (props.active ? bellActiveIcon : bellIcon)});
    background-size: contain;
    background-repeat: no-repeat;
`

export const DropMenu = styled.div`
    width: 148px;
    padding: 10px 0;
    position: absolute;
    right: 0;
    top: calc(100% + 10px);
    background: #f7f8fc;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    visibility: hidden;
    margin-top: 5px;
    opacity: 0;
    z-index: 3;
    transition: margin-top 0.3s 0.1s ease, opacity 0.3s 0.1s ease, visibility 0s 0.4s ease;

    a,
    div {
        height: 40px;
        padding: 0 14px;
        font-family: inherit;
        font-size: 16px;
        line-height: 127.3%;
        color: #707b8e;
        display: flex;
        align-items: center;
        white-space: nowrap;
        text-decoration: none;

        :hover {
            background-color: #eaecf0;
        }
    }
`

export const Profile = styled.div`
    flex: 0 0 71px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    cursor: pointer;
    position: relative;

    :hover {
        ${DropMenu} {
            visibility: visible;
            margin-top: 0px;
            opacity: 1;
            transition: margin-top 0.3s ease, opacity 0.3s ease, visibility 0s ease;
        }
    }
`

export const Avatar = styled.div<{ img: string }>`
    flex: 0 0 40px;
    height: 40px;
    background-image: url(${(props) => props.img});
    background-size: contain;
    background-repeat: no-repeat;
    border-radius: 50%;
`

export const DropArrow = styled.img.attrs({ src: arrowIcon })`
    width: 24px;
    height: 24px;
`
