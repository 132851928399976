import { TextInput as UITextInput, Dot as UIDot } from 'components/ui'
import styled from 'styled-components'

export const Component = styled.div``

export const Tools = styled.div`
    display: flex;
    gap: 23px;
`

export const SearchInput = styled(UITextInput)`
    flex: 1;
`

export const Title = styled.div`
    font-weight: bold;
    font-size: 24px;
    margin: 33px 0 19px;

    & > span:nth-child(1) {
        color: #39434b;
    }
    & > span:nth-child(2) {
        color: #b7bcd6;
        margin-left: 12px;
    }
`

export const Dot = styled(UIDot)`
    display: inline-flex !important;
    top: -2px;
    margin-right: 2px;
`

export const TheadComp = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #b7bcd6;
    padding: 0 15px;
    display: flex;
    margin-bottom: 14px;

    & > div:nth-child(1) {
        flex: 0 0 85px;
    }
    & > div:nth-child(2) {
        flex: 0 0 170px;
    }
    & > div:nth-child(3) {
        flex: 1;
    }
    & > div:nth-child(4) {
        flex: 0 0 110px;
    }
    & > div:nth-child(5) {
        flex: 0 0 140px;
    }
    & > div:nth-child(6) {
        flex: 0 0 250px;
    }
`

export const ItemComp = styled.div`
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #111111;
    padding: 0 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    height: 56px;

    & > div:nth-child(1) {
        flex: 0 0 90px;
    }
    & > div:nth-child(2) {
        flex: 0 0 170px;

        & > span:nth-child(2) {
            color: #b7bcd6;
            margin-left: 8px;
        }
    }
    & > div:nth-child(3) {
        flex: 1;
    }
    & > div:nth-child(4) {
        flex: 0 0 110px;
    }
    & > div:nth-child(5) {
        flex: 0 0 140px;
    }
    & > div:nth-child(6) {
        flex: 0 0 132px;
    }
    & > div:nth-child(7) {
        padding-top: 2px;
    }
`

export const Button = styled.button<{ icon: string }>`
    display: inline-block;
    border: 1px solid #b7bcd6;
    box-sizing: border-box;
    border-radius: 8px;
    height: 36px;
    width: 54px;
    background-color: white;
    background-image: url(${(props) => props.icon});
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    &:nth-child(2) {
        margin-left: 10px;
    }

    :hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    }

    :active {
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
    }

    :disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`
