import { formatNumber } from 'utils'

export const plural = (count: number, ...words: string[]) => {
    return formatNumber(count) + ' ' + pluralDesc(count, ...words)
}

export const pluralDesc = (count: number, ...words: string[]) => {
    let cases = [2, 0, 1, 1, 1, 2]
    return words[count % 100 > 4 && count % 100 < 20 ? 2 : cases[Math.min(count % 10, 5)]]
}
