import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { Create, Default, Info, Edit, Reviews } from './routes'

export const Admins = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/reviews/:id`}>
                <Reviews />
            </Route>
            <Route path={`${path}/edit/:id`}>
                <Edit />
            </Route>
            <Route path={`${path}/create`}>
                <Create />
            </Route>
            <Route path={`${path}/:id`}>
                <Info />
            </Route>
            <Route path={path}>
                <Default />
            </Route>
        </Switch>
    )
}
