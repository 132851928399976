import { Loader } from 'components/ui'
import { PureComponent } from 'react'
import { observer } from 'mobx-react'
import { store } from 'store'
import { formatMoney } from 'utils'
import { Component, Thead, Row, Datetime, Money } from './balance.styled'
import Scrollbars from 'react-custom-scrollbars-2'

interface BalanceProps {
    accountId: number
}

@observer
export class Balance extends PureComponent<BalanceProps> {
    get accountId() {
        return this.props.accountId
    }

    componentDidMount() {
        store.address.accountBalance.resolve(this.accountId)
    }

    handleScroll(e: React.UIEvent<HTMLDivElement, UIEvent>) {
        const { scrollHeight, clientHeight, scrollTop } = e.target as HTMLDivElement

        if ((scrollHeight - clientHeight - scrollTop) / (scrollHeight - clientHeight) < 0.25) {
            store.address.accountBalance.resolveMore(this.accountId)
        }

        e.preventDefault()
        e.stopPropagation()
    }

    render() {
        const { accountId } = this

        if (!store.address.accountBalance.isResolved(accountId)) {
            return <Loader />
        }

        const transactions = store.address.accountBalance.get(accountId)!

        return (
            <Component>
                <Thead>
                    <span>Дата</span>
                    <span>Зачислено</span>
                    <span>Баланс</span>
                    <span>Тип оплаты</span>
                </Thead>
                <Scrollbars
                    onScroll={(e) => this.handleScroll(e)}
                    style={{ height: Math.min(340, transactions.length * 68), width: '100%' }}
                >
                    {transactions.map(({ id, createdAt, amount, balance, type }) => {
                        const datetime = new Date(createdAt)

                        return (
                            <Row key={id}>
                                <Datetime>
                                    <span>{datetime.toLocaleDateString()}</span>
                                    <span>{datetime.toLocaleTimeString()}</span>
                                </Datetime>
                                <Money isPositive={amount > 0}>{formatMoney(amount)}</Money>
                                <Money isPositive={balance > 0}>{formatMoney(balance)}</Money>
                                <span>{transactionType[type]}</span>
                            </Row>
                        )
                    })}
                </Scrollbars>
            </Component>
        )
    }
}

const transactionType = {
    qr: 'QR платёж',
    marketplace: 'Маркетплейс',
    provider: 'Провайдер',
}
