import { Popup } from 'components/ui'
import { Img, Heading, Desc, Button } from './invite_failure.styled'

interface InviteFailurePopupProps {
    onClose: () => void
}

export const InviteFailurePopup = (props: InviteFailurePopupProps) => {
    const { onClose } = props

    return (
        <Popup width={486}>
            <Img />
            <Heading>Ошибка при отправке</Heading>
            <Desc>Проверьте сетевое соединение</Desc>
            <Button small secondary onClick={onClose}>
                Попробовать снова
            </Button>
        </Popup>
    )
}
