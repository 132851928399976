import { Loader } from 'components/ui'
import { PureComponent } from 'react'
import { store } from 'store'
import { download, fileUrl, formatMoney } from 'utils'
import { Component, Title, Table, Row } from './transactions.styled'
import { createScrollAutoloader } from 'utils'
import { observer } from 'mobx-react'

type TransactionsProps = {
    id: number
}

@observer
export class Transactions extends PureComponent<TransactionsProps> {
    private detachScrollListener!: () => void

    componentDidMount() {
        const { id } = this.props

        store.admin.transactions.resolve(id)
        this.detachScrollListener = createScrollAutoloader(() => store.admin.transactions.resolveMore(id))
    }

    componentWillUnmount() {
        this.detachScrollListener()
    }

    render() {
        const { id } = this.props

        if (!store.admin.transactions.isResolved(id)) {
            return <Loader />
        }

        const data = store.admin.transactions.get(id)!

        return (
            <Component>
                <Title>Все транзакции</Title>
                <Table>
                    {data.transactions.map((i) => {
                        const date = new Date(i.createdAt)

                        return (
                            <Row key={i.id}>
                                <div>
                                    <span>Дата</span>
                                    <b>{formarDate(date)}</b>
                                </div>
                                <div>
                                    <span>Процент</span>
                                    <b>{i.percent}%</b>
                                </div>
                                <div>
                                    <span>Сумма</span>
                                    <b>{formatMoney(i.amount)}</b>
                                </div>
                                <div>
                                    <span>Коммисия сервиса</span>
                                    <b>{formatMoney(i.serviceFee)}</b>
                                </div>
                                <div>
                                    <button onClick={() => download(fileUrl(i.reportFileId))}>Скачать отчёт</button>
                                </div>
                            </Row>
                        )
                    })}
                </Table>
            </Component>
        )
    }
}

const monthFormatter = new Intl.DateTimeFormat('ru', { month: 'long' })

const formarDate = (date: Date) => {
    const rawMonth = monthFormatter.format(date)
    const year = date.getFullYear()
    const month = rawMonth[0].toUpperCase() + rawMonth.slice(1)

    return `${month} ${year}`
}
