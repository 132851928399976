import { api } from 'api'
import { store } from 'store'
import { runNoticesWatcher, stopNoticesWatcher } from './notices'

export const login = async (username: string, password: string) => {
    const { user, token, refreshToken } = await api.auth.login(username, password)

    api.token.updateTokens(token, refreshToken)

    await store.regions.resolve()

    store.user.set(user)

    runNoticesWatcher()
}

export const initialLoad = async () => {
    await initialLoadUserInfo()
}

export const initialLoadUserInfo = async () => {
    try {
        const { user } = await api.auth.info()

        await store.regions.resolve()

        store.user.set(user)

        runNoticesWatcher()
    } catch (e) {
        console.log(e)
    }
}

export const softLogout = () => {
    store.user.clear()
    store.notices.clear()
    api.token.removeTokens()
    stopNoticesWatcher()
}

export const sendEmailConfirmationCode = async () => {
    await api.auth.requestEmail()
}

export const sendPhoneConfirmationCode = async () => {
    await api.auth.requestSms()
}

export const sendLegalConfirmationCode = async () => {
    await api.auth.requestLegal()
}

export const confirmEmail = async (code: string) => {
    await api.auth.checkEmail(code)
    const user = store.user.get()!
    user.isNeedEmailValidation = false
}

export const confirmPhone = async (code: string) => {
    await api.auth.checkSms(code)
    const user = store.user.get()!
    user.isNeedSMSValidation = false
}

export const confirmLegal = async (code: string) => {
    await api.auth.chechLegal(code)
    const user = store.user.get()!
    user.isNeedLegalValidation = false
}
