import styled from 'styled-components'
import { TextInput as UITextInput } from 'components/ui'

export const Component = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 24px 30px;
    margin-top: 20px;
`

export const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #39434b;
`

export const Inn = styled.div`
    position: relative;
    max-width: 715px;
`

export const InnInput = styled(UITextInput)`
    width: 100%;
    max-width: 715px;
    margin-top: 25px;
`

export const Inputs = styled.div`
    border-top: 1px solid #eaecf0;
    padding-top: 30px;
    margin-top: 30px;
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: 350px 349px;
    grid-template-areas: 'bic bill';
`

export const Bank = styled.div`
    grid-area: bic;
    position: relative;
`

export const BankInput = styled(UITextInput)``

export const BillInput = styled(UITextInput)`
    grid-area: bill;
`

export const Params = styled.div`
    display: grid;
    grid-column-gap: 20px;
    grid-row-gap: 18px;
    grid-template-columns: 200px 1fr;
    margin-top: 20px;
`

export const Name = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #707b8e;
`

export const Value = styled.div`
    font-size: 16px;
    line-height: 127.3%;
    color: #111111;
`

export const Bubble = styled.div`
    position: absolute;
    z-index: 10;
    bottom: calc(100% + 10px);
    left: 0;
    min-width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 14px rgba(133, 140, 166, 0.236724);
    border-radius: 4px;
`

export const BubbleOption = styled.div`
    padding: 8px 17px;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;

    div {
        &:nth-child(1) {
            font-size: 18px;
            color: #39434b;
            margin-top: 2px;
        }
        &:nth-child(2) {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #707b8e;
        }
    }

    :hover {
        background-color: #f1faf3;
    }

    :last-child {
        border-radius: 0 0 4px 4px;
    }
`
