import { Component, Common, Datetime, DownloadButton, Arrow, Details, Thead, Row, Dot } from './payment.styled'
import { PureComponent } from 'react'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import { CustomerPaymentsItemData } from 'store'
import { download, formatMoney } from 'utils'

interface PaymentProps {
    data: CustomerPaymentsItemData
}

@observer
export class Payment extends PureComponent<PaymentProps> {
    @observable
    private collapsed = true

    constructor(props: PaymentProps) {
        super(props)
        makeObservable(this)
    }

    render() {
        const { createdAt, type, amount, items, link, serviceFee } = this.props.data
        const date = new Date(createdAt)

        return (
            <Component>
                <Common>
                    <div>
                        <div>Дата</div>
                        <Datetime>
                            <span>{date.toLocaleDateString()}</span>
                            <span>{date.toLocaleTimeString()}</span>
                        </Datetime>
                    </div>
                    <div>
                        <div>Способ оплаты</div>
                        <div>{paymentTypes[type]}</div>
                    </div>
                    <div>
                        <div>Сумма</div>
                        <div>{formatMoney(amount)}</div>
                    </div>
                    <div>
                        <div>Коммисия</div>
                        <div>{formatMoney(serviceFee)}</div>
                    </div>
                    <DownloadButton onClick={() => download(link)}>Скачать чек</DownloadButton>
                    <Arrow collapsed={this.collapsed} onClick={action(() => (this.collapsed = !this.collapsed))} />
                </Common>
                <Details collapsed={this.collapsed}>
                    <Thead>
                        <span>Лицевой счёт</span>
                        <span>Провайдер</span>
                        <span>ИНН</span>
                        <span>Сумма</span>
                        <span>Коммисия</span>
                        <span>Зачислено</span>
                        <span>Тип</span>
                    </Thead>
                    {items.map((item) => (
                        <Row key={item.id}>
                            <span>{item.account.number}</span>
                            <span>{item.provider.shortLegalEntity.name}</span>
                            <span>{item.provider.shortLegalEntity.inn}</span>
                            <span>{formatMoney(item.amountWithFee)}</span>
                            <span>{formatMoney(item.serviceFee)}</span>
                            <span>{formatMoney(item.amount)}</span>
                            <span>
                                <Dot color={calcDotColor(item.state)} label={calcDotDesc(item.state)} />
                                {itemTypes[item.type]}
                            </span>
                        </Row>
                    ))}
                </Details>
            </Component>
        )
    }
}

const calcDotColor = (state: 'error' | 'success' | 'wait') => {
    if (state === 'error') return 'red'
    if (state === 'success') return 'green'
    if (state === 'wait') return 'yellow'

    throw new Error('Unknown item state')
}

const calcDotDesc = (state: 'error' | 'success' | 'wait') => {
    if (state === 'error') return 'Ошибка'
    if (state === 'success') return 'Успешно'
    if (state === 'wait') return 'В ожидании'

    throw new Error('Unknown item state')
}

const paymentTypes = {
    apple: 'Apple pay',
    google: 'Google pay',
    card: 'Оплата картой',
}

const itemTypes = {
    qr: 'QR платёж',
    marketplace: 'Маркетплейс',
}
