import { PureComponent } from 'react'
import { CabinetContext } from 'components/app/cabinet/context'
import { store, AdminsOrderBy } from 'store'
import { Admin } from './admin'
import {
    Component,
    SearchInputWrapper,
    SearchInput,
    Head,
    Title,
    Counter,
    Button,
    Spacer,
    SelectInput,
} from './default.styled'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import { Loader } from 'components/ui'
import { createScrollAutoloader } from 'utils'

const sortSelectOptions = [
    ['name', 'По имени'],
    ['date', 'По дате'],
    ['rating', 'По рейтингу'],
    ['role', 'По роли'],
] as [string, string][]

@observer
export class Default extends PureComponent {
    static contextType = CabinetContext

    @observable
    private query = store.admins.getParams().query
    private queryTimeout!: NodeJS.Timeout
    private detachScrollListener!: () => void

    constructor(props: {}) {
        super(props)
        makeObservable(this)
    }

    componentDidMount() {
        this.context.setTitle('Все администраторы')
        store.admins.resolve()
        this.detachScrollListener = createScrollAutoloader(store.admins.resolveMore)
    }

    componentWillUnmount() {
        this.detachScrollListener()
    }

    @action
    handleQueryChange(query: string) {
        this.query = query

        clearTimeout(this.queryTimeout)

        this.queryTimeout = setTimeout(() => {
            store.admins.setParams({ query })
        }, 500)
    }

    handleOrderChange(orderBy: AdminsOrderBy) {
        store.admins.setParams({ orderBy })
    }

    render() {
        if (!store.admins.isResolved()) {
            return <Loader />
        }

        const { orderBy } = store.admins.getParams()
        const { users, length } = store.admins.get()
        const currentUser = store.user.get()!

        return (
            <Component>
                <SearchInputWrapper>
                    <SearchInput
                        placeholder="Поиск"
                        value={this.query}
                        onChange={(e) => this.handleQueryChange(e.target.value)}
                    />
                </SearchInputWrapper>
                <Head>
                    <Title>Администраторы</Title>
                    <Counter>{length}</Counter>
                    <Spacer />
                    <SelectInput
                        options={sortSelectOptions}
                        value={orderBy}
                        onChange={(v) => this.handleOrderChange(v)}
                        small
                    />
                    {(currentUser.isOwner || currentUser.type === 'admin') && (
                        <Button to="admins/create">+ Создать</Button>
                    )}
                </Head>
                {users.map((admin) => (
                    <Admin key={admin.id} data={admin} />
                ))}
            </Component>
        )
    }
}
