import styled, { css } from 'styled-components'
import logo from 'images/logo_white.svg'
import { NavLink } from 'react-router-dom'

export const Component = styled.aside`
    grid-area: aside;
    background: #4a5662;
    position: fixed;
    left: 0;
    top: 0;
    bottom: 0;
    width: 270px;
    z-index: 1;
`

export const Logo = styled.img.attrs({
    src: logo,
    alt: 'logo',
})`
    display: block;
    width: 143px;
    height: 28px;
    margin: 21px 0 58px 28px;
`

export const MenuItemIcon = styled.span<{ name: string }>`
    mask: url(${(props) => require(`./images/${props.name}.svg`).default}) no-repeat center;
    background-color: white;
    transition: background-color 0.3s ease;
    width: 24px;
    height: 24px;
    margin: 5px;
`

export const MenuItemName = styled.span`
    color: white;
    margin-left: 10px;
    font-size: 18px;
    font-weight: 400;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 18px;
    line-height: 150.7%;
`

export const MenuItem = styled(NavLink).attrs({
    activeClassName: 'active',
})<{ disabled?: boolean }>`
    display: flex;
    align-items: center;
    color: white;
    padding: 0 20px;
    text-decoration: none;
    height: 47px;
    margin: 10px 0;
    user-select: none;

    :hover,
    &.active {
        background: #5d6874;
        color: #68c376;

        ${MenuItemIcon} {
            background-color: #68c376;
        }

        ${MenuItemName} {
            color: #68c376;
        }
    }

    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;

            ${MenuItemIcon} {
                background-color: #707b8e;
            }

            ${MenuItemName} {
                color: #707b8e;
            }
        `}
`

export const Section = styled.div`
    border-top: 1px solid #707b8e;
    margin: 10px 0;

    :empty {
        display: none;
    }
`
