import { Link } from 'react-router-dom'
import styled from 'styled-components'
import { Label as UILabel } from 'components/ui'

export const Component = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    padding: 24px 30px 33px 53px;
    margin-top: 20px;
`

export const Avatar = styled.img`
    width: 90px;
    height: 90px;
    margin-right: 46px;
    border-radius: 50%;
`

export const Head = styled.div`
    display: flex;
    align-items: center;
`

export const NameAndLabel = styled.div`
    flex: 1;
`

export const Name = styled.div`
    font-family: Arsenal;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 124.7%;
    color: #39434b;
    margin-top: -10px;
`

export const Label = styled(UILabel)`
    margin-top: 4px;
`

export const Params = styled.div`
    display: grid;
    grid-row-gap: 12px;
    grid-template-columns: 130px 1fr;
    align-items: center;
    margin: 40px 0 15px 137px;
`

export const Param = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #707b8e;
    width: 130px;
`

export const Value = styled.div`
    font-size: 18px;
    line-height: 150.7%;
    color: #39434b;
    margin-right: 30px;
`

export const Activity = styled.div`
    display: flex;
    align-items: center;
    border-top: 1px solid #eaecf0;
    padding-top: 24px;
    margin-left: 137px;
    max-width: 744px;
`

export const Button = styled(Link)`
    width: 134px;
    padding: 0 11px;
    height: 37px;
    background: #68c376;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    align-self: flex-start;

    :hover {
        background: #68c376;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    }

    :active {
        background: #68c376;
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
        border-radius: 8px;
    }

    :disabled {
        background: #68c376;
        opacity: 0.5;
        border-radius: 8px;
    }
`
