import { Button, Heading, Popup } from 'components/ui'
import { Desc, Buttons } from './remove.styled'

interface RemovePopupProps {
    onConfirm: () => void
    onClose: () => void
}

export const RemovePopup = (props: RemovePopupProps) => {
    const { onConfirm, onClose } = props

    return (
        <Popup width={655}>
            <Heading>Удалить провайдера</Heading>
            <Desc>
                Вы уверенны что вы хотите удалить <br /> провайдера?
            </Desc>
            <Buttons>
                <Button secondary onClick={onClose}>
                    Нет
                </Button>
                <Button onClick={onConfirm}>Да</Button>
            </Buttons>
        </Popup>
    )
}
