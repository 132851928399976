import { PureComponent } from 'react'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Component, Photos, Photo, ArrowLeft, ArrowRight, Dots, Dot } from './slider.styled'
import { fileUrl } from 'utils'

interface PhotoItem {
    link: string
    name: string
    mimeType: string
    size: string
    createdAt: string
    updatedAt: string
}

interface SliderProps {
    photos: PhotoItem[]
}

@observer
export class Slider extends PureComponent<SliderProps> {
    @observable
    private cursor = 0

    constructor(props: SliderProps) {
        super(props)
        makeObservable(this)
    }

    @action
    prev() {
        this.cursor = this.cursor === 0 ? this.props.photos.length - 1 : this.cursor - 1
    }

    @action
    next() {
        this.cursor = this.cursor === this.props.photos.length - 1 ? 0 : this.cursor + 1
    }

    render() {
        const { photos } = this.props

        return (
            <Component>
                <Photos>
                    {photos.map((photo, i) => (
                        <Photo key={photo.link} src={fileUrl(photo.link)} active={i === this.cursor} />
                    ))}
                </Photos>
                {photos.length > 1 && (
                    <>
                        <ArrowLeft onClick={() => this.prev()} />
                        <ArrowRight onClick={() => this.next()} />
                        <Dots>
                            {photos.map((photo, i) => (
                                <Dot key={photo.link} active={i === this.cursor} />
                            ))}
                        </Dots>
                    </>
                )}
            </Component>
        )
    }
}
