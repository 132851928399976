import { api } from 'api'
import { action, computed, observable, runInAction } from 'mobx'

const STORE = observable.box<NoticesData>({ length: 0, notifications: [] })

const HAS_UNREAD = computed(() => {
    return !!STORE.get().notifications.filter((i) => i.state === 'created').length
})

export const hasUnread = () => {
    return HAS_UNREAD.get()
}

export const get = () => {
    return STORE.get()
}

export const readAll = action(() => {
    const { notifications } = STORE.get()

    for (const item of notifications) {
        item.state = 'read'
    }
})

export const clear = action(() => {
    STORE.set({ length: 0, notifications: [] })
})

export const remove = action((id: number) => {
    const { notifications } = STORE.get()

    let i = 0

    for (const notice of notifications) {
        if (notice.id === id) {
            notifications.splice(i, 1)

            return
        }

        i++
    }

    throw new Error(`Notice with id ${id} not found`)
})

export const sync = action(({ length, notifications }: NoticesData) => {
    const data = STORE.get()
    const firstId = data.notifications.length ? data.notifications[0].id : null

    let i = 0

    for (const item of notifications) {
        if (item.id === firstId) {
            break
        }

        i++
    }

    const sublist = notifications.slice(0, i)

    data.notifications.unshift(...sublist)
    data.length = length
})

let BUSY = false

export const isResolved = () => {
    return !!STORE.get()
}

export const resolve = async () => {
    const data = await api.notification.list(20, 0)

    runInAction(() => STORE.set(data))

    BUSY = false
}

export const resolveMore = async () => {
    if (!isResolved()) return

    if (!BUSY) {
        BUSY = true

        const { notifications } = get()
        const offset = notifications.length
        const data = await api.notification.list(20, offset)

        if (data.notifications.length) {
            BUSY = false

            runInAction(() => notifications.push(...data.notifications))
        }
    }
}

export interface NoticesData {
    length: number
    notifications: NoticesItemData[]
}

export interface NoticesItemData {
    id: number
    title: string
    createdAt: string
    user: User
    state: 'created' | 'read'
}

interface User {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}
