import { PureComponent } from 'react'
import {
    Breadcrumbs,
    Component,
    Content,
    Info,
    Flex,
    Id,
    Timeleft,
    Title,
    Messages,
    MessagesList,
    OpenedImage,
    Picture,
    CloseOpenedImage,
} from './ticket.styled'
import { CabinetContext } from 'components/app/cabinet/context'
import { match, withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import { Aside } from './aside'
import { Message } from './message'
import { NewMessage, NewTicketMessageData } from './new_message'
import { store } from 'store'
import { observer } from 'mobx-react'
import { Loader } from 'components/ui'
import { elapsedTime } from 'utils'
import { Scrollbars } from 'react-custom-scrollbars-2'
import { actions } from 'actions'
import { action, makeObservable, observable } from 'mobx'

interface TicketProps {
    match?: match<{ id: string }>
}

@(withRouter as any)
@observer
export class Ticket extends PureComponent<TicketProps> {
    static contextType = CabinetContext

    private scrollbars!: Scrollbars
    private autoscrollTimer!: NodeJS.Timeout

    @observable
    private openedImage = ''

    constructor(props: TicketProps) {
        super(props)
        makeObservable(this)
    }

    get ticketId() {
        return parseInt(this.props.match!.params.id)
    }

    initScrollbars(c: Scrollbars) {
        if (!this.scrollbars) {
            this.scrollbars = c
            this.runAutoscrollToBottom()
        }
    }

    runAutoscrollToBottom() {
        this.autoscrollTimer = setTimeout(() => {
            this.scrollbars.scrollToBottom()
            this.runAutoscrollToBottom()
        }, 200)
    }

    stopAutoscrollToBottom() {
        clearTimeout(this.autoscrollTimer)
    }

    handleScroll() {
        const height = this.scrollbars.getClientHeight()
        const scrollHeight = this.scrollbars.getScrollHeight()
        const scrollTop = this.scrollbars.getScrollTop()

        this.stopAutoscrollToBottom()

        if (scrollHeight + scrollTop === height) {
            this.runAutoscrollToBottom()
        }
        if (scrollTop / scrollHeight < 0.5) {
            store.ticket.resolveMoreMessages(this.ticketId)
        }
    }

    componentDidMount() {
        this.context.setTitle('Поддержка')
        store.ticket.resolve(this.ticketId)
    }

    componentWillUnmount() {
        this.stopAutoscrollToBottom()
    }

    @action
    handleOpenImage(src: string) {
        this.openedImage = src
    }

    @action
    closeOpenedImage() {
        this.openedImage = ''
    }

    async sendMessage(message: NewTicketMessageData) {
        await actions.sendTicketMessage(this.ticketId, message)
        this.scrollbars.scrollToBottom()
    }

    render() {
        const { ticketId, openedImage } = this

        if (!store.ticket.isResolved(ticketId)) {
            return <Loader />
        }

        const { id, title, updatedAt, messages, status } = store.ticket.get(ticketId)!
        const elapsed = Date.now() - new Date(updatedAt).getTime()

        return (
            <Component>
                {!!openedImage && (
                    <OpenedImage>
                        <Picture>
                            <CloseOpenedImage onClick={() => this.closeOpenedImage()} />
                            <img src={openedImage} alt="" />
                        </Picture>
                    </OpenedImage>
                )}
                <Content>
                    <Breadcrumbs>
                        <Link to="/support">Поддержка</Link>
                        <span>Заявка #{id}</span>
                    </Breadcrumbs>
                    <Info>
                        <Flex>
                            <Id>
                                Заявка #{id} ({ticketStatus(status)})
                            </Id>
                            <Timeleft>
                                <span>Время ожидания:</span>
                                <span>{elapsedTime(elapsed)}</span>
                            </Timeleft>
                        </Flex>
                        <Title>{title}</Title>
                    </Info>
                    <Messages>
                        <Scrollbars ref={(c) => c && this.initScrollbars(c)} onScroll={(e) => this.handleScroll()}>
                            <MessagesList>
                                {messages.map((item) => (
                                    <Message
                                        key={item.id}
                                        data={item}
                                        onOpenImage={(src) => this.handleOpenImage(src)}
                                    />
                                ))}
                            </MessagesList>
                        </Scrollbars>
                    </Messages>
                </Content>
                <Aside ticketId={id} />
                <NewMessage ticketId={id} onSend={(m) => this.sendMessage(m)} />
            </Component>
        )
    }
}

const ticketStatus = (status: 'active' | 'wait' | 'close') => {
    switch (status) {
        case 'active':
            return 'Активный'
        case 'wait':
            return 'Ожидание'
        case 'close':
            return 'Архивный'
    }
}
