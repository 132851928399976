import { Button, Heading, Popup } from 'components/ui'
import { Desc, Buttons } from './connect.styled'

interface ConnectPopupProps {
    onConfirm: () => void
    onClose: () => void
}

export const ConnectPopup = (props: ConnectPopupProps) => {
    const { onConfirm, onClose } = props

    return (
        <Popup width={655}>
            <Heading>Подключиться к провайдеру</Heading>
            <Desc>
                Вы уверены что вы хотите <br /> подключиться к провайдеру??
            </Desc>
            <Buttons>
                <Button secondary onClick={onClose}>
                    Нет
                </Button>
                <Button onClick={onConfirm}>Да</Button>
            </Buttons>
        </Popup>
    )
}
