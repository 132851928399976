import styled from 'styled-components'
import searchIcon from './images/search.svg'
import { Link } from 'react-router-dom'
import arrowRightIcon from './images/arrow_right.svg'

export const Component = styled.div``

export const Bar = styled.div`
    display: flex;
    align-items: center;
    background-color: #fff;
    margin: -20px -28px 20px -18px;
    padding-left: 10px;
`

export const Title = styled.div`
    margin-bottom: 20px;

    b {
        font-weight: bold;
        font-size: 24px;
        line-height: 124.7%;
        color: #39434b;
    }
    span {
        font-weight: bold;
        font-size: 24px;
        line-height: 124.7%;
        color: #b7bcd6;
        margin-left: 12px;
    }
`

export const SearchInput = styled.input.attrs({
    type: 'text',
})`
    flex: 1;
    display: block;
    width: 100%;
    height: 51px;
    background-color: #ffffff;
    background-image: url(${searchIcon});
    background-position: 20px center;
    background-repeat: no-repeat;
    padding: 0 20px 0 52px;
    outline: none;
    border: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;

    ::placeholder {
        font-size: 16px;
        line-height: 127.3%;
        color: #b7bcd6;
    }
`

export const AreaComponent = styled(Link)`
    margin-top: 10px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    height: 67px;
    display: flex;
    align-items: center;
    padding-left: 30px;
    padding-right: 82px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    text-decoration: none;
    font-size: 18px;
    line-height: 150.7%;
    color: #111111;
    cursor: pointer;
    background-image: url(${arrowRightIcon});
    background-position: calc(100% - 15px) center;
    background-repeat: no-repeat;
    background-size: 24px;

    div:nth-child(2) {
        color: #b7bcd6;
    }

    div:nth-child(3),
    div:nth-child(4),
    div:nth-child(5) {
        color: #68c376;
    }
`

export const Column = styled.div<{ flex: string; alignRight?: boolean }>`
    flex: ${(props) => props.flex};
    text-align: ${(props) => (props.alignRight ? 'right' : 'left')};
`
