import styled from 'styled-components'
import successIcon from './images/success.svg'
import { Heading as UIHeading, Button as UIButton } from 'components/ui'

export const Img = styled.div`
    width: 64px;
    height: 64px;
    background-image: url(${successIcon});
    background-size: 64px;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 20px;
`

export const Heading = styled(UIHeading)`
    text-align: center;
    margin-bottom: 36px;
`

export const Button = styled(UIButton)`
    font-size: 14px;
`
