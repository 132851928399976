import { action, reaction, runInAction } from 'mobx'
import { api } from 'api'
import { createStore } from './abstract'
import { YEAR } from 'utils'

export * as details from './ads_details'

type AdStatus = 'all' | 'active' | 'archive' | 'blocked'
type AdOrder = 'countView' | 'createdAt' | 'price' | 'countClaim'

interface Params {
    year: number
    regionId: number
    status: AdStatus
    hasClaim: boolean
    orderBy: AdOrder
}

const store = createStore<Params, AdsData>({
    year: YEAR,
    regionId: 0,
    status: 'all',
    orderBy: 'createdAt',
    hasClaim: false,
})

export const get = store.get
export const setParams = store.setParams
export const getParams = store.getParams
export const isResolved = store.isResolved

const BUSY_MAP = new Map<string, boolean>()

export const resolve = async () => {
    const key = store.getKey()
    const params = store.getParams()
    const data = await api.ads.list(20, 0, params)

    BUSY_MAP.set(key, false)

    store.set(key, data)
}

export const resolveMore = async () => {
    if (!isResolved()) return

    const key = store.getKey()

    if (!BUSY_MAP.get(key)) {
        BUSY_MAP.set(key, true)

        const { sales } = store.get()
        const offset = sales.length
        const params = getParams()
        const data = await api.ads.list(20, offset, params)

        if (data.sales.length === 0) {
            return
        }

        BUSY_MAP.set(key, false)

        runInAction(() => sales.push(...data.sales))
    }
}

// resolver

let lastKey: string

reaction(
    () => store.getRealKey(),
    (key) => {
        lastKey = key

        const params = store.getRealParams()

        if (store.hasKey(key)) {
            store.setKey(key)

            const limit = store.get(key).sales.length

            api.ads.list(limit, 0, params).then(action((data) => store.set(key, data)))
        } else {
            api.ads.list(20, 0, params).then(
                action((data) => {
                    store.set(key, data)

                    if (key === lastKey) {
                        store.setKey(key)
                    }
                })
            )
        }
    }
)

export interface AdsData {
    chart: Chart
    sales: AdsItemData[]
    length: number
}

export interface AdsItemData {
    id: string
    user: User
    section: string
    sectionName: string
    category: string
    categoryName: string
    name: string
    description: string
    price: number
    files: File[]
    addressId: number
    address: Address
    canChat: boolean
    canCall: boolean
    status: 'active' | 'archive' | 'blocked'
    lastDetail: LastDetail
    countView: number
    createdAt: string
    updatedAt: string
    hasClaim: boolean
    countClaim: number
    detailBlock: string
}

interface LastDetail {
    type: string
    detail: string
    createdAt: string
    user: User
}

interface Address {
    city: string
    address: string
}

interface File {
    link: string
    name: string
    mimeType: string
    size: string
    createdAt: string
    updatedAt: string
}

interface User {
    id: number
    type: string
    name: string
    avatar: string
}

interface Chart {
    current: Current[]
}

interface Current {
    month: number
    count: number
}
