import styled from 'styled-components'

export const Component = styled.div``

export const Breadcrumbs = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 36px;

    a,
    span {
        :not(:last-child) {
            font-size: 16px;
            line-height: 127.3%;
            color: #707b8e;
            text-decoration: none;
            transition: color 0.2s ease;

            :after {
                content: '•';
                margin: 0 8px;
                text-decoration: none;
            }
        }
        :last-child {
            font-size: 18px;
            line-height: 21px;
            color: #68c376;
            font-weight: 500;
        }
    }

    a:hover {
        color: #4e5a6f;
    }
`

export const Wrapper = styled.div`
    padding: 30px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    border-radius: 10px;
`

export const Title = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 124.7%;
    color: #39434b;
    margin-bottom: 17px;
`

export const Body = styled.div`
    font-size: 16px;
    line-height: 170%;
    color: #111111;
    margin-bottom: 28px;

    a {
        letter-spacing: -0.24px;
        text-decoration-line: underline;
        color: #707b8e;
        margin-left: 8px;
    }
`

export const Stats = styled.div`
    display: flex;
    margin-top: 50px;
    color: #111111;

    div {
        margin-right: 32px;
        font-size: 14px;

        span {
            display: inline-block;
        }
        span:nth-child(1) {
            color: #707b8e;
        }
        span:nth-child(2) {
            margin-left: 8px;
            font-weight: 500;
        }
        span:nth-child(3) {
            color: #707b8e;
            margin-left: 4px;
        }
    }
`

export const Button = styled.a`
    display: inline-flex;
    padding: 0 28px;
    height: 51px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 500;
    font-size: 18px;
    outline: none;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    color: #ffffff;
    background-color: #68c376;
    text-decoration: none;

    :hover {
        background: #68c376;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    }

    :active {
        background-color: #68c376;
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
    }

    :disabled {
        background-color: #68c376;
        opacity: 0.5;
        pointer-events: none;
    }
`
