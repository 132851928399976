const STORAGE_TOKEN = '_TOKEN_'
const STORAGE_REFRESH_TOKEN = '_REFRESH_TOKEN_'

let token = localStorage.getItem(STORAGE_TOKEN) || ''
let refreshToken = localStorage.getItem(STORAGE_REFRESH_TOKEN) || ''

export const getToken = () => token

export const hasTokens = () => !!token && !!refreshToken

export const getRefreshToken = () => refreshToken

export const updateTokens = (newToken: string, newRefreshToken: string) => {
    token = newToken
    refreshToken = newRefreshToken

    localStorage.setItem(STORAGE_TOKEN, newToken)
    localStorage.setItem(STORAGE_REFRESH_TOKEN, newRefreshToken)
}

export const removeTokens = () => {
    token = ''
    refreshToken = ''

    localStorage.removeItem(STORAGE_TOKEN)
    localStorage.removeItem(STORAGE_REFRESH_TOKEN)
}
