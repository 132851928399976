import { actions } from 'actions'
import { Link, withRouter, RouteComponentProps } from 'react-router-dom'
import { store } from 'store'
import { avatarUrl } from 'utils'
import { Component, Title, NoticeLink, Bell, Profile, Avatar, DropArrow, DropMenu } from './head.styled'

interface HeadProps {
    title: string
}

export const Head = withRouter((props: HeadProps & RouteComponentProps) => {
    const { title } = props
    const user = store.user.get()!
    const isBellActive = store.notices.hasUnread()
    const newTicketUrl = props.location.pathname === '/support/create' ? '/support/new' : '/support/create'

    return (
        <Component>
            <Title>{title}</Title>
            <NoticeLink to="/notices">
                <Bell active={isBellActive} />
            </NoticeLink>
            <Profile>
                <Avatar img={avatarUrl(user.avatar)} />
                <DropArrow />
                <DropMenu>
                    <a
                        href="https://tartan-coal-42b.notion.site/17f30104a05740d0a57de916be839068"
                        target="_blank"
                        rel="noreferrer"
                    >
                        База знаний
                    </a>
                    <Link to={newTicketUrl}>Создать тикет</Link>
                    <div onClick={() => actions.softLogout()}>Выход</div>
                </DropMenu>
            </Profile>
        </Component>
    )
})
