import styled from 'styled-components'

export const Desc = styled.div`
    font-size: 18px;
    line-height: 150.7%;
    text-align: center;
    color: #39434b;
    margin: 10px 0px 36px;
`

export const Space = styled.div`
    height: 42px;
`

export const Buttons = styled.div`
    display: flex;
    column-gap: 20px;
`
