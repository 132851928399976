import { Popup } from 'components/ui'
import { Img, Heading, Button } from './invite_success.styled'

interface InviteSuccessPopupProps {
    onClose: () => void
}

export const InviteSuccessPopup = (props: InviteSuccessPopupProps) => {
    const { onClose } = props

    return (
        <Popup width={486}>
            <Img />
            <Heading>Приглашение успешно отправлено</Heading>
            <Button small secondary onClick={onClose}>
                Продолжить
            </Button>
        </Popup>
    )
}
