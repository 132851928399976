import { PureComponent, ChangeEvent } from 'react'
import { Button, Heading, Popup, CheckboxInput } from 'components/ui'
import { Desc, Help, Info, Row, Name, Value, CheckboxWrapper, Buttons } from './info_confirmation.styled'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import { store } from 'store'

interface InfoConfirmationPopupProps {
    onSend: () => void
    onCancel: () => void
}

@observer
export class InfoConfirmationPopup extends PureComponent<InfoConfirmationPopupProps> {
    @observable
    private buttonDisabled = false

    constructor(props: InfoConfirmationPopupProps) {
        super(props)
        makeObservable(this)
    }

    @action
    handleCheckboxChange(e: ChangeEvent<HTMLInputElement>) {
        this.buttonDisabled = !e.target.checked
    }

    render() {
        const { onSend, onCancel } = this.props
        const user = store.user.get()!
        const { name, inn, bankName, bik, account, bankAccount } = user.legalEntity

        return (
            <Popup>
                <Heading>Верификация партнёра</Heading>
                <Desc>Подтвердите ваши данные</Desc>
                <Info>
                    <Row>
                        <Name>Получатель</Name>
                        <Value>
                            {name} (ИНН {inn})
                        </Value>
                    </Row>
                    <Row>
                        <Name>Банк</Name>
                        <Value>{bankName}</Value>
                    </Row>
                    <Row>
                        <Name>БИК</Name>
                        <Value>{bik}</Value>
                    </Row>
                    <Row>
                        <Name>Кор. счёт</Name>
                        <Value>{bankAccount}</Value>
                    </Row>
                    <Row>
                        <Name>Расчётный счёт</Name>
                        <Value>{account}</Value>
                    </Row>
                </Info>
                <CheckboxWrapper>
                    <CheckboxInput checked onChange={(e) => this.handleCheckboxChange(e)}>
                        Ознакомлен и согласен с условиями <a href="/">Договора-оферты</a>
                    </CheckboxInput>
                </CheckboxWrapper>
                <Buttons>
                    <Button secondary onClick={onCancel}>
                        Отмена
                    </Button>
                    <Button onClick={onSend} disabled={this.buttonDisabled}>
                        Подтвердить
                    </Button>
                </Buttons>
                <Help>
                    Реквизиты неверны? Обратитесь к администратору <br />
                    <a href="/">Написать email</a>
                </Help>
            </Popup>
        )
    }
}
