import { withRouter, match } from 'react-router'
import { Link } from 'react-router-dom'
import { CabinetContext } from 'components/app/cabinet/context'
import { Component, Breadcrumbs, Title } from './address.styled'
import { PureComponent } from 'react'
import { store } from 'store'
import { Account } from './account'
import { observer } from 'mobx-react'
import { Loader } from 'components/ui'
import { plural } from 'utils'

interface AddressProps {
    match?: match<{ addressId: string }>
}

@(withRouter as any)
@observer
export class Address extends PureComponent<AddressProps> {
    static contextType = CabinetContext

    get addressId() {
        return parseInt(this.props.match!.params.addressId)
    }

    componentDidMount() {
        this.context.setTitle('Объект недвижимости')

        const { addressId } = this

        store.address.resolve(addressId)
    }

    render() {
        const { addressId } = this

        if (!store.address.isResolved(addressId)) {
            return <Loader />
        }

        const { short, full, accounts } = store.address.get(addressId)!

        return (
            <Component>
                <Breadcrumbs>
                    <Link to="/objects">Объекты недвижимости</Link>
                    <span>{short}</span>
                </Breadcrumbs>
                {/* <Customer>
                    <Avatar src={avatarUrl(customer.avatar)} />
                    <Name>
                        {customer.last} {customer.first} {customer.patronymic}
                    </Name>
                    <Label type={customer.type} />
                    <Spacer />
                    <Button to={`${matchedUrl}/tickets/create`}>+ Создать тикет</Button>
                </Customer> */}
                <Title>
                    <b>{full}</b>
                    <span>{plural(accounts.length, 'лицевой счет', 'лицевых счета', 'лицевых счетов')}</span>
                </Title>
                {accounts.map((data) => (
                    <Account key={data.id} data={data} />
                ))}
            </Component>
        )
    }
}
