import { withRouter, match } from 'react-router'
import { Link } from 'react-router-dom'
import { CabinetContext } from 'components/app/cabinet/context'
import { Provider } from '../../provider'
import { Component, Breadcrumbs, Title } from './reviews.styled'
import { PureComponent } from 'react'
import { store } from 'store'
import { Review } from './review'
import { Loader } from 'components/ui'
import { createScrollAutoloader } from 'utils'
import { observer } from 'mobx-react'

interface ReviewsProps {
    match?: match<{ id: string }>
}

@(withRouter as any)
@observer
export class Reviews extends PureComponent<ReviewsProps> {
    static contextType = CabinetContext
    private scrollAutoloadDisposer!: () => void

    get providerId() {
        return parseInt(this.props.match!.params.id)
    }

    componentDidMount() {
        this.context.setTitle('Отзывы о провайдере')

        const { providerId } = this

        store.provider.resolve(providerId)
        store.reviews.provider.resolve(providerId)

        this.scrollAutoloadDisposer = createScrollAutoloader(() => store.reviews.provider.resolveMore(providerId))
    }

    componentWillUnmount() {
        this.scrollAutoloadDisposer()
    }

    render() {
        const { providerId } = this

        if (!store.provider.isResolved(providerId) || !store.reviews.provider.isResolved(providerId)) {
            return <Loader />
        }

        const provider = store.provider.get(providerId)!
        const { length, reviews } = store.reviews.provider.get(providerId)!

        return (
            <Component>
                <Breadcrumbs>
                    <Link to="/providers">Провайдеры</Link>
                    <span>Отзывы о провайдере ID{providerId}</span>
                </Breadcrumbs>
                <Provider data={provider} />
                <Title>
                    <b>Отзывы</b>
                    <span>{length}</span>
                </Title>
                {reviews.map((data) => (
                    <Review key={data.id} data={data} />
                ))}
            </Component>
        )
    }
}
