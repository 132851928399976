import styled from 'styled-components'

export const Component = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    margin-top: 24px;
    padding: 24px 30px;
`

export const Table = styled.div`
    display: grid;
    grid-template-columns: 200px 1fr;
    grid-row-gap: 18px;
    grid-column-gap: 16px;
`

export const Param = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #707b8e;
`

export const Value = styled.div`
    font-size: 16px;
    line-height: 127.3%;
    color: #111111;
`

export const Hr = styled.hr`
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: #eaecf0;
    margin: 24px 0 30px;
    max-width: 904px;
`
