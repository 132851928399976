import { Button, Heading, Popup } from 'components/ui'
import { Desc, Space, Buttons } from './need_email_confirmation.styled'

interface NeedEmailConfirmationPopupProps {
    email: string
    onSend: () => void
    onCancel: () => void
}

export const NeedEmailConfirmationPopup = (props: NeedEmailConfirmationPopupProps) => {
    const { onSend, onCancel, email } = props

    return (
        <Popup>
            <Heading>Подтвердите email</Heading>
            <Desc>На {email} будет отправлен код</Desc>
            <Buttons>
                <Button secondary onClick={onCancel}>
                    Отмена
                </Button>
                <Button onClick={onSend}>Отправить код</Button>
            </Buttons>
            <Space />
        </Popup>
    )
}
