import { TextInput as UITextInput, Label as UILabel } from 'components/ui'
import emptyIcon from './images/empty.svg'
import userIcon from './images/user.svg'
import clockIcon from './images/clock.svg'
import mailIcon from './images/mail.svg'
import { NavLink, Link as RouterLink } from 'react-router-dom'
import styled from 'styled-components'

export const Component = styled.div``

export const Links = styled.div`
    display: flex;
    height: 36px;
    width: 420px;
    margin-top: -56px;
    margin-bottom: 20px;
`

export const Link = styled(NavLink).attrs({
    activeClassName: 'active',
})<{ disabled?: boolean }>`
    flex: 0 0 33.3%;
    color: #707b8e;
    padding: 0 20px;
    text-decoration: none;
    text-align: center;
    user-select: none;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    border-bottom: 5px solid transparent;
    transition: border-bottom-color 0.2s ease;

    :hover {
        color: #68c376;
    }

    &.active {
        border-bottom-color: #68c376;
        color: #111111;
    }
`

export const Tools = styled.div`
    display: flex;
    gap: 23px;
`

export const SearchInput = styled(UITextInput)`
    flex: 1;
`

export const Title = styled.div`
    font-weight: bold;
    font-size: 24px;
    margin: 33px 0 19px;

    span:nth-child(1) {
        color: #39434b;
    }
    span:nth-child(2) {
        color: #b7bcd6;
        margin-left: 12px;
    }
`

export const Empty = styled.div`
    height: 100px;
    background-image: url(${emptyIcon});
    background-repeat: no-repeat;
    background-position: center;
`

export const ItemComp = styled(RouterLink)`
    padding: 20px 30px;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    background-color: #ffffff;
    display: block;
    text-decoration: none;
    font-family: Roboto;

    :not(:last-child) {
        margin-bottom: 10px;
    }
`

export const Button = styled.button`
    padding: 0 28px;
    height: 44px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    color: #ffffff;
    background-color: #68c376;

    :hover {
        background: #68c376;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    }

    :active {
        background-color: #68c376;
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
    }

    :disabled {
        background-color: #68c376;
        opacity: 0.5;
        pointer-events: none;
    }
`

export const Label = styled(UILabel)`
    margin-left: 14px;
`

export const Datetime = styled.div`
    font-size: 14px;
    line-height: 20px;

    span:nth-child(1) {
        color: #39434b;
    }
    span:nth-child(2) {
        margin-left: 14px;
        color: #b7bcd6;
    }
`

export const ItemTitle = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 124.7%;
    color: #111111;
    margin-bottom: 28px;
`

export const ItemData = styled.div`
    display: flex;
    align-items: stretch;
`

export const Avatar = styled.div<{ src: string }>`
    width: 48px;
    height: 48px;
    background: url(${(props) => props.src}) no-repeat center;
    background-size: contain;
    border-radius: 50%;
`

export const ItemDataOther = styled.div`
    display: flex;
    flex-direction: column;
    gap: 7px;
    margin: -3px 0;
    margin-left: 16px;
    flex: 1;
`

export const Flex = styled.div`
    display: flex;
    align-items: center;
`

export const Spacer = styled.div`
    flex: 1;
`

export const Manager = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #39434b;
`

export const Id = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #707b8e;
    margin-left: 14px;
`

export const Executor = styled.div`
    background: url(${userIcon}) no-repeat left 1px;
    padding-left: 20px;

    span {
        &:nth-child(1) {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #707b8e;
        }
        &:nth-child(2) {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #68c376;
            margin-left: 3px;
        }
    }
`

export const Timeleft = styled.div`
    margin-left: 36px;
    background: url(${clockIcon}) no-repeat left 2px;
    padding-left: 20px;

    span {
        &:nth-child(1) {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #707b8e;
        }
        &:nth-child(2) {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #111111;
            margin-left: 3px;
        }
    }
`

export const Messages = styled.div`
    background: url(${mailIcon}) no-repeat left 1px;
    padding-left: 25px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #68c376;
`
