import styled from 'styled-components'

export const Desc = styled.div`
    font-size: 18px;
    line-height: 150.7%;
    text-align: center;
    color: #39434b;
    margin: 10px 0px 36px;
`

export const Inputs = styled.div`
    display: flex;
    margin-bottom: 36px;
`

export const Input = styled.input.attrs({ type: 'text' })<{ error: boolean }>`
    border-width: 1px;
    border-style: solid;
    border-color: ${(props) => (props.error ? '#EB5757 !important' : '#b7bcd6')};
    border-radius: 5px;
    margin: 0px 12px;
    flex: 0 0 44px;
    max-width: 44px;
    height: 44px;
    outline: none;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    text-align: center;
    color: #000000;
`

export const Timer = styled.div`
    margin-top: 20px;
    font-size: 18px;
    line-height: 21px;
    color: #39434b;
`

export const Repeat = styled.div`
    margin-top: 20px;
    font-size: 18px;
    line-height: 21px;
    color: #68c376;
    text-decoration: underline;
    cursor: pointer;
`

export const Buttons = styled.div`
    display: flex;
    column-gap: 20px;
`
