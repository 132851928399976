import { Button, Heading, Popup } from 'components/ui'
import { Desc, Space, Buttons } from './need_phone_confirmation.styled'

interface NeedPhoneConfirmationPopupProps {
    phone: string
    onSend: () => void
    onCancel: () => void
}

export const NeedPhoneConfirmationPopup = (props: NeedPhoneConfirmationPopupProps) => {
    const { onSend, onCancel, phone } = props

    return (
        <Popup>
            <Heading>Подтвердите телефон</Heading>
            <Desc>На {phone} будет отправлен код</Desc>
            <Buttons>
                <Button secondary onClick={onCancel}>
                    Отмена
                </Button>
                <Button onClick={onSend}>Отправить код</Button>
            </Buttons>
            <Space />
        </Popup>
    )
}
