import { createRef, PureComponent, ChangeEvent } from 'react'
import { Button, Heading, Popup } from 'components/ui'
import { Desc, Inputs, Input, Buttons } from './connect_confirmation.styled'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'

interface ConnectConfirmationPopupProps {
    id: number
    error: boolean
    onConfirm: (code: string) => void
    onClose: () => void
}

@observer
export class ConnectConfirmationPopup extends PureComponent<ConnectConfirmationPopupProps> {
    @observable
    private code = '******'

    private inputs = Array.from({ length: 6 }, () => createRef()) as React.RefObject<HTMLInputElement>[]

    constructor(props: ConnectConfirmationPopupProps) {
        super(props)
        makeObservable(this)
    }

    @action
    handleChangeEvent(e: ChangeEvent<HTMLInputElement>, index: number) {
        const value = e.target.value.slice(-1)
        const chars = this.code.split('')

        chars.splice(index, 1, value)

        this.code = chars.join('')

        if (index < 5) {
            this.inputs[index + 1].current!.focus()
        } else {
            this.inputs[index].current!.blur()
        }
    }

    getInputValue(index: number) {
        const value = this.code.slice(index, index + 1)
        return value === '*' ? '' : value
    }

    render() {
        const { onConfirm, error, onClose } = this.props

        return (
            <Popup width={655}>
                <Heading>Требуется подтверждение</Heading>
                <Desc>Введите код доступа к личному кабинету провайдера</Desc>
                <Inputs>
                    <Input
                        ref={this.inputs[0]}
                        value={this.getInputValue(0)}
                        onChange={(e) => this.handleChangeEvent(e, 0)}
                        error={error}
                        autoFocus
                    />
                    <Input
                        ref={this.inputs[1]}
                        value={this.getInputValue(1)}
                        onChange={(e) => this.handleChangeEvent(e, 1)}
                        error={error}
                    />
                    <Input
                        ref={this.inputs[2]}
                        value={this.getInputValue(2)}
                        onChange={(e) => this.handleChangeEvent(e, 2)}
                        error={error}
                    />
                    <Input
                        ref={this.inputs[3]}
                        value={this.getInputValue(3)}
                        onChange={(e) => this.handleChangeEvent(e, 3)}
                        error={error}
                    />
                    <Input
                        ref={this.inputs[4]}
                        value={this.getInputValue(4)}
                        onChange={(e) => this.handleChangeEvent(e, 4)}
                        error={error}
                    />
                    <Input
                        ref={this.inputs[5]}
                        value={this.getInputValue(5)}
                        onChange={(e) => this.handleChangeEvent(e, 5)}
                        error={error}
                    />
                </Inputs>
                <Buttons>
                    <Button secondary onClick={onClose}>
                        Нет
                    </Button>
                    <Button onClick={() => onConfirm(this.code)}>Подтвердить</Button>
                </Buttons>
            </Popup>
        )
    }
}
