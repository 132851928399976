import { FILES_STORE } from 'config'
import { api } from 'api'
import defaultAvatar from './images/avatar.svg'

export const avatarUrl = (id: string) => {
    if (!id) {
        return defaultAvatar
    }

    const token = api.token.getToken()

    return `${FILES_STORE}/biglogo?fileId=${id}&token=${token}&type=admin`
}

export const fileUrl = (id: string) => {
    const token = api.token.getToken()

    return `${FILES_STORE}/get?fileId=${id}&token=${token}&type=admin`
}

export const download = (url: string) => {
    return window.open(url, '_blank')
}
