import { Component, Spinner } from './loader.styled'

export function Loader() {
    return (
        <Component>
            <Spinner>
                <div />
                <div />
                <div />
                <div />
            </Spinner>
        </Component>
    )
}
