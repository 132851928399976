import styled from 'styled-components'
import { Link } from 'react-router-dom'

export const Component = styled.div``

export const Head = styled.div`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 52px;
`

export const THead = styled.div`
    display: flex;
    margin-bottom: 7px;
    padding-left: 20px;
    padding-right: 17px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #707b8e;
`

export const Mail = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    height: 56px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    padding-left: 20px;
    padding-right: 17px;

    div:nth-child(1) {
        font-weight: bold;
    }
`

export const Column = styled.div<{ flex: string }>`
    flex: ${(props) => props.flex};

    span:nth-child(2) {
        color: #b7bcd6;
        margin-left: 8px;
    }
`

export const OpenButton = styled(Link)`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b7bcd6;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #707b8e;
    padding: 0 25px;
    height: 36px;
    text-decoration: none;
`
