import styled from 'styled-components'

export const Component = styled.div`
    display: flex;
    gap: 16px;
    margin-top: 24px;
`

const plainColors = {
    blue: '#3F5AEC',
    green: '#68C376',
    yellow: '#E8A944',
} as { [k: string]: string }

export const Plain = styled.div<{ color: string }>`
    max-width: 364px;
    background-color: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 0px 20px rgba(40, 48, 77, 0.05);
    border-radius: 8px;
    border-left-width: 9px;
    border-left-style: solid;
    border-left-color: ${(props) => plainColors[props.color]};
    display: flex;
    flex: 1 0;
    align-items: center;
    height: 70px;
    padding-left: 30px;

    b {
        font-weight: bold;
        font-size: 24px;
        line-height: 124.7%;
        color: #111111;
    }

    span,
    a {
        font-family: inherit;
        font-weight: 500;
        font-size: 18px;
        line-height: 21px;
        color: #707b8e;
        margin-left: 12px;
        text-decoration: none;

        :hover {
            text-decoration: underline;
        }
    }
`
