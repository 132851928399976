import { store } from 'store'
import { Component, RegionsList, Title } from './regions.styled'

type RegionsProps = {
    id: number
}

export const Regions = (props: RegionsProps) => {
    const { id } = props
    const { regions } = store.admin.get(id)!

    return (
        <Component>
            <Title>Регионы</Title>
            <RegionsList>
                {regions.map((i) => (
                    <span key={i.id}>{i.name}</span>
                ))}
            </RegionsList>
            {/* <Hr />
            <Title>Провайдеры</Title>
            <Provider>
                <ProviderName>ООО Новатэк-Челябинск</ProviderName>
                <ProviderInfo>ИНН 7453236330 | ID 22</ProviderInfo>
            </Provider>
            <Provider>
                <ProviderName>ЗАО ЭНЕРГОСБЫТ (ФИЛИАЛ МЕТЭНЕРГОСБЫТ)</ProviderName>
                <ProviderInfo>ИНН 7456734892 | ID 23</ProviderInfo>
            </Provider> */}
        </Component>
    )
}
