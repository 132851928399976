import { PureComponent } from 'react'
import { Component, Counter, Chart } from './graph.styled'
import { CartesianGrid, XAxis, YAxis, ResponsiveContainer, BarChart, Bar } from 'recharts'
import { observer } from 'mobx-react'
import { store } from 'store'

@observer
export class Graph extends PureComponent {
    render() {
        const { chart, length } = store.providers.get()
        const data = months.map((name, i) => {
            const found = chart.current.filter((item) => item.month === i + 1)
            const count = found.length ? found[0].count : 0
            return { name, count }
        })

        return (
            <Component>
                <Counter>
                    <span>Всего провайдеров</span>
                    <b>{length}</b>
                </Counter>
                <Chart>
                    <ResponsiveContainer>
                        <BarChart width={150} height={40} data={data}>
                            <CartesianGrid vertical={false} strokeDasharray="5 5" />
                            <Bar
                                isAnimationActive={false}
                                dataKey="count"
                                fill="#7ACA86"
                                scale={0.7}
                                label={{ fill: 'white', fontSize: 14 }}
                            />
                            <XAxis tickLine={false} axisLine={false} tick={<AxisXTick />} dataKey="name" />
                            <YAxis tickLine={false} axisLine={false} tick={<AxisYTick />} />
                        </BarChart>
                    </ResponsiveContainer>
                </Chart>
            </Component>
        )
    }
}

type AxisTickProps = {
    x?: number
    y?: number
    payload?: any
}

const AxisXTick = (props: AxisTickProps) => {
    const { x, y, payload } = props

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={25} dx={0} fontSize="14px" fontFamily="Roboto" textAnchor="middle" fill="#707B8E">
                {payload.value}
            </text>
        </g>
    )
}

const AxisYTick = (props: AxisTickProps) => {
    const { x, y, payload } = props

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={5} dx={-55} fontSize="14px" fontFamily="Roboto" textAnchor="start" fill="#707B8E">
                {payload.value}
            </text>
        </g>
    )
}

const months = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
]
