import { PureComponent } from 'react'
import { Component, Head, Title, SelectInput, List } from './ads.styled'
import { Graph } from './graph'
import { Ad } from './ad'
import { CabinetContext } from '../../context'
import { observer } from 'mobx-react'
import { store } from 'store'
import { Loader, ToggleInput } from 'components/ui'
import { action } from 'mobx'
import { createScrollAutoloader } from 'utils'

type AdOrder = 'countView' | 'createdAt' | 'price' | 'countClaim'

const orderByOptions = [
    ['createdAt', 'Дата создания'],
    ['countView', 'Количество просмотров'],
    ['price', 'Цена'],
    ['countClaim', 'Количество жалоб'],
] as [string, string][]

@observer
export class Ads extends PureComponent {
    static contextType = CabinetContext

    private scrollAutoloadDisposer!: () => void

    componentDidMount() {
        this.context.setTitle('Управление объявлениями')

        store.ads.resolve()

        this.scrollAutoloadDisposer = createScrollAutoloader(store.ads.resolveMore)
    }

    componentWillUnmount() {
        this.scrollAutoloadDisposer()
    }

    @action
    handleHasClaimChange(hasClaim: boolean) {
        store.ads.setParams({ hasClaim })
    }

    @action
    handleOrderChange(orderBy: AdOrder) {
        store.ads.setParams({ orderBy })
    }

    render() {
        if (!store.ads.isResolved()) {
            return <Loader />
        }

        const { sales } = store.ads.get()
        const { hasClaim, orderBy } = store.ads.getParams()

        return (
            <Component>
                <Graph />
                <Head>
                    <Title>Объявления</Title>
                    <ToggleInput onChange={(value) => this.handleHasClaimChange(value)} checked={hasClaim} labelOnLeft>
                        Показать только с жалобами
                    </ToggleInput>
                    <SelectInput
                        options={orderByOptions}
                        value={orderBy}
                        onChange={(v) => this.handleOrderChange(v)}
                        small
                    />
                </Head>
                <List>
                    {sales.map((i) => (
                        <Ad key={i.id} data={i} />
                    ))}
                </List>
            </Component>
        )
    }
}
