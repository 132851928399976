import styled from 'styled-components'
import logo from 'images/logo.svg'
import loginBg from 'images/login_bg.svg'
import { Heading } from 'components/ui'
import { TextInput as UITextInput, PasswordInput as UIPasswordInput } from 'components/ui'

export const Component = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    min-height: 100vh;
    background-color: #f7f8fc;
`

export const LeftSide = styled.form`
    padding-right: 140px;
    display: flex;
    flex-direction: column;
    align-items: stretch;

    ${Heading} {
        margin-bottom: 14px;
    }
`

export const RightSide = styled.div`
    flex: 0 0 50%;
    align-self: stretch;
    background-image: url(${loginBg});
    background-size: 85%;
    background-position: right bottom;
    background-repeat: no-repeat;
`

export const Logo = styled.img.attrs({
    src: logo,
    alt: 'logo',
})`
    position: absolute;
    top: 33px;
    left: 46px;
`

export const ForgotLink = styled.a`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 16px;
    color: #b7bcd6;
    margin-bottom: 48px;
    display: inline-block;
    cursor: pointer;
    align-self: flex-start;

    :hover {
        text-decoration: underline;
    }
`

export const LoginInput = styled(UITextInput)`
    margin-bottom: 24px;

    input {
        background-color: transparent;
        border: 1px solid #b7bcd6;
    }
`

export const PasswordInput = styled(UIPasswordInput)`
    margin-bottom: 18px;

    input {
        background-color: transparent;
        border: 1px solid #b7bcd6;
    }
`
