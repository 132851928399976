import * as auth from './auth'
import * as admins from './admins'
import * as mailings from './mailings'
import * as providers from './providers'
import * as customers from './customers'
import * as dadata from './dadata'
import * as notices from './notices'
import * as tickets from './tickets'

export const actions = {
    ...auth,
    ...admins,
    ...mailings,
    ...providers,
    ...customers,
    ...dadata,
    ...notices,
    ...tickets,
}
