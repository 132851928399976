import styled, { css } from 'styled-components'
import arrowUpIcon from './images/arrow_up.svg'
import arrowDownIcon from './images/arrow_down.svg'

export const Component = styled.div`
    position: relative;
    outline: none;
`

export const Label = styled.label`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #39434b;
    flex: none;
    order: 0;
    flex-grow: 0;
    margin-bottom: 2px;

    :empty {
        display: none;
    }
`

export const Current = styled.div<{ disabled: boolean; bubbleVisibility: boolean; small: boolean; error: boolean }>`
    display: flex;
    align-items: center;
    background: #ffffff;
    height: ${(props) => (props.small ? '37px' : '44px')};
    background-image: url(${(props) => (props.bubbleVisibility ? arrowUpIcon : arrowDownIcon)});
    background-position: calc(100% - 10px) center;
    background-repeat: no-repeat;
    padding: 0 40px 0 17px;
    cursor: pointer;
    border-width: 1px;
    border-style: solid;
    border-radius: 4px;
    border-color: ${(props) => (props.bubbleVisibility ? '#A9B6FF' : props.error ? '#EB5757 !important' : '#eaecf0')};
    transition: border-color 0.2s ease;
    font-size: ${(props) => (props.small ? '14px' : '16px')};
    line-height: 127.3%;
    color: #707b8e;
    user-select: none;

    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;
            opacity: 0.8;
        `}
`

export const Error = styled.div`
    color: #eb5757;
    font-family: Roboto;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    position: absolute;
    left: 0;
    top: calc(100% + 4px);
`

export const Bubble = styled.div<{ visible: boolean }>`
    position: absolute;
    z-index: 10;
    top: calc(100% + 10px);
    right: 0;
    min-width: 100%;
    margin-top: 5px;
    opacity: 0;
    visibility: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 14px rgba(133, 140, 166, 0.236724);
    border-radius: 0 0 4px 4px;
    transition: margin-top 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s;

    ${(props) =>
        props.visible &&
        css`
            opacity: 1;
            visibility: visible;
            margin-top: 0;
            transition: margin-top 0.3s ease, opacity 0.3s ease, visibility 0s;
        `}
`

export const Option = styled.div<{ current: boolean; small: boolean }>`
    height: ${(props) => (props.small ? '37px' : '44px')};
    font-size: ${(props) => (props.small ? '16px' : '18px')};
    display: flex;
    align-items: center;
    padding: 0 17px;
    cursor: pointer;
    color: ${(props) => (props.current ? '#66D576' : 'inherit')};
    background-color: ${(props) => (props.current ? '#f1faf3' : 'transparent')};
    transition: background-color 0.2s ease, color 0.2s ease;

    & > div {
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }

    :hover {
        background-color: #f1faf3;
    }

    :last-child {
        border-radius: 0 0 4px 4px;
    }
`
