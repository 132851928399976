import { AddressAccountData } from 'store'
import { Component, Thead, Row, Datetime } from './counters.styled'

interface CountersProps {
    data: AddressAccountData
}

export const Counters = (props: CountersProps) => {
    const { counters } = props.data

    return (
        <Component>
            <Thead>
                <span>Инвентарный №</span>
                <span>Показания</span>
                <span>Дата показаний</span>
                <span>Источник</span>
                <span>Статус</span>
            </Thead>
            {counters.map(({ id, number, meters, isRemove }) => (
                <Row key={id}>
                    <span>{number}</span>
                    <span>{meters.length ? meters[0].value : '-'}</span>
                    {meters.length ? (
                        <Datetime>
                            <span>{new Date(meters[0].createdAt).toLocaleDateString()}</span>
                            <span>{new Date(meters[0].createdAt).toLocaleTimeString()}</span>
                        </Datetime>
                    ) : (
                        <span>-</span>
                    )}
                    {meters.length ? <span>{metersSource[meters[0].source]}</span> : <span>-</span>}
                    <span>{isRemove ? 'Удалён' : 'Активен'}</span>
                </Row>
            ))}
        </Component>
    )
}

const metersSource = {
    user: 'Пользователь',
    provider: 'Провайдер',
}
