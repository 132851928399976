import { Link } from 'react-router-dom'
import { store } from 'store'
import { formatMoney, plural } from 'utils'
import { Component, Plain } from './stats.styled'

interface StatsProps {
    id: number
}

export const Stats = (props: StatsProps) => {
    const customer = store.customer.get(props.id)!

    return (
        <Component>
            <Plain color="blue">
                <b>{formatMoney(customer.totalPayments)}</b>
                <Link to={`/payings/history/${customer.id}`}>
                    {plural(customer.countPayments, 'оплата', 'оплаты', 'оплат')}
                </Link>
            </Plain>
            <Plain color="green">
                <b>{customer.countSales}</b>
                <span>Объявлений</span>
            </Plain>
            <Plain color="yellow">
                <b>{customer.rate}</b>
                <Link to={`/customers/reviews/${customer.id}`}>
                    {plural(customer.countReviews, 'отзыв', 'отзыва', 'отзывов')}
                </Link>
            </Plain>
        </Component>
    )
}
