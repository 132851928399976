import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { Options as UIOptions, Label as UILabel } from 'components/ui'
import lockIcon from './images/lock.svg'

export const Component = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    padding: 24px 30px 33px 53px;
    margin-top: 20px;
`

export const Avatar = styled.div<{ src: string; locked: boolean }>`
    background-image: url(${(props) => props.src});
    background-repeat: no-repeat;
    background-position: center;
    background-size: cover;
    width: 90px;
    height: 90px;
    margin-right: 46px;
    border-radius: 50%;
    position: relative;

    ${(props) =>
        props.locked &&
        css`
            :before {
                content: '';
                display: block;
                position: absolute;
                background-image: url(${lockIcon});
                background-repeat: no-repeat;
                width: 19px;
                height: 19px;
                right: 3px;
                bottom: 3px;
            }
        `};
`

export const Head = styled.div`
    display: flex;
    align-items: center;
`

export const NameAndLabel = styled.div`
    flex: 1;
`

export const Name = styled.div`
    font-family: Arsenal;
    font-style: normal;
    font-weight: bold;
    font-size: 36px;
    line-height: 124.7%;
    color: #39434b;
    margin-top: -10px;
`

export const Label = styled(UILabel)`
    margin-top: 4px;
`

export const Button = styled(Link)`
    width: 134px;
    padding: 0 11px;
    height: 37px;
    background: #68c376;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    color: #ffffff;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    text-decoration: none;
    align-self: flex-start;

    :hover {
        background: #68c376;
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    }

    :active {
        background: #68c376;
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
        border-radius: 8px;
    }

    :disabled {
        background: #68c376;
        opacity: 0.5;
        border-radius: 8px;
    }
`

export const Options = styled(UIOptions)`
    align-self: flex-start;
    margin-right: 10px;
`

export const LockButton = styled.button`
    color: #eb5757 !important;
`

export const UnlockButton = styled.button`
    color: #68c376 !important;
`
