import styled from 'styled-components'

export const Component = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 0px 20px rgba(40, 48, 77, 0.05);
    border-radius: 10px;
    padding: 25px 30px 30px;
    margin-top: 26px;
`

export const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #39434b;
    margin-bottom: 20px;
`

export const Rating = styled.div`
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 1fr 1fr;
    grid-gap: 14px;
`

export const Region = styled.div`
    background: #ffffff;
    border-width: 1px;
    border-style: solid;
    border-radius: 10px;
    padding: 20px 30px;

    :nth-child(1),
    :nth-child(4) {
        border-color: #a9b6ff;
    }

    :nth-child(2),
    :nth-child(5) {
        border-color: #68c376;
    }

    :nth-child(3),
    :nth-child(6) {
        border-color: #e8a944;
    }
`

export const RegionName = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #111111;
`

export const RegionInfo = styled.div`
    display: flex;
    margin-top: 8px;
`

export const RegionStats = styled.div`
    flex: 1;
    font-size: 18px;
    line-height: 150.7%;
    color: #68c376;
`

export const RegionPayments = styled.div`
    font-size: 18px;
    line-height: 150.7%;
    color: #111111;
`
