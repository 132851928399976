import styled from 'styled-components'
import attachIcon from './images/attach.svg'
import spinnerIcon from './images/spinner.svg'
import removeIcon from './images/remove.svg'
import TextareaAutosize from 'react-textarea-autosize'
import linkIcon from './images/link.svg'
import crossIcon from './images/cross.svg'

export const Component = styled.div`
    grid-area: new_message;
    background-color: #fcfcfe;
    padding: 25px 30px;
`

export const Inputs = styled.form`
    display: flex;

    input[type='file'] {
        display: none;
    }
`

export const AttachMenu = styled.div`
    width: 200px;
    padding: 10px 0;
    position: absolute;
    left: 0;
    bottom: 100%;
    background: #f7f8fc;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    visibility: hidden;
    margin-bottom: 8px;
    opacity: 0;
    z-index: 3;
    transition: margin-bottom 0.3s 0.1s ease, opacity 0.3s 0.1s ease, visibility 0s 0.4s ease;
`

export const AttachmentsLinksBubble = styled.div`
    position: absolute;
    width: 452px;
    height: 240px;
    background: #ffffff;
    padding: 20px 0;
    box-shadow: 0px 4px 66px rgba(0, 0, 0, 0.04);
    border-radius: 25px;
    visibility: hidden;
    margin-left: 5px;
    bottom: 5px;
    left: 65%;
    opacity: 0;
    z-index: 10;
`

export const AttachmentsLinksBubbleItem = styled.div`
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0 12px 0 54px;
    background-image: url(${linkIcon});
    background-position: 12px 14px;
    background-repeat: no-repeat;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    cursor: pointer;
    transition: color 0.2s ease, background-color 0.2s ease;

    :hover {
        color: #68c376;
        background-color: #f1faf3;
    }
`

export const FastAnswersBubble = styled.div`
    position: absolute;
    width: 452px;
    height: 240px;
    background: #ffffff;
    padding: 20px 0;
    box-shadow: 0px 4px 66px rgba(0, 0, 0, 0.04);
    border-radius: 25px;
    visibility: hidden;
    margin-left: 5px;
    bottom: 5px;
    left: 65%;
    opacity: 0;
    z-index: 10;
`

export const FastAnswersBubbleItem = styled.div`
    padding: 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    cursor: pointer;
    color: #111111;
    white-space: pre-wrap;
    transition: color 0.2s ease, background-color 0.2s ease;

    :hover {
        color: #68c376;
        background-color: #f1faf3;
    }
`

export const AttachMenuItem = styled.div`
    height: 40px;
    padding: 0 14px;
    font-size: 16px;
    line-height: 127.3%;
    color: #707b8e;
    display: flex;
    align-items: center;
    white-space: nowrap;
    position: relative;

    :hover {
        background-color: #eaecf0;

        ${AttachmentsLinksBubble}, ${FastAnswersBubble} {
            visibility: visible;
            margin-left: 0px;
            opacity: 1;
            transition: margin-top 0.3s ease, opacity 0.3s ease, visibility 0s ease;
        }
    }
`

export const Attach = styled.div`
    width: 36px;
    height: 36px;
    position: relative;
    cursor: pointer;
    background: url(${attachIcon}) no-repeat center;

    :hover {
        ${AttachMenu} {
            visibility: visible;
            margin-bottom: 3px;
            opacity: 1;
            transition: margin-bottom 0.3s ease, opacity 0.3s ease, visibility 0s ease;
        }
    }
`

export const TextInput = styled(TextareaAutosize)`
    background: #f7f8fc;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    border-radius: 4px;
    flex: 1;
    min-height: 36px;
    margin: 0 16px;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 127.3%;
    color: #111111;
    padding: 7px 16px 5px;
    outline: none;
    resize: none;

    ::placeholder {
        color: #b7bcd6;
        font-family: Roboto;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 127.3%;
    }
`

export const SendButton = styled.button`
    display: inline-flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b7bcd6;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #707b8e;
    padding: 0 12px;
    height: 36px;
    text-decoration: none;
    background-color: transparent;
    cursor: pointer;

    :disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`

export const AttachmentsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
    margin-left: 52px;
    margin-top: 16px;
`

export const AttachmentPreview = styled.div<{ src: string }>`
    background-image: url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 113px;
    height: 113px;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const AttachmentLoader = styled.div`
    width: 100px;
    height: 100px;
    background-image: url(${spinnerIcon});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`

export const AttachmentRemove = styled.div`
    position: absolute;
    background-image: url(${removeIcon});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    right: 7px;
    top: 7px;
    background-color: #eaecf0;
    opacity: 0.73;
    border-radius: 4px;
    transition: opacity 0.2s ease;
    cursor: pointer;

    :hover {
        opacity: 1;
    }
`

export const LinksList = styled.div`
    margin-left: 52px;
    margin-top: 16px;
`

export const LinksListItem = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #707b8e;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    background-image: url(${linkIcon});
    background-position: 0 7px;
    background-repeat: no-repeat;

    span:nth-child(1) {
        flex: 1;
    }

    span:nth-child(2) {
        width: 12px;
        height: 12px;
        background-image: url(${crossIcon});
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-left: 10px;
    }
`
