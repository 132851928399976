import { CheckboxInput, TextInput } from 'components/ui'
import styled, { css } from 'styled-components'

export const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    align-self: stretch;
    margin-top: 20px;
    gap: 20px;
`

export const Buttons = styled.div`
    display: flex;
    gap: 16px;
`

export const Additionals = styled.div`
    margin-bottom: 82px;
    display: grid;
    grid-template-columns: 1fr 362px;
    grid-template-rows: 44px 44px;
    column-gap: 15px;
    row-gap: 25px;
    align-items: center;
    grid-template-areas:
        'checkbox url'
        '. button_name';
`

export const ButtonToggleInput = styled(CheckboxInput)`
    grid-area: checkbox;
`

export const UrlInput = styled(TextInput)<{ visible: boolean }>`
    grid-area: url;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;

    ${(props) =>
        props.visible &&
        css`
            opacity: 1;
            pointer-events: all;
        `}
`

export const ButtonNameInput = styled(TextInput)<{ visible: boolean }>`
    grid-area: button_name;
    opacity: 0;
    pointer-events: none;
    transition: opacity 0.2s ease;

    ${(props) =>
        props.visible &&
        css`
            opacity: 1;
            pointer-events: all;
        `}
`
