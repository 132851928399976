import { action, computed, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import React, { PureComponent } from 'react'
import { MONTH, YEAR } from 'utils'
import { Arrow, Bubble, Component, CurrentValue, Month, SelectMonth, SelectYear, Year } from './date_picker.styled'
import arrowLeftIcon from './images/arrow_left.svg'
import arrowRightIcon from './images/arrow_right.svg'

const MONTHS = [
    'Январь',
    'Февраль',
    'Март',
    'Апрель',
    'Май',
    'Июнь',
    'Июль',
    'Август',
    'Сентябрь',
    'Октябрь',
    'Ноябрь',
    'Декабрь',
]

const SHORT_MONTHS = ['Янв', 'Фев', 'Мар', 'Апр', 'Май', 'Июн', 'Июл', 'Авг', 'Сен', 'Окт', 'Ноя', 'Дек']

interface DatePickerProps {
    month?: number
    year?: number
    onChange?: (value: DatePickerValue) => void
}

export interface DatePickerValue {
    month: number
    year: number
}

@observer
export class DatePicker extends PureComponent<DatePickerProps> {
    @observable
    private value: DatePickerValue

    @observable
    private bubbleVisibility = false

    constructor(props: DatePickerProps) {
        super(props)
        makeObservable(this)
        const { month = MONTH, year = YEAR } = props
        this.value = { month, year }
    }

    @action
    componentDidUpdate(prevProps: DatePickerProps) {
        const { month, year } = this.props

        if (month && year && (month !== prevProps.month || year !== prevProps.year)) {
            this.value = { month, year }
        }
    }

    @action
    setValue(value: DatePickerValue) {
        this.value = value

        if (this.props.onChange) {
            this.props.onChange(this.value)
        }
    }

    nextMonth() {
        let { month, year } = this.value

        if (month === 12) {
            month = 1
            year++
        } else {
            month++
        }

        this.setValue({ month, year })
    }

    @action
    prevMonth() {
        let { month, year } = this.value

        if (month === 1) {
            month = 12
            year--
        } else {
            month--
        }

        this.setValue({ month, year })
    }

    nextYear() {
        const { month, year } = this.value

        this.setValue({ month, year: year + 1 })
    }

    prevYear() {
        const { month, year } = this.value

        this.setValue({ month, year: year - 1 })
    }

    @action
    setMonth(month: number) {
        const { year } = this.value

        this.setValue({ month, year })
        this.toggleBubble(false)
    }

    @action
    toggleBubble(value?: boolean) {
        this.bubbleVisibility = arguments.length ? value! : !this.bubbleVisibility
    }

    @computed
    get currentTextValue() {
        const { month, year } = this.value

        return `${MONTHS[month - 1]} ${year}`
    }

    render() {
        const { year, month } = this.value
        const { currentTextValue } = this

        return (
            <Component tabIndex={1} onBlur={() => this.toggleBubble(false)}>
                <Arrow icon={arrowLeftIcon} substrate onClick={() => this.prevMonth()} />
                <CurrentValue onClick={() => this.toggleBubble()}>{currentTextValue}</CurrentValue>
                <Arrow icon={arrowRightIcon} substrate onClick={() => this.nextMonth()} />
                <Bubble visible={this.bubbleVisibility}>
                    <SelectYear>
                        <Arrow icon={arrowLeftIcon} onClick={() => this.prevYear()} />
                        <Year>{year}</Year>
                        <Arrow icon={arrowRightIcon} onClick={() => this.nextYear()} />
                    </SelectYear>
                    <SelectMonth>
                        {SHORT_MONTHS.map((name, i) => (
                            <Month selected={month === i + 1} onClick={() => this.setMonth(i + 1)} key={i}>
                                {name}
                            </Month>
                        ))}
                    </SelectMonth>
                </Bubble>
            </Component>
        )
    }
}
