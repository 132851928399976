import styled from 'styled-components'
import { SelectInput as UISelectInput } from 'components/ui'

export const Desc = styled.div`
    font-size: 18px;
    line-height: 150.7%;
    text-align: center;
    color: #39434b;
    margin: 10px 0px 36px;
`

export const SelectInput = styled(UISelectInput)`
    align-self: stretch;
`

export const Buttons = styled.div`
    margin-top: 50px;
    display: flex;
    gap: 16px;
`
