import styled from 'styled-components'
import { SelectInput as UISelectInput } from 'components/ui'

export const Component = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 24px 30px;
    margin-top: 20px;
`

export const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #39434b;
`

export const Inputs = styled.div`
    max-width: 715px;
    margin-top: 30px;
`

export const RegionInput = styled(UISelectInput)`
    margin-bottom: 25px;
`
