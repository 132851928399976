import { PureComponent } from 'react'
import { CabinetContext } from 'components/app/cabinet/context'
import { Component, AreaComponent, Title, Column } from './default.styled'
import { store, RegionsItemData } from 'store'
import { plural } from 'utils'
import { Loader } from 'components/ui'

export class Default extends PureComponent {
    static contextType = CabinetContext

    componentDidMount() {
        this.context.setTitle('Объекты недвижимости')
        store.regions.resolve()
    }

    render() {
        if (!store.regions.isResolved()) {
            return <Loader />
        }

        const regions = store.regions.get()

        return (
            <Component>
                <Title>
                    <b>Всего областей</b>
                    <span>{regions.length}</span>
                </Title>
                {regions.map((area) => (
                    <Area key={area.id} data={area} />
                ))}
            </Component>
        )
    }
}

interface ObjProps {
    data: RegionsItemData
}

const Area = (props: ObjProps) => {
    const { data } = props
    const { id, name, countAddresses, countClients, countSales, partner } = data

    return (
        <AreaComponent to={`/objects/region/${id}`}>
            <Column flex="0 0 330px">{name}</Column>
            <Column flex="1">{plural(countAddresses, 'адрес', 'адреса', 'адресов')}</Column>
            <Column flex="1">
                {partner && `${partner.lastname} ${partner.firstname.slice(0, 1)}.${partner.patronymic.slice(0, 1)}.`}
            </Column>
            <Column flex="0 0 160px">{plural(countClients, 'абонент', 'абонента', 'абонентов')}</Column>
            <Column flex="0 0 160px">{plural(countSales, 'объявление', 'объявления', 'объявлений')}</Column>
        </AreaComponent>
    )
}
