import { action, computed, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { PureComponent } from 'react'
import { Component, Current, Bubble, Option } from './select.styled'
import { Scrollbars } from 'react-custom-scrollbars-2'

interface SelectInputProps<T = any> {
    options: [T, string][]
    icon?: string
    value?: T
    onChange?: (v: T) => void
}

@observer
export class SelectInput<T> extends PureComponent<SelectInputProps> {
    @observable
    private value: T

    @observable
    private bubbleVisibility = false

    constructor(props: SelectInputProps) {
        super(props)
        makeObservable(this)
        this.value = 'value' in props ? props.value : props.options[0][0]
    }

    @computed
    get currentOptionIndex() {
        const { options } = this.props

        for (let i = 0; i < options.length; i++) {
            if (options[i][0] === this.value) {
                return i
            }
        }

        throw new Error('Unknown select value')
    }

    @action
    componentDidUpdate(prevProps: SelectInputProps<T>) {
        if (this.props.value !== prevProps.value) {
            this.value = this.props.value
        }
    }

    @action
    toggleBubble(value?: boolean) {
        this.bubbleVisibility = arguments.length ? value! : !this.bubbleVisibility
    }

    @action
    setValue(value: T) {
        this.value = value
        this.toggleBubble(false)

        if (this.props.onChange) {
            this.props.onChange(value)
        }
    }

    render() {
        const { options, icon, ...other } = this.props
        const { currentOptionIndex, bubbleVisibility } = this
        const currentOptionName = options[currentOptionIndex][1]

        return (
            <Component tabIndex={1} icon={icon} onBlur={() => this.toggleBubble(false)} {...other}>
                <Current bubbleVisibility={bubbleVisibility} onClick={() => this.toggleBubble()}>
                    {currentOptionName}
                </Current>
                <Bubble visible={bubbleVisibility}>
                    <Scrollbars style={{ height: Math.min(340, options.length * 34), width: 380 }}>
                        {options.map(([value, name], i) => (
                            <Option key={i} current={value === this.value} onClick={() => this.setValue(value)}>
                                {name}
                            </Option>
                        ))}
                    </Scrollbars>
                </Bubble>
            </Component>
        )
    }
}
