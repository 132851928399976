import { TextInput as UITextInput, Dot as UIDot } from 'components/ui'
import styled from 'styled-components'

export const Component = styled.div``

export const Tools = styled.div`
    display: flex;
    gap: 23px;
`

export const SearchInput = styled(UITextInput)`
    flex: 1;
`

export const Total = styled.div`
    display: flex;
    align-items: center;
    justify-content: flex-end;
    margin: 27px 0 27px;

    & > span:nth-child(1) {
        font-size: 16px;
        line-height: 127.3%;
        color: #707b8e;
    }
    & > span:nth-child(2) {
        font-weight: bold;
        font-size: 24px;
        line-height: 124.7%;
        color: #68c376;
        margin-left: 12px;
    }
`

export const Dot = styled(UIDot)`
    display: inline-flex;
    margin-right: 2px;
    top: -2px;
`

export const TheadComp = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #b7bcd6;
    padding: 0 10px 0 15px;
    display: flex;
    margin-bottom: 14px;

    & > div:nth-child(1) {
        flex: 0 0 210px;
    }
    & > div:nth-child(2) {
        flex: 1 0;
    }
    & > div:nth-child(3) {
        flex: 1 0;
    }
    & > div:nth-child(4) {
        flex: 0 0 184px;
    }
`

export const ItemComp = styled.div`
    font-size: 16px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #111111;
    padding: 0 10px;
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    height: 56px;

    & > div:nth-child(1) {
        flex: 0 0 210px;

        & > span:nth-child(3) {
            color: #b7bcd6;
            margin-left: 8px;
        }
    }
    & > div:nth-child(2) {
        flex: 1 0;

        & > span:nth-child(2) {
            color: #b7bcd6;
            margin-left: 8px;
        }
    }
    & > div:nth-child(3) {
        flex: 1 0;
    }
    & > div:nth-child(4) {
        flex: 0 0 130px;
    }
    & > div:nth-child(5) {
        flex: 0 0 54px;
    }
`

export const Button = styled.button<{ icon: string }>`
    display: block;
    border: 1px solid #b7bcd6;
    box-sizing: border-box;
    border-radius: 8px;
    height: 36px;
    width: 54px;
    background-color: white;
    background-image: url(${(props) => props.icon});
    background-position: center;
    background-repeat: no-repeat;
    cursor: pointer;

    &:nth-child(2) {
        margin-left: 10px;
    }

    :hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    }

    :active {
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
    }

    :disabled {
        opacity: 0.5;
        pointer-events: none;
    }
`
