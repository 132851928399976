import { Component, Circle, Bubble, Colors } from './dot.styled'

interface DotProps {
    color: Colors
    label?: string
}

export const Dot = (props: DotProps) => {
    const { color, label, ...other } = props

    return (
        <Component {...other}>
            <Circle color={color} />
            <Bubble>{label}</Bubble>
        </Component>
    )
}
