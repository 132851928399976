import { api } from 'api'
import { runInAction } from 'mobx'
import { store } from 'store'

export const lockProvider = async (id: number) => {
    await api.provider.block(id)

    const provider = store.providers.find(id)!

    runInAction(() => (provider.isBlocked = true))
}

export const unlockProvider = async (id: number) => {
    await api.provider.unblock(id)

    const provider = store.providers.find(id)!

    runInAction(() => (provider.isBlocked = false))
}

export const removeProvider = async (id: number) => {
    await api.provider.remove(id)

    store.providers.remove(id)
}

export const inviteProvider = async (email: string, inn: string) => {
    await api.provider.invite(email, inn)
}

export const connectProvider = async (id: number) => {
    await api.provider.enter(id)
}

export const confirmConnectProvider = async (id: number, code: string) => {
    const { link } = await api.provider.enterCheck(id, code)
    return link
}
