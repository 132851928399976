import { PureComponent, ChangeEvent, createRef } from 'react'
import { Button, Heading, Popup } from 'components/ui'
import { Desc, Inputs, Input, Help, Buttons } from './invoice_4_confirmation.styled'
import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'

interface Invoice4ConfirmationPopupProps {
    onSend: (code: string) => void
    onCancel: () => void
    error: boolean
}

@observer
export class Invoice4ConfirmationPopup extends PureComponent<Invoice4ConfirmationPopupProps> {
    @observable
    private code = '****'
    private inputs = Array.from({ length: 4 }, () => createRef()) as React.RefObject<HTMLInputElement>[]

    constructor(props: Invoice4ConfirmationPopupProps) {
        super(props)
        makeObservable(this)
    }

    @action
    handleChangeEvent(e: ChangeEvent<HTMLInputElement>, index: number) {
        const value = e.target.value.slice(-1)
        const chars = this.code.split('')

        chars.splice(index, 1, value)

        this.code = chars.join('')

        if (index < 3) {
            this.inputs[index + 1].current!.focus()
        } else {
            this.inputs[index].current!.blur()
        }
    }

    getInputValue(index: number) {
        const value = this.code.slice(index, index + 1)
        return value === '*' ? '' : value
    }

    render() {
        const { onSend, onCancel, error } = this.props

        return (
            <Popup>
                <Heading>Код из платёжки</Heading>
                <Desc>
                    Введите сумму платежа (4 цифры 00,00)
                    <br />
                    Платёж может идти 1–3 дня
                </Desc>
                <Inputs>
                    <Input
                        ref={this.inputs[0]}
                        value={this.getInputValue(0)}
                        onChange={(e) => this.handleChangeEvent(e, 0)}
                        error={error}
                        autoFocus
                    />
                    <Input
                        ref={this.inputs[1]}
                        value={this.getInputValue(1)}
                        onChange={(e) => this.handleChangeEvent(e, 1)}
                        error={error}
                    />
                    <Input
                        ref={this.inputs[2]}
                        value={this.getInputValue(2)}
                        onChange={(e) => this.handleChangeEvent(e, 2)}
                        error={error}
                    />
                    <Input
                        ref={this.inputs[3]}
                        value={this.getInputValue(3)}
                        onChange={(e) => this.handleChangeEvent(e, 3)}
                        error={error}
                    />
                </Inputs>
                <Buttons>
                    <Button secondary onClick={onCancel}>
                        Отмена
                    </Button>
                    <Button onClick={() => onSend(this.code)}>Подтвердить</Button>
                </Buttons>
                <Help>Не получили платёж! Ссылка на статью</Help>
            </Popup>
        )
    }
}
