import { withRouter, match } from 'react-router'
import { Link } from 'react-router-dom'
import { Common } from './common'
import { Stats } from './stats'
import { Transactions } from './transactions'
import { Regions } from './regions'
import { Details } from './details'
import { Component, Breadcrumbs } from './info.styled'
import { PureComponent } from 'react'
import { observer } from 'mobx-react'
import { makeObservable, observable, runInAction } from 'mobx'
import { LockPopup, UnlockPopup, ResetPasswordPopup } from './popups'
import { actions } from 'actions'
import { store } from 'store'
import { Loader } from 'components/ui'
import { Switch, Route } from 'react-router-dom'
import { CabinetContext } from 'components/app/cabinet/context'

interface InfoProps {
    match?: match<{ id: string }>
}

@(withRouter as any)
@observer
export class Info extends PureComponent<InfoProps> {
    static contextType = CabinetContext

    @observable
    private adminLockPopupVisibility = false
    @observable
    private adminUnlockPopupVisibility = false
    @observable
    private resetPasswordPopupVisibility = false

    get adminId() {
        return parseInt(this.props.match!.params.id)
    }

    constructor(props: InfoProps) {
        super(props)
        makeObservable(this)
    }

    componentDidMount() {
        this.context.setTitle('Все администраторы')
        store.admin.resolve(this.adminId)
    }

    toggleAdminLockPopup(visibility?: boolean) {
        this.adminLockPopupVisibility = arguments.length ? visibility! : !this.adminLockPopupVisibility
    }

    toggleAdminUnlockPopup(visibility?: boolean) {
        this.adminUnlockPopupVisibility = arguments.length ? visibility! : !this.adminUnlockPopupVisibility
    }

    toggleResetPasswordPopup(visibility?: boolean) {
        this.resetPasswordPopupVisibility = arguments.length ? visibility! : !this.resetPasswordPopupVisibility
    }

    async lock() {
        const { adminId } = this

        await actions.lockAdmin(adminId)

        const data = store.admin.get(adminId)!

        runInAction(() => (data.isBlocked = true))

        this.toggleAdminLockPopup(false)
    }

    async unlock() {
        const { adminId } = this

        await actions.unlockAdmin(adminId)

        const data = store.admin.get(adminId)!

        runInAction(() => (data.isBlocked = false))

        this.toggleAdminUnlockPopup(false)
    }

    async resetPassword() {
        await actions.resetAdminPassword(this.adminId)

        this.toggleResetPasswordPopup(false)
    }

    render() {
        const id = parseInt(this.props.match!.params.id)

        if (!store.admin.isResolved(id)) {
            return <Loader />
        }

        const { type } = store.admin.get(id)!

        return (
            <Component>
                {this.adminLockPopupVisibility && (
                    <LockPopup onConfirm={() => this.lock()} onClose={() => this.toggleAdminLockPopup(false)} />
                )}
                {this.adminUnlockPopupVisibility && (
                    <UnlockPopup onConfirm={() => this.unlock()} onClose={() => this.toggleAdminUnlockPopup(false)} />
                )}
                {this.resetPasswordPopupVisibility && (
                    <ResetPasswordPopup
                        onConfirm={() => this.resetPassword()}
                        onClose={() => this.toggleResetPasswordPopup(false)}
                    />
                )}

                <Switch>
                    <Route path={`/admins/:id`} exact>
                        <Breadcrumbs>
                            <Link to="/admins">Администраторы</Link>
                            <span>ID{id}</span>
                        </Breadcrumbs>
                    </Route>
                    <Route path={`/admins/:id/transactions`} exact>
                        <Breadcrumbs>
                            <Link to="/admins">Администраторы</Link>
                            <Link to={`/admins/${id}`}>Данные профиля</Link>
                            <span>Транзакции</span>
                        </Breadcrumbs>
                    </Route>
                </Switch>
                <Common
                    id={id}
                    onClickLockButton={() => this.toggleAdminLockPopup()}
                    onClickUnlockButton={() => this.toggleAdminUnlockPopup()}
                    onClickResetPasswordButton={() => this.toggleResetPasswordPopup()}
                />
                <Switch>
                    <Route path={`/admins/:id`} exact>
                        <Stats id={id} />
                    </Route>
                </Switch>
                {type === 'partner' && (
                    <>
                        <Regions id={id} />
                        <Switch>
                            <Route path={`/admins/:id`} exact>
                                <Details id={id} />
                            </Route>
                            <Route path={`/admins/:id/transactions`} exact>
                                <Transactions id={id} />
                            </Route>
                        </Switch>
                    </>
                )}
            </Component>
        )
    }
}
