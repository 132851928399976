import styled from 'styled-components'
import crossIcon from './images/cross.svg'

export const Component = styled.div``

export const NoticeComponent = styled.div``

export const Day = styled.div`
    height: 60px;
    display: flex;
    align-items: center;
    padding-left: 32px;
    font-size: 14px;
    letter-spacing: -0.24px;
    color: #b7bcd6;

    :empty {
        height: 10px;
    }
`

export const Body = styled.div`
    padding: 0 30px;
    display: flex;
    align-items: center;
    padding: 0 30px;
    height: 80px;
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    transition: border-color 0.2s ease;

    :hover {
        border: 1px solid #68c376;
    }
`

export const Time = styled.div`
    font-size: 18px;
    color: #707b8e;
    margin-right: 36px;
`

export const New = styled.div`
    width: 8px;
    height: 8px;
    border-radius: 50%;
    background-color: #68c376;
    margin-right: 10px;
`

export const Content = styled.div`
    font-size: 16px;
    color: #39434b;
    margin-right: 32px;
`

export const Avatar = styled.div<{ url: string }>`
    width: 40px;
    height: 40px;
    background-image: url(${(props) => props.url});
    background-repeat: no-repeat;
    background-size: 40px;
    margin-right: 16px;
`

export const Name = styled.div`
    font-size: 16px;
    color: #68c376;
`

export const Spacer = styled.div`
    flex: 1;
`

export const Remove = styled.div`
    width: 24px;
    height: 24px;
    mask: url(${crossIcon}) no-repeat center;
    background-color: #39434b;
    cursor: pointer;
    transition: background-color 0.3s ease;

    :hover {
        background-color: #eb5757;
    }
`
