import { actions } from 'actions'
import axios from 'axios'
import { API_URL, FILES_STORE } from 'config'
import { getRefreshToken, getToken, hasTokens, updateTokens } from './token'

export const client = axios.create({
    baseURL: API_URL,
    auth: {
        username: 'dom',
        password: 'iXn8nt8Q',
    },
})

interface Response<T> {
    success: boolean
    data: T & { error?: string }
}

interface RefreshResponse {
    refreshToken: string
    token: string
}

const query = async <T>(url: string, params?: object) => {
    const token = getToken()

    try {
        const response = await client.post<Response<T>>(url, params, {
            headers: { 'X-API-Key': token },
        })

        const { success, data } = response.data

        if (success) {
            return data as T
        }

        throw data.error
    } catch (e: any) {
        throw e.response.data?.data?.error || e
    }
}

export const request = async <T>(url: string, params?: object) => {
    while (true) {
        try {
            return await query<T>(url, params)
        } catch (e) {
            if (e === 'noauth' && hasTokens()) {
                try {
                    const refreshToken = getRefreshToken()
                    const response = await request<RefreshResponse>('auth/refresh', { refreshToken })

                    updateTokens(response.token, response.refreshToken)

                    continue
                } catch (e) {
                    if (e === 'noauth') {
                        actions.softLogout()
                        throw new Error('NEED_AUTH')
                    }

                    throw e
                }
            }

            throw e
        }
    }
}

interface Attachment {
    link: string
    name: string
    mimeType: string
    size: string
    createdAt: string
    updatedAt: string
}

export const upload = async (blob: Blob) => {
    const token = getToken()

    try {
        const uploadUrl = `${FILES_STORE}/upload`
        const formData = new FormData()

        formData.append('file', blob)

        const response = await client.post<Response<Attachment>>(uploadUrl, formData, {
            headers: { 'X-API-Key': token, 'X-Auth-Type': 'admin' },
        })

        const { success, data } = response.data

        if (success) {
            return data as Attachment
        }

        throw data.error
    } catch (e: any) {
        throw e.response.data?.data?.error || e
    }
}
