import styled from 'styled-components'
import { TextInput as UITextInput } from 'components/ui'

export const Desc = styled.div`
    font-size: 18px;
    line-height: 150.7%;
    text-align: center;
    color: #39434b;
    margin: 10px 0px 36px;
`

export const Buttons = styled.div`
    display: flex;
    gap: 16px;
`

export const EmailInput = styled(UITextInput)`
    align-self: stretch;
    margin-bottom: 20px;
`

export const Inn = styled.div`
    align-self: stretch;
    position: relative;
    margin-bottom: 36px;
`

export const InnInput = styled(UITextInput)`
    width: 100%;
`

export const Bubble = styled.div`
    position: absolute;
    z-index: 10;
    bottom: calc(100% + 10px);
    left: 0;
    min-width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 14px rgba(133, 140, 166, 0.236724);
    border-radius: 4px;
`

export const BubbleOption = styled.div`
    padding: 8px 17px;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;

    div {
        &:nth-child(1) {
            font-size: 18px;
            color: #39434b;
            margin-top: 2px;
        }
        &:nth-child(2) {
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #707b8e;
        }
    }

    :hover {
        background-color: #f1faf3;
    }

    :last-child {
        border-radius: 0 0 4px 4px;
    }
`
