import { api } from 'api'

export const createAdmin = async (data: NewAdmin) => {
    const admin = await api.user.create(data)

    return admin.user
}

export const saveAdmin = async (data: SavedAdmin) => {
    await api.user.save(data)
}

export const resetAdminPassword = async (id: number) => {
    await api.user.resetPassword(id)
}

export const lockAdmin = async (id: number) => {
    await api.user.block(id)
}

export const unlockAdmin = async (id: number) => {
    await api.user.unblock(id)
}

type UserType = 'admin' | 'support' | 'partner' | 'analyst'
type UserSex = 'male' | 'female'

interface NewAdmin {
    type: UserType
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    birthDate: string
    sex: UserSex
}

interface SavedAdmin {
    id: number
    type: UserType
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    birthDate: string
    sex: UserSex
}
