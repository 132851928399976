import { CustomersItemData } from 'store'
import { avatarUrl, formatPhone, plural } from 'utils'
import { Component, Avatar, Button, NameAndPhone, Name, Phone, Reviews, Stars, ObjectsCount } from './customer.styled'
import defaultAvatar from './images/avatar.svg'

type CustomerProps = {
    data: CustomersItemData
}

export const Customer = (props: CustomerProps) => {
    const { id, first, last, phone, countAddresses, countReviews, rate, avatar } = props.data

    return (
        <Component>
            <Avatar src={avatar ? avatarUrl(avatar) : defaultAvatar} />
            <NameAndPhone>
                <Name>
                    {last} {first}
                </Name>
                <Phone>{formatPhone(phone)}</Phone>
            </NameAndPhone>
            <ObjectsCount>{plural(countAddresses, 'объект', 'объекта', 'объектов')} недвижимости</ObjectsCount>
            <Stars>{rate.toFixed(1)}</Stars>
            <Reviews to={`/customers/reviews/${id}`}>{plural(countReviews, 'отзыв', 'отзыва', 'отзывов')}</Reviews>
            <Button to={`/support/create/user/${id}`}>Создать тикет</Button>
            <Button to={`/customers/${id}`}>Карточка</Button>
        </Component>
    )
}
