import { api } from 'api'
import { observable } from 'mobx'

export const STORE = observable.map<number, MailingData>()

export const resolve = async (id: number) => {
    const { mailing } = await api.mailing.get(id)
    STORE.set(id, mailing)
}

export const isResolved = (id: number) => {
    return STORE.has(id)
}

export const get = (id: number) => {
    return STORE.get(id)
}

type MailingType = 'all' | 'user' | 'client' | 'provider' | 'admin' | 'support' | 'partner' | 'analyst'

export interface MailingData {
    id: number
    type: MailingType
    title: string
    createdAt: string
    countSent: number
    countViewed: number
    description: string
    withButton: boolean
    button: string
    link: string
}
