import {
    Component,
    Avatar,
    Head,
    Label,
    Name,
    NameAndLabel,
    Button,
    Options,
    LockButton,
    UnlockButton,
} from './common.styled'
import { store } from 'store'
import { Link, Route, Switch } from 'react-router-dom'
import { avatarUrl, formatPhone } from 'utils'
import { CommonInfo } from './common_info'
import defaultAvatar from './images/avatar.svg'
import { TransactionsInfo } from './transactions_info'

type CommonProps = {
    id: number
    onClickLockButton: () => void
    onClickUnlockButton: () => void
    onClickResetPasswordButton: () => void
}

export const Common = (props: CommonProps) => {
    const { id, onClickLockButton, onClickUnlockButton, onClickResetPasswordButton } = props
    const admin = store.admin.get(id)!

    return (
        <Component>
            <Head>
                <Avatar src={admin.avatar ? avatarUrl(admin.avatar) : defaultAvatar} locked={admin.isBlocked} />
                <NameAndLabel>
                    <Name>
                        {admin.firstname} {admin.lastname}
                    </Name>
                    <Label type={admin.type} isOwner={admin.isOwner} />
                </NameAndLabel>
                <Options label="Опции">
                    <Link to={`/admins/edit/${id}`}>Редактировать</Link>
                    <button onClick={onClickResetPasswordButton}>Сбросить пароль</button>
                    {admin.isBlocked ? (
                        <UnlockButton onClick={onClickUnlockButton}>Разблокировать</UnlockButton>
                    ) : (
                        <LockButton onClick={onClickLockButton}>Заблокировать</LockButton>
                    )}
                </Options>
                <Button to={`/support/create/admin/${id}`}>+ Создать тикет</Button>
            </Head>
            <Switch>
                <Route path={`/admins/:id`} exact>
                    <CommonInfo id={id} />
                </Route>
                <Route path={`/admins/:id/transactions`} exact>
                    <TransactionsInfo id={id} />
                </Route>
            </Switch>
        </Component>
    )
}
