import { PureComponent } from 'react'
import { observer } from 'mobx-react'
import { AdsItemData } from 'store'
import { avatarUrl, formatMoney } from 'utils'
import {
    Component,
    Dot,
    Face,
    Info,
    Title,
    Flex,
    Datetime,
    Views,
    User,
    Arrow,
    Button,
    Content,
    ContentFlex,
    Details,
} from './ad.styled'
import { action, makeObservable, observable } from 'mobx'
import { Slider } from './slider'
import { Actions } from './actions'
import { api } from 'api'
import { LockPopup } from './popups'

interface AdProps {
    data: AdsItemData
}

@observer
export class Ad extends PureComponent<AdProps> {
    @observable
    private collapsed = true

    @observable
    private lockPopupVisibility = false

    constructor(props: AdProps) {
        super(props)
        makeObservable(this)
    }

    @action
    toggleCollapsing() {
        this.collapsed = !this.collapsed
    }

    @action
    lock(reason: string) {
        const { data } = this.props

        api.ads.lock(data.id, reason)

        this.toggleLockPopup(false)

        data.status = 'blocked'
    }

    @action
    unlock() {
        const { data } = this.props

        api.ads.unlock(data.id)

        data.status = 'archive'
    }

    @action
    toggleLockPopup(visibility: boolean) {
        this.lockPopupVisibility = visibility
    }

    render() {
        const {
            id,
            name,
            status,
            createdAt,
            countView,
            user,
            address,
            description,
            sectionName,
            categoryName,
            price,
            files,
        } = this.props.data
        const datetime = new Date(createdAt)

        return (
            <Component>
                {this.lockPopupVisibility && (
                    <LockPopup onClose={() => this.toggleLockPopup(false)} onConfirm={(reason) => this.lock(reason)} />
                )}
                <Dot color={calcDotColor(status)} label={calcDotDesc(status)} />
                <Face>
                    <Info>
                        <Title>{name}</Title>
                        <Flex>
                            <Datetime>
                                <span>{datetime.toLocaleDateString()}</span>
                                <span>{datetime.toLocaleTimeString()}</span>
                            </Datetime>
                            <Views>
                                <span>Просмотров</span>
                                <b>{countView}</b>
                            </Views>
                            <User avatar={avatarUrl(user.avatar)}>{user.name}</User>
                        </Flex>
                    </Info>
                    {status === 'active' && <Button onClick={() => this.toggleLockPopup(true)}>Заблокировать</Button>}
                    {status === 'blocked' && <Button onClick={() => this.unlock()}>Разблокировать</Button>}
                    <Arrow turned={!this.collapsed} onClick={() => this.toggleCollapsing()} />
                </Face>
                <Content collapsed={this.collapsed}>
                    <ContentFlex>
                        <Details>
                            <div>
                                <div>Текст объявления</div>
                                <div>{description}</div>
                            </div>
                            <div>
                                <div>Раздел</div>
                                <div>{sectionName}</div>
                            </div>
                            <div>
                                <div>Категория</div>
                                <div>{categoryName}</div>
                            </div>
                            <div>
                                <div>Цена</div>
                                <div>{formatMoney(price)}</div>
                            </div>
                            <div>
                                <div>Адрес</div>
                                <div>{address?.address}</div>
                            </div>
                        </Details>
                        <Slider photos={files} />
                    </ContentFlex>
                    {!this.collapsed && <Actions id={id} />}
                </Content>
            </Component>
        )
    }
}

const calcDotColor = (status: 'active' | 'archive' | 'blocked') => {
    if (status === 'active') return 'green'
    if (status === 'archive') return 'gray'
    if (status === 'blocked') return 'red'

    throw new Error('Unknown item state')
}

const calcDotDesc = (status: 'active' | 'archive' | 'blocked') => {
    if (status === 'active') return 'Активное объявление'
    if (status === 'archive') return 'Архивное объявление'
    if (status === 'blocked') return 'Заблокированное объявление'

    throw new Error('Unknown item state')
}
