import { action, reaction } from 'mobx'
import { api } from 'api'
import { createStore } from './abstract'
import { MONTH, YEAR } from 'utils'

interface Params {
    month: number
    year: number
    query: string
}

const store = createStore<Params, ImportsData>({
    month: MONTH,
    year: YEAR,
    query: '',
})

export const get = store.get
export const setParams = store.setParams
export const getParams = store.getParams
export const isResolved = store.isResolved

const BUSY_MAP = new Map<string, boolean>()

export const resolve = async () => {
    const key = store.getKey()
    const { month, year, query } = store.getParams()
    const { imports } = await api.imports.list(month, year, query)

    BUSY_MAP.set(key, false)

    store.set(key, imports)
}

// resolver

let lastKey: string

reaction(
    () => store.getRealKey(),
    (key) => {
        lastKey = key

        const { month, year, query } = store.getRealParams()

        if (store.hasKey(key)) {
            store.setKey(key)

            api.imports.list(month, year, query).then(action(({ imports }) => store.set(key, imports)))
        } else {
            api.imports.list(month, year, query).then(
                action(({ imports }) => {
                    store.set(key, imports)

                    if (key === lastKey) {
                        store.setKey(key)
                    }
                })
            )
        }
    }
)

export interface ImportsData {
    created: ImportsItemData[]
    successed: ImportsItemData[]
    breaked: ImportsItemData[]
    failed: ImportsItemData[]
}

export interface ImportsItemData {
    id: number
    state: string
    provider: Provider
    fileId: string
    errorFileId: string
    type: 'csv' | 'api'
    prepCount: number
    errorCount: number
    finalCount: number
    createdAt: string
    updatedAt: string
}

interface Provider {
    id: number
    name: string
    short: string
    shopCode: string
    createdAt: string
    partnerUserId: number
    partnerUser: PartnerUser
    rate: number
    countReviews: number
    countAccounts: number
    balance: number
    subscriptionFee: number
    shortLegalEntity: ShortLegalEntity
}

interface ShortLegalEntity {
    inn: string
    name: string
}

interface PartnerUser {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}
