import {
    Component,
    Executor,
    Button,
    Hr,
    Manager,
    Avatar,
    Info,
    Name,
    Phone,
    Label,
    CopyButton,
    SpamButton,
    Reassign,
    ReassignFlex,
    ExecutorInput,
    Bubble,
    BubbleOption,
    ExecutorError,
    CurrentExecutor,
    CancelReassign,
    ButtonLink,
} from './aside.styled'
import { store } from 'store'
import { avatarUrl, formatPhone } from 'utils'
import { observer } from 'mobx-react'
import { PureComponent } from 'react'
import { action, makeObservable, observable } from 'mobx'
import { CloseTicketPopup } from './popups'
import { api } from 'api'
import Scrollbars from 'react-custom-scrollbars-2'
import { actions } from 'actions'
import copyToClipboard from 'copy-to-clipboard'

interface AsideProps {
    ticketId: number
}

@observer
export class Aside extends PureComponent<AsideProps> {
    @observable
    private reassign = false

    @observable
    private executorQuery = ''

    @observable
    private executor = null as ExecutorData | null

    @observable
    private executorError = ''

    @observable
    private executorSuggestions = [] as ExecutorData[]

    @observable
    private closeTicketPopupVisibility = false

    private loadExecutorsTimer!: NodeJS.Timeout

    constructor(props: AsideProps) {
        super(props)
        makeObservable(this)
    }

    get ticketId() {
        return this.props.ticketId
    }

    @action
    handleReassignClick() {
        this.reassign = true
        this.handleExecutorQueryChange('')
    }

    @action
    handleExecutorQueryChange(query: string) {
        this.executorQuery = query

        clearTimeout(this.loadExecutorsTimer)
        this.loadExecutorsTimer = setTimeout(() => {
            api.ticket.executors(query).then(action(({ users }) => (this.executorSuggestions = users)))
        }, 500)
    }

    @action
    selectExecutor(e: ExecutorData | null) {
        this.executorError = ''
        this.executor = e
    }

    async changeExecutor() {
        const { id, type } = this.executor!
        const assignUserType = generateAssignUserType(type)
        await actions.assignTicket(this.ticketId, id, assignUserType)
        this.cancelReassignExecutor()
    }

    @action
    cancelReassignExecutor() {
        this.reassign = false
        this.executorQuery = ''
        this.executor = null
        this.executorError = ''
        this.executorSuggestions = []
    }

    @action
    changeTicketStatus(force = false) {
        const { ticketId } = this
        const { status } = store.ticket.get(ticketId)!

        if (force || status === 'close') {
            actions.changeTicketStatus(ticketId)
        } else {
            this.closeTicketPopupVisibility = true
        }
    }

    render() {
        const { ticketId, executor, executorQuery, executorSuggestions, executorError } = this
        const { assignUser, creatorUser, status, details } = store.ticket.get(ticketId)!
        const user = store.user.get()!
        const iIsExecutor = assignUser.id === user.id && assignUser.type === user.type
        const onExecutorQueryChange = (e: React.ChangeEvent<HTMLInputElement>) =>
            this.handleExecutorQueryChange(e.target.value)

        return (
            <Component>
                {this.closeTicketPopupVisibility && (
                    <CloseTicketPopup
                        onConfirm={action(() => {
                            this.closeTicketPopupVisibility = false
                            this.changeTicketStatus(true)
                        })}
                        onClose={action(() => (this.closeTicketPopupVisibility = false))}
                    />
                )}
                <Executor>
                    <span>Ответственный:</span>
                    <span>
                        {assignUser.name}
                        {iIsExecutor && ' (вы)'}
                    </span>
                </Executor>
                {this.reassign ? (
                    <Reassign>
                        {!!executor ? (
                            <ReassignFlex>
                                <CurrentExecutor
                                    avatar={avatarUrl(executor.avatar)}
                                    onClick={() => this.selectExecutor(null)}
                                >
                                    {executor.name}
                                </CurrentExecutor>
                                <Button onClick={() => this.changeExecutor()}>Назначить</Button>
                                <CancelReassign onClick={() => this.cancelReassignExecutor()} />
                            </ReassignFlex>
                        ) : (
                            <>
                                <ReassignFlex>
                                    <ExecutorInput
                                        autoFocus
                                        placeholder="Поиск исполнителя"
                                        value={executorQuery}
                                        onChange={onExecutorQueryChange}
                                    />
                                    <CancelReassign onClick={() => this.cancelReassignExecutor()} />
                                </ReassignFlex>
                                {!!executorSuggestions.length && (
                                    <Bubble>
                                        <Scrollbars
                                            style={{
                                                height: Math.min(240, executorSuggestions.length * 48),
                                                width: '100%',
                                            }}
                                        >
                                            {executorSuggestions.map((i) => (
                                                <BubbleOption
                                                    key={i.id}
                                                    avatar={avatarUrl(i.avatar)}
                                                    onClick={() => this.selectExecutor(i)}
                                                >
                                                    {i.name}
                                                </BubbleOption>
                                            ))}
                                        </Scrollbars>
                                    </Bubble>
                                )}
                            </>
                        )}
                        <ExecutorError>{executorError}</ExecutorError>
                    </Reassign>
                ) : (
                    <div>
                        <Button onClick={() => this.handleReassignClick()}>Переназначить</Button>
                        <Button onClick={() => this.changeTicketStatus()}>{statusTransitions[status]}</Button>
                    </div>
                )}
                <Hr />
                <Manager>
                    <Avatar src={avatarUrl(creatorUser.avatar)} />
                    <Info>
                        <Name>{creatorUser.name}</Name>
                        <div>
                            <Phone>{formatPhone(creatorUser.phone)}</Phone>
                            <Label type={creatorUser.type} />
                        </div>
                        <ButtonLink to={`/support/${generateAssignUserType(creatorUser.type)}/${creatorUser.id}`}>
                            Посмотреть все тикеты
                        </ButtonLink>
                    </Info>
                </Manager>
                <CopyButton onClick={() => copyToClipboard(details)}>Скопировать технические параметры</CopyButton>
                {status !== 'close' && (
                    <SpamButton onClick={() => actions.moveTicketToArchive(ticketId)}>Добавить в спам</SpamButton>
                )}
            </Component>
        )
    }
}

const statusTransitions = {
    active: 'Закрыть',
    wait: 'Закрыть',
    close: 'Восстановить',
}

const generateAssignUserType = (type: 'admin' | 'analyst' | 'partner' | 'support' | 'user' | 'provider' | 'client') => {
    switch (type) {
        case 'admin':
        case 'analyst':
        case 'partner':
        case 'support':
            return 'admin'
        case 'client':
        case 'user':
            return 'user'
        case 'provider':
            return 'provider'
        default:
            throw new Error('Invalid user type')
    }
}

interface ExecutorData {
    id: number
    type: 'admin' | 'analyst' | 'partner' | 'support' | 'user' | 'provider'
    name: string
    avatar: string
}
