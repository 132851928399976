import { api } from 'api'
import { observable, runInAction } from 'mobx'
import type { ReviewsData } from './reviews'

const STORE = observable.map<number, ReviewsData>()

const BUSY_MAP = new Map<number, boolean>()

export const resolve = async (id: number) => {
    const data = await api.review.list(20, 0, id, 0, 0)

    BUSY_MAP.set(id, false)

    runInAction(() => STORE.set(id, data))
}

export const resolveMore = async (id: number) => {
    if (!isResolved(id)) {
        return
    }

    if (!BUSY_MAP.get(id)) {
        BUSY_MAP.set(id, true)

        const current = STORE.get(id)!
        const offset = current.reviews.length
        const { reviews } = await api.review.list(20, offset, id, 0, 0)

        if (reviews.length) {
            BUSY_MAP.set(id, false)

            runInAction(() => current.reviews.push(...reviews))
        }
    }
}

export const get = (id: number) => {
    return STORE.get(id)
}

export const isResolved = (id: number) => {
    return STORE.has(id)
}

export const remove = (reviewId: number) => {
    main: for (const data of STORE.values()) {
        for (let i = 0; i < data.reviews.length; i++) {
            const review = data.reviews[i]

            if (review.id === reviewId) {
                runInAction(() => {
                    data.reviews.splice(i, 1)
                    data.length--
                })
                break main
            }
        }
    }

    api.review.remove(reviewId)
}
