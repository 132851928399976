import { api } from 'api'
import { observable } from 'mobx'

export * as transactions from './admin_transactions'

export const STORE = observable.map<string, AdsDetailsData>()

export const resolve = async (id: string) => {
    const data = await api.ads.details(20, 0, id)
    STORE.set(id, data)
}

const BUSY_MAP = new Map<string, boolean>()

export const resolveMore = async (id: string) => {
    if (!isResolved(id)) {
        return
    }
    if (BUSY_MAP.get(id)) {
        return
    }

    BUSY_MAP.set(id, true)

    const current = get(id)!
    const offset = current.length
    const data = await api.ads.details(20, offset, id)

    current.details.push(...data.details)

    BUSY_MAP.set(id, false)
}

export const isResolved = (id: string) => {
    return STORE.has(id)
}

export const get = (id: string) => {
    return STORE.get(id)
}

export interface AdsDetailsData {
    details: AdsDetailsItemData[]
    length: number
}

type DetailType =
    | 'drafted'
    | 'activated'
    | 'archivated'
    | 'claimed'
    | 'blocked'
    | 'unblocked'
    | 'reactivated'
    | 'removed'
    | 'saved'

export interface AdsDetailsItemData {
    type: DetailType
    detail: string
    createdAt: string
    user: User
}

interface User {
    id: number
    type: string
    name: string
    avatar: string
}
