import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { Default, Region, Address } from './routes'

export const Objects = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/region/:regionId`}>
                <Region />
            </Route>
            <Route path={`${path}/address/:addressId`}>
                <Address />
            </Route>
            <Route path={path}>
                <Default />
            </Route>
        </Switch>
    )
}
