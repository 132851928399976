import { Options } from 'components/ui'
import { PureComponent } from 'react'
import { action, makeObservable, observable } from 'mobx'
import { ProvidersItemData, store } from 'store'
import { avatarUrl, formatMoney, formatPhone, plural } from 'utils'
import {
    Component,
    Avatar,
    Buttons,
    Button,
    Row,
    Name,
    Small,
    Admin,
    ReviewsCount,
    Stars,
    Hr,
    Column,
    Value,
    ColumnName,
    LockButton,
    UnlockButton,
    RedButton,
} from './provider.styled'
import { ConnectPopup, ConnectConfirmationPopup, LockPopup, UnlockPopup, RemovePopup } from './popups'
import { observer } from 'mobx-react'
import { actions } from 'actions'
import { Link } from 'react-router-dom'

interface ProviderProps {
    data: ProvidersItemData
}

@observer
export class Provider extends PureComponent<ProviderProps> {
    @observable
    private lockPopupVisibility = false
    @observable
    private unlockPopupVisibility = false
    @observable
    private removePopupVisibility = false
    @observable
    private connectPopupVisibility = false
    @observable
    private connectConfirmationPopupVisibility = false
    @observable
    private connectConfirmationError = false

    constructor(props: ProviderProps) {
        super(props)
        makeObservable(this)
    }

    toggleConnectPopup(visibility?: boolean) {
        this.connectPopupVisibility = arguments.length ? visibility! : !this.connectPopupVisibility
    }

    toggleConnectConfirmationPopup(visibility?: boolean) {
        this.connectConfirmationPopupVisibility = arguments.length
            ? visibility!
            : !this.connectConfirmationPopupVisibility
    }

    toggleLockPopup(visibility?: boolean) {
        this.lockPopupVisibility = arguments.length ? visibility! : !this.lockPopupVisibility
    }

    toggleUnlockPopup(visibility?: boolean) {
        this.unlockPopupVisibility = arguments.length ? visibility! : !this.unlockPopupVisibility
    }

    toggleRemovePopup(visibility?: boolean) {
        this.removePopupVisibility = arguments.length ? visibility! : !this.removePopupVisibility
    }

    async lock() {
        const { id } = this.props.data

        await actions.lockProvider(id)

        this.toggleLockPopup(false)
    }

    async unlock() {
        const { id } = this.props.data

        await actions.unlockProvider(id)

        this.toggleUnlockPopup(false)
    }

    async remove() {
        const { id } = this.props.data

        await actions.removeProvider(id)

        this.toggleRemovePopup(false)
    }

    @action
    async connect() {
        this.connectConfirmationError = false

        this.toggleConnectPopup(false)

        const { id } = this.props.data

        await actions.connectProvider(id)

        this.toggleConnectConfirmationPopup()
    }

    @action
    async confirmConnect(code: string) {
        const newWindow = window.open()

        try {
            const { id } = this.props.data

            this.connectConfirmationError = false

            const link = await actions.confirmConnectProvider(id, code)

            if (newWindow) {
                newWindow.location = link
                newWindow.focus()
            }

            this.toggleConnectConfirmationPopup(false)
        } catch (e) {
            newWindow?.close()
            this.connectConfirmationError = true
        }
    }

    render() {
        const {
            id,
            short,
            shopCode,
            createdAt,
            partnerUser,
            kind,
            rate,
            logo,
            email,
            countAccounts,
            countActiveAccounts,
            countReviews,
            phone,
            balance,
            subscriptionFee,
            isBlocked,
        } = this.props.data
        const user = store.user.get()!

        return (
            <Component>
                {this.connectPopupVisibility && (
                    <ConnectPopup onConfirm={() => this.connect()} onClose={() => this.toggleConnectPopup(false)} />
                )}
                {this.connectConfirmationPopupVisibility && (
                    <ConnectConfirmationPopup
                        id={id}
                        onConfirm={(code: string) => this.confirmConnect(code)}
                        onClose={() => this.toggleConnectConfirmationPopup(false)}
                        error={this.connectConfirmationError}
                    />
                )}
                {this.lockPopupVisibility && (
                    <LockPopup onConfirm={() => this.lock()} onClose={() => this.toggleLockPopup(false)} />
                )}
                {this.unlockPopupVisibility && (
                    <UnlockPopup onConfirm={() => this.unlock()} onClose={() => this.toggleUnlockPopup(false)} />
                )}
                {this.removePopupVisibility && (
                    <RemovePopup onConfirm={() => this.remove()} onClose={() => this.toggleRemovePopup(false)} />
                )}
                <Avatar src={avatarUrl(logo)} locked={isBlocked} />
                <Row>
                    <Name verified={kind === 'valid'}>{short}</Name>
                </Row>
                <Row marginTop={7}>
                    {isBlocked && <Small red>Заблокирован</Small>}
                    <Small>ID {id.toString().padStart(6, '0')}</Small>
                    <Small>ShopCode {shopCode}</Small>
                    <Small>Зарегистрирован {new Date(createdAt).toLocaleDateString()}</Small>
                </Row>
                {partnerUser && (
                    <Row marginTop={7}>
                        <Small>Партнёр</Small>
                        <Admin>
                            {partnerUser.lastname} {partnerUser.firstname} {partnerUser.patronymic}
                        </Admin>
                    </Row>
                )}
                <Row marginTop={13}>
                    <Stars>{rate.toFixed(1)}</Stars>
                    <ReviewsCount to={`/providers/reviews/${id}`}>
                        {plural(countReviews, 'отзыв', 'отзыва', 'отзывов')}
                    </ReviewsCount>
                </Row>
                <Hr />
                <Buttons>
                    <Options label="Опции">
                        {kind !== 'isSpec' && (
                            <>
                                <Link to={`/support/create/provider/${id}`}>Создать тикет провайдеру</Link>
                                <Link to={`/monitoring/imports/${id}`}>Посмотреть импорты</Link>
                            </>
                        )}
                        {['admin', 'provider'].includes(user.type) &&
                            (isBlocked ? (
                                <UnlockButton onClick={() => this.toggleUnlockPopup()}>Разблокировать</UnlockButton>
                            ) : (
                                <LockButton onClick={() => this.toggleLockPopup()}>Заблокировать</LockButton>
                            ))}
                        {kind !== 'isSpec' && user.isOwner && (
                            <RedButton onClick={() => this.toggleRemovePopup()}>Удалить</RedButton>
                        )}
                    </Options>
                    {['admin', 'provider'].includes(user.type) && (
                        <Button onClick={() => this.toggleConnectPopup()} disabled={isBlocked || kind === 'drafts'}>
                            Подключиться
                        </Button>
                    )}
                </Buttons>
                <Row>
                    <Column flex="0 0 210px">
                        <ColumnName>Количество счетов</ColumnName>
                        <Value>
                            {countAccounts} / {plural(countActiveAccounts, 'активный', 'активных', 'активных')}
                        </Value>
                    </Column>
                    <Column flex="0 0 185px">
                        <ColumnName>Телефон</ColumnName>
                        <Value>{formatPhone(phone)}</Value>
                    </Column>
                    <Column flex="0 1 220px">
                        <ColumnName>Email</ColumnName>
                        <Value>{email}</Value>
                    </Column>
                    <Column flex="1" />
                    <Column flex="0 0 70px" alignRight>
                        <ColumnName>Баланс</ColumnName>
                        <Value red>{formatMoney(balance)}</Value>
                    </Column>
                    <Column flex="0 0 180px" alignRight>
                        <ColumnName>Абонентская плата</ColumnName>
                        <Value>{formatMoney(subscriptionFee)}</Value>
                    </Column>
                </Row>
            </Component>
        )
    }
}
