import styled from 'styled-components'

export const Component = styled.div``

export const Breadcrumbs = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    a,
    span {
        :not(:last-child) {
            font-size: 16px;
            line-height: 127.3%;
            color: #707b8e;
            text-decoration: none;
            transition: color 0.2s ease;

            :after {
                content: '•';
                margin: 0 8px;
                text-decoration: none;
            }
        }
        :last-child {
            font-size: 18px;
            line-height: 21px;
            color: #68c376;
            font-weight: 500;
        }
    }

    a:hover {
        color: #4e5a6f;
    }
`
