import { Loader } from 'components/ui'
import { observer } from 'mobx-react'
import { Fragment, PureComponent } from 'react'
import { store } from 'store'
import { Component, Desc, Name, Monitor, Monitors, Price } from './default.styled'

@observer
export class Default extends PureComponent {
    componentDidMount() {
        store.monitoring.resolve()
    }

    render() {
        if (!store.monitoring.isResolved()) {
            return <Loader />
        }

        const monitors = store.monitoring.get()

        return (
            <Component>
                <Monitors>
                    {monitors.map((m, i) => (
                        <Monitor key={i}>
                            <Name>{m.name}</Name>
                            {m.params.map((p, i) => (
                                <Fragment key={i}>
                                    <Price>{p.value}</Price>
                                    <Desc>{p.name}</Desc>
                                </Fragment>
                            ))}
                        </Monitor>
                    ))}
                </Monitors>
            </Component>
        )
    }
}
