import styled from 'styled-components'
import { TextInput as UITextInput, SelectInput as UISelectInput } from 'components/ui'

export const Component = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 24px 30px 33px;
    margin-top: 20px;
`

export const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #39434b;
`

export const Hr = styled.hr`
    border-width: 1px 0 0 0;
    border-style: solid;
    border-color: #eaecf0;
    margin: 24px 0;
`

export const GridOne = styled.div`
    margin: 30px 0 30px;
    display: grid;
    grid-column-gap: 16px;
    grid-row-gap: 27px;
    grid-template-columns: 145px 269px 269px 269px;
    grid-template-areas:
        'avatar lastname firstname middlename'
        'avatar birthdate gender role';
`

export const LastnameInput = styled(UITextInput)`
    grid-area: lastname;
`

export const FirstnameInput = styled(UITextInput)`
    grid-area: firstname;
`

export const MiddlenameInput = styled(UITextInput)`
    grid-area: middlename;
`

export const BirthdateInput = styled(UITextInput)`
    grid-area: birthdate;
`

export const GenderInput = styled(UISelectInput)`
    grid-area: gender;
`

export const RoleInput = styled(UISelectInput)`
    grid-area: role;
`

export const GridTwo = styled.div`
    margin-top: 22px;
    display: grid;
    grid-column-gap: 16px;
    grid-template-columns: 430px 269px;
    grid-template-areas: 'email phone';
`

export const EmailInput = styled(UITextInput)`
    grid-area: email;
`

export const PhoneInput = styled(UITextInput)`
    grid-area: phone;
`

export const Avatar = styled.label`
    grid-area: avatar;
    display: block;
    text-align: center;
    cursor: pointer;

    img {
        width: 90px;
        height: 90px;
        border-radius: 50%;
        margin-bottom: 13px;
    }

    span:hover {
        text-decoration: underline;
        cursor: pointer;
    }

    input {
        display: none;
    }
`

export const UploadAvatar = styled.span<{ error: boolean }>`
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: ${(props) => (props.error ? '#EB5757' : '#707b8e')};

    span:hover {
        text-decoration: underline;
        cursor: pointer;
    }
`
