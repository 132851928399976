import {
    Component,
    Tools,
    SearchInput,
    Title,
    ItemComp,
    Empty,
    Links,
    Link,
    Button,
    Label,
    Datetime,
    ItemTitle,
    ItemData,
    Avatar,
    ItemDataOther,
    Flex,
    Manager,
    Id,
    Executor,
    Timeleft,
    Messages,
    Spacer,
} from './default.styled'
import React from 'react'
import { store, TicketsItemData } from 'store'
import searchIcon from './images/search.svg'
import { CabinetContext } from 'components/app/cabinet/context'
import { match, withRouter } from 'react-router-dom'
import { avatarUrl, createScrollAutoloader, elapsedTime } from 'utils'
import { NewTicketData, NewTicketPopup } from './popups'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import { plural } from 'utils'
import { actions } from 'actions'
import { Loader } from 'components/ui'

interface DefaultProps {
    history?: {
        push(p: string): void
    }
    match?: match<{
        filter?: string
        userId?: string
        assignType?: 'admin' | 'user' | 'provider'
        assignId: string
    }>
}

type UserType = 'admin' | 'user' | 'provider'

@(withRouter as any)
@observer
export class Default extends React.Component<DefaultProps> {
    static contextType = CabinetContext

    @observable
    private newTicketPopupVisibility = false
    private newTicketPopupAssign: { id: number; type: 'admin' | 'user' | 'provider' } | undefined = undefined

    @observable
    private query: string

    private detachScrollListener!: () => void
    private queryTimer!: NodeJS.Timeout

    constructor(props: DefaultProps) {
        super(props)

        makeObservable(this)

        const { isMine, userId, userType } = this.filter

        if (store.tickets.isResolved(isMine, userId, userType)) {
            const { query } = store.tickets.getParams(isMine, userId, userType)
            this.query = query
        } else {
            this.query = ''
        }

        this.openNewTicketPopupByUrl()
    }

    @action
    openNewTicketPopupByUrl() {
        const { match } = this.props

        if (match?.url.startsWith('/support/create') || match?.url.startsWith('/support/new')) {
            this.newTicketPopupVisibility = true

            if (match?.params.assignType && match?.params.assignId) {
                this.newTicketPopupAssign = {
                    id: parseInt(match!.params.assignId),
                    type: match!.params.assignType,
                }
            }
        }
    }

    get filter() {
        const { filter, userId } = this.props.match!.params

        if (filter) {
            if (filter === 'my') {
                return { isMine: true, userId: 0, userType: 'user' as UserType }
            }
            if (['admin', 'user', 'provider'].includes(filter) && /^[0-9]+$/.test(userId!)) {
                return { isMine: false, userId: parseInt(userId!), userType: filter as UserType }
            }
        }

        return { isMine: false, userId: 0, userType: 'user' as UserType }
    }

    componentDidMount() {
        this.context.setTitle('')

        const { isMine, userId, userType } = this.filter

        store.tickets.resolve(isMine, userId, userType)

        this.detachScrollListener = createScrollAutoloader(() => {
            const { isMine, userId } = this.filter

            store.tickets.resolveMore(isMine, userId, userType)
        })
    }

    componentWillUnmount() {
        this.detachScrollListener()
    }

    componentDidUpdate(prevProps: DefaultProps) {
        if (prevProps.match?.url !== this.props.match?.url) {
            const { isMine, userId, userType } = this.filter

            store.tickets.resolve(isMine, userId, userType)

            if (store.tickets.isResolved(isMine, userId, userType)) {
                const { query } = store.tickets.getParams(isMine, userId, userType)
                this.query = query
            } else {
                this.query = ''
            }

            this.openNewTicketPopupByUrl()
        }
    }

    @action
    toggleNewTicketPopup(visibility: boolean) {
        this.newTicketPopupVisibility = visibility

        if (!visibility) {
            this.newTicketPopupAssign = undefined
        }
    }

    @action
    handleQueryChange(query: string) {
        const { isMine, userId, userType } = this.filter

        this.query = query

        clearTimeout(this.queryTimer)

        this.queryTimer = setTimeout(
            action(() => store.tickets.setParams(isMine, userId, userType, { query })),
            500
        )
    }

    async handleCreateTicket(data: NewTicketData) {
        const id = await actions.createTicket(data)
        this.toggleNewTicketPopup(false)
        this.props.history?.push(`/support/ticket/${id}`)
    }

    render() {
        const { isMine, userId, userType } = this.filter

        if (!store.tickets.isResolved(isMine, userId, userType)) {
            return <Loader />
        }

        const { active, wait, close } = store.tickets.get(isMine, userId, userType)

        const onClickNewTicketButton = () => this.toggleNewTicketPopup(true)
        const closeNewTicketPopup = () => this.toggleNewTicketPopup(false)

        return (
            <Component>
                {this.newTicketPopupVisibility && (
                    <NewTicketPopup
                        onClose={closeNewTicketPopup}
                        onSend={(data) => this.handleCreateTicket(data)}
                        assign={this.newTicketPopupAssign}
                    />
                )}
                <Links>
                    <Link to="/support/my">Мои заявки</Link>
                    <Link to="/support" exact>
                        Все
                    </Link>
                </Links>
                <Tools>
                    <SearchInput
                        icon={searchIcon}
                        placeholder="Поиск по ID обращения, ФИО заявителя или ответственного лица"
                        value={this.query}
                        onChange={(e) => this.handleQueryChange(e.target.value)}
                    />
                    <Button onClick={onClickNewTicketButton}>+ Создать тикет</Button>
                </Tools>
                <Title>
                    <span>Активные</span>
                    <span>{active.length}</span>
                </Title>
                {active.length ? active.map((item) => <Item key={item.id} data={item} />) : <Empty />}
                <Title>
                    <span>В ожидании</span>
                    <span>{wait.length}</span>
                </Title>
                {wait.length ? wait.map((item) => <Item key={item.id} data={item} />) : <Empty />}
                <Title>
                    <span>Архивные</span>
                    <span>{close.count}</span>
                </Title>
                {close.count ? close.items.map((item) => <Item key={item.id} data={item} />) : <Empty />}
            </Component>
        )
    }
}

interface ItemProps {
    data: TicketsItemData
}

const Item = (props: ItemProps) => {
    const { id, title, createdAt, updatedAt, creatorUser, countMessage, assignUser } = props.data
    const datetime = new Date(createdAt)
    const user = store.user.get()!
    const iIsExecutor = user.id === assignUser.id && user.type === assignUser.type
    const elapsed = Date.now() - new Date(updatedAt).getTime()

    return (
        <ItemComp to={`/support/ticket/${id}`}>
            <ItemTitle>{title}</ItemTitle>
            <ItemData>
                <Avatar src={avatarUrl(creatorUser.avatar)} />
                <ItemDataOther>
                    <Flex>
                        <Manager>{creatorUser.name}</Manager>
                        <Label type={creatorUser.type} />
                        <Id>#{id}</Id>
                        <Spacer />
                        <Datetime>
                            <span>{datetime.toLocaleDateString()}</span>
                            <span>{datetime.toLocaleTimeString()}</span>
                        </Datetime>
                    </Flex>
                    <Flex>
                        <Executor>
                            <span>Ответственный:</span>
                            <span>
                                {assignUser.name}
                                {iIsExecutor && ' (вы)'}
                            </span>
                        </Executor>
                        <Timeleft>
                            <span>Время ожидания:</span>
                            <span>{elapsedTime(elapsed)}</span>
                        </Timeleft>
                        <Spacer />
                        <Messages>{plural(countMessage, 'сообщение', 'сообщения', 'сообщений')}</Messages>
                    </Flex>
                </ItemDataOther>
            </ItemData>
        </ItemComp>
    )
}
