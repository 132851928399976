import { PureComponent } from 'react'
import { CabinetContext } from 'components/app/cabinet/context'
import { Component, Title } from './editor.styled'
import { Common } from './common'
import { Regions } from './regions'
import { BankInfo, InnInfo, Partner } from './partner'
import { Button } from 'components/ui'
import { action, computed, makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { dataURItoBlob, formatPhone, testBirthDate, testEmail, testEmailChar, testPhone } from 'utils'
import { api } from 'api'
import { actions } from 'actions'

type UserType = 'admin' | 'support' | 'partner' | 'analyst'
type UserSex = 'male' | 'female'

export interface EditData {
    type: UserType
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    birthDate: string
    sex: UserSex
    regions?: { id: number }[]
    regionIds?: number[]
    legalEntity?: {
        name?: string
        fullName?: string
        id?: number
        inn?: string
        kpp?: string
        ogrn?: string
        bankName?: string
        bankAddress?: string
        bik?: string
        bankAccount?: string // корр счет
        taxType?: 'none' | 'vat0' | 'vat10' | 'vat20'
        account?: string // р/с
        details?: string
        firstName?: string
        lastName?: string
        middleName?: string
        birthDate?: string
        phone?: string
        state?: 'novalid' | 'sended' | 'valid'
    }
}

interface EditorProps {
    mode: 'create' | 'edit'
    data: Partial<EditData>
    onSave: (data: EditData) => Promise<void>
}

@observer
export class Editor extends PureComponent<EditorProps> {
    static contextType = CabinetContext

    @observable.ref private _type!: UserType
    @observable.ref private _email!: string
    @observable.ref private _phone!: string
    @observable.ref private _avatar!: string
    @observable.ref private _lastname!: string
    @observable.ref private _firstname!: string
    @observable.ref private _patronymic!: string
    @observable.ref private _birthDate!: string
    @observable.ref private _sex!: UserSex
    @observable.ref private _regions = [] as number[]
    @observable.ref private _inn!: string
    @observable.ref private _bank!: string
    @observable.ref private _account = ''
    @observable.ref private emailError = ''
    @observable.ref private phoneError = ''
    @observable.ref private avatarError = ''
    @observable.ref private lastnameError = ''
    @observable.ref private firstnameError = ''
    @observable.ref private patronymicError = ''
    @observable.ref private birthDateError = ''
    @observable.ref private regionsError = ''
    @observable.ref private innError = ''
    @observable.ref private bankError = ''
    @observable.ref private accountError = ''
    @observable.ref private innSuggestions = [] as InnInfo[]
    @observable.ref private bankSuggestions = [] as BankInfo[]

    @computed
    get innInfo() {
        if (this.innSuggestions.length && this.innSuggestions[0].data.inn === this.inn) {
            return this.innSuggestions[0]
        }
        return null
    }

    get bankInfo() {
        if (
            this.bankSuggestions.length &&
            (this.bankSuggestions[0].data.bic === this.bank || this.bankSuggestions[0].data.name.payment === this.bank)
        ) {
            return this.bankSuggestions[0]
        }
        return null
    }
    get type() {
        return this._type
    }
    get account() {
        return this._account
    }
    get email() {
        return this._email
    }
    get phone() {
        return this._phone
    }
    get avatar() {
        return this._avatar
    }
    get lastname() {
        return this._lastname
    }
    get firstname() {
        return this._firstname
    }
    get patronymic() {
        return this._patronymic
    }
    get birthDate() {
        return this._birthDate
    }
    get sex() {
        return this._sex
    }
    get regions() {
        return this._regions
    }
    get inn() {
        return this._inn
    }
    get bank() {
        return this._bank
    }
    set email(v: string) {
        this.emailError = ''
        this._email = v
            .split('')
            .filter((l) => testEmailChar(l))
            .join('')
    }
    set type(v: UserType) {
        if (v !== 'partner') {
            this.inn = ''
            this.bank = ''
            this.account = ''
            this.regions = []
            this.innError = ''
            this.bankError = ''
            this.accountError = ''
            this.regionsError = ''
            this.innSuggestions = []
            this.bankSuggestions = []
        }
        this._type = v
    }
    set phone(v: string) {
        this.phoneError = ''
        this._phone = v
    }
    set avatar(v: string) {
        this.avatarError = ''
        this._avatar = v
    }
    set lastname(v: string) {
        this.lastnameError = ''
        this._lastname = v
    }
    set firstname(v: string) {
        this.firstnameError = ''
        this._firstname = v
    }
    set patronymic(v: string) {
        this.patronymicError = ''
        this._patronymic = v
    }
    set birthDate(v: string) {
        this.birthDateError = ''
        this._birthDate = v
    }
    set sex(v: UserSex) {
        this._sex = v
    }
    set regions(v: number[]) {
        this.regionsError = ''
        this._regions = v
    }
    set account(v: string) {
        this.accountError = ''
        this._account = v.replace(/[^0-9]/g, '')
    }
    set inn(v: string) {
        if (v !== '') {
            actions
                .getInnInfo(v)
                .then(
                    action((list) => {
                        this.innSuggestions = list.slice(0, 5)

                        if (!!v && !list.length) {
                            throw new Error('error')
                        }
                    })
                )
                .catch(
                    action(() => {
                        this.innSuggestions = []
                        this.innError = 'Такой ИНН не существует'
                    })
                )
        }
        this.innError = ''
        this._inn = v
    }
    set bank(v: string) {
        if (v !== '') {
            actions
                .getBankInfo(v)
                .then(
                    action((list) => {
                        this.bankSuggestions = list.slice(0, 5)

                        if (!!v && !list.length) {
                            throw new Error('error')
                        }
                    })
                )
                .catch(
                    action(() => {
                        this.bankSuggestions = []
                        this.bankError = 'Банка с таким  БИК не существует'
                    })
                )
        }
        this.bankError = ''
        this._bank = v
    }

    constructor(props: EditorProps) {
        super(props)
        makeObservable(this)

        this.type = props.data.type || 'partner'
        this.email = props.data.email || ''
        this.phone = (props.data.phone && formatPhone(props.data.phone)) || ''
        this.avatar = props.data.avatar || ''
        this.lastname = props.data.lastname || ''
        this.firstname = props.data.firstname || ''
        this.patronymic = props.data.patronymic || ''
        this.birthDate = (props.data.birthDate && new Date(props.data.birthDate).toLocaleDateString()) || ''
        this.sex = props.data.sex || 'male'
        this.inn = props.data.legalEntity?.inn || ''
        this.bank = props.data.legalEntity?.bik || ''
        this.account = props.data.legalEntity?.account || ''
        this.regions = props.data.regions?.map((i) => i.id) || []
    }

    componentDidMount() {
        this.context.setTitle('Новый администратор')
    }

    @action
    handleAvatarChange(file: File) {
        const image = new Image()
        const reader = new FileReader()
        const canvas = document.createElement('canvas')
        const ctx = canvas.getContext('2d')!

        canvas.width = 100
        canvas.height = 100

        reader.readAsDataURL(file)

        reader.onload = (event) => {
            image.src = event.target!.result as string
        }
        image.onload = (e) => {
            const size = Math.min(image.width, image.height)

            const sx = image.width > image.height ? (image.width - size) / 2 : 0
            const sy = image.width < image.height ? (image.height - size) / 2 : 0

            ctx.drawImage(image, sx, sy, size, size, 0, 0, 100, 100)

            this.avatar = canvas.toDataURL()
        }
    }

    @action
    handleRegionsChange(i: number, v: number) {
        const regions = this.regions.slice()

        regions[i] = v

        this.regions = regions
    }

    async handleCreateClick() {
        const {
            type,
            email,
            phone,
            lastname,
            firstname,
            patronymic,
            birthDate,
            sex,
            regions,
            bankInfo,
            innInfo,
            account,
        } = this
        let { avatar } = this

        runInAction(() => {
            if (avatar === '') this.avatarError = 'Выберите аватар'
            if (email === '') this.emailError = 'Введите email'
            if (phone === '') this.phoneError = 'Введите телефон'
            if (lastname === '') this.lastnameError = 'Введите Имя'
            if (firstname === '') this.firstnameError = 'Введите фамилию'
            if (patronymic === '') this.patronymicError = 'Введите отчество'
            if (birthDate === '') this.birthDateError = 'Введите дату рождения'
            if (email && !testEmail(email)) this.emailError = 'Некорректный e-mail'
            if (phone && !testPhone(phone)) this.phoneError = 'Некорректный телефон'
            if (birthDate && !testBirthDate(birthDate)) this.birthDateError = 'Некорректная дата рождения'
            if (type === 'partner' && regions.length === 0) this.regionsError = 'Выберите хотя бы один регион'
            if (type === 'partner' && !bankInfo) this.bankError = 'Укажите информацию о банке'
            if (type === 'partner' && !innInfo) this.innError = 'Введите ИНН'
            if (type === 'partner' && !account) this.accountError = 'Введите расчетный счет'
        })

        if (
            this.avatarError ||
            this.emailError ||
            this.phoneError ||
            this.lastnameError ||
            this.firstnameError ||
            this.patronymicError ||
            this.birthDateError ||
            this.emailError ||
            this.phoneError ||
            this.birthDateError ||
            this.regionsError ||
            this.innError ||
            this.bankError ||
            this.accountError
        ) {
            return
        }

        try {
            if (avatar.startsWith('data:image')) {
                const file = dataURItoBlob(avatar)
                const attach = await api.upload(file)
                avatar = attach.link
            }

            const data = {
                type,
                email,
                phone: phone.replace(/[^0-9+]/g, ''),
                avatar,
                lastname,
                firstname,
                patronymic,
                birthDate: birthDate.replace(/^(\d+)\.(\d+)\.(\d+)$/, '$2-$1-$3'),
                sex,
            } as EditData

            if (type === 'partner') {
                data.regionIds = regions

                const [lastName, firstName, middleName] = innInfo!.data?.management?.name?.split(' ') || ['', '', '']

                data.legalEntity = {
                    name: innInfo!.data?.name?.short_with_opf,
                    fullName: innInfo!.data?.name?.full_with_opf,
                    //id: 0,
                    inn: innInfo!.data?.inn,
                    kpp: innInfo!.data?.kpp,
                    ogrn: innInfo!.data?.ogrn,
                    bankName: bankInfo!.data.name.payment,
                    bankAddress: bankInfo!.data.address.unrestricted_value,
                    bik: bankInfo!.data.bic,
                    bankAccount: bankInfo!.data.correspondent_account,
                    taxType: 'none',
                    account: account,
                    details: '',
                    firstName: firstName,
                    lastName: lastName,
                    middleName: middleName,
                    // birthDate:,
                    // phone:,
                    // state:,
                }
            }

            await this.props.onSave(data)
        } catch (e) {
            if (e === 'EMAIL_ALREADY_USE') {
                this.emailError = 'Этот e-mail уже используется'
            }
            if (e === 'WRONG_ACCOUNT' || e === 'NO_VALID_ACCOUNT') {
                this.accountError = 'Не правильный расчетный счет'
            }
        }
    }

    render() {
        const { mode } = this.props
        const {
            type,
            email,
            phone,
            avatar,
            lastname,
            firstname,
            patronymic,
            birthDate,
            sex,
            inn,
            bank,
            account,
            innError,
            bankError,
            accountError,
            emailError,
            phoneError,
            avatarError,
            lastnameError,
            firstnameError,
            patronymicError,
            birthDateError,
            regionsError,
        } = this

        const onTypeChange = action((v: UserType) => (this.type = v))
        const onEmailChange = action((v: string) => (this.email = v))
        const onPhoneChange = action((v: string) => (this.phone = v))
        const onAvatarChange = (f: File) => this.handleAvatarChange(f)
        const onLastnameChange = action((v: string) => (this.lastname = v))
        const onFirstnameChange = action((v: string) => (this.firstname = v))
        const onPatronymicChange = action((v: string) => (this.patronymic = v))
        const onBirthDateChange = action((v: string) => (this.birthDate = v))
        const onSexChange = action((v: UserSex) => (this.sex = v))
        const onInnChange = action((v: string) => (this.inn = v))
        const onBankChange = action((v: string) => (this.bank = v))
        const onAccountChange = action((v: string) => (this.account = v))

        const commonProps = {
            type,
            email,
            phone,
            avatar,
            lastname,
            firstname,
            patronymic,
            birthDate,
            sex,
            emailError,
            phoneError,
            avatarError,
            lastnameError,
            firstnameError,
            patronymicError,
            birthDateError,
            onTypeChange,
            onEmailChange,
            onPhoneChange,
            onAvatarChange,
            onLastnameChange,
            onFirstnameChange,
            onPatronymicChange,
            onBirthDateChange,
            onSexChange,
        }

        return (
            <Component>
                {mode === 'create' && <Title>Создание профиля</Title>}
                <Common mode={mode} {...commonProps} />
                {type === 'partner' && (
                    <>
                        <Regions
                            regions={this.regions}
                            onRegionsChange={(i, v) => this.handleRegionsChange(i, v)}
                            error={regionsError}
                        />
                        <Partner
                            inn={inn}
                            innInfo={this.innInfo}
                            innSuggestions={this.innSuggestions}
                            innError={innError}
                            onInnChange={onInnChange}
                            bank={bank}
                            bankInfo={this.bankInfo}
                            bankSuggestions={this.bankSuggestions}
                            bankError={bankError}
                            onBankChange={onBankChange}
                            account={account}
                            accountError={accountError}
                            onAccountChange={onAccountChange}
                        />
                    </>
                )}
                <Button onClick={() => this.handleCreateClick()}>{mode === 'create' ? 'Создать' : 'Сохранить'}</Button>
            </Component>
        )
    }
}

// inn 2323003147
