export const MONTH = new Date().getMonth() + 1
export const YEAR = new Date().getFullYear()

export function elapsedTime(time: number) {
    const second = 1000
    const minute = second * 60
    const hour = minute * 60
    const day = hour * 24

    const days = Math.floor(time / day)
    const hours = Math.floor((time - days * day) / hour)
    const minutes = Math.floor((time - days * day - hours * hour) / minute)

    const result = [] as string[]

    if (days) result.push(`${days} дн.`)
    if (hours) result.push(`${hours} ч.`)
    if (minutes) result.push(`${minutes} мин.`)
    if (!result.length) result.push('меньше минуты')

    return result.join(' ')
}
