import { action, makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { PureComponent, ChangeEvent, ReactNode } from 'react'
import { Component, Label, Substrate, Dot } from './toggle.styled'

interface ToggleInputProps {
    labelOnLeft?: boolean
    checked?: boolean
    disabled?: boolean
    onChange?: (value: boolean) => void
    children?: ReactNode
}

@observer
export class ToggleInput extends PureComponent<ToggleInputProps> {
    @observable
    private checked: boolean

    constructor(props: ToggleInputProps) {
        super(props)

        this.checked = !!props.checked

        makeObservable(this)
    }

    @action
    handleChange(e: ChangeEvent<HTMLInputElement>) {
        this.checked = !this.checked

        if (this.props.onChange) {
            this.props.onChange(this.checked)
        }
    }

    @action
    componentDidUpdate(prevProps: ToggleInputProps) {
        if (this.props.checked !== prevProps.checked) {
            this.checked = !!this.props.checked
        }
    }

    render() {
        const { disabled, children, labelOnLeft } = this.props
        const { checked } = this

        return (
            <Component checked={checked} disabled={!!disabled}>
                <Substrate>
                    <Dot />
                </Substrate>
                <Label left={!!labelOnLeft}>{children}</Label>
                <input type="checkbox" checked={checked} onChange={(e) => this.handleChange(e)} />
            </Component>
        )
    }
}
