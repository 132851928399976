import { TextInput } from 'components/ui'
import styled from 'styled-components'
import attachmentIcon from './images/attachment.svg'
import crossIcon from './images/cross.svg'
import linkIcon from './images/link.svg'
import spinnerIcon from './images/spinner.svg'
import removeIcon from './images/remove.svg'

export const Form = styled.form`
    align-self: stretch;
    display: flex;
    flex-direction: column;
    align-items: center;
`

export const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
    margin-top: 20px;
    margin-bottom: 32px;
    align-self: stretch;
    gap: 30px;

    input[type='file'] {
        display: none;
    }
`

export const Buttons = styled.div`
    display: flex;
    gap: 16px;
    margin-bottom: 4px;
`

export const Executor = styled.div`
    position: relative;
`

export const ExecutorError = styled.div`
    color: #eb5757;
    font-family: Roboto;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    position: absolute;
    left: 0;
    top: calc(100% + 4px);
`

export const CurrentExecutor = styled.div<{ avatar: string }>`
    display: flex;
    align-items: center;
    width: 100%;
    box-sizing: border-box;
    padding: 8px 17px;
    height: 44px;
    border-width: 1px;
    border-style: solid;
    border-color: #eaecf0;
    border-radius: 5px;
    color: #111111;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    cursor: pointer;

    &::before {
        content: '';
        display: block;
        background-image: url(${(props) => props.avatar});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        margin-right: 16px;
    }
`

export const CurrentExecutorLabel = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #39434b;
    margin-bottom: 2px;
    height: 17px;
`

export const ExecutorInput = styled(TextInput)``

export const Bubble = styled.div`
    position: absolute;
    z-index: 10;
    top: calc(100% + 5px);
    left: 0;
    min-width: 100%;
    background: #ffffff;
    box-shadow: 0px 2px 14px rgba(133, 140, 166, 0.236724);
    border-radius: 4px;
`

export const BubbleOption = styled.div<{ avatar: string }>`
    padding: 8px 17px;
    white-space: nowrap;
    cursor: pointer;
    transition: background-color 0.2s ease, color 0.2s ease;
    display: flex;
    align-items: center;

    &::before {
        content: '';
        display: block;
        background-image: url(${(props) => props.avatar});
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;
        border-radius: 50%;
        width: 32px;
        height: 32px;
        margin-right: 16px;
    }

    :hover {
        background-color: #f1faf3;
    }

    :last-child {
        border-radius: 0 0 4px 4px;
    }
`

export const TextareaWrapper = styled.div`
    position: relative;
`

export const AttachmentsBubble = styled.div`
    width: 250px;
    padding: 10px 0;
    position: absolute;
    left: 0;
    top: calc(100% + 10px);
    background: #f7f8fc;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    visibility: hidden;
    margin-top: 5px;
    opacity: 0;
    z-index: 3;
    transition: margin-top 0.3s 0.1s ease, opacity 0.3s 0.1s ease, visibility 0s 0.4s ease;
`

export const AttachmentsLinksBubble = styled.div`
    position: absolute;
    width: 452px;
    height: 240px;
    background: #ffffff;
    padding: 20px 0;
    box-shadow: 0px 4px 66px rgba(0, 0, 0, 0.04);
    border-radius: 25px;
    visibility: hidden;
    margin-left: 5px;
    bottom: 5px;
    left: 65%;
    opacity: 0;
    z-index: 10;
`

export const AttachmentsLinksBubbleItem = styled.div`
    height: 44px;
    display: flex;
    align-items: center;
    padding: 0 12px 0 54px;
    background-image: url(${linkIcon});
    background-position: 12px 14px;
    background-repeat: no-repeat;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    cursor: pointer;
    transition: color 0.2s ease, background-color 0.2s ease;

    :hover {
        color: #68c376;
        background-color: #f1faf3;
    }
`

export const FastAnswersBubble = styled.div`
    position: absolute;
    width: 452px;
    height: 240px;
    background: #ffffff;
    padding: 20px 0;
    box-shadow: 0px 4px 66px rgba(0, 0, 0, 0.04);
    border-radius: 25px;
    visibility: hidden;
    margin-left: 5px;
    bottom: 5px;
    left: 65%;
    opacity: 0;
    z-index: 10;
`

export const FastAnswersBubbleItem = styled.div`
    padding: 12px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    cursor: pointer;
    color: #111111;
    white-space: pre-wrap;
    transition: color 0.2s ease, background-color 0.2s ease;

    :hover {
        color: #68c376;
        background-color: #f1faf3;
    }
`

export const AttachmentsBubbleItem = styled.div`
    height: 40px;
    position: relative;
    padding: 0 14px;
    font-size: 16px;
    line-height: 127.3%;
    color: #707b8e;
    display: flex;
    align-items: center;
    white-space: nowrap;

    :hover {
        background-color: #eaecf0;

        ${AttachmentsLinksBubble}, ${FastAnswersBubble} {
            visibility: visible;
            margin-left: 0px;
            opacity: 1;
            transition: margin-top 0.3s ease, opacity 0.3s ease, visibility 0s ease;
        }
    }
`

export const Attachments = styled.div`
    position: absolute;
    z-index: 1;
    bottom: 13px;
    left: 16px;
    width: 24px;
    height: 24px;
    cursor: pointer;

    :hover {
        ${AttachmentsBubble} {
            visibility: visible;
            margin-top: 0px;
            opacity: 1;
            transition: margin-top 0.3s ease, opacity 0.3s ease, visibility 0s ease;
        }
    }
`

export const AttachmentIcon = styled.div`
    width: 24px;
    height: 24px;
    background-image: url(${attachmentIcon});
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
`

export const AttachmentsList = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 14px;
`

export const AttachmentListItem = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #707b8e;
    height: 30px;
    display: flex;
    align-items: center;

    span:nth-child(1) {
        flex: 1;
    }

    span:nth-child(2) {
        width: 12px;
        height: 12px;
        background-image: url(${crossIcon});
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-left: 10px;
    }
`

export const AttachmentPreview = styled.div<{ src: string }>`
    background-image: url(${(props) => props.src});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 100px;
    height: 100px;
    border-radius: 8px;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;
`

export const AttachmentLoader = styled.div`
    width: 100px;
    height: 100px;
    background-image: url(${spinnerIcon});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
`

export const AttachmentRemove = styled.div`
    position: absolute;
    background-image: url(${removeIcon});
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
    width: 24px;
    height: 24px;
    right: 7px;
    top: 7px;
    background-color: #eaecf0;
    opacity: 0.73;
    border-radius: 4px;
    transition: opacity 0.2s ease;
    cursor: pointer;

    :hover {
        opacity: 1;
    }
`

export const LinksList = styled.div`
    align-self: stretch;
`

export const LinksListItem = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    color: #707b8e;
    height: 30px;
    display: flex;
    align-items: center;
    padding-left: 40px;
    background-image: url(${linkIcon});
    background-position: 0 7px;
    background-repeat: no-repeat;

    span:nth-child(1) {
        flex: 1;
    }

    span:nth-child(2) {
        width: 12px;
        height: 12px;
        background-image: url(${crossIcon});
        background-position: center;
        background-repeat: no-repeat;
        cursor: pointer;
        margin-left: 10px;
    }
`
