import { ReactNode } from 'react'
import { Component, Overlay } from './popup.styled'

interface PopupProps {
    children: ReactNode
    width?: number
}

export const Popup = ({ children, width = 486 }: PopupProps) => {
    return (
        <Overlay>
            <Component width={width}>{children}</Component>
        </Overlay>
    )
}
