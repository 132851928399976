import { request } from './request'

export const qrList = (limit: number, offset: number, month: number, year: number, query: string) => {
    return request<QrListResponse>('payment/qr/list', { limit, offset, month, year, query })
}

export const paymentList = (limit: number, offset: number, month: number, year: number) => {
    return request<PaymentListResponse>('payment/payment/list', { limit, offset, month, year })
}

export const paymentClientList = (limit: number, offset: number, id: number) => {
    return request<PaymentClientListResponse>('payment/payment/client-list', { id, limit, offset })
}

export const paymentAccountList = (limit: number, offset: number, id: number) => {
    return request<PaymentAccountListResponse>('payment/payment/account-list', { id, limit, offset })
}

interface QrListResponse {
    qrpayments: QrPayment[]
    total: number
}

interface PaymentListResponse {
    payments: Payment[]
    total: number
    chart: Chart
}

interface PaymentClientListResponse {
    payments: Payment3[]
    length: number
}

interface PaymentAccountListResponse {
    transactions: Transaction[]
}

interface Transaction {
    id: number
    createdAt: string
    amount: number
    balance: number
    type: 'qr' | 'marketplace' | 'provider'
}

interface Payment3 {
    id: number
    createdAt: string
    clientId: number
    client: Client
    type: 'apple' | 'google' | 'card'
    amount: number
    serviceFee: number
    link: string
    items: Item[]
}

interface Item {
    id: number
    accountId: number
    account: ItemAccount
    providerId: number
    provider: Provider
    amountWithFee: number
    serviceFee: number
    percent: number
    amount: number
    type: 'qr' | 'marketplace'
    state: 'error' | 'success' | 'wait'
}

interface ItemAccount {
    id: number
    providerId: number
    lastImportAt?: any
    addressId: number
    legalEntityId: number
    number: string
    address?: any
    fiasId?: any
    flat?: any
    dadata?: any
    fio: string
    isClosed: boolean
    isSpec: boolean
    createdAt: string
    updatedAt: string
    deletedAt?: any
}

interface Chart {
    payment: Payment2
}

interface Payment2 {
    current: Current[]
}

interface Current {
    date: string
    amount: number
}

interface Payment {
    id: number
    createdAt: string
    clientId: number
    client: Client
    type: 'apple' | 'google' | 'card'
    amount: number
    serviceFee: number
    bankFee: number
}

interface Client {
    id: number
    phone: string
    first: string
    last: string
    patronymic: string
    createdAt: string
    updatedAt: string
    address: string
    countAddresses: number
    account: string
    countAccounts: number
    rate: number
    countReviews: number
    sex: string
    birth: string
    email: string
    lastSignInAt: string
    countSales: number
    countPayments: number
    totalPayments: number
    addresses: Address[]
}

interface Address {
    id: number
    full: string
    short: string
    fiasId: string
    postalCode: string
    region: string
    city: string
    street: string
    house: string
    flat: string
    countClients: number
    countAccounts: number
    countSales: number
}

interface QrPayment {
    id: number
    provider: Provider
    note: string
    amount: number
    fileId: string
    createdAt: string
    state: 'wait' | 'done' | 'cancel'
}

interface Provider {
    id: number
    name: string
    short: string
    shopCode: string
    createdAt: string
    partnerUserId: number
    partnerUser: PartnerUser
    rate: number
    countReviews: number
    countAccounts: number
    countActiveAccounts: number
    balance: number
    subscriptionFee: number
    shortLegalEntity: ShortLegalEntity
    email: string
}

interface ShortLegalEntity {
    inn: string
    name: string
}

interface PartnerUser {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}
