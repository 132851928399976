import { api } from 'api'
import { store } from 'store'

type MailingType = 'all' | 'user' | 'client' | 'provider' | 'admin' | 'support' | 'partner' | 'analyst'

interface NewMailingData {
    type: MailingType
    title: string
    description: string
    withButton: boolean
    button: string
    link: string
}

export const createMailing = async (data: NewMailingData) => {
    const { mailing } = await api.mailing.create(data)

    store.mailings.insert(mailing)
}
