import styled from 'styled-components'

const colors = {
    purple: '#b780de',
    yellow: '#FFBC3A',
    green: '#66D576',
    red: '#EB5757',
    gray: '#707B8E',
}

export type Colors = keyof typeof colors

export const Bubble = styled.div`
    position: absolute;
    visibility: hidden;
    margin-bottom: 5px;
    bottom: 100%;
    left: 1px;
    opacity: 0;
    z-index: 10;
    background: #ffffff;
    box-shadow: 0px 2px 14px rgba(133, 140, 166, 0.236724);
    border-radius: 4px;
    padding: 7px 13px;
    border: 1px solid #eaecf0;
    font-size: 11px;
    color: #111111;
    letter-spacing: -0.24px;
    white-space: nowrap;

    :empty {
        display: none;
    }

    :before {
        content: '';
        display: block;
        width: 6px;
        height: 6px;
        border-left: 1px solid #eaecf0;
        border-bottom: 1px solid #eaecf0;
        position: absolute;
        z-index: 1;
        left: 5px;
        bottom: -4px;
        background-color: white;
        transform: rotate(-45deg);
    }
`

export const Component = styled.div`
    width: 20px;
    height: 20px;
    display: flex;
    align-items: center;
    position: relative;
    justify-content: center;

    :hover {
        ${Bubble} {
            visibility: visible;
            margin-bottom: 0px;
            opacity: 1;
            transition: margin-bottom 0.3s ease, opacity 0.3s ease, visibility 0s ease;
        }
    }
`

export const Circle = styled.div<{ color: Colors }>`
    width: 7px;
    height: 7px;
    border-radius: 50%;
    background-color: ${(props) => colors[props.color]};
`
