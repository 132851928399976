import { api } from 'api'
import { runInAction } from 'mobx'
import { store } from 'store'

export const createTicket = async (data: NewTicketData) => {
    const { ticket } = await api.ticket.create(data)
    return ticket.id
}

export const assignTicket = async (id: number, userId: number, type: 'admin' | 'user' | 'provider') => {
    try {
        await api.ticket.assign(id, userId, type)
        store.ticket.resolve(id)
    } catch (e) {
        console.log(e)
    }
}

export const sendTicketMessage = async (ticketId: number, message: NewTicketMessageData) => {
    try {
        const data = await api.ticket.answer(ticketId, message)
        const ticket = store.ticket.get(ticketId)!
        runInAction(() => ticket.messages.push(data.message))
    } catch (e) {
        console.log(e)
    }
}

export const moveTicketToArchive = async (ticketId: number) => {
    try {
        await api.ticket.spam(ticketId)
        const ticket = store.ticket.get(ticketId)!
        runInAction(() => (ticket.status = 'close'))
    } catch (e) {
        console.log(e)
    }
}

export const changeTicketStatus = async (ticketId: number) => {
    const transitions = {
        active: 'close',
        wait: 'close',
        close: 'active',
    }

    try {
        await api.ticket.action(ticketId)
        const ticket = store.ticket.get(ticketId)!
        runInAction(() => (ticket.status = transitions[ticket.status] as 'active' | 'wait' | 'close'))
    } catch (e) {
        console.log(e)
    }
}

export interface NewTicketData {
    assignUserId: number
    assignUserType: 'admin' | 'user' | 'provider'
    title: string
    message: NewTicketMessageData
}

interface NewTicketMessageData {
    message: string
    attachments: Attachment[]
    links: Link[]
}

interface Attachment {
    link: string
    name: string
    mimeType: string
    size: string
    createdAt: string
    updatedAt: string
}

interface Link {
    id: string
    link: string
    name: string
}
