import { withRouter, match } from 'react-router-dom'
import { PureComponent } from 'react'
import { CabinetContext } from 'components/app/cabinet/context'
import {
    Component,
    Bar,
    SearchInput,
    Addresses,
    AddressComponent,
    Title,
    Query,
    Bubble,
    BubbleOption,
} from './region.styled'
import { plural } from 'utils'
import { observer } from 'mobx-react'
import { store, AddressesItemData } from 'store'
import { Loader } from 'components/ui'
import { createScrollAutoloader } from 'utils'
import { action, makeObservable, observable } from 'mobx'
import { api } from 'api'

interface RegionProps {
    match?: match<{ regionId: string }>
}

const FIAS_SUGGEST_MAP = new Map<string, string>()

@(withRouter as any)
@observer
export class Region extends PureComponent<RegionProps> {
    static contextType = CabinetContext

    private detachScrollListener!: () => void
    private phoneSearchTimer!: NodeJS.Timeout

    @observable
    private method = 'dadata' as 'dadata' | 'phone'

    @observable
    private query: string

    @observable.ref
    private suggestions = [] as Suggestion[]

    @observable
    private suggestionsVisibility = false

    constructor(props: RegionProps) {
        super(props)
        makeObservable(this)

        if (store.addresses.isResolved(this.regionId)) {
            const { fiasId } = store.addresses.getParams(this.regionId)
            this.query = FIAS_SUGGEST_MAP.get(fiasId) || ''
        } else {
            this.query = ''
        }
    }

    get regionId() {
        return parseInt(this.props.match!.params.regionId)
    }

    componentDidMount() {
        this.context.setTitle('Объекты недвижимости')

        const { regionId } = this

        store.addresses.resolve(regionId)

        this.detachScrollListener = createScrollAutoloader(() => store.addresses.resolveMore(regionId))
    }

    componentWillUnmount() {
        this.detachScrollListener()
    }

    @action
    toggleSuggestions(visibility: boolean) {
        this.suggestionsVisibility = visibility
    }

    @action
    handleQueryChange(query: string) {
        this.query = query

        if (this.method === 'dadata') {
            if (query === '') {
                this.suggestions = []
            } else {
                api.dadata.suggest(query).then(action((suggestions) => (this.suggestions = suggestions.slice(0, 10))))
            }

            store.addresses.setParams(this.regionId, { fiasId: '' })
        } else {
            clearTimeout(this.phoneSearchTimer)
            this.phoneSearchTimer = setTimeout(() => store.addresses.setParams(this.regionId, { query }), 500)
        }
    }

    @action
    handleSuggestionClick(suggestion: Suggestion) {
        if (suggestion.data.fias_level === '8') {
            const fiasId = suggestion.data.fias_id
            store.addresses.setParams(this.regionId, { fiasId })
            FIAS_SUGGEST_MAP.set(fiasId, suggestion.value)
            this.query = suggestion.value
            this.suggestionsVisibility = false
        } else {
            this.handleQueryChange(suggestion.value)
        }
    }

    @action
    handleKeyDown(key: string) {
        if (key === 'Enter' && this.suggestions.length) {
            this.handleSuggestionClick(this.suggestions[0])
        }
    }

    @action
    handleMethodChange(method: 'dadata' | 'phone') {
        this.method = method
        this.query = ''
        this.suggestions = []
        store.addresses.setParams(this.regionId, { query: '', fiasId: '' })
    }

    render() {
        const { regionId } = this

        if (!store.addresses.isResolved(regionId)) {
            return <Loader />
        }

        const { addresses, length } = store.addresses.get(regionId)
        const { fiasId } = store.addresses.getParams(regionId)

        return (
            <Component>
                <Bar>
                    {/* <SearchTypeInput
                        options={searchOptions}
                        value={this.method}
                        onChange={(v) => this.handleMethodChange(v)}
                        icon={selectIcon}
                    /> */}
                    <Query
                        tabIndex={1}
                        onFocus={() => this.toggleSuggestions(true)}
                        onBlur={(e) => {
                            const { currentTarget } = e

                            requestAnimationFrame(() => {
                                if (!currentTarget.contains(document.activeElement)) {
                                    this.toggleSuggestions(false)
                                }
                            })
                        }}
                    >
                        <SearchInput
                            placeholder="Поиск"
                            value={this.query}
                            onChange={(e) => this.handleQueryChange(e.target.value)}
                            onKeyDown={(e) => this.handleKeyDown(e.key)}
                        />
                        {this.suggestionsVisibility && !fiasId && !!this.suggestions.length && (
                            <Bubble>
                                {this.suggestions.map((s, i) => (
                                    <BubbleOption key={i} onClick={(e) => this.handleSuggestionClick(s)}>
                                        {s.value}
                                    </BubbleOption>
                                ))}
                            </Bubble>
                        )}
                    </Query>
                </Bar>
                <Title>
                    <b>Адресов</b>
                    <span>{length}</span>
                </Title>
                <Addresses>
                    {addresses.map((data) => (
                        <Address key={data.id} data={data} />
                    ))}
                </Addresses>
            </Component>
        )
    }
}

interface AddressProps {
    data: AddressesItemData
}

const Address = (props: AddressProps) => {
    const { data } = props
    const { id, countClients, short, countSales } = data

    return (
        <AddressComponent to={`/objects/address/${id}`}>
            <div>{short}</div>
            <div>{plural(countClients, 'абонент', 'абонента', 'абонентов')}</div>
            <div>{plural(countSales, 'объявление', 'объявления', 'объявлений')}</div>
        </AddressComponent>
    )
}

interface Suggestion {
    value: string
    unrestrictedValue: string
    data: Data
}

interface Data {
    postal_code?: any
    country: string
    country_iso_code: string
    federal_district?: any
    region_fias_id: string
    region_kladr_id: string
    region_iso_code: string
    region_with_type: string
    region_type: string
    region_type_full: string
    region: string
    area_fias_id?: any
    area_kladr_id?: any
    area_with_type?: any
    area_type?: any
    area_type_full?: any
    area?: any
    city_fias_id: string
    city_kladr_id: string
    city_with_type: string
    city_type: string
    city_type_full: string
    city: string
    city_area?: any
    city_district_fias_id?: any
    city_district_kladr_id?: any
    city_district_with_type?: any
    city_district_type?: any
    city_district_type_full?: any
    city_district?: any
    settlement_fias_id?: any
    settlement_kladr_id?: any
    settlement_with_type?: any
    settlement_type?: any
    settlement_type_full?: any
    settlement?: any
    street_fias_id: string
    street_kladr_id: string
    street_with_type: string
    street_type: string
    street_type_full: string
    street: string
    house_fias_id?: any
    house_kladr_id?: any
    house_cadnum?: any
    house_type?: any
    house_type_full?: any
    house?: any
    block_type?: any
    block_type_full?: any
    block?: any
    entrance?: any
    floor?: any
    flat_fias_id?: any
    flat_cadnum?: any
    flat_type?: any
    flat_type_full?: any
    flat?: any
    flat_area?: any
    square_meter_price?: any
    flat_price?: any
    postal_box?: any
    fias_id: string
    fias_code: string
    fias_level: string
    fias_actuality_state: string
    kladr_id: string
    geoname_id: string
    capital_marker: string
    okato: string
    oktmo: string
    tax_office: string
    tax_office_legal: string
    timezone?: any
    geo_lat: string
    geo_lon: string
    beltway_hit?: any
    beltway_distance?: any
    metro?: any
    qc_geo: string
    qc_complete?: any
    qc_house?: any
    history_values: string[]
    unparsed_parts?: any
    source?: any
    qc?: any
}
