import styled, { css } from 'styled-components'

export const Component = styled.label<{ disabled: boolean; checked: boolean }>`
    display: flex;
    align-items: center;
    cursor: pointer;

    ${(props) =>
        props.disabled &&
        css`
            pointer-events: none;
        `}

    ${(props) =>
        props.checked &&
        css`
            ${Substrate} {
                background-color: #68c376;
            }
            ${Dot} {
                margin-left: 20px;
            }
        `}

    input {
        display: none;
    }
`

export const Label = styled.div<{ left: boolean }>`
    flex: 1;
    font-size: 16px;
    line-height: 127.3%;
    color: #707b8e;

    ${(props) =>
        props.left
            ? css`
                  margin-right: 16px;
                  order: -1;
              `
            : css`
                  margin-left: 16px;
              `}

    :empty {
        display: none;
    }
`

export const Substrate = styled.div`
    width: 48px;
    height: 28px;
    padding: 2px;
    border-radius: 16px;
    background-color: #eaecf0;
    transition: background-color 0.2s ease;
`

export const Dot = styled.div`
    background-color: white;
    width: 24px;
    height: 24px;
    border-radius: 50%;
    transition: margin-left 0.2s ease;
`
