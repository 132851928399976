import { PureComponent } from 'react'
import { Component, Links, Link } from './monitoring.styled'
import { CabinetContext } from 'components/app/cabinet/context'
import { Switch, Route } from 'react-router-dom'
import { Imports, QRPayments, Default } from './routes'

export class Monitoring extends PureComponent {
    static contextType = CabinetContext

    componentDidMount() {
        this.context.setTitle('')
    }

    render() {
        return (
            <Component>
                <Links>
                    <Link to="/monitoring" exact>
                        Монитор
                    </Link>
                    <Link to="/monitoring/imports">Импорты</Link>
                    <Link to="/monitoring/payments">QR платежи</Link>
                </Links>
                <Switch>
                    <Route path="/monitoring/imports/:query?">
                        <Imports />
                    </Route>
                    <Route path="/monitoring/payments">
                        <QRPayments />
                    </Route>
                    <Route path="/monitoring">
                        <Default />
                    </Route>
                </Switch>
            </Component>
        )
    }
}
