import { Loader } from 'components/ui'
import { makeObservable, observable, runInAction } from 'mobx'
import { observer } from 'mobx-react'
import { PureComponent } from 'react'
import { store } from 'store'
import {
    Component,
    Head,
    Claim,
    List,
    Action,
    Datetime,
    Desc,
    Other,
    User,
    LoadMore,
    LoadMoreLoader,
} from './actions.styled'

interface ActionsProps {
    id: string
}

@observer
export class Actions extends PureComponent<ActionsProps> {
    @observable
    private spinner = false

    constructor(props: ActionsProps) {
        super(props)
        makeObservable(this)
    }

    componentDidMount() {
        store.ads.details.resolve(this.props.id)
    }

    async loadMore() {
        runInAction(() => (this.spinner = true))

        await store.ads.details.resolveMore(this.props.id)

        runInAction(() => (this.spinner = false))
    }

    render() {
        const { id } = this.props

        if (!store.ads.details.isResolved(id)) {
            return <Loader />
        }

        const { details, length } = store.ads.details.get(id)!

        return (
            <Component>
                <Head>
                    <div>История действий</div>
                    <div>Всего</div>
                    <div>{length}</div>
                </Head>
                <List>
                    {details.map((detail, i) => {
                        const datetime = new Date(detail.createdAt)

                        return (
                            <Action>
                                <Datetime>
                                    <span>{datetime.toLocaleDateString()}</span>
                                    <span>{datetime.toLocaleTimeString()}</span>
                                </Datetime>
                                <Desc>
                                    {generateDescription(detail.type)}
                                    <Other>{detail.detail}</Other>
                                </Desc>
                                <User>{detail.user.name}</User>
                            </Action>
                        )
                    })}
                </List>
                {details.length < length &&
                    (this.spinner ? (
                        <LoadMoreLoader>
                            <Loader />
                        </LoadMoreLoader>
                    ) : (
                        <LoadMore onClick={() => this.loadMore()}>Загрузить ещё</LoadMore>
                    ))}
            </Component>
        )
    }
}

const generateDescription = (type: DetailType) => {
    switch (type) {
        case 'drafted':
            return <span>Сохранено в черновик</span>
        case 'activated':
            return <span>Объявление опубликовано</span>
        case 'archivated':
            return <span>Объявление перемещено в арзив</span>
        case 'claimed':
            return <Claim>Жалоба</Claim>
        case 'blocked':
            return <span>Объявление заблокировано</span>
        case 'unblocked':
            return <span>Объявление разблокировано</span>
        case 'reactivated':
            return <span>Повторная публикация</span>
        case 'removed':
            return <span>Объявление удалено</span>
        case 'saved':
            return <span>Объявление сохранено</span>
    }

    throw new Error(`Unknown ad detail type ${type}`)
}

type DetailType =
    | 'drafted'
    | 'activated'
    | 'archivated'
    | 'claimed'
    | 'blocked'
    | 'unblocked'
    | 'reactivated'
    | 'removed'
    | 'saved'
