import { Link } from 'react-router-dom'
import styled from 'styled-components'

export const Component = styled.div`
    margin-top: 24px;
`

export const Title = styled.div`
    margin-bottom: 18px;

    b {
        font-weight: bold;
        font-size: 24px;
        line-height: 124.7%;
        color: #39434b;
    }
    span {
        font-weight: bold;
        font-size: 24px;
        line-height: 124.7%;
        color: #b7bcd6;
        margin-left: 12px;
    }
`

export const ObjComponent = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    height: 75px;
    padding: 0 30px;
    display: flex;
    align-items: center;
    margin-top: 8px;
`

export const Address = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #111111;
    flex: 1;
`

export const AccountsLink = styled(Link)`
    padding: 0 25px;
    height: 36px;
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #707b8e;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    background-color: white;
    border-color: #707b8e;
    text-decoration: none;

    :hover {
        box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    }

    :active {
        box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
        border-radius: 8px;
    }

    :disabled {
        opacity: 0.5;
        border-radius: 8px;
    }
`
