import { Component, Title, Inputs, RegionInput } from './regions.styled'
import { store } from 'store'

interface RegionsProps {
    regions: number[]
    onRegionsChange: (i: number, v: number) => void
    error: string
}

export const Regions = (props: RegionsProps) => {
    const { regions, onRegionsChange, error } = props
    const list = store.regions.get().map((r) => [r.id, r.name]) as [number, string][]

    return (
        <Component>
            <Title>Регионы</Title>
            <Inputs>
                {regions.concat(null as any).map((v, i) => (
                    <RegionInput
                        key={v}
                        label="Регион"
                        options={list.filter((r) => r[0] === v || !regions.includes(r[0]))}
                        onChange={(v) => onRegionsChange(i, v)}
                        value={v}
                        error={error}
                        placeholder="Выберите регион"
                    />
                ))}
            </Inputs>
            {/* <ManualAddLink>Добавить провайдера вручную</ManualAddLink> */}
        </Component>
    )
}
