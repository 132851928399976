import { PureComponent } from 'react'
import { CabinetContext } from 'components/app/cabinet/context'
import { CustomersOrderBy, store } from 'store'
import { Customer } from './customer'
import {
    Component,
    SearchInputWrapper,
    SearchInput,
    Head,
    Title,
    Counter,
    Button,
    Spacer,
    SelectInput,
} from './default.styled'
import { observer } from 'mobx-react'
import { action, makeObservable, observable } from 'mobx'
import { InvitePopup, InviteSuccessPopup, InviteFailurePopup } from './popups'
import { actions } from 'actions'
import { Loader } from 'components/ui'
import { createScrollAutoloader } from 'utils'

const sortSelectOptions = [
    ['name', 'По имени'],
    ['createdAt', 'По дате'],
    ['rate', 'По рейтингу'],
] as [string, string][]

@observer
export class Default extends PureComponent {
    static contextType = CabinetContext

    @observable
    private invitePopupVisibility = false

    @observable
    private inviteSuccessPopupVisibility = false

    @observable
    private inviteFailurePopupVisibility = false

    @observable
    private query = store.customers.getParams().query
    private queryTimeout!: NodeJS.Timeout
    private detachScrollListener!: () => void

    constructor(props: {}) {
        super(props)
        makeObservable(this)
    }

    componentDidMount() {
        this.context.setTitle('Список абонентов')

        store.customers.resolve()

        this.detachScrollListener = createScrollAutoloader(store.customers.resolveMore)
    }

    componentWillUnmount() {
        this.detachScrollListener()
    }

    toggleInvitePopup(visibility?: boolean) {
        this.invitePopupVisibility = arguments.length ? visibility! : !this.invitePopupVisibility
    }

    toggleInviteSuccessPopup(visibility?: boolean) {
        this.inviteSuccessPopupVisibility = arguments.length ? visibility! : !this.inviteSuccessPopupVisibility
    }

    toggleInviteFailurePopup(visibility?: boolean) {
        this.inviteFailurePopupVisibility = arguments.length ? visibility! : !this.inviteFailurePopupVisibility
    }

    async sendInvite(phone: string) {
        try {
            await actions.sendCustomerInvite(phone)
            this.toggleInviteSuccessPopup()
        } catch (e) {
            this.toggleInviteFailurePopup()
        }
        this.toggleInvitePopup(false)
    }

    @action
    handleQueryChange(query: string) {
        this.query = query

        clearTimeout(this.queryTimeout)

        this.queryTimeout = setTimeout(() => store.customers.setParams({ query }), 500)
    }

    handleOrderChange(orderBy: CustomersOrderBy) {
        store.customers.setParams({ orderBy })
    }

    render() {
        if (!store.customers.isResolved()) {
            return <Loader />
        }

        const { orderBy } = store.customers.getParams()
        const { clients, length } = store.customers.get()

        return (
            <Component>
                {this.invitePopupVisibility && (
                    <InvitePopup
                        onClose={() => this.toggleInvitePopup(false)}
                        onSend={(phone: string) => this.sendInvite(phone)}
                    />
                )}
                {this.inviteSuccessPopupVisibility && (
                    <InviteSuccessPopup onClose={() => this.toggleInviteSuccessPopup(false)} />
                )}
                {this.inviteFailurePopupVisibility && (
                    <InviteFailurePopup onClose={() => this.toggleInviteFailurePopup(false)} />
                )}
                <SearchInputWrapper>
                    <SearchInput
                        placeholder="Поиск"
                        value={this.query}
                        onChange={(e) => this.handleQueryChange(e.target.value)}
                    />
                </SearchInputWrapper>
                <Head>
                    <Title>Абоненты</Title>
                    <Counter>{length}</Counter>
                    <Spacer />
                    <SelectInput
                        options={sortSelectOptions}
                        value={orderBy}
                        onChange={(v) => this.handleOrderChange(v)}
                        small
                    />
                    <Button onClick={() => this.toggleInvitePopup()} disabled>
                        + Пригласить
                    </Button>
                </Head>
                {clients.map((customer) => (
                    <Customer key={customer.id} data={customer} />
                ))}
            </Component>
        )
    }
}
