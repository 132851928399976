import styled from 'styled-components'
import downloadIcon from './images/download.svg'

export const Component = styled.div`
    margin-top: 24px;
`

export const Title = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: bold;
    font-size: 24px;
    line-height: 124.7%;
    color: #39434b;
    margin-bottom: 24px;
`

export const Table = styled.div`
    display: flex;
    row-gap: 10px;
    flex-direction: column;
`

export const Row = styled.div`
    display: grid;
    grid-template-columns: 3fr 2fr 3fr 2fr 2fr;
    column-gap: 40px;
    height: 91px;
    background-color: white;
    padding: 0 30px;

    & > div {
        display: flex;
        flex-direction: column;
        row-gap: 4px;
        justify-content: center;

        & > span {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #707b8e;
        }
        & > b {
            font-family: Roboto;
            font-style: normal;
            font-weight: 500;
            font-size: 18px;
            line-height: 21px;
            color: #111111;
        }
        & > button {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 127.3%;
            color: #68c376;
            text-decoration: none;
            padding-right: 22px;
            background-image: url(${downloadIcon});
            background-position: center right;
            background-repeat: no-repeat;
            align-self: flex-end;
            cursor: pointer;
            border: none;
            outline: none;
            background-color: transparent;

            :hover {
                text-decoration: underline;
            }
        }
    }
`
