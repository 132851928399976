import styled from 'styled-components'

export const Overlay = styled.div`
    position: fixed;
    z-index: 10;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.33);
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Component = styled.div<{ width: number }>`
    max-width: ${(props) => props.width}px;
    min-width: ${(props) => props.width}px;
    background: #ffffff;
    box-shadow: 0px 4px 66px rgba(0, 0, 0, 0.04);
    border-radius: 25px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 58px 48px 40px;
`
