import { Switch, Route, useRouteMatch } from 'react-router-dom'
import { Default, History } from './routes'

export const Payments = () => {
    const { path } = useRouteMatch()

    return (
        <Switch>
            <Route path={`${path}/history/:id`}>
                <History />
            </Route>
            <Route path={path}>
                <Default />
            </Route>
        </Switch>
    )
}
