import styled from 'styled-components'

export const Heading = styled.h1`
    font-family: Arsenal;
    font-style: normal;
    font-weight: 700;
    font-size: 36px;
    line-height: 124.7%;
    color: #39434b;
`
