import { Link } from 'react-router-dom'
import { store } from 'store'
import { formatMoney, generateAssignUserType, plural, pluralDesc } from 'utils'
import { Component, Plain } from './stats.styled'

type StatsProps = {
    id: number
}

export const Stats = (props: StatsProps) => {
    const { id } = props
    const user = store.admin.get(id)!

    return (
        <Component>
            {user.type === 'partner' && (
                <Plain color="blue">
                    <b>{formatMoney((user as any).totalTurnover)}</b>
                    <Link to={`/admins/${id}/transactions`}>Суммарный оборот</Link>
                </Plain>
            )}
            <Plain color="green">
                <b>{user.countTickets}</b>
                <Link to={`/support/${generateAssignUserType(user.type)}/${id}`}>
                    {pluralDesc(user.countTickets, 'Открытый тикет', 'Открытых тикета', 'Открытых тикетов')}
                </Link>
            </Plain>
            <Plain color="yellow">
                <b>{user.rate}</b>
                <Link to={`/admins/reviews/${id}`}>{plural(user.countReviews, 'отзыв', 'отзыва', 'отзывов')}</Link>
            </Plain>
        </Component>
    )
}
