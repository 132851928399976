import styled from 'styled-components'

export const Component = styled.div`
    border-top: 1px solid #eaecf0;
`

export const Thead = styled.div`
    height: 54px;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #707b8e;
    display: flex;
    align-items: center;

    & > span {
        &:nth-child(1) {
            flex: 1;
        }
        &:nth-child(2) {
            flex: 0 0 200px;
            text-align: right;
        }
        &:nth-child(3) {
            flex: 0 0 200px;
            text-align: right;
        }
        &:nth-child(4) {
            flex: 0 0 174px;
            margin-left: 100px;
        }
    }
`

export const Row = styled.div`
    height: 64px;
    background: #f6faf8;
    display: flex;
    align-items: center;
    padding: 0 14px;

    :not(:last-child) {
        margin-bottom: 4px;
    }

    & > span {
        &:nth-child(1) {
            flex: 1;
        }
        &:nth-child(2) {
            flex: 0 0 200px;
            text-align: right;
        }
        &:nth-child(3) {
            flex: 0 0 200px;
            text-align: right;
        }
        &:nth-child(4) {
            flex: 0 0 160px;
            margin-left: 100px;
        }
    }
`

export const Datetime = styled.span`
    & > span:nth-child(2) {
        color: #b7bcd6;
        margin-left: 4px;
    }
`

export const Money = styled.span<{ isPositive: boolean }>`
    color: ${(props) => (props.isPositive ? '#68C376;' : '#EB5757')};
`
