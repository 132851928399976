import { observable, computed, action } from 'mobx'

export interface Store<P, D> {
    get: (k?: string | undefined) => NonNullable<D>
    set: (k: string, d: D) => void
    getKey: () => string
    setKey: (k: string) => void
    hasKey: (k: string) => boolean
    getRealKey: () => string
    getRealParams: () => P
    getParams: () => P
    setParams: (params: Partial<P>) => void
    isResolved: () => boolean
}

export const createStore = <P, D>(params: P): Store<P, D> => {
    const STORE = observable.map<string, D>()

    const REAL_PARAMS = observable.box<P>(params)

    const REAL_KEY = computed(() => {
        return JSON.stringify(REAL_PARAMS.get())
    })

    const KEY = observable.box(REAL_KEY.get())

    const PARAMS = computed(() => {
        return JSON.parse(KEY.get()) as P
    })

    const DATA = computed(() => {
        return STORE.get(KEY.get())
    })

    const getRealKey = () => {
        return REAL_KEY.get()
    }

    const getKey = () => {
        return KEY.get()
    }

    const setKey = action((k: string) => {
        KEY.set(k)
    })

    const hasKey = (k: string) => {
        return STORE.has(k)
    }

    const getRealParams = () => {
        return REAL_PARAMS.get()
    }

    const get = (k?: string) => {
        return k ? STORE.get(k)! : DATA.get()!
    }

    const set = action((k: string, d: D) => {
        STORE.set(k, d)
    })

    const getParams = () => {
        return PARAMS.get()
    }

    const setParams = action((params: Partial<P>) => {
        REAL_PARAMS.set({ ...getParams(), ...params })
    })

    const isResolved = () => {
        return STORE.has(KEY.get())
    }

    return {
        get,
        set,
        getKey,
        setKey,
        hasKey,
        getRealKey,
        getRealParams,
        getParams,
        setParams,
        isResolved,
    }
}
