import styled from 'styled-components'

export const Component = styled.div`
    padding-top: 17px;
`

export const Monitors = styled.div`
    display: flex;
    flex-wrap: wrap;
    gap: 16px;
`

export const Monitor = styled.div`
    flex: 0 0 263px;
    background: #ffffff;
    border-radius: 10px;
    padding: 24px;
    border: 1px solid transparent;
    transition: border-color 0.2s ease;
    min-height: 214px;

    :hover {
        border: 1px solid #68c376;
        box-shadow: 0px 4px 15px rgba(104, 195, 118, 0.09);
    }
`

export const Name = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 124.7%;
    color: #39434b;
`

export const Price = styled.div<{ red?: boolean }>`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    margin-top: 27px;
    color: ${(props) => (props.red ? '#EB5757' : '#39434b')};
`

export const Desc = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #b7bcd6;
`
