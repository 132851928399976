import styled from 'styled-components'

export const Component = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    padding: 20px 30px;
    margin-top: 24px;
`

export const Title = styled.div`
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #39434b;
    margin-bottom: 16px;
`

export const RegionsList = styled.div`
    span {
        font-size: 18px;
        line-height: 150.7%;
        color: #39434b;

        :not(:last-child):after {
            content: '•';
            margin: 0 8px;
        }
    }
`
