import {
    Component,
    Avatar,
    Flex,
    System,
    Author,
    Datetime,
    Body,
    Images,
    Img,
    Files,
    Links,
    AvatarLink,
} from './message.styled'
import { TicketMessageData } from 'store'
import { avatarUrl, download, fileUrl } from 'utils'
import { Link } from 'react-router-dom'

interface MessageProps {
    data: TicketMessageData
    onOpenImage: (src: string) => void
}

export const Message = (props: MessageProps) => {
    const { data, onOpenImage } = props
    const { user, createdAt, message, isSystem, template, attachments, links } = data

    switch (isSystem && template) {
        case 'action':
            return systemActionMessage(data)
        case 'account':
            return systemAccountMessage(data)
        case 'assign':
            return systemAssignMessage(data)
    }

    const images = [] as Attachment[]
    const files = [] as Attachment[]

    for (const file of attachments) {
        if (imagesMime.includes(file.mimeType)) {
            images.push(file)
        } else {
            files.push(file)
        }
    }

    const datetime = new Date(createdAt)
    const authorLink = generateAuthorLink(user)

    return (
        <Component>
            <AvatarLink to={authorLink}>
                <Avatar src={avatarUrl(user.avatar)} />
            </AvatarLink>
            <Flex>
                <Author to={authorLink}>{user.name}</Author>
                <Datetime>
                    <span>{datetime.toLocaleDateString()}</span>
                    <span>{datetime.toLocaleTimeString()}</span>
                </Datetime>
            </Flex>
            <Body>
                {!!images.length && (
                    <Images>
                        {images.map((i) => (
                            <Img key={i.link} src={fileUrl(i.link)} onClick={() => onOpenImage(fileUrl(i.link))} />
                        ))}
                    </Images>
                )}
                {!!message.length && <div>{message}</div>}
                {!!files.length && (
                    <Files>
                        {files.map((i) => (
                            <div key={i.link} onClick={() => download(fileUrl(i.link))}>
                                {i.name}
                            </div>
                        ))}
                    </Files>
                )}
                {!!links.length && (
                    <Links>
                        {links.map((i) => (
                            <a key={i.id} target="_blank" rel="noreferrer" href={i.link}>
                                {i.name}
                            </a>
                        ))}
                    </Links>
                )}
            </Body>
        </Component>
    )
}

const generateAuthorLink = (author: TicketMessageData['user']) => {
    switch (author.type) {
        case 'client':
            return `/customers/${author.id}`
        case 'provider':
            return `/providers/${author.id}`
        default:
            return `/admins/${author.id}`
    }
}

const imagesMime = ['image/jpeg', 'image/png']

interface Attachment {
    link: string
    name: string
    mimeType: string
    size: string
    createdAt: string
    updatedAt: string
}

interface SystemActionOptions {
    assignUserType: string
    assignUserId: number
    assignUserName: string
    state: 'wait' | 'close' | 'active'
}

const status = {
    wait: 'ожидающий',
    close: 'архивный',
    active: 'активный',
}

const systemActionMessage = (data: TicketMessageData) => {
    const { createdAt, options } = data
    const datetime = new Date(createdAt)
    const { assignUserId, assignUserName, state } = options as SystemActionOptions

    return (
        <Component>
            <Flex>
                <System>
                    <Link to={`/admins/${assignUserId}`}>{assignUserName}</Link>
                    {` изменил статус на ${status[state]}`}
                </System>
                <Datetime>
                    <span>{datetime.toLocaleDateString()}</span>
                    <span>{datetime.toLocaleTimeString()}</span>
                </Datetime>
            </Flex>
        </Component>
    )
}

interface SystemAssignOptions {
    oldAssignUserType: string
    oldAssignUserId: number
    oldAssignUserName: string
    newAssignUserType: string
    newAssignUserId: number
    newAssignUserName: string
}

const systemAssignMessage = (data: TicketMessageData) => {
    const { createdAt, options } = data
    const datetime = new Date(createdAt)
    const { oldAssignUserId, oldAssignUserName, newAssignUserId, newAssignUserName } = options as SystemAssignOptions

    return (
        <Component>
            <Flex>
                <System>
                    <Link to={`/admins/${oldAssignUserId}`}>{oldAssignUserName}</Link>
                    {' назначил(а) задачу пользователю '}
                    <Link to={`/admins/${newAssignUserId}`}>{newAssignUserName}</Link>
                </System>
                <Datetime>
                    <span>{datetime.toLocaleDateString()}</span>
                    <span>{datetime.toLocaleTimeString()}</span>
                </Datetime>
            </Flex>
        </Component>
    )
}

interface SystemAccountOptions {
    account: string
}

const systemAccountMessage = (data: TicketMessageData) => {
    const { createdAt, options } = data
    const datetime = new Date(createdAt)
    const { account } = options as SystemAccountOptions

    return (
        <Component>
            <Flex>
                <System>{`Номер лицевого счета ${account}`}</System>
                <Datetime>
                    <span>{datetime.toLocaleDateString()}</span>
                    <span>{datetime.toLocaleTimeString()}</span>
                </Datetime>
            </Flex>
        </Component>
    )
}
