import { Button, Heading, Popup } from 'components/ui'
import { Desc, Buttons } from './lock.styled'

interface LockPopupProps {
    onConfirm: () => void
    onClose: () => void
}

export const LockPopup = (props: LockPopupProps) => {
    const { onConfirm, onClose } = props

    return (
        <Popup width={655}>
            <Heading>Заблокировать провайдера</Heading>
            <Desc>
                Вы уверенны что вы хотите заблокировать <br /> провайдера?
            </Desc>
            <Buttons>
                <Button secondary onClick={onClose}>
                    Нет
                </Button>
                <Button onClick={onConfirm}>Да</Button>
            </Buttons>
        </Popup>
    )
}
