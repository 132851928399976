import { PureComponent } from 'react'
import { Component } from './dashboard.styled'
import { Graph } from './graph'
import { Info } from './info'
import { Stats } from './stats'
import { Regions } from './regions'
import { CabinetContext } from '../../context'
import { observer } from 'mobx-react'
import { store } from 'store'
import { Loader } from 'components/ui'

@observer
export class Dashboard extends PureComponent {
    static contextType = CabinetContext

    componentDidMount() {
        this.context.setTitle('Панель управления')
        store.dashboard.resolve()
    }

    render() {
        if (!store.dashboard.isResolved()) {
            return <Loader />
        }

        return (
            <Component>
                <Graph />
                <Info />
                <Stats />
                <Regions />
            </Component>
        )
    }
}
