import { Button, Heading, Popup } from 'components/ui'
import { PureComponent } from 'react'
import { makeObservable, observable } from 'mobx'
import { observer } from 'mobx-react'
import { Desc, Buttons, PhoneInput } from './invite.styled'

interface InvitePopupProps {
    onSend: (phone: string) => void
    onClose: () => void
}

@observer
export class InvitePopup extends PureComponent<InvitePopupProps> {
    @observable
    private phone = ''

    @observable
    private phoneError = ''

    constructor(props: InvitePopupProps) {
        super(props)
        makeObservable(this)
    }

    handleSendClick() {
        if (!this.phone) {
            this.phoneError = 'Введите телефон'
            return
        }

        this.props.onSend(this.phone)
    }

    handlePhoneChange(v: string) {
        this.phone = v

        if (!this.phone) {
            this.phoneError = 'Введите телефон'
            return
        }

        this.phoneError = ''
        return
    }

    render() {
        const { onClose } = this.props

        return (
            <Popup width={486}>
                <Heading>Приглашение</Heading>
                <Desc>Для приглашения нового абонента укажите его номер телефона </Desc>
                <PhoneInput
                    placeholder="Телефон"
                    value={this.phone}
                    error={this.phoneError}
                    onChange={(e) => this.handlePhoneChange(e.target.value)}
                />
                <Buttons>
                    <Button secondary onClick={onClose}>
                        Отмена
                    </Button>
                    <Button onClick={() => this.handleSendClick()}>Отправить</Button>
                </Buttons>
            </Popup>
        )
    }
}
