import { store } from 'store'
import { Component, Table, Param, Value, Hr } from './details.styled'

type DetailsProps = {
    id: number
}

export const Details = (props: DetailsProps) => {
    const { id } = props
    const { legalEntity } = store.admin.get(id)!

    return (
        <Component>
            {legalEntity.isTaxpayer ? (
                <Table>
                    <Param>Статус</Param>
                    <Value>Самозанятый</Value>
                    <Param>ИНН</Param>
                    <Value>{legalEntity.inn}</Value>
                </Table>
            ) : (
                <Table>
                    <Param>Полное наименование юридического лица</Param>
                    <Value>{legalEntity.fullName}</Value>
                    <Param>Сокращённое наименование</Param>
                    <Value>{legalEntity.name}</Value>
                    {/* <Param>Юридический адрес</Param>
            <Value>{legalEntity.a}</Value> */}
                    <Param>ФИО руководителя юридического лица</Param>
                    <Value>
                        {legalEntity.lastName} {legalEntity.firstName} {legalEntity.middleName}
                    </Value>
                    <Param>ИНН</Param>
                    <Value>{legalEntity.inn}</Value>
                    <Param>ОГРН</Param>
                    <Value>{legalEntity.ogrn}</Value>
                    <Param>КПП</Param>
                    <Value>{legalEntity.kpp}</Value>
                </Table>
            )}

            <Hr />
            <Table>
                <Param>Банк</Param>
                <Value>{legalEntity.bankName}</Value>
                <Param>БИК</Param>
                <Value>{legalEntity.bik}</Value>
                <Param>Корреспонденский счёт</Param>
                <Value>{legalEntity.bankAccount}</Value>
                <Param>Рассчетный счёт</Param>
                <Value>{legalEntity.account}</Value>
            </Table>
        </Component>
    )
}
