import { action, reaction, runInAction } from 'mobx'
import { api } from 'api'
import { createStore } from './abstract'

interface Params {
    region: number
    kind: 'all' | ProvidersItemKind
    orderBy: ProvidersOrderBy
    query: string
}

const store = createStore<Params, ProvidersData>({
    region: 0,
    kind: 'all' as 'all' | ProvidersItemKind,
    orderBy: 'name' as ProvidersOrderBy,
    query: '',
})

export const get = store.get
export const setParams = store.setParams
export const getParams = store.getParams
export const isResolved = store.isResolved

const BUSY_MAP = new Map<string, boolean>()

export const find = (id: number) => {
    for (const item of store.get().providers) {
        if (item.id === id) {
            return item
        }
    }
    return null
}

export const remove = action((id: number) => {
    const data = store.get()

    for (let i = 0; i < data.providers.length; i++) {
        const item = data.providers[i]

        if (item.id === id) {
            data.providers.splice(i, 1)
            data.length--
            return
        }
    }
})

export const resolve = async () => {
    const key = store.getKey()
    const { region, kind, orderBy, query } = store.getParams()
    const data = await api.provider.list(20, 0, region, kind, orderBy, query)

    BUSY_MAP.set(key, false)

    store.set(key, data)
}

export const resolveMore = async () => {
    if (!isResolved()) return

    const key = store.getKey()

    if (!BUSY_MAP.get(key)) {
        BUSY_MAP.set(key, true)

        const { providers } = store.get()
        const offset = providers.length
        const { region, kind, orderBy, query } = getParams()
        const data = await api.provider.list(20, offset, region, kind, orderBy, query)

        if (data.providers.length === 0) {
            return
        }

        BUSY_MAP.set(key, false)

        runInAction(() => providers.push(...data.providers))
    }
}

// resolver

let lastKey: string

reaction(
    () => store.getRealKey(),
    (key) => {
        lastKey = key

        const { region, kind, orderBy, query } = store.getRealParams()

        if (store.hasKey(key)) {
            store.setKey(key)

            const limit = store.get(key).providers.length

            api.provider.list(limit, 0, region, kind, orderBy, query).then(action((data) => store.set(key, data)))
        } else {
            api.provider.list(20, 0, region, kind, orderBy, query).then(
                action((data) => {
                    store.set(key, data)

                    if (key === lastKey) {
                        store.setKey(key)
                    }
                })
            )
        }
    }
)

export type ProvidersItemKind = 'valid' | 'novalid' | 'isSpec' | 'drafts' | 'block'
export type ProvidersOrderBy = 'name' | 'balance' | 'subscriptionFee' | 'createdAt' | 'rate'

export interface ProvidersData {
    total: number
    chart: Chart
    providers: ProvidersItemData[]
    length: number
}

export interface ProvidersItemData {
    id: number
    name: string
    logo: string
    short: string
    shopCode: string
    createdAt: string
    isBlocked: boolean
    partnerUserId: number
    partnerUser: PartnerUser
    rate: number
    countReviews: number
    countAccounts: number
    countActiveAccounts: number
    email: string
    balance: number
    phone: string
    subscriptionFee: number
    shortLegalEntity: ShortLegalEntity
    kind: ProvidersItemKind
}

interface PartnerUser {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}

interface ShortLegalEntity {
    inn: string
    name: string
}

interface Chart {
    current: Current[]
}

interface Current {
    month: number
    count: number
}
