import styled from 'styled-components'

export const Component = styled.div`
    display: flex;
    flex-direction: column;
    align-items: stretch;
`

export const Head = styled.div`
    display: flex;
    align-items: center;
    column-gap: 16px;
    margin-bottom: 19px;

    & > div {
        &:nth-child(1) {
            flex: 1;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 127.3%;
            color: #111111;
        }
        &:nth-child(2) {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 127.3%;
            color: #707b8e;
        }
        &:nth-child(3) {
            font-family: Roboto;
            font-style: normal;
            font-weight: bold;
            font-size: 24px;
            line-height: 124.7%;
            display: flex;
            align-items: center;
            color: #39434b;
        }
    }
`

export const List = styled.div`
    display: flex;
    flex-direction: column;
    row-gap: 6px;
`

export const Action = styled.div`
    display: flex;
    align-items: center;
    column-gap: 16px;
    height: 64px;
    background-color: #f6faf8;
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 127.3%;
    color: #111111;
    padding: 0 14px;
`

export const Claim = styled.span`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 127.3%;
    color: #eb5757;
`

export const Datetime = styled.div`
    span:nth-child(1) {
        color: #39434b;
    }
    span:nth-child(2) {
        margin-left: 6px;
        color: #b7bcd6;
    }
`

export const Other = styled.div`
    color: #707b8e;
    margin-left: 10px;

    :empty {
        display: none;
    }
`

export const Desc = styled.div`
    flex: 1;
`

export const User = styled.div`
    color: #68c376;
    flex: 0 0 250px;
`

export const LoadMore = styled.a`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 127.3%;
    color: #68c376;
    outline: none;
    text-decoration: none;
    align-self: center;
    margin-top: 28px;
    cursor: pointer;
    height: 20px;

    :hover {
        text-decoration: underline;
    }
`

export const LoadMoreLoader = styled.div`
    align-self: center;
    position: relative;
    margin-top: 28px;
`
