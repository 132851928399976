import { api } from 'api'
import { observable } from 'mobx'

export * as transactions from './admin_transactions'

export const STORE = observable.map<number, AdminData>()

export const resolve = async (id: number) => {
    const { user } = await api.user.get(id)
    STORE.set(id, user)
}

export const isResolved = (id: number) => {
    return STORE.has(id)
}

export const get = (id: number) => {
    return STORE.get(id)
}

type UserType = 'admin' | 'support' | 'partner' | 'analyst'
type UserSex = 'male' | 'female'

export interface AdminData {
    id: number
    type: UserType
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isOwner: boolean
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
    birthDate: string
    sex: UserSex
    createdAt: string
    lastSignInAt: string
    isBlocked: boolean
    regions: Region[]
    legalEntity: LegalEntity
    totalTurnover: number
}

interface LegalEntity {
    name: string
    fullName: string
    id: number
    inn: string
    kpp: string
    ogrn: string
    bankName: string
    bankAddress: string
    bik: string
    bankAccount: string // корр счет
    taxType: 'none' | 'vat0' | 'vat10' | 'vat20'
    account: string // р/с
    details: string
    firstName: string
    lastName: string
    middleName: string
    birthDate: string
    phone: string
    state: 'novalid' | 'sended' | 'valid'
    isTaxpayer: boolean
}

interface Region {
    id: number
    name: string
    countAddresses: number
    countClients: number
    countSales: number
    countPayments: number
}
