import { store } from 'store'
import { avatarUrl } from 'utils'
import {
    Component,
    Title,
    Hr,
    GridOne,
    Avatar,
    LastnameInput,
    FirstnameInput,
    MiddlenameInput,
    BirthdateInput,
    GenderInput,
    RoleInput,
    GridTwo,
    EmailInput,
    PhoneInput,
    UploadAvatar,
} from './common.styled'
import avatarIcon from './images/avatar.svg'

type UserType = 'admin' | 'support' | 'partner' | 'analyst'
type UserSex = 'male' | 'female'

interface CommonProps {
    mode: 'create' | 'edit'
    type: UserType
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    birthDate: string
    sex: UserSex
    emailError: string
    phoneError: string
    avatarError: string
    lastnameError: string
    firstnameError: string
    patronymicError: string
    birthDateError: string
    onTypeChange: (v: UserType) => void
    onEmailChange: (v: string) => void
    onPhoneChange: (v: string) => void
    onAvatarChange: (v: File) => void
    onLastnameChange: (v: string) => void
    onFirstnameChange: (v: string) => void
    onPatronymicChange: (v: string) => void
    onBirthDateChange: (v: string) => void
    onSexChange: (v: UserSex) => void
}

export const Common = (props: CommonProps) => {
    const {
        mode,
        type,
        email,
        phone,
        avatar,
        lastname,
        firstname,
        patronymic,
        birthDate,
        sex,
        emailError,
        phoneError,
        avatarError,
        lastnameError,
        firstnameError,
        patronymicError,
        birthDateError,
        onTypeChange,
        onEmailChange,
        onPhoneChange,
        onAvatarChange,
        onLastnameChange,
        onFirstnameChange,
        onPatronymicChange,
        onBirthDateChange,
        onSexChange,
    } = props
    const currentUser = store.user.get()!
    const roleVariants = generateRoleVariants(currentUser)

    return (
        <Component>
            <Title>Данные пользователя</Title>
            <GridOne>
                <Avatar>
                    <img
                        src={avatar ? (avatar.startsWith('data:image') ? avatar : avatarUrl(avatar)) : avatarIcon}
                        alt=""
                    />
                    <input
                        type="file"
                        name="name"
                        accept="image/jpeg,image/png"
                        onChange={(e) => onAvatarChange(e.target.files![0])}
                    />
                    <UploadAvatar error={!!avatarError}>Загрузить фото</UploadAvatar>
                </Avatar>
                <LastnameInput
                    label="Фамилия"
                    value={lastname}
                    error={lastnameError}
                    onChange={(e) => onLastnameChange(e.target.value)}
                />
                <FirstnameInput
                    label="Имя"
                    value={firstname}
                    error={firstnameError}
                    onChange={(e) => onFirstnameChange(e.target.value)}
                />
                <MiddlenameInput
                    label="Отчество"
                    value={patronymic}
                    error={patronymicError}
                    onChange={(e) => onPatronymicChange(e.target.value)}
                />
                <BirthdateInput
                    label="Дата рождения"
                    value={birthDate}
                    mask="99.99.9999"
                    maskPlaceholder="дд.мм.гггг"
                    error={birthDateError}
                    onChange={(e) => onBirthDateChange(e.target.value)}
                />
                <GenderInput
                    label="Пол"
                    value={sex}
                    onChange={(v) => onSexChange(v)}
                    options={[
                        ['male', 'мужской'],
                        ['female', 'женский'],
                    ]}
                />
                <RoleInput
                    label="Роль в системе"
                    value={type}
                    onChange={(v) => onTypeChange(v)}
                    disabled={mode === 'edit'}
                    options={roleVariants}
                />
            </GridOne>
            <Hr />
            <Title>Данные для входа</Title>
            <GridTwo>
                <EmailInput
                    label="E-mail"
                    value={email}
                    error={emailError}
                    onChange={(e) => onEmailChange(e.target.value)}
                />
                <PhoneInput
                    label="Телефон"
                    value={phone}
                    error={phoneError}
                    onChange={(e) => onPhoneChange(e.target.value)}
                    mask="+7 (999) 999-99-99"
                    maskPlaceholder="+7 (___) ___-__-__"
                />
            </GridTwo>
        </Component>
    )
}

const generateRoleVariants = (user: { isOwner: boolean }) => {
    const acc = [] as [string, string][]

    if (user.isOwner) {
        acc.push(['admin', 'Администратор'])
    }

    acc.push(['partner', 'Партнёр'])
    acc.push(['analyst', 'Аналитик'])
    acc.push(['support', 'Служба поддержки'])

    return acc
}
