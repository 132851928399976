import { Component } from './label.styled'

interface LabelProps {
    type: string
    isOwner?: boolean
}

export const Label = (props: LabelProps) => {
    const { type, isOwner, ...other } = props

    return (
        <Component type={isOwner ? 'owner' : type} {...other}>
            {getLabelText(type, !!isOwner)}
        </Component>
    )
}

const getLabelText = (type: string, isOwner: boolean) => {
    if (isOwner) {
        return 'Владелец'
    }

    switch (type) {
        case 'admin':
            return 'Администратор'
        case 'support':
            return 'Поддержка'
        case 'partner':
            return 'Партнер'
        case 'analyst':
            return 'Аналитик'
        case 'client':
            return 'Абонент'
        case 'provider':
            return 'Провайдер'
        default:
            return 'Хз кто :)'
    }
}
