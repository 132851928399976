import { request } from './request'

export const get = (id: number) => {
    return request<GetResponse>('address/get', { id })
}

export const list = (limit: number, offset: number, regionId: number, fiasId: string, query: string) => {
    return request<ListResponse>('address/list', { limit, offset, regionId, fiasId, query })
}

export const regionList = () => {
    return request<RegionListResponse>('address/region/list')
}

interface GetResponse {
    address: FullAddress
}

interface ListResponse {
    addresses: Address[]
    length: number
}

interface RegionListResponse {
    regions: Region[]
}

interface FullAddress {
    id: number
    full: string
    short: string
    fiasId: string
    postalCode: string
    region: string
    city: string
    street: string
    house: string
    flat: string
    countClients: number
    countAccounts: number
    countSales: number
    dadata: Dadata
    accounts: Account[]
}

interface Account {
    id: number
    number: string
    provider: Provider
    isClosed: boolean
    fio: string
    lastImportAt: string
    balance: number
    penalty: number
    counters: Counter[]
}

interface Counter {
    id: number
    meters: Meter[]
    counterTypeId: number
    counterType: CounterType
    number: string
    installDate: string
    removeDate: string
    isRemove: boolean
    createdAt: string
    updatedAt: string
}

interface CounterType {
    id: number
    name: string
    measure: string
    format: string
}

interface Meter {
    id: number
    source: 'user' | 'provider'
    value: number
    createdAt: string
    updatedAt: string
}

interface Provider {
    id: number
    name: string
    shopCode: string
    createdAt: string
    partnerUserId: number
    partnerUser: PartnerUser
    rate: number
    countReviews: number
    countAccounts: number
    countActiveAccounts: number
    balance: number
    subscriptionFee: number
    shortLegalEntity: ShortLegalEntity
    email: string
}

interface ShortLegalEntity {
    inn: string
    name: string
}

interface PartnerUser {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}

interface Dadata {}

interface Address {
    id: number
    full: string
    short: string
    fiasId: string
    postalCode: string
    region: string
    city: string
    street: string
    house: string
    flat: string
    countClients: number
    countAccounts: number
    countSales: number
}

interface Region {
    id: number
    name: string
    countAddresses: number
    countClients: number
    countSales: number
    countPayments: number
    partner: Partner | null
}

interface Partner {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}
