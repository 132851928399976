import { api } from 'api'
import { observable, runInAction } from 'mobx'

export const STORE = observable.box<MonitoringData>()

export const get = () => {
    return STORE.get()
}

export const resolve = async () => {
    const { monitors } = await api.monitor.list()
    runInAction(() => STORE.set(monitors))
}

export const isResolved = () => {
    return !!STORE.get()
}

export type MonitoringData = MonitoringItemData[]

export interface MonitoringItemData {
    name: string
    params: Param[]
}

interface Param {
    name: string
    value: string
}
