import styled from 'styled-components'
import clockIcon from './images/clock.svg'
import fileIcon from './images/file.svg'
import crossIcon from './images/cross.svg'

export const Component = styled.div`
    height: calc(100vh - 76px);
    max-height: calc(100vh - 76px);
    display: grid;
    margin: -20px -28px -30px -18px;
    grid-template-columns: 1fr 392px;
    grid-template-rows: 1fr min-content;
    grid-template-areas:
        'content aside'
        'new_message aside';
`

export const Content = styled.div`
    grid-area: content;
    padding: 20px 15px 15px 18px;
    display: flex;
    flex-direction: column;
`

export const Breadcrumbs = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    a,
    span {
        :not(:last-child) {
            font-size: 16px;
            line-height: 127.3%;
            color: #707b8e;
            text-decoration: none;
            transition: color 0.2s ease;

            :after {
                content: '•';
                margin: 0 8px;
                text-decoration: none;
            }
        }
        :last-child {
            font-size: 18px;
            line-height: 21px;
            color: #68c376;
            font-weight: 500;
        }
    }

    a:hover {
        color: #4e5a6f;
    }
`

export const Info = styled.div`
    background: #ffffff;
    border-radius: 10px;
    padding: 20px;
`

export const Flex = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
`

export const Id = styled.div`
    background: url(${fileIcon}) no-repeat left -2px;
    padding-left: 25px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #39434b;
`

export const Timeleft = styled.div`
    background: url(${clockIcon}) no-repeat left 1px;
    padding-left: 20px;

    span {
        &:nth-child(1) {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #707b8e;
        }
        &:nth-child(2) {
            font-weight: normal;
            font-size: 14px;
            line-height: 20px;
            letter-spacing: -0.24px;
            color: #111111;
            margin-left: 3px;
        }
    }
`

export const Title = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 124.7%;
    color: #111111;
    margin-top: 14px;
`

export const Messages = styled.div`
    flex: 1;
    margin-top: 40px;
    margin-right: -14px;
`

export const MessagesList = styled.div`
    padding-right: 14px;
`

export const OpenedImage = styled.div`
    z-index: 1;
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(50, 50, 50, 0.7);
    display: flex;
    align-items: center;
    justify-content: center;
`

export const Picture = styled.div`
    position: relative;

    img {
        max-width: 80vw;
        max-height: 80vh;
    }
`

export const CloseOpenedImage = styled.div`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 24px;
    height: 24px;
    border-radius: 3px;
    background-color: white;
    opacity: 0.7;
    cursor: pointer;
    transition: opacity 0.2s ease;
    background-image: url(${crossIcon});
    background-position: center;
    background-repeat: no-repeat;

    :hover {
        opacity: 1;
    }
`
