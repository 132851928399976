import styled from 'styled-components'

export const Component = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 26px;
`

export const Block = styled.div`
    flex: 0 0 calc((100% - 36px) / 3);
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-shadow: 0px 0px 20px rgba(40, 48, 77, 0.05);
    border-radius: 8px;
    padding: 30px 30px 20px;
`

export const Summ = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 124.7%;
    color: #111111;
`

export const Desk = styled.div`
    font-size: 16px;
    line-height: 127.3%;
    color: #47505a;
    margin-top: 5px;
`
