import { withRouter, match } from 'react-router'
import { Link } from 'react-router-dom'
import { CabinetContext } from 'components/app/cabinet/context'
import {
    Component,
    Breadcrumbs,
    Title,
    Label,
    Button,
    Options,
    Admin,
    Avatar,
    Name,
    Spacer,
    UnlockButton,
    LockButton,
} from './reviews.styled'
import { PureComponent } from 'react'
import { store } from 'store'
import { Review } from './review'
import { makeObservable, observable, runInAction } from 'mobx'
import { ResetPasswordPopup } from './popups'
import { observer } from 'mobx-react'
import { Loader } from 'components/ui'
import { avatarUrl, createScrollAutoloader } from 'utils'
import { actions } from 'actions'

interface ReviewsProps {
    match?: match<{ id: string }>
}

@(withRouter as any)
@observer
export class Reviews extends PureComponent<ReviewsProps> {
    static contextType = CabinetContext
    private scrollAutoloadDisposer!: () => void

    @observable
    private adminLockPopupVisibility = false
    @observable
    private adminUnlockPopupVisibility = false
    @observable
    private resetPasswordPopupVisibility = false

    constructor(props: ReviewsProps) {
        super(props)
        makeObservable(this)
    }

    get adminId() {
        return parseInt(this.props.match!.params.id)
    }

    componentDidMount() {
        this.context.setTitle('Отзывы об администраторе')

        const { adminId } = this

        store.admin.resolve(adminId)
        store.reviews.admin.resolve(adminId)

        this.scrollAutoloadDisposer = createScrollAutoloader(() => store.reviews.admin.resolveMore(adminId))
    }

    componentWillUnmount() {
        this.scrollAutoloadDisposer()
    }

    toggleAdminLockPopup(visibility?: boolean) {
        this.adminLockPopupVisibility = arguments.length ? visibility! : !this.adminLockPopupVisibility
    }

    toggleAdminUnlockPopup(visibility?: boolean) {
        this.adminUnlockPopupVisibility = arguments.length ? visibility! : !this.adminUnlockPopupVisibility
    }

    toggleResetPasswordPopup(visibility?: boolean) {
        this.resetPasswordPopupVisibility = arguments.length ? visibility! : !this.resetPasswordPopupVisibility
    }

    async lock() {
        const { adminId } = this

        await actions.lockAdmin(adminId)

        const data = store.admin.get(adminId)!

        runInAction(() => (data.isBlocked = true))

        this.toggleAdminLockPopup(false)
    }

    async unlock() {
        const { adminId } = this

        await actions.unlockAdmin(adminId)

        const data = store.admin.get(adminId)!

        runInAction(() => (data.isBlocked = false))

        this.toggleAdminUnlockPopup(false)
    }

    async resetPassword() {
        await actions.resetAdminPassword(this.adminId)

        this.toggleResetPasswordPopup(false)
    }

    render() {
        const { adminId } = this

        if (!store.admin.isResolved(adminId) || !store.reviews.admin.isResolved(adminId)) {
            return <Loader />
        }

        const admin = store.admin.get(adminId)!
        const { reviews, length } = store.reviews.admin.get(adminId)!
        const onClickLockButton = () => this.toggleAdminLockPopup()
        const onClickUnlockButton = () => this.toggleAdminUnlockPopup()
        const onClickResetPasswordButton = () => this.toggleResetPasswordPopup()

        return (
            <Component>
                {this.resetPasswordPopupVisibility && (
                    <ResetPasswordPopup
                        onConfirm={() => this.resetPassword()}
                        onClose={() => this.toggleResetPasswordPopup(false)}
                    />
                )}
                <Breadcrumbs>
                    <Link to="/admins">Администраторы</Link>
                    <Link to={`/admins/${adminId}`}>ID{adminId}</Link>
                    <span>Отзывы</span>
                </Breadcrumbs>
                <Admin>
                    <Avatar src={avatarUrl(admin.avatar)} />
                    <Name>
                        {admin.lastname} {admin.firstname} {admin.patronymic}
                    </Name>
                    <Label type={admin.type} />
                    <Spacer />
                    <Options label="Опции">
                        <Link to={`/admins/edit/${adminId}`}>Редактировать</Link>
                        <button onClick={onClickResetPasswordButton}>Сбросить пароль</button>
                        {admin.isBlocked ? (
                            <UnlockButton onClick={onClickUnlockButton}>Разблокировать</UnlockButton>
                        ) : (
                            <LockButton onClick={onClickLockButton}>Заблокировать</LockButton>
                        )}
                    </Options>
                    <Button to={`/support/create/admin/${admin.id}`}>+ Создать тикет</Button>
                </Admin>
                <Title>
                    <b>Отзывы</b>
                    <span>{length}</span>
                </Title>
                {reviews.map((data) => (
                    <Review key={data.id} data={data} />
                ))}
            </Component>
        )
    }
}
