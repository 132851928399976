import { api } from 'api'
import { observable } from 'mobx'

export * as accountBalance from './address_account_balance'

export const STORE = observable.map<number, AddressData>()

export const resolve = async (id: number) => {
    const { address } = await api.address.get(id)
    STORE.set(id, address)
}

export const isResolved = (id: number) => {
    return STORE.has(id)
}

export const get = (id: number) => {
    return STORE.get(id)
}

export interface AddressData {
    id: number
    full: string
    short: string
    fiasId: string
    postalCode: string
    region: string
    city: string
    street: string
    house: string
    flat: string
    countClients: number
    countAccounts: number
    countSales: number
    dadata: Dadata
    accounts: AddressAccountData[]
}

export interface AddressAccountData {
    id: number
    number: string
    provider: Provider
    isClosed: boolean
    fio: string
    lastImportAt: string
    balance: number
    penalty: number
    counters: Counter[]
}

interface Counter {
    id: number
    meters: Meter[]
    counterTypeId: number
    counterType: CounterType
    number: string
    installDate: string
    removeDate: string
    isRemove: boolean
    createdAt: string
    updatedAt: string
}

interface CounterType {
    id: number
    name: string
    measure: string
    format: string
}

interface Meter {
    id: number
    source: 'user' | 'provider'
    value: number
    createdAt: string
    updatedAt: string
}

interface Provider {
    id: number
    name: string
    shopCode: string
    createdAt: string
    partnerUserId: number
    partnerUser: PartnerUser
    rate: number
    countReviews: number
    countAccounts: number
    countActiveAccounts: number
    balance: number
    subscriptionFee: number
    shortLegalEntity: ShortLegalEntity
    email: string
}

interface ShortLegalEntity {
    inn: string
    name: string
}

interface PartnerUser {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}

interface Dadata {}
