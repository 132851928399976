import { withRouter, match } from 'react-router'
import { CabinetContext } from 'components/app/cabinet/context'
import { Link } from 'react-router-dom'
import { Common } from './common'
import { Stats } from './stats'
import { Objects } from './objects'
import { Component, Breadcrumbs } from './info.styled'
import { PureComponent } from 'react'
import { observer } from 'mobx-react'
import { store } from 'store'
import { Loader } from 'components/ui'

type InfoProps = { match?: match<{ id: string }> }

@(withRouter as any)
@observer
export class Info extends PureComponent<InfoProps> {
    static contextType = CabinetContext

    componentDidMount() {
        this.context.setTitle('Данные профиля')
        store.customer.resolve(this.customerId)
    }

    get customerId() {
        return parseInt(this.props.match!.params.id)
    }

    render() {
        const { customerId } = this

        if (!store.customer.isResolved(customerId)) {
            return <Loader />
        }

        return (
            <Component>
                <Breadcrumbs>
                    <Link to="/customers">Абоненты</Link>
                    <span>ID{customerId}</span>
                </Breadcrumbs>
                <Common id={customerId} />
                <Stats id={customerId} />
                <Objects id={customerId} />
            </Component>
        )
    }
}
