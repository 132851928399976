import styled, { css } from 'styled-components'
import calendarIcon from './images/calendar.svg'

export const Component = styled.div`
    border: 1px solid #eaecf0;
    border-radius: 4px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    height: 44px;
    position: relative;
    width: 250px;
    user-select: none;
    background-color: #ffffff;
    outline: none;
`

export const Arrow = styled.div<{ icon: string; substrate?: boolean }>`
    width: 24px;
    height: 24px;
    margin: 10px;
    cursor: pointer;
    background-image: url(${(props) => props.icon});
    background-position: center;
    background-repeat: no-repeat;
    background-color: ${(props) => (props.substrate ? '#F7F8FC' : 'none ')};
    border-radius: 6px;
    transition: background-color 0.1s ease;

    :hover {
        background-color: #e2e5ef;
    }
`

export const CurrentValue = styled.div`
    background-image: url(${calendarIcon});
    background-position: center left;
    background-repeat: no-repeat;
    padding-left: 30px;
    height: 30px;
    line-height: 33px;
    margin-left: -10px;
    cursor: pointer;
`

export const Bubble = styled.div<{ visible: boolean }>`
    width: 250px;
    height: 206px;
    display: flex;
    flex-direction: column;
    position: absolute;
    z-index: 10;
    top: calc(100% + 10px);
    margin-top: 5px;
    opacity: 0;
    visibility: hidden;
    background: #ffffff;
    box-shadow: 0px 2px 14px rgba(133, 140, 166, 0.236724);
    border-radius: 4px;
    transition: margin-top 0.3s ease, opacity 0.3s ease, visibility 0s 0.3s;

    ${(props) =>
        props.visible &&
        css`
            opacity: 1;
            visibility: visible;
            margin-top: 0;
            transition: margin-top 0.3s ease, opacity 0.3s ease, visibility 0s;
        `}
`

export const SelectYear = styled.div`
    display: flex;
    align-items: center;
    margin-top: 5px;
`

export const Year = styled.div`
    flex: 1;
    font-size: 18px;
    line-height: 150.7%;
    text-align: center;
    color: #68c376;
`

export const SelectMonth = styled.div`
    flex: 1;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    grid-template-rows: 1fr 1fr 1fr 1fr;
    padding: 0 30px 15px;
`

export const Month = styled.div<{ selected: boolean }>`
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #39434b;
    cursor: pointer;

    ${(props) =>
        props.selected &&
        css`
            background: #ebfaed;
            border-radius: 50px;
        `}
`
