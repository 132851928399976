import styled from 'styled-components'
import arrowIcon from './images/arrow.svg'

export const Label = styled.div`
    height: 37px;
    left: 843px;
    border: 1px solid #b7bcd6;
    box-sizing: border-box;
    border-radius: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    background-image: url(${arrowIcon});
    background-position: calc(100% - 5px) center;
    background-repeat: no-repeat;
    padding-left: 26px;
    padding-right: 35px;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    text-align: center;
    color: #707b8e;
    transition: border-color 0.2s ease;
`

export const Bubble = styled.div`
    padding: 6px 0;
    position: absolute;
    top: 100%;
    right: 0;
    background: #f7f8fc;
    box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
    border-radius: 6px;
    visibility: hidden;
    margin-top: 5px;
    opacity: 0;
    transition: margin-top 0.3s 0.1s ease, opacity 0.3s 0.1s ease, visibility 0s 0.4s ease;

    a,
    button {
        display: flex;
        align-items: center;
        padding: 0 20px;
        background-color: transparent;
        transition: background-color 0.2s ease;
        font-size: 16px;
        line-height: 127.3%;
        color: #707b8e;
        white-space: nowrap;
        height: 40px;
        text-decoration: none;
        border: none;
        outline: none;
        width: 100%;
        cursor: pointer;

        :hover {
            background-color: #eaecf0;
        }
    }
`

export const Component = styled.div`
    position: relative;

    :hover {
        ${Bubble} {
            visibility: visible;
            margin-top: 2px;
            opacity: 1;
            transition: margin-top 0.3s ease, opacity 0.3s ease, visibility 0s ease;
        }

        ${Label} {
            border-color: #a9b6ff;
        }
    }
`
