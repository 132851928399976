import styled, { css } from 'styled-components'

export const Button = styled.button<{ small?: boolean; secondary?: boolean; width?: number; red?: boolean }>`
    padding: ${(props) => (props.small ? '0 11px' : '0 28px;')};
    height: ${(props) => (props.small ? '37px' : '51px;')};
    width: ${(props) => (props.width ? `${props.width}px` : 'unset')};
    border: 1px solid transparent;
    border-radius: 8px;
    font-weight: 500;
    font-size: 18px;
    outline: none;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    white-space: nowrap;
    position: relative;

    ${(props) =>
        props.red
            ? css`
                  color: #ffffff;
                  background-color: #eb5757;

                  :hover {
                      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
                  }

                  :active {
                      box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
                  }

                  :disabled {
                      opacity: 0.5;
                      pointer-events: none;
                  }
              `
            : props.secondary
            ? css`
                  color: #707b8e;
                  background-color: white;
                  border-color: #707b8e;

                  :hover {
                      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
                  }

                  :active {
                      box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
                  }

                  :disabled {
                      opacity: 0.5;
                      pointer-events: none;
                  }
              `
            : css`
                  color: #ffffff;
                  background-color: #68c376;

                  :hover {
                      background: #68c376;
                      box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.14);
                  }

                  :active {
                      background-color: #68c376;
                      box-shadow: inset 0px 4px 10px rgba(0, 0, 0, 0.17);
                  }

                  :disabled {
                      background-color: #68c376;
                      opacity: 0.5;
                      pointer-events: none;
                  }
              `}
`
