import { PureComponent } from 'react'
import { CabinetContext } from 'components/app/cabinet/context'
import { EditData, Editor } from '../../editor'
import { withRouter } from 'react-router-dom'
import { actions } from 'actions'

@(withRouter as any)
export class Create extends PureComponent {
    static contextType = CabinetContext

    componentDidMount() {
        this.context.setTitle('Новый администратор')
    }

    async handleEditorSave(data: EditData) {
        const { id } = await actions.createAdmin(data)

        const props = this.props as any

        props.history.push(`/admins/${id}`)
    }

    render() {
        return <Editor mode="create" data={{}} onSave={(data) => this.handleEditorSave(data)} />
    }
}
