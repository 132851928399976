import { AddressAccountData } from 'store'
import { formatMoney } from 'utils'
import { Component, Thead, Row, Datetime, Balance } from './info.styled'

interface InfoProps {
    data: AddressAccountData
}

export const Info = (props: InfoProps) => {
    const { fio, lastImportAt, balance, penalty } = props.data
    const datetime = new Date(lastImportAt)

    return (
        <Component>
            <Thead>
                <span>Владелец</span>
                <span>Дата последнего импорта</span>
                <span>Баланс</span>
                <span>Пени</span>
            </Thead>
            <Row>
                <span>{fio}</span>
                <Datetime>
                    {lastImportAt ? (
                        <>
                            <span>{datetime.toLocaleDateString()}</span>
                            <span>{datetime.toLocaleTimeString()}</span>
                        </>
                    ) : (
                        '—'
                    )}
                </Datetime>
                <Balance isPositive={balance > 0}>{formatMoney(balance)}</Balance>
                <span>{formatMoney(penalty)}</span>
                {/* <Button to="/support/create">+ Создать тикет</Button> */}
            </Row>
        </Component>
    )
}
