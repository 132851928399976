import { PureComponent } from 'react'
import { CabinetContext } from 'components/app/cabinet/context'
import { Graph } from './graph'
import { Component, Head, Column, PaymentComponent, PaymentDate, PaymentInfo } from './default.styled'
import { store, PaymentData } from 'store'
import { Loader } from 'components/ui'
import { formatMoney, formatPhone } from 'utils'
import { observer } from 'mobx-react'
import { createScrollAutoloader } from 'utils'

@observer
export class Default extends PureComponent {
    static contextType = CabinetContext

    private detachScrollListener!: () => void

    componentDidMount() {
        this.context.setTitle('История оплат')
        store.payments.resolve()
        this.detachScrollListener = createScrollAutoloader(store.payments.resolveMore)
    }

    componentWillUnmount() {
        this.detachScrollListener()
    }

    render() {
        if (!store.payments.isResolved()) {
            return <Loader />
        }

        let lastPaymentDate: string

        const { payments } = store.payments.get()

        return (
            <Component>
                <Graph />
                <Head>
                    <Column flex="0 0 110px">Дата</Column>
                    <Column flex="0 0 160px">Плательщик</Column>
                    <Column flex="0 0 190px"></Column>
                    <Column flex="1">Способ оплаты</Column>
                    <Column flex="0 0 130px" alignRight>
                        Эквайринг
                    </Column>
                    <Column flex="0 0 130px" alignRight>
                        Комиссия
                    </Column>
                    <Column flex="0 0 130px" alignRight>
                        Сумма
                    </Column>
                </Head>
                {payments.map((payment) => {
                    const paymentDate = new Date(payment.createdAt).toLocaleDateString()
                    const date =
                        !lastPaymentDate || lastPaymentDate !== paymentDate ? (lastPaymentDate = paymentDate) : ''

                    return <Payment key={payment.id} data={payment} dateString={date} />
                })}
            </Component>
        )
    }
}

interface PaymentProps {
    data: PaymentData
    dateString: string
}

const Payment = (props: PaymentProps) => {
    const { data, dateString } = props
    const { createdAt, client, type, amount, serviceFee, bankFee } = data
    const time = new Date(createdAt).toLocaleTimeString()

    return (
        <PaymentComponent>
            <PaymentDate>{dateString}</PaymentDate>
            <PaymentInfo to={`/payings/history/${client.id}`}>
                <Column flex="0 0 110px">{time}</Column>
                <Column flex="0 0 160px">
                    {client.last} {client.first ? `${client.first[0].toUpperCase()}.` : ''}
                    {client.patronymic ? `${client.patronymic[0].toUpperCase()}.` : ''}
                </Column>
                <Column flex="0 0 190px">{formatPhone(client.phone)}</Column>
                <Column flex="1">{paymentTypes[type]}</Column>
                <Column flex="0 0 130px" alignRight>
                    {formatMoney(bankFee)}
                </Column>
                <Column flex="0 0 130px" alignRight>
                    {formatMoney(serviceFee)}
                </Column>
                <Column flex="0 0 130px" alignRight>
                    {formatMoney(amount)}
                </Column>
            </PaymentInfo>
        </PaymentComponent>
    )
}

const paymentTypes = {
    apple: 'Apple pay',
    google: 'Google pay',
    card: 'Оплата картой',
}
