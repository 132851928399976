import styled from 'styled-components'
import trashIcon from './images/trash.svg'
import { Label as UILabel } from 'components/ui'

export const Component = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    border-radius: 10px;
    margin-bottom: 10px;
    padding: 20px 20px 20px 94px;
    position: relative;
`

export const Avatar = styled.div<{ src: string }>`
    top: 20px;
    left: 30px;
    width: 48px;
    height: 48px;
    position: absolute;
    background-image: url(${(props) => props.src});
    background-position: center;
    background-size: 48px;
    background-repeat: no-repeat;
    border-radius: 50%;
`

export const Head = styled.div`
    display: flex;
    align-items: center;
    border-bottom: 1px solid #eaecf0;
    padding: 8px 0 16px;
`

export const Name = styled.div`
    font-size: 18px;
    line-height: 21px;
    color: #39434b;
    font-weight: 500;
`

export const Label = styled(UILabel)``

export const Spacer = styled.div`
    flex: 1;
`

export const DateAndStars = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`

export const Datetime = styled.div`
    font-size: 14px;
    line-height: 20px;
    margin-top: -12px;
    margin-bottom: 9px;

    span:nth-child(1) {
        color: #39434b;
    }
    span:nth-child(2) {
        margin-left: 6px;
        color: #b7bcd6;
    }
`

export const Text = styled.div`
    font-size: 16px;
    line-height: 127.3%;
    color: #707b8e;
    padding: 14px 0 20px;
`

export const DeleteButton = styled.button`
    background-color: transparent;
    background-image: url(${trashIcon});
    background-position: 0px center;
    background-size: 15px;
    background-repeat: no-repeat;
    padding-left: 22px;
    padding-top: 5px;
    outline: none;
    border: none;
    position: absolute;
    bottom: 15px;
    right: 20px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #707b8e;
    cursor: pointer;
`
