import styled from 'styled-components'

export const Params = styled.div`
    display: grid;
    grid-row-gap: 12px;
    grid-template-columns: 160px 1fr;
    align-items: center;
    margin: 40px 0 15px 137px;
`

export const Param = styled.div`
    font-size: 14px;
    line-height: 20px;
    letter-spacing: -0.24px;
    color: #707b8e;
    width: 160px;
`

export const Value = styled.div`
    font-size: 18px;
    line-height: 150.7%;
    color: #39434b;
    margin-right: 30px;

    b {
        font-weight: 600;
    }
`

export const Activity = styled.div`
    border-top: 1px solid #eaecf0;
    display: grid;
    grid-row-gap: 12px;
    grid-template-columns: 160px 1fr;
    align-items: center;
    margin: 20px 0 15px 137px;
    padding-top: 20px;
`
