import styled, { css } from 'styled-components'
import { Dot as UIDot } from 'components/ui'
import ArrowIcon from './images/arrow.svg'

export const Component = styled.div`
    background: #ffffff;
    border: 1px solid #eaecf0;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 24px 28px 24px 28px;
    position: relative;
`

export const Dot = styled(UIDot)`
    position: absolute;
    left: 24px;
    top: 23px;
`

export const Face = styled.div`
    display: flex;
    align-items: center;
    column-gap: 20px;
`

export const Info = styled.div`
    flex: 1;
`

export const Title = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 21px;
    color: #111111;
    margin-bottom: 12px;
    padding-left: 20px;
`

export const Flex = styled.div`
    display: flex;
    align-items: center;
    column-gap: 34px;
`

export const Datetime = styled.div`
    font-size: 16px;
    line-height: 20px;
    margin-right: 34px;

    & > span:nth-child(1) {
        color: #39434b;
    }
    & > span:nth-child(2) {
        margin-left: 6px;
        color: #b7bcd6;
    }
`

export const Views = styled.div`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 127.3%;

    & > span:nth-child(1) {
        color: #b7bcd6;
    }
    & > b:nth-child(2) {
        margin-left: 6px;
        color: #111111;
    }
`

export const User = styled.div<{ avatar: string }>`
    font-family: Roboto;
    font-style: normal;
    font-weight: normal;
    font-size: 16px;
    line-height: 127.3%;
    color: #68c376;
    display: flex;
    align-items: center;

    :before {
        content: '';
        width: 24px;
        height: 24px;
        display: block;
        background-image: url(${(props) => props.avatar});
        background-size: cover;
        background-repeat: no-repeat;
        background-position: center;
        border-radius: 50%;
        margin-right: 10px;
    }
`

export const Arrow = styled.div<{ turned: boolean }>`
    width: 24px;
    height: 24px;
    background-image: url(${ArrowIcon});
    background-size: 24px;
    background-repeat: no-repeat;
    background-position: center;
    cursor: pointer;
    transform: rotate(${(props) => (props.turned ? '180deg' : '0deg')});
    transition: transform 0.2s ease;
`

export const Button = styled.button`
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #b7bcd6;
    box-sizing: border-box;
    border-radius: 8px;
    font-family: Roboto;
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 16px;
    color: #707b8e;
    padding: 0 25px;
    height: 36px;
    margin-left: 15px;
    text-decoration: none;
    outline: none;
    background-color: transparent;
    cursor: pointer;
`

export const Content = styled.div<{ collapsed: boolean }>`
    overflow: hidden;
    transition: max-height 0.2s ease, opacity 0.2s ease;

    ${(props) =>
        props.collapsed
            ? css`
                  max-height: 0;
                  opacity: 0;
              `
            : css`
                  max-height: 1000px;
                  opacity: 1;
              `}
`

export const ContentFlex = styled.div`
    display: flex;
    column-gap: 50px;
    border-top: 1px solid #eaecf0;
    border-bottom: 1px solid #eaecf0;
    padding: 20px 0;
    margin: 25px 0;
`

export const Details = styled.div`
    margin-top: 16px;
    flex: 1;
    display: flex;
    flex-direction: column;
    row-gap: 12px;

    & > div {
        display: flex;
        column-gap: 20px;

        & > div:nth-child(1) {
            flex: 0 0 140px;
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 127.3%;
            color: #707b8e;
        }
        & > div:nth-child(2) {
            font-family: Roboto;
            font-style: normal;
            font-weight: normal;
            font-size: 16px;
            line-height: 127.3%;
            color: #111111;
        }
    }
`
