import { PureComponent } from 'react'
import { Component, Counter, Head, Chart, Space, ChartTooltip } from './graph.styled'
import { DatePicker, DatePickerValue } from 'components/ui'
import { CartesianGrid, XAxis, YAxis, ResponsiveContainer, Tooltip, Area, AreaChart } from 'recharts'
import { observer } from 'mobx-react'
import { store } from 'store'
import { formatMoney } from 'utils'
import { action } from 'mobx'

@observer
export class Graph extends PureComponent {
    @action
    handleDateChange(value: DatePickerValue) {
        store.payments.setParams(value)
    }

    render() {
        const { chart, total } = store.payments.get()
        const { month, year } = store.dashboard.getParams()
        const data = chart.payment.current.map(({ amount }, i) => ({ name: i + 1, amount }))

        return (
            <Component>
                <Head>
                    <DatePicker month={month} year={year} onChange={(v) => this.handleDateChange(v)} />
                    <Space />
                    <Counter>
                        <span>Всего за месяц</span>
                        <b>{formatMoney(total)}</b>
                    </Counter>
                </Head>
                <Chart>
                    <ResponsiveContainer>
                        <AreaChart data={data}>
                            <CartesianGrid vertical={false} strokeDasharray="5 5" />
                            <XAxis tickLine={false} axisLine={false} tick={<AxisXTick />} dataKey="name" />
                            <YAxis tickLine={false} axisLine={false} tick={<AxisYTick />} />
                            <Tooltip content={<CustomTooltip />} />
                            <Area type="monotone" dataKey="amount" stroke="#68C376" fill="#D6EFDA" />
                        </AreaChart>
                    </ResponsiveContainer>
                </Chart>
            </Component>
        )
    }
}

type AxisTickProps = {
    x?: number
    y?: number
    payload?: any
}

const AxisXTick = (props: AxisTickProps) => {
    const { x, y, payload } = props

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={25} dx={0} fontSize="14px" fontFamily="Roboto" textAnchor="middle" fill="#707B8E">
                {payload.value}
            </text>
        </g>
    )
}

const AxisYTick = (props: AxisTickProps) => {
    const { x, y, payload } = props

    return (
        <g transform={`translate(${x},${y})`}>
            <text x={0} y={0} dy={5} dx={-55} fontSize="14px" fontFamily="Roboto" textAnchor="start" fill="#707B8E">
                {payload.value}
            </text>
        </g>
    )
}

const currency = Intl.NumberFormat('ru', { style: 'currency', currency: 'RUB', minimumFractionDigits: 2 })

interface CustomTooltipProps {
    active?: boolean
    payload?: { name: string; value: number }[]
}

const CustomTooltip = (props: CustomTooltipProps) => {
    const { active, payload } = props

    if (!active) {
        return null
    }

    return (
        <ChartTooltip>
            <div>{currency.format(payload![0].value)}</div>
            <div>Оборот</div>
        </ChartTooltip>
    )
}
