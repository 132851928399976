import styled from 'styled-components'

export const Component = styled.div``

export const Breadcrumbs = styled.div`
    display: flex;
    align-items: center;
    margin-bottom: 20px;

    a,
    span {
        :not(:last-child) {
            font-size: 16px;
            line-height: 127.3%;
            color: #707b8e;
            text-decoration: none;
            transition: color 0.2s ease;

            :after {
                content: '•';
                margin: 0 8px;
                text-decoration: none;
            }
        }
        :last-child {
            font-size: 18px;
            line-height: 21px;
            color: #68c376;
            font-weight: 500;
        }
    }

    a:hover {
        color: #4e5a6f;
    }
`

export const Title = styled.div`
    margin-top: 20px;
    margin-bottom: 20px;

    b {
        font-weight: bold;
        font-size: 24px;
        line-height: 124.7%;
        color: #39434b;
    }
    span {
        font-weight: bold;
        font-size: 24px;
        line-height: 124.7%;
        color: #b7bcd6;
        margin-left: 12px;
    }
`
