import styled from 'styled-components'

export const Component = styled.div`
    display: flex;
    justify-content: space-between;
    margin-top: 26px;
`

const chartBlockColors = {
    blue: '#A9B6FF',
    green: '#68C376',
    yellow: '#E8A944',
}

export const ChartBlock = styled.div<{ color: 'blue' | 'green' | 'yellow' }>`
    flex: 0 0 calc((100% - 54px) / 4);
    background: #ffffff;
    background-color: white;
    border: 1px solid #eaecf0;
    box-shadow: 0px 0px 20px rgba(40, 48, 77, 0.05);
    border-radius: 8px;
    padding: 17px 90px 20px 20px;
    position: relative;
    border-top: 8px solid #b7bcd6;

    :after {
        content: '';
        display: block;
        position: absolute;
        top: 20px;
        right: 0;
        bottom: 0;
        width: 90px;
        background-color: ${(props) => chartBlockColors[props.color]};
        mask: url(${require(`./images/chart.svg`).default}) no-repeat top center;
    }
`

export const Amount = styled.div`
    font-weight: bold;
    font-size: 24px;
    line-height: 124.7%;
    color: #111111;
`

export const Desk = styled.div`
    font-size: 16px;
    line-height: 127.3%;
    color: #47505a;
    margin-top: 5px;
`

export const MobileBlock = styled.div`
    flex: 0 0 calc((100% - 54px) / 4);
    background: #ffffff;
    background-color: white;
    border: 1px solid #eaecf0;
    box-shadow: 0px 0px 20px rgba(40, 48, 77, 0.05);
    border-radius: 8px;
    padding: 17px 20px 20px;
    position: relative;
    border-top: 8px solid #b7bcd6;
`

export const Counters = styled.div`
    display: flex;
    justify-content: space-between;
    margin-bottom: 18px;
`

export const Counter = styled.div<{ type: 'ios' | 'android' }>`
    flex: 0 0 calc((100% - 8px) / 2);
    justify-content: space-between;
    background-image: url(${(props) => require(`./images/${props.type}.svg`).default});
    background-position: 2px center;
    background-repeat: no-repeat;
    background-color: #eeeeee;
    padding-left: 38px;
    font-weight: bold;
    font-size: 24px;
    color: #111111;
    height: 36px;
    line-height: 36px;
    border-radius: 18px;
`
