import { request } from './request'

export const list = (query: string) => {
    return request<ListResponse>('sale/list', { query })
}

interface ListResponse {
    sales: Sale[]
}

interface Sale {
    title: string
}
