import { request } from './request'

export const list = (
    limit: number,
    offset: number,
    region: number,
    kind: 'all' | ProviderKind,
    orderBy: OrderType,
    query: string
) => {
    return request<ListResponse>('provider/list', { limit, offset, region, kind, orderBy, query })
}

export const get = (id: number) => {
    return request<GetResponse>('provider/get', { id })
}

export const enter = (id: number) => {
    return request<EnterResponse>('provider/enter', { id })
}

export const enterCheck = (id: number, code: string) => {
    return request<EnterCheckResponse>('provider/enter/check', { id, code })
}

export const invite = (email: string, inn: string) => {
    return request<InviteResponse>('provider/invite', { email, inn })
}

export const block = (id: number) => {
    return request<BlockResponse>('provider/block', { id })
}

export const unblock = (id: number) => {
    return request<UnblockResponse>('provider/unblock', { id })
}

export const remove = (id: number) => {
    return request<RemoveResponse>('provider/delete', { id })
}

type OrderType = 'name' | 'balance' | 'subscriptionFee' | 'createdAt' | 'rate'

type ProviderKind = 'valid' | 'novalid' | 'isSpec' | 'drafts' | 'block'

interface ListResponse {
    total: number
    chart: Chart
    providers: Provider[]
    length: number
}

interface GetResponse {
    provider: ProviderFull
}

interface EnterResponse {}

interface EnterCheckResponse {
    link: string
}

interface InviteResponse {}

interface BlockResponse {}

interface UnblockResponse {}

interface RemoveResponse {}

interface ProviderFull {
    id: number
    name: string
    short: string
    shopCode: string
    createdAt: string
    partnerUserId: number
    partnerUser: PartnerUser
    rate: number
    countReviews: number
    countAccounts: number
    countActiveAccounts: number
    balance: number
    subscriptionFee: number
    shortLegalEntity: ShortLegalEntity
    email: string
    encode: string
    service: string
    logo: string
    phone: string
    siteUrl: string
    lastChangePassword: string
    legalEntity: LegalEntity
    emails: Email[]
    isBlocked: boolean
    kind: ProviderKind
}

interface Email {
    id: number
    email: string
    type: string
    state: string
}

interface LegalEntity {
    inn: string
    name: string
    id: number
    fullName: string
    kpp: string
    ogrn: string
    bankName: string
    bankAddress: string
    bik: string
    bankAccount: string
    taxType: string
    account: string
    details: string
    firstName: string
    lastName: string
    middleName: string
    birthDate: string
    phone: string
    state: string
}

interface Provider {
    id: number
    name: string
    logo: string
    short: string
    shopCode: string
    createdAt: string
    partnerUserId: number
    partnerUser: PartnerUser
    rate: number
    countReviews: number
    countAccounts: number
    countActiveAccounts: number
    balance: number
    subscriptionFee: number
    shortLegalEntity: ShortLegalEntity
    email: string
    isBlocked: boolean
    phone: string
    kind: ProviderKind
}

interface ShortLegalEntity {
    inn: string
    name: string
}

interface PartnerUser {
    id: number
    type: string
    username: string
    email: string
    phone: string
    avatar: string
    lastname: string
    firstname: string
    patronymic: string
    isNeedSMSValidation: boolean
    isNeedEmailValidation: boolean
    isNeedLegalValidation: boolean
    rate: number
    countReviews: number
    countTickets: number
}

interface Chart {
    current: Current[]
}

interface Current {
    month: number
    count: number
}
